import { useState, useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { getTemplateBoardsApi, getTemplateBoardApi } from 'apis/templateBoard';

import QuillEditor from 'components/editor/QuillEditor';

let selectedId = -1;

function HistoryTemplateDialog({ isOpen, selectedTemplate, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const [bltTemplates, setBltTemplates] = useState(null);
  const [bltTemplate, setBltTemplate] = useState(null);

  const getTemplateBoards = async () => {
    let params = {};
    params.canvas_type = 'L';
    params.blt_type = '2';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };

    const result = await getTemplateBoardsApi(params);
    setBltTemplates(result.data.sort((a, b) => a.order_num - b.order_num));
  };

  const getTemplateBoard = async id => {
    const result = await getTemplateBoardApi(id);
    setBltTemplate(result.data);
  };

  const handleSelectedId = id => {
    selectedId = id;
    getTemplateBoard(id);
  };

  const handleSelectedTemplate = () => {
    selectedTemplate(bltTemplate);
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getTemplateBoards();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-template-01" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">자서전 템플릿</h2>
                  <p className="modal__desc">
                    템플릿을 선택하시면 아래부분에서
                    <br />
                    확인하실 수 있습니다.
                  </p>
                  <button
                    className="modal__close"
                    aria-label="Close modal"
                    data-micromodal-close
                    onClick={onClose}
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  {/* S : 자서전 템플릿 */}
                  <div className="js-tab">
                    <ul className="template-list">
                      {bltTemplates && bltTemplates.length > 0
                        ? bltTemplates.map(bltTemplate => (
                            <li
                              key={bltTemplate.id}
                              className={bltTemplate.id === selectedId ? 'is-active' : ''}
                            >
                              <button
                                type="button"
                                data-target="tab-content11"
                                onClick={() => handleSelectedId(bltTemplate.id)}
                              >
                                {bltTemplate.blt_title}
                              </button>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                  {bltTemplate ? (
                    <div className="tab-contents history-template">
                      <div id="tab-content11" className="tab-content is-active">
                        {/* <div style={{ height: '350px', background: 'rgba(249,99,99,.3)' }}> */}
                        <div style={{ height: '350px' }}>
                          <QuillEditor value={bltTemplate.blt_contents} viewerMode />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* E : 자서전 템플릿 */}
                </div>

                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn-blue"
                    data-micromodal-close
                    onClick={() => handleSelectedTemplate()}
                  >
                    선택
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-micromodal-close
                    onClick={onClose}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default HistoryTemplateDialog;

import { useState, useEffect, forwardRef } from 'react';
import { Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';

import useLayout from 'stores/layout/hook';

import { getTemplateBoardsApi, getTemplateBoardApi } from 'apis/templateBoard';

import QuillEditor from 'components/editor/QuillEditor';

function BltTemplateDialog({ isOpen, onClose, selectedTemplate }) {
  //const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const [selectedId, setSelectedId] = useState(-1);
  const [bltTemplates, setBltTemplates] = useState(null);
  const [bltTemplate, setBltTemplate] = useState(null);

  const getTemplateBoards = async () => {
    let params = {};
    params.canvas_type = 'M';
    params.blt_type = '1';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };

    const result = await getTemplateBoardsApi(params);
    setBltTemplates(result.data);
  };

  const getTemplateBoard = async id => {
    const result = await getTemplateBoardApi(id);
    setBltTemplate(result.data);
  };

  const handleSelectedId = id => {
    setSelectedId(id);
  };

  const handleSelectedTemplate = () => {
    selectedTemplate(bltTemplate);
  };

  useEffect(() => {
    getTemplateBoard(selectedId);
  }, [selectedId]);

  useEffect(() => {
    getTemplateBoards();
  }, []);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-obituary-10" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">일대기 템플릿 선택</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <p className="title-desc">원하시는 템플릿을 선택하세요.</p>
                <ul className="rc-list">
                  {bltTemplates && bltTemplates.length > 0
                    ? bltTemplates.map(bltTemplate => (
                        <li>
                          <span
                            className="rc-item"
                            key={bltTemplate.id}
                            onClick={() => handleSelectedId(bltTemplate.id)}
                          >
                            <input
                              type="radio"
                              name="rdo-template-1"
                              checked={bltTemplate.id === selectedId ? true : false}
                            />
                            <label>{bltTemplate.blt_title}</label>
                          </span>
                        </li>
                      ))
                    : null}
                </ul>

                {bltTemplate ? (
                  <>
                    <div className="box-template">
                      {/* <div style={{ height: '250px', background: 'rgba(249,99,99,.3)' }}>content</div> */}
                      <QuillEditor value={bltTemplate.blt_contents} viewerMode />
                    </div>
                  </>
                ) : null}
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close
                  onClick={() => handleSelectedTemplate()}
                >
                  선택
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close
                  onClick={onClose}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default BltTemplateDialog;

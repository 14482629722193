import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useScript from 'utils/useScript';
import configs from '@configs/configs';

function KiccMobile({ wreathId, memorial_id, getValues }) {
  const formRef = useRef();
  const navigate = useNavigate();

  const status = useScript(configs.KICC_MOBILE_SCRIPT);

  const KICC_MOBILE_ACTION_URL = configs.KICC_MOBILE_ACTION_URL;
  const SP_MALL_ID = '05571685';
  const SP_MALL_NM = encodeURIComponent('(주)엠서클_추모관');
  const SP_ORDER_NO = wreathId;
  const SP_MOBILE_RETURN_URL = `${configs.KICC_MOBILE_RETURN_URL}?wreath_id=${wreathId}`;
  const SP_PAY_TYPE = getValues('pay_type');
  const SP_USER_ID = getValues('register_id');
  const SP_USER_NM = encodeURIComponent(getValues('register'));

  let SP_PRODUCT_NM = encodeURIComponent('디지털화환 일반');
  let SP_PRODUCT_AMT = 30000;

  if (getValues('type') === 'N') {
    SP_PRODUCT_NM = encodeURIComponent('디지털화환 일반');
    SP_PRODUCT_AMT = 30000;
  } else if (getValues('type') === 'R') {
    SP_PRODUCT_NM = encodeURIComponent('디지털화환 고급');
    SP_PRODUCT_AMT = 50000;
  } else if (getValues('type') === 'P') {
    SP_PRODUCT_NM = encodeURIComponent('디지털화환 프리미엄');
    SP_PRODUCT_AMT = 100000;
  }

  const formRefSubmit = () => {
    if (formRef && formRef.current) {
      formRef.current.submit();
    }
  };
  useEffect(() => {
    if (status === 'ready') {
      formRefSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div>
      <form name="frm" method="post" action={KICC_MOBILE_ACTION_URL} ref={formRef}>
        <input type="hidden" name="sp_return_url" value={SP_MOBILE_RETURN_URL} />
        <input type="hidden" name="sp_order_no" value={SP_ORDER_NO} />
        <input type="hidden" name="sp_mall_id" value={SP_MALL_ID} />
        <input type="hidden" name="sp_mall_nm" value={SP_MALL_NM} />
        <input type="hidden" name="sp_pay_type" value={SP_PAY_TYPE} />
        <input type="hidden" name="sp_user_id" value={SP_USER_ID} />
        <input type="hidden" name="sp_memb_user_no" value={SP_USER_ID} />
        <input type="hidden" name="sp_user_nm" value={SP_USER_NM} />
        <input type="hidden" name="sp_product_nm" value={SP_PRODUCT_NM} />
        <input type="hidden" name="sp_product_amt" value={SP_PRODUCT_AMT} />
        <input type="hidden" name="sp_product_type" value="1" />
        <input type="hidden" name="sp_window_type" value="submit" />
        <input type="hidden" name="sp_currency" value="00" />
        <input type="hidden" name="sp_lang_flag" value="KOR" />
        <input type="hidden" name="sp_charset" value="UTF-8" />
        <input type="hidden" name="sp_user_mail" value="" />
        <input type="hidden" name="card_nick_name" value="" />
        <input type="hidden" name="sp_user_phone1" value="" />
        <input type="hidden" name="sp_user_phone2" value="" />
        <input type="hidden" name="sp_user_addr" value="" />
        <input type="hidden" name="sp_app_scheme" value="" />
        <input type="hidden" name="sp_cert_type" value="" />
        <input type="hidden" name="sp_product_expr" value="" />
        <input type="hidden" name="sp_usedcard_code" value="029:031:045:047" />
        <input type="hidden" name="sp_res_cd" value="" />
        <input type="hidden" name="sp_res_msg" value="" />
        <input type="hidden" name="sp_tr_cd" value="" />
        <input type="hidden" name="sp_ret_pay_type" value="" />
        <input type="hidden" name="sp_trace_no" value="" />
        <input type="hidden" name="sp_sessionkey" value="" />
        <input type="hidden" name="sp_encrypt_data" value="" />
      </form>
    </div>
  );
}

export default KiccMobile;

import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchAllValue: '',
};

const searchAllSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchAllValueState: (state, action) => {
      state.searchAllValue = action.payload;
    },
  },
});

const { reducer, actions } = searchAllSlice;

export const { setSearchAllValueState } = actions;

export const searchAllState = state => {
  return state.search;
};

export const searchAllValueState = createSelector(searchAllState, state => {
  return state.searchAllValue;
});

export default reducer;

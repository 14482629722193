import { useEffect } from 'react';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import { changePasswordApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from '@mui/material/Dialog';

import 'css/popup.css';

function ChangePasswordDialog({ isOpen, onClose }) {
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    member_old_pwd: Yup.string().required('* 필수 입력 항목입니다.'),
    member_pwd: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
        '* 패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
      ),
    member_pwd_confirm: Yup.string().oneOf(
      [Yup.ref('member_pwd'), null],
      '* 비밀번호가 일치하지 않습니다',
    ),
  }).required();

  const defaultValues = {
    email: loginUser.email,
    member_old_pwd: '',
    member_pwd: '',
    member_pwd_confirm: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async params => {
    const result = await changePasswordApi(params);

    if (result.code === '0000') {
      handleClose();
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const onError = error => {
    console.log('error > ', error);
  };

  useEffect(() => {
    if (loginUser && loginUser.member_id) setValue('email', loginUser.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div class="modal" id="modal-remembrance-09" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title">비밀번호 초기화</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={handleClose}
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div class="modal__content">
                <div class="popup-form">
                  <div class="ip-item is-error">
                    <label for="user-pw" class="offscreen">
                      현재 비밀번호
                    </label>
                    <input
                      {...register('member_old_pwd')}
                      aria-invalid={errors.member_old_pwd ? 'true' : 'false'}
                      type="password"
                      id="user-pw"
                      placeholder="현재 비밀번호를 입력해 주세요"
                    />
                    <p class="message">{errors.member_old_pwd && errors.member_old_pwd.message}</p>
                  </div>
                  <div class="ip-item is-error">
                    <label for="user-pw2" class="offscreen">
                      새로운 비밀번호
                    </label>
                    <input
                      {...register('member_pwd')}
                      aria-invalid={errors.member_pwd ? 'true' : 'false'}
                      type="password"
                      id="user-pw2"
                      placeholder="최소 8자 이상 16자 이하 영문, 숫자, 특수기호 포함"
                    />
                    <p class="message">{errors.member_pwd && errors.member_pwd.message}</p>
                  </div>
                  <div class="ip-item is-error">
                    <label for="user-pw4" class="offscreen">
                      새로운 비밀번호 확인
                    </label>
                    <input
                      {...register('member_pwd_confirm')}
                      aria-invalid={errors.member_pwd_confirm ? 'true' : 'false'}
                      type="password"
                      id="user-pw4"
                      placeholder="새 비밀번호를 다시 한 번 입력해주세요"
                    />
                    <p class="message">
                      {errors.member_pwd_confirm && errors.member_pwd_confirm.message}
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal__footer">
                <button
                  type="button"
                  class="btn btn-blue"
                  data-micromodal-close
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  확인
                </button>
                <button
                  type="button"
                  class="btn btn-white"
                  data-micromodal-close
                  onClick={handleClose}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ChangePasswordDialog;

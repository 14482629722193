import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useLogin from 'stores/login/hook';

function LoginNaver() {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const { naverLogin } = useLogin();

  const loginNaver = async token => {
    const result = await naverLogin(token);
    console.log('result > ', result);
    // 이전 경로로 리디렉션
    const previous_path = localStorage.getItem('previous_path'); // 저장된 previous_path 확인
    if (previous_path) {
      navigate(previous_path); // 이전 경로로 이동
    } else {
      navigate('/pet'); // 기본 경로로 이동
    }
  };

  const getNaverToken = () => {
    if (!hash) return;
    const token = hash.split('=')[1].split('&')[0];
    loginNaver(token);
  };

  useEffect(() => {
    getNaverToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default LoginNaver;

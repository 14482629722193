import { combineReducers } from '@reduxjs/toolkit';

import dialog from './dialog';
import layout from './layout';
import code from './code';
import login from './login';
import search from './search';
import common from './common';

/**
 * Root Reducer 생성
 *
 * @param {(State | undefined)} [state={} as State]
 * @param {AnyAction} action
 * @return {*}  {State}
 */
const combineReducer = combineReducers({
  layout,
  dialog,
  code,
  login,
  search,
  common,
});

export default combineReducer;

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useLayout from 'stores/layout/hook';

import { uploadApi } from 'apis/openapi';
import { saveMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from 'utils/date';

import ChudosaSampleDialog from 'pages/life-canvas/settings/components/ChudosaSampleDialog';
import PreviewDialog from 'pages/memorial-canvas/obituary/components/PreviewDialog';
import Blts from './components/Blts';
import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';
import { fontSize } from '@mui/system';

function ChangeMyCanvas() {
  const navigate = useNavigate();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    // chief_mourner_desc: Yup.string().required('* 고인과의 관계를 선택하세요.'),
    // chief_mourner: Yup.string()
    //   .required('* 상주명을 입력하세요.')
    //   .max(50, '* 최대 입력은 20자까지 가능합니다.'),
    // chief_mourner_phone: Yup.string()
    //   .required('* 휴대폰 번호를 입력하세요.')
    //   .matches(
    //     '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
    //     '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
    //   ),
    deceased_name: Yup.string()
      .required('* 이름을 입력하세요.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    deceased_date: Yup.string().required('* 임종일자를 입력하세요.'),
    // deceased_hour: Yup.string().required('* 임종시간을 입력하세요.'),
    // birth_date: Yup.string().required('* 발인일자를 입력하세요.'),
    // birth_hour: Yup.string().required('* 발인시간을 입력하세요.'),
    // funeral_place: Yup.string().required('* 장지주소를 입력하세요.'),
    // funeral_addr: Yup.string()
    //  .required('* 등록된 장례식장 정보가 없습니다.')
    //  .max(100, '* 최대 입력은 100자까지 가능합니다.'),
    // relations_datas: Yup.array().of(
    //   Yup.object().shape({
    //     relations_name: Yup.string().required('* 상주명을 입력하세요.'),
    //     relations_detail: Yup.string().required('* 고인관계를 선택하세요.'),
    //   }),
    // ),
    // condolatory_datas: Yup.array().of(
    //   Yup.object().shape({
    //     applicant_name: Yup.string().required('* 이름을 입력하세요.'),
    //     bank_name: Yup.string().required('* 은행을 선택하세요.'),
    //     account_number: Yup.string().required('* 계좌번호를 입력하세요.'),
    //   }),
    // ),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 조문보 주제를 등록 하세요.')
          .max(20, '* 조문보 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string().required('* 조문보를 입력하세요.'),
      }),
    ),
    //deceased_introduction: Yup.string().required('* 안내글을 입력하세요.'),
    chudosa: Yup.string().required('* 추도사를 입력하세요.'),
  }).required();

  const defaultValues = {
    chief_mourner: '',
    chief_mourner_desc: '',
    chief_mourner_phone: '',
    digital_wreath_yn: 'Y',
    deceased_name: '',
    deceased_birthday: getDate(-30000),
    deceased_job_title: '',
    deceased_date: getDate(0),
    deceased_hour: '00:00',
    birth_date: getDate(3),
    birth_hour: '00:00',
    funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    deceased_photo: '',
    symbol_comment_yn: 'N',
    deceased_introduction: '',
    chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    condolatory_yn: 'N',
    donation_yn: 'N',
    status: '2',
    relations_datas: [
      { relations_type: 'S', relations_detail: '', relations_name: '', mobile: '' },
    ],
    condolatory_datas: [{ applicant_name: '', bank_name: '', account_number: '' }],
    blt_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    medias: [],
    deceased_video_link: '',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const location = useLocation();

  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);

  const [symbolYn, setSymbolYn] = useState(false);
  const [videoYn, setVideoYn] = useState(false);
  const [displayYn, setDisplayYn] = useState(true);
  const [videoLink, setVideoLink] = useState('');
  const [medias, setMedias] = useState([]);
  const [checked, setChecked] = useState(true);

  const saveMemorial = async params => {
    const result = await saveMemorialApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successSaveMemorial(result.id));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const handleSymbolYn = value => {
    setValue('symbol_comment_yn', value ? 'Y' : 'N');
    setSymbolYn(value);
  };

  const handleChangeDisplayYn = value => {
    setValue('display_yn', value ? 'Y' : 'N');
    setDisplayYn(value);
  };

  const onChangeChecked = checked => {
    setChecked(!checked);
  };

  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };

  const handleClose = () => {
    navigate('/life/settings/canvas');
  };

  const onSubmit = data => {
    saveMemorial(data);
  };

  const onError = error => {
    showAlert('알림', '잘못된 입력된 정보가 존재합니다. 입력값을 확인하세요.', () => hideAlert());
    console.log('error > ', error);
  };

  const successSaveMemorial = memorialId => {
    hideAlert();
    window.location.href = `/memorial/memorial/view/${memorialId}`;
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }

    if (loginUser && loginUser.member_id) {
      setValue('deceased_name', loginUser.member_name);
      // setValue('deceased_birthday', loginUser.);
      setValue('own_member_id', loginUser.member_id);
      setValue('create_member_id', loginUser.member_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-setting is-mycanvas">
          <h2>내 캔버스 메모리얼 캔버스 이동</h2>
          <p className="desc">* 사랑하는 사람에게 남기는 기록입니다</p>
          <h3>정보입력</h3>

          <div className="ip-item is-error">
            <label for="user-name">이름</label>
            <input
              type="text"
              aria-invalid={errors.deceased_name ? 'true' : 'false'}
              {...register('deceased_name')}
              id="user-name"
            />
            {errors.deceased_name && <p class="message">{errors.deceased_name.message}</p>}
          </div>
          <div className="ip-item">
            <label for="user-appointment">직위</label>
            <input type="text" {...register('deceased_job_title')} />
          </div>
          <div className="ip-item is-error">
            <label for="user-birth">생년월일</label>
            <input
              type="date"
              className="birth date"
              aria-invalid={errors.deceased_birthday ? 'true' : 'false'}
              {...register('deceased_birthday')}
            />
            {errors.deceased_birthday && <p class="message">{errors.deceased_birthday.message}</p>}
          </div>
          <div className="ip-item is-error">
            <label for="user-passing">임종일자</label>
            <input
              type="date"
              className="date"
              aria-invalid={errors.deceased_date ? 'true' : 'false'}
              {...register('deceased_date')}
            />
            {errors.deceased_date && <p class="message">{errors.deceased_date.message}</p>}
          </div>
          <div className="ip-wrap ip-file-wrap">
            <label for="user-passing">사진등록</label>
            <div className="ip-file">
              <input
                type="file"
                //accept="image/jpg, image/jpeg, image/png"
                //multiple
                className="input-hidden"
                id="ip-file-upload"
                onChange={changeImageFile}
              />
              <label for="ip-file-upload">
                <i
                  className="ic ic-file-add"
                  onClick={() => document.getElementById('imageFile').click()}
                />
                <span className="offscreen">파일첨부</span>
              </label>
            </div>

            {deceasedPhoto ? (
              <div className="uploaded-file chk-size" onChange={changeImageFile}>
                <img
                  //src="https://image.shutterstock.com/z/stock-photo-summer-menu-drink-orange-and-grapefruit-refreshing-drink-with-ice-copy-space-1763838455.jpg"
                  alt=""
                  src={deceasedPhoto}
                />
                <button type="button" onClick={removeImageFile}>
                  <i className="ic ic-x"></i>
                  <span className="offscreen">파일삭제</span>
                </button>
              </div>
            ) : (
              <div className="uploaded-file chk-size" onChange={changeImageFile}>
                <img
                  src="https://image.shutterstock.com/z/stock-photo-summer-menu-drink-orange-and-grapefruit-refreshing-drink-with-ice-copy-space-1763838455.jpg"
                  alt=""
                />
              </div>
            )}
          </div>
          {/* S : 22-09-19 고인 일대기(영상) 추가 */}
          <div className="ip-wrap ip-vod-wrap has-public">
            <label for="ip-file-upload2">고인 일대기(영상)</label>
            <div className="ip-file">
              {/* <input type="file2" className="input-hidden" id="ip-file-upload2" /> */}
              {/* <label for="ip-file-upload">+파일추가</label> */}

              <span style={{ marginTop: '10px', fontSize: '14px' }}>(동영상 파일형식 : MP4)</span>
            </div>
            <div className="ip-file-name" style={{ marginTop: '0px' }}>
              <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
            </div>
            <div className="rc-item rc-toggle">
              <input type="checkbox" id="ip-public01" />
              <label for="ip-public01" onClick={() => handleVideoYn(videoYn ? false : true)}>
                공개여부 <span className="toggle"></span>
              </label>
            </div>
          </div>
          {/* E : 22-09-19 고인 일대기(영상) 추가 */}

          {/* <div className="ip-wrap has-public">
            <label for="user-symbolic">상징문구</label>
            <div class="ip-item">
              <input
                type="text"
                {...register('symbol_comment')}
                id="user-symbolic"
                placeholder="고인을 상징할 수 있는 문구 (예:명랑소녀000여사)"
              />
            </div>
            <div class="rc-item rc-toggle">
              <input type="checkbox" id="ip-public02" />
              <label for="ip-public02" onClick={() => handleSymbolYn(symbolYn ? false : true)}>
                공개여부 <span class="toggle"></span>
              </label>
            </div>
          </div> */}

          <div className="ip-item is-error">
            <label for="user-notice">안내글</label>
            <input
              type="text"
              {...register('memorial_introduction')}
              aria-invalid={errors.memorial_introduction ? 'true' : 'false'}
              id="info_input"
              className="reg_input"
              placeholder="예 : 저희 상가는 조의금을 정중히 사양합니다."
            />
            {errors.memorial_introduction && (
              <p class="message">{errors.memorial_introduction.message}</p>
            )}
          </div>
          <div className="ip-wrap has-sample">
            <div className="ip-item is-error">
              <label for="user-funeral">추도사</label>
              <textarea
                {...register('chudosa')}
                className="memorial_textarea"
                id="memorial_textarea"
                placeholder="예 : 삼가 영전(靈前)에 깊은 애도(哀悼)의 뜻을 표합니다."
              ></textarea>
              {errors.chudosa && <p class="message">{errors.chudosa.message}</p>}
            </div>
            <button
              type="button"
              className="btn-sample"
              data-micromodal-trigger="modal-sample-01"
              onClick={() => setOpenChudosaSampleDialog(true)}
            >
              <i className="ic ic-sample"></i>
              샘플보기
            </button>
          </div>

          <Blts
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            setMode={'2'}
          />
          <div class="rc-item rc-toggle center">
            <input
              type="checkbox"
              id="ip-public22"
              checked={checked}
              onChange={() => onChangeChecked(checked)}
            />
            <label
              for="ip-public22"
              onClick={() => handleChangeDisplayYn(displayYn ? false : true)}
            >
              추모관 공개여부 <span class="toggle"></span>
            </label>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn btn-white" onClick={() => handlePreview()}>
              미리보기
            </button>
            <button
              type="button"
              className="btn btn-blue"
              onClick={handleSubmit(onSubmit, onError)}
            >
              등록완료
            </button>
            <a href={() => false} className="btn btn-white" onClick={handleClose}>
              취소
            </a>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
      {openChudosaSampleDialog && (
        <ChudosaSampleDialog
          isOpen={openChudosaSampleDialog}
          onClose={() => setOpenChudosaSampleDialog(false)}
        />
      )}
      {openPreviewDialog && (
        <PreviewDialog
          isOpen={openPreviewDialog}
          onClose={() => setOpenPreviewDialog(false)}
          getValues={getValues}
        />
      )}
    </section>
  );
}
export default ChangeMyCanvas;

import { useRef, useEffect, useState } from 'react';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';

import Dialog from '@mui/material/Dialog';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import ContentLoader from 'react-content-loader';

import TabFuneralContent from 'pages/pet-memorial-canvas/obituary/components/TabFuneralContent';
import TabBltContent from 'pages/pet-memorial-canvas/obituary/components/TabBltContent';

import noDeceasedPhoto from 'assets/images/no_img_obituary.jpg';
import imgSlider01 from 'assets/images/wreath_basic.jpg';
import imgSlider02 from 'assets/images/wreath_highend.jpg';
import imgSlider03 from 'assets/images/wreath_premium.jpg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'css/swiper.min.css';
import { Link } from 'react-router-dom';
import WreathContent from '../WreathContent';
import SharedDialog from '../SharedDialog';
import CondolatoryDialog from '../CondolatoryDialog';
import WreathDialog from '../WreathDialog';
import WreathCustomDialog from '../WreathCustomDialog';
import MediaViewDialog from '../MediaViewDialog';
import FuneralViewDialog from '../FuneralViewDialog';
import TabChudosasContent from '../TabChudosasContent';
import TabAlbumContent from '../../../memorial/components/TabAlbumContent';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { timestampToDate } from '../../../../../utils/date';
import TabCondolencesGroupContent from '../TabCondolencesGroupContent';

function ObituaryViewPage({ isOpen, onClose, getValues }) {
  const { showAlert, hideAlert } = useDialog();

  const { setGnbType } = useLayout();

  const [residents, setResidents] = useState(null);

  const [bltContents, setBltContents] = useState(null);
  const [chudosaContents, setChudosaContents] = useState(null);

  const [hall, setHall] = useState(null);
  const [funeralAddr, setFuneralAddr] = useState(null);
  const [tabBlt, setTabBlt] = useState(false);
  const [tabState, setTabState] = useState(false);

  const [tabIndex, setTabIndex] = useState(1);

  const [obituary, setObituary] = useState(null);
  const [relations, setRelations] = useState(null);

  const [blts, setBlts] = useState(null);
  const [chudosas, setChudosas] = useState(null);
  const [deceasedName, setDeceasedName] = useState(null);

  const [isActive, setActive] = useState(false);

  const [openFuneralViewDialog, setOpenFuneralViewDialog] = useState(false);

  const handleNoAccess = () => {
    showAlert('알림', '미리보기에서 지원하지 않는 기능입니다.', () => hideAlert());
  };
  const xy = useRef(null);

  const onClickTab = index => {
    if (tabIndex === index && index !== 1) {
      setTabState(!tabState);
      return;
    }
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
    setTabIndex(index);
  };

  const homeRef = useRef();
  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setResidents(
      relations && relations.length > 0
        ? relations.reduce(
            (accumulator, currentObject, index) =>
              // `${index > 0 ? accumulator + ', ' : ''} ${currentObject.relations_detail} ${
              //   currentObject.relations_name
              // }`,
              `${
                index > 0 && currentObject.relations_name !== ''
                  ? accumulator + ', '
                  : currentObject.relations_name === ''
                  ? ''
                  : ','
              } ${currentObject.relations_detail} ${currentObject.relations_name}`,
            '',
          )
        : '',
    );
  }, [relations]);

  useEffect(() => {
    setBltContents(
      blts && blts.length > 0
        ? blts.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [blts]);

  useEffect(() => {
    setChudosaContents(
      chudosas && chudosas.length > 0
        ? chudosas.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [chudosas]);

  useEffect(() => {
    setHall(obituary && obituary.hall_data ? obituary.hall_data : null);
    setFuneralAddr(obituary && obituary.funeral_addr_yn ? obituary.funeral_addr_yn : null);
  }, [obituary]);

  useEffect(() => {
    setObituary(getValues());
    setRelations(getValues('relations_datas'));
    setBlts(getValues('blt_datas'));
    setDeceasedName(getValues('deceased_name'));
  }, [getValues]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="false" fullWidth>
      <div className="wrap is-new" id="petCanvas">
        <div class="modal is-full" id="modal-obituary-14" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title">미리보기</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div class="modal__content">
                {/* S : 서브 비주얼 + 타이틀 */}
                {/* <div className="visual-wrap">
                <div className="contents-width">
                  <h2 className="title">부고</h2>
                  <p>
                    사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
                    <br className="d-md-block d-none" /> 발송 대행하고{' '}
                    <br className="d-block d-md-none" />
                    장례 의식 공지 등의 서비스를 제공합니다.
                  </p>
                </div>
              </div> */}
                {/* E : 서브 비주얼 + 타이틀 */}

                {/* S : 부고 상세 : 미리보기 영역 (부고 상세만 디자인 이슈로 기존의 페이지와 컨텐츠 마크업이 다릅니다 확인해주세요) */}

                <div className="content">
                  {/* S : 서브 비주얼 + 타이틀 */}
                  {/*<div className="visual-wrap">*/}
                  {/*  <div className="contents-width">*/}
                  {/*    <h2 className="title">{obituary.deceased_name}님 부고</h2>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/* E : 서브 비주얼 + 타이틀 */}
                  {/* S : 23-04-21 메모리얼 캔버스 개편에 따른 마크업 수정 */}
                  {/*
                   *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
                   */}
                  <div className="fixed-list is-obituary">
                    <ul>
                      <li className={tabIndex === 1 && tabBlt === true ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            onClickTab(1);
                            // setTabBlt(true);
                          }}
                        >
                          발자국
                        </button>
                      </li>
                      <li className={tabIndex === 2 ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                          }}
                        >
                          떠나보내는 글
                        </button>
                      </li>
                      <li className={tabIndex === 3 ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                          }}
                        >
                          추억앨범
                        </button>
                      </li>
                      <li className={tabIndex === 4 ? 'is-active' : ''}>
                        {/*<button type="button" onClick={() => moveUrl()}>*/}
                        {/*<button type="button">*/}
                        {/*  <Link to={`/memorial/letter/${memorialId}`}>추모의 글</Link>*/}
                        {/*</button>*/}
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                            //setCount(count + 1);
                          }}
                        >
                          추모의 글
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div className="wrap-detail">
                    {/* S : 영정 사진 */}
                    {/* S : 사진 없을 때 */}
                    {/*
                     *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
                     */}
                    {obituary ? (
                      <>
                        {obituary.deceased_photo ? (
                          <div className="wrap-portrait is-obituary has-portrait">
                            <div className="box-title">
                              <h3 className="title">세상에 남겨진 단 하나뿐인 이야기</h3>
                              <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                            </div>
                            <div className="box-portrait">
                              <img
                                src={
                                  obituary.deceased_photo
                                    ? obituary.deceased_photo
                                    : noDeceasedPhoto
                                }
                                alt=""
                              />
                              {/*<img src="https://images.unsplash.com/photo-1648183185045-7a5592e66e9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1084&q=80" />*/}
                              {/*<img src="../../static/media/user_no_portrait_obituary.png" />*/}
                            </div>
                          </div>
                        ) : (
                          <div className="wrap-portrait is-obituary">
                            <div className="box-title">
                              <h3 className="title">
                                세상에 남겨진 <br className="d-md-block d-none" /> 단 하나뿐인
                                이야기
                              </h3>
                              <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                            </div>
                          </div>
                        )}
                        {/* E : 사진 있을 때 */}
                        {/* E : 영정 사진 */}

                        {/* S : 고인 정보 */}
                        <div className="wrap-deceased contents-width">
                          <div className="box-name">
                            <h4 className="name">{`${obituary.deceased_name} ${obituary.deceased_job_title} `}</h4>
                          </div>
                          {obituary.deceased_birthday !== '' && obituary.deceased_date !== '' ? (
                            <p className="birth">{`${obituary.deceased_birthday} ~ ${obituary.deceased_date}`}</p>
                          ) : obituary.deceased_birthday !== '' && obituary.deceased_date === '' ? (
                            <p className="birth">{`${obituary.deceased_birthday} ~ `}</p>
                          ) : obituary.deceased_birthday === '' && obituary.deceased_date !== '' ? (
                            <p className="birth">{`~ ${obituary.deceased_date}`}</p>
                          ) : (
                            ''
                          )}

                          <div className="box-family">
                            <div className="contents-width">
                              <p>
                                {obituary.chief_mourner_desc
                                  ? `${obituary.chief_mourner_desc}`
                                  : ''}{' '}
                                {obituary.chief_mourner ? `${obituary.chief_mourner} ` : ''}
                                {residents}
                              </p>
                            </div>
                          </div>

                          {/* S : 버튼 */}
                          <div className="btns">
                            <ul>
                              {obituary.delivery_yn === 'Y' ? (
                                <li>
                                  <button type="button" onClick={() => handleNoAccess()}>
                                    <i className="ic ic-send-obituary"></i>부고보내기
                                  </button>
                                </li>
                              ) : (
                                ''
                              )}
                              {obituary.condolatory_yn === 'Y' ? (
                                <li>
                                  <button type="button" onClick={() => handleNoAccess()}>
                                    <i className="ic ic-condolence-money"></i>조의금전달
                                  </button>
                                </li>
                              ) : (
                                ''
                              )}
                              {obituary.digital_wreath_yn === 'Y' &&
                                obituary.calculate_request_yn === 'N' && (
                                  <>
                                    {/* 운영반영 */}
                                    <li>
                                      {1 === 2 && (
                                        <button type="button">
                                          <i
                                            className="ic ic-digital-wreath"
                                            onClick={() => handleNoAccess()}
                                          ></i>
                                          디지털화환
                                        </button>
                                      )}
                                      <button type="button" onClick={() => handleNoAccess()}>
                                        <i className="ic ic-digital-wreath"></i>디지털화환
                                      </button>
                                    </li>

                                    {/* 개발반영 */}
                                    {/*<li>*/}
                                    {/*  */}
                                    {/*      <button type="button">*/}
                                    {/*        <i className="ic ic-digital-wreath"  onClick={() => selectedReceiver(obituary.chief_mourner)}></i>디지털화환*/}
                                    {/*      </button>*/}

                                    {/*</li>*/}
                                  </>
                                )}
                            </ul>
                          </div>
                          {/* E : 버튼 */}
                        </div>
                        {/* E : 고인 정보 */}

                        {/* S : 장례식장, 알림글 */}
                        <div className="wrap-info">
                          <div className="contents-width">
                            <p>
                              {obituary.deceased_introduction &&
                                parse(
                                  obituary.deceased_introduction.replace(/(\n|\r\n)/g, '<br />'),
                                )}
                            </p>
                          </div>
                        </div>
                        {/* E : 장례식장, 알림글  */}

                        {/* S : 추모 영상 */}
                        {obituary.deceased_video_yn === 'Y' &&
                        obituary.deceased_video_link !== '' ? (
                          <div className="wrap-remembrance-vod">
                            <div className="contents-width">
                              <div className="box-title">
                                <h4 className="title">추모 영상</h4>
                                <p className="desc">고인의 삶을 영상으로 담아 추억합니다.</p>
                              </div>
                              <div className="box-vod">
                                <div className="inner-item">
                                  {obituary.video_thumbnail ? (
                                    <video className="video" poster={obituary.video_thumbnail} />
                                  ) : (
                                    <video
                                      className="video"
                                      poster="https://plus.unsplash.com/premium_photo-1675826774817-fe983ceb0475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                                    />
                                  )}
                                  {/*  <source src="../../static/media/temp/sample.mp4" type="video/mp4" />*/}
                                  {/*</video>*/}
                                  <button
                                    type="button"
                                    className="btn-vod-play is-show"
                                    onClick={() => handleNoAccess()}
                                  >
                                    <span className="offscreen">추모영상 재생하기</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ' '
                        )}
                        {/* E : 추모 영상 */}

                        {/* S : 디지털 화환 */}
                        {obituary.digital_wreath_yn === 'Y' && (
                          <div className="wrap-digital-wreath contents-width">
                            <div className="box-title">
                              <h3 className="title">화환 해주신 분</h3>
                              <p className="desc">디지털 화환으로 애도의 뜻을 전합니다</p>
                            </div>
                            <div className="wreath-swiper">
                              <div className="swiper-asset">
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                              </div>
                              <Swiper
                                modules={[Navigation, Pagination]}
                                // spaceBetween={23}
                                // slidesPerView={}
                                centeredSlides={false}
                                loop={true}
                                // autoHeight={true}
                                simulateTouch={true}
                                autoplay={{ delay: 2000 }}
                                navigation={{
                                  nextEl: '.swiper-button-next',
                                  prevEl: '.swiper-button-prev',
                                }}
                                pagination={{ el: '.swiper-asset', clickable: true }}
                              >
                                <div className="swiper-wrapper ">
                                  <SwiperSlide className="wreath-item">
                                    <div className="wreath-item swiper-slide">
                                      <div className="box-wreath type-01">
                                        {/* 국화 1송이 */}
                                        <p>삼가 고인의 명복을 빕니다. 최대 20자</p>
                                        <p className="info">일산 산악동호회 일동</p>
                                        <p className="info">2022.04.01</p>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                  <SwiperSlide className="wreath-item">
                                    <div className="wreath-item swiper-slide">
                                      <div className="box-wreath type-02">
                                        {/* 국화 3송이 */}
                                        <p>삼가 고인의 명복을 빕니다. 최대 20자</p>
                                        <p className="info">일산 산악동호회 일동</p>
                                        <p className="info">2022.04.01</p>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                  <SwiperSlide className="wreath-item">
                                    <div className="wreath-item swiper-slide">
                                      <div className="box-wreath type-03">
                                        {/* 근조화환 */}
                                        <p>삼가 고인의 명복을 빕니다. 최대 20자</p>
                                        <p className="info">일산 산악동호회 일동</p>
                                        <p className="info">2022.04.01</p>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                </div>
                              </Swiper>
                            </div>
                          </div>
                        )}
                        {/* E : 디지털 화환 */}

                        {/* S : 부고/추모관 공통 컨텐츠 */}
                        {/* S : 일대기 */}
                        {tabIndex === 1 && (
                          <TabBltContent
                            bltContents={bltContents}
                            deceasedName={deceasedName}
                            // xy={xy}
                            // tabState={tabState}
                          />
                        )}
                        {/*{tabIndex === 2 && (*/}
                        {/*  <TabChudosasContent*/}
                        {/*    chudosaContents={chudosaContents}*/}
                        {/*    tabState={tabState}*/}
                        {/*  />*/}
                        {/*)}*/}
                        {/*{tabIndex === 3 && (*/}
                        {/*  <TabAlbumContent*/}
                        {/*    memorialId={memorialId}*/}
                        {/*    deceasedName={deceasedName}*/}
                        {/*    ownMemberId={ownMemberId}*/}
                        {/*    tabState={tabState}*/}
                        {/*  />*/}
                        {/*)}*/}
                        {/*{tabIndex === 4 && (*/}
                        {/*  <TabCondolencesGroupContent*/}
                        {/*    currentLastUrl={currentLastUrl}*/}
                        {/*    tabState={tabState}*/}
                        {/*  />*/}
                        {/*)}*/}
                        <FuneralViewDialog
                          isOpen={openFuneralViewDialog}
                          onClose={() => setOpenFuneralViewDialog(false)}
                          hall={hall}
                          funeralAddr={funeralAddr}
                        />
                        {/* E : 일대기 */}
                      </>
                    ) : (
                      ''
                    )}
                    {/* E : 부고 상세 (부고 상세만 디자인 이슈로 기존의 페이지와 컨텐츠 마크업이 다릅니다 확인해주세요) */}
                  </div>
                </div>
              </div>
              <div class="modal__footer">
                <button type="button" class="btn btn-blue" onClick={onClose} data-micromodal-close>
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ObituaryViewPage;

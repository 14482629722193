import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useSearch from 'stores/search/hook';

import { getMemorialsApi } from 'apis/memorial';

import ContentLoader from 'react-content-loader';
import Pagination from 'react-js-pagination';

import noObituaryPhoto from 'assets/images/no_img_obituary.jpg';
import noMemorialPhoto from 'assets/images/no_img_memorial.jpg';

import 'css/pagination.css';

function MemorialPage() {
  const { searchAllValue, setSearchAllValue } = useSearch();

  const { setGnbType } = useLayout();

  const [loading, setLoading] = useState(true);

  const [searchTotalCount, setSearchTotalCount] = useState(0);

  const [obituarys, setObituarys] = useState(null);
  const [memorials, setMemorials] = useState(null);

  const [obituaryPage, setObituaryPage] = useState(1);
  const [obituaryListSize] = useState(6);
  const [obituaryTotalCount, setObituaryTotalCount] = useState(0);

  const [memorialPage, setMemorialPage] = useState(1);
  const [memorialListSize] = useState(6);
  const [memorialTotalCount, setMemorialTotalCount] = useState(0);

  const getSearchObituarys = async pageNum => {
    let params = {};
    params.status = 1;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.page = pageNum;
    params.itemInPage = obituaryListSize;
    if (searchAllValue && searchAllValue !== '') params.searchWord = searchAllValue;

    const result = await getMemorialsApi(params);
    setObituarys(result.data);
    setObituaryTotalCount(result.data_cnt);
  };

  const getSearchMemorials = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.page = pageNum;
    params.itemInPage = memorialListSize;
    if (searchAllValue && searchAllValue !== '') params.searchWord = searchAllValue;

    const result = await getMemorialsApi(params);
    setMemorials(result.data);
    setMemorialTotalCount(result.data_cnt);
  };

  const onChangeSearhAllValue = event => {
    setSearchAllValue(event.target.value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    getSearchObituarys(1);
    getSearchMemorials(1);
  };

  const handleObituaryPageChange = selectedPage => {
    setObituaryPage(selectedPage);
    getSearchObituarys(selectedPage);
  };

  const handleMemorialPageChange = selectedPage => {
    setMemorialPage(selectedPage);
    getSearchMemorials(selectedPage);
  };

  useEffect(() => {
    setSearchTotalCount(obituaryTotalCount + memorialTotalCount);
  }, [obituaryTotalCount, memorialTotalCount]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getSearchObituarys(1);
    getSearchMemorials(1);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="loading_wrap">
      <div className="loading_inner">
        <ContentLoader />
      </div>
    </div>
  ) : (
    <div className="content_wrap bg_type">
      <div className="content_inner">
        <div className="inquiry_wrap">
          <div className="title_area">
            <h2 className="content_title">
              검색<span className="block"></span>결과
              <span className="num">({searchTotalCount})</span>
            </h2>
          </div>
          <div className="inquiry_content">
            <div className="top_area">
              <div className="search_form">
                <fieldset>
                  <legend>고인/상주명 검색</legend>
                  <input
                    type="text"
                    placeholder="고인/상주명을 입력하세요"
                    className="search_input"
                    value={searchAllValue}
                    onChange={onChangeSearhAllValue}
                    onKeyPress={onKeyPress}
                  />
                  <button className="ico_sch" onClick={handleSearch}>
                    검색
                  </button>
                </fieldset>
              </div>
            </div>
            <div className="all_search">
              <h3 className="search_title">부고</h3>
              {obituarys && obituarys.length > 0 ? (
                <>
                  <ul className="inquiry_list">
                    {obituarys.map(obituary => (
                      <li key={obituary.memorial_id}>
                        <Link to={`/memorial/obituary/view/${obituary.memorial_id}`}>
                          <span className="img_area">
                            <img
                              alt=""
                              src={
                                obituary.deceased_photo ? obituary.deceased_photo : noObituaryPhoto
                              }
                            />
                          </span>
                          <div className="info">
                            <span className="nm">{`${obituary.deceased_name} ${obituary.deceased_job_title}`}</span>
                            <span className="date">{`${obituary.deceased_birthday} ~ ${obituary.deceased_date}`}</span>
                            <div className="funeral_info">
                              <dl>
                                <dt>상주</dt>
                                <dd>{`${
                                  obituary.chief_mourner_desc ? obituary.chief_mourner_desc : ''
                                } ${obituary.chief_mourner ? obituary.chief_mourner : ''}`}</dd>
                              </dl>
                              {/* <dl>
                                <dt>발인</dt>
                                <dd>{`${obituary.birth_date} ${obituary.birth_hour}`}</dd>
                              </dl>
                              <dl>
                                <dt>빈소</dt>
                                <dd>{obituary.funeral_addr_view}</dd>
                              </dl> */}
                            </div>
                            <span className="mark">조문</span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <Pagination
                    activePage={obituaryPage}
                    itemsCountPerPage={obituaryListSize}
                    totalItemsCount={obituaryTotalCount}
                    pageRangeDisplayed={5}
                    firstPageText={''}
                    prevPageText={''}
                    nextPageText={''}
                    lastPageText={''}
                    onChange={handleObituaryPageChange}
                    itemClassFirst={'first'}
                    itemClassPrev={'prev'}
                    itemClassNext={'next'}
                    itemClassLast={'last'}
                  />
                </>
              ) : (
                <div className="no_data">일치하는 데이터가 없습니다.</div>
              )}
            </div>
            <div className="all_search memorial_area">
              <h3 className="search_title">추모관</h3>
              {memorials && memorials.length > 0 ? (
                <>
                  <ul className="inquiry_list">
                    {memorials.map(memorial => (
                      <li key={memorial.memorial_id}>
                        <Link to={`/memorial/memorial/view/${memorial.memorial_id}`}>
                          <span className="img_area">
                            <img
                              alt=""
                              src={
                                memorial.deceased_photo ? memorial.deceased_photo : noMemorialPhoto
                              }
                            />
                            <p className="text">{memorial.symbol_comment}</p>
                          </span>
                          <div className="info">
                            <span className="nm">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</span>
                            <span className="date">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</span>
                            <span className="link">&gt;</span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <Pagination
                    activePage={memorialPage}
                    itemsCountPerPage={memorialListSize}
                    totalItemsCount={memorialTotalCount}
                    pageRangeDisplayed={5}
                    firstPageText={''}
                    prevPageText={''}
                    nextPageText={''}
                    lastPageText={''}
                    onChange={handleMemorialPageChange}
                    itemClassFirst={'first'}
                    itemClassPrev={'prev'}
                    itemClassNext={'next'}
                    itemClassLast={'last'}
                  />
                </>
              ) : (
                <div className="no_data">일치하는 데이터가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemorialPage;

import api from '@apis/api';
import configs from '@configs/configs';

export async function getTemplateBoardsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/templateBoard`, params);
  return response.data;
}

export async function getTemplateBoardApi(id) {
  const response = await api.get(`${configs.API_BASEURL}/templateBoard?id=${id}`);
  return response.data;
}

export async function saveTemplateBoardApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/templateBoard/ins`, params);
  return response.data;
}

export async function modifyTemplateBoardApi(params) {
  try {
    const response = await api.put(`${configs.API_BASEURL}/templateBoard`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function deleteTemplateBoardApi(id) {
  const response = await api.delete(`${configs.API_BASEURL}/templateBoard?id=${id}`);
  return response.data;
}

import { styled } from '@mui/material/styles';

export default styled('div')(() => {
  return {
    '& .ql-toolbar': {
      border: 'none',
      borderBottom: '1px solid #cccccc',
    },
    '& .ql-container': {
      border: 'none',
    },
  };
});

import { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import { uploadApi } from 'apis/openapi';

import 'react-quill/dist/quill.snow.css';
import ViewerStyle from 'components/editor/QuillEditor/ViewerStyle';

import 'css/editor.css';

const Font = Quill.import('formats/font');
Font.whitelist = [
  'NotoSansKR',
  'NotoSerifKR',
  'AritaBuri',
  'AritaBuri-Semi',
  'ChosunSm',
  'ChosunSg',
  'KOTRA_SONGEULSSI',
  'Hankc',
  'Humanbumsuk',
  'MapoFlowerIsland',
  'RIDIBatang',
  'SeoulNamsan',
];
Quill.register(Font, true);

const Size = Quill.import('formats/size');
Size.whitelist = ['Header1', 'Header2', 'Content1', 'Content2', 'Content3', false];
Quill.register(Size, true);

Quill.register('modules/ImageResize', ImageResize);

function QuillEditor(props) {
  const quillRef = useRef();

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.png, .jpg, .jpeg');
    input.click();
    input.onchange = async e => {
      const files = e.target.files;
      const imgUrl = await uploadImageFile(files[0]);
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range.index, 'image', imgUrl);
    };
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);
    return result.uploadUrl;
  };

  const modules = useMemo(
    () => ({
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler: (range, context) => {
              const quill = context.quill;
              quill.insertText(range.index, '\n', Quill.sources.USER);
              quill.setSelection(range.index + 1, Quill.sources.SILENT);
            },
          },
        },
      },
      toolbar: {
        container: [
          [{ font: Font.whitelist }, { size: Size.whitelist }],
          [
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
                '#273862',
                '#192B57',
                '#8893AA',
              ],
            },
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
          ],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          // [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        //parchment: Quill.import('parchment'),
        modules: ['Resize'],
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formats = [
    'font',
    'size',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  return (
    <>
      {props.viewerMode ? (
        <ViewerStyle>
          <ReactQuill theme="snow" {...props} readOnly />
        </ViewerStyle>
      ) : (
        <ReactQuill ref={quillRef} modules={modules} formats={formats} theme="snow" {...props} />
      )}
    </>
  );
}

export default QuillEditor;

import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { getTemplateBoardsApi } from 'apis/templateBoard';
import useDialog from 'stores/dialog/hook';
import { useState } from 'react';
let selectedHeader = null;
let selectedValue = null;

function BucketlistTemplateDialog({ isOpen, getSelectedValue, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const [templates, setTemplates] = useState();
  const [templateHeader, setTemplateHeader] = useState();
  const [templateList, setTemplateList] = useState();
  const [step, setStep] = useState(1);

  const getTemplateBoards = async () => {
    let params = {};
    params.canvas_type = 'L';
    params.blt_type = '4';
    params.display_yn = 'Y';
    const result = await getTemplateBoardsApi(params);

    if (result.code === '0000') {
      setTemplates(result.data.sort((a, b) => a.order_num - b.order_num));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleHeader = header => {
    selectedHeader = header;
  };

  const handleNext = () => {
    if (selectedHeader) {
      setTemplateList(templates.filter(template => template.qna_type === selectedHeader));
      setStep(2);
    } else {
      alert('유형을 선택하세요.');
    }
  };

  const handleList = value => {
    selectedValue = value;
  };

  const handleSelected = () => {
    if (selectedValue) {
      getSelectedValue(selectedValue);
    } else {
      alert('버킷리스트를 선택하세요.');
    }
  };

  useEffect(() => {
    if (templates) {
      let headers = [];
      templates.map(template => headers.push(template.qna_type));
      headers = [...new Set(headers)];
      setTemplateHeader(headers);
    }
  }, [templates]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getTemplateBoards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        {/* S : 버킷리스트 템플릿 */}
        <div className="wrap" id="lifeCanvas">
          {step === 1 && (
            <div className="modal" id="modal-template-01" aria-hidden="true">
              <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">버킷리스트 템플릿</h2>
                    <p className="modal__desc">연령대별 버킷리스트 확인하기</p>
                    <button
                      className="modal__close"
                      aria-label="Close modal"
                      data-micromodal-close
                      onClick={onClose}
                    >
                      <i className="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    {/* S : 템플릿 리스트 */}
                    <ul className="template-list">
                      {templateHeader &&
                        templateHeader.length > 0 &&
                        templateHeader.map(header => (
                          <li key={header}>
                            <button type="button" onClick={() => handleHeader(header)}>
                              {header}
                            </button>
                          </li>
                        ))}
                    </ul>
                    {/* E : 템플릿 리스트 */}
                  </div>

                  <div className="modal__footer">
                    <button
                      type="button"
                      className="btn btn-blue btn-bucket-template"
                      onClick={() => handleNext()}
                    >
                      다음
                    </button>
                    <button
                      type="button"
                      className="btn btn-white"
                      data-micromodal-close
                      onClick={onClose}
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* E : 버킷리스트 템플릿 */}
          {/* S : 연령별 대 버킷 리스트 */}
          {step === 2 && (
            <div className="modal" id="modal-template-02" aria-hidden="true">
              <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">{selectedHeader}</h2>
                    <button
                      className="modal__close"
                      aria-label="Close modal"
                      data-micromodal-close
                      onClick={onClose}
                    >
                      <i className="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    {/* S : 버킷 리스트 목록 */}
                    <ul>
                      <li>
                        {templateList &&
                          templateList.length > 0 &&
                          templateList.map(template => (
                            <div className="rc-item" key={template.id}>
                              <input
                                type="radio"
                                name="bucket-10"
                                id={template.id}
                                value={template.blt_title}
                                onChange={e => handleList(e.target.value)}
                              />
                              <label htmlFor={template.id}>{template.blt_title}</label>
                            </div>
                          ))}
                      </li>
                    </ul>
                    {/* E : 버킷 리스트 목록 */}
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-blue"
                        data-micromodal-close
                        onClick={() => handleSelected()}
                      >
                        선택완료
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* E : 연령별 대 버킷 리스트 */}
      </>
    </Dialog>
  );
}

export default BucketlistTemplateDialog;

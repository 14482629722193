import { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { getCanvasApi, modifyCanvasApi } from 'apis/canvas';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import { v4 as uuidv4 } from 'uuid';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadImageApi } from 'apis/openapi';

import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';

function ModifyMyProfile({ setEdit }) {
  const schema = Yup.object({
    canvas_id: Yup.number().required('* 필수 입력 항목입니다.'),
    title: Yup.string().required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    canvas_id: 0,
    background_link: '',
    profile_link: '',
    title: '',
    birthday: '',
    motto: '',
    member_id: '',
    letter_contents: [],
  };

  const { register, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    // console.log(data);
    modifyCanvas(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const modifyCanvas = async params => {
    const result = await modifyCanvasApi(params);

    if (result.code === '0000') {
      // showLifeAlert('알림', result.message, () => successModifyCanvas());
      successModifyCanvas();
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const successModifyCanvas = () => {
    hideLifeAlert();
    setEdit(false);
  };

  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const [letterValue, setLetterValue] = useState();
  const [backgroundLink, setBackgroundLink] = useState();
  const [profileLink, setProfileLink] = useState();
  const [motto, setMotto] = useState();
  const [text, setText] = useState('');

  const [letters, setLetters] = useState([]);

  const getCanvas = async () => {
    let params = {};
    params.canvas_id = loginUser.canvas_id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getCanvasApi(params);

    if (result.code === '0000') {
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'background_link') setBackgroundLink(value);
        if (key === 'profile_link') setProfileLink(value);
        if (key === 'letter_contents') setLetters(value);
        if (key === 'motto') setMotto(value);
      });
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const addLetter = () => {
    let item = {};
    item.uuid = uuidv4();
    item.letter_contents = letterValue;
    setLetters([...letters, item]);
    setLetterValue('');
  };

  const onChange = e => {
    setText(e.target.value);
  };

  const uploadPercentage = value => {
    console.log('pregress > ', value);
    // setProgressBarPercentage(value);
  };

  const removeLetter = uuid => {
    const filterLetters = letters.filter(letter => letter.uuid !== uuid);
    setLetters([...filterLetters]);
  };

  const uploadBackgroundImage = async event => {
    const { files } = event.target;
    const result = await uploadImageApi(files[0], uploadPercentage);

    if (result.code === '0000') {
      setValue('background_link', result.uploadUrl);
      setBackgroundLink(result.uploadUrl);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const uploadProfileImage = async event => {
    const { files } = event.target;
    const result = await uploadImageApi(files[0], uploadPercentage);

    if (result.code === '0000') {
      setValue('profile_link', result.uploadUrl);
      setProfileLink(result.uploadUrl);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const init = async () => {
    if (loginUser?.member_id) await getCanvas();
  };
  useEffect(() => {
    let letterContents = [];
    for (let i = 0; i < letters.length; i++) {
      let item = {};
      item.uuid = letters[i].uuid;
      item.letter_contents = letters[i].letter_contents;
      item.letter_num = i;
      letterContents.push(item);
    }

    setValue('letter_contents', letterContents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letters]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="cover-canvas">
      {/* S : 프로필 이미지, 이름, 생년월일 */}
      <div className="user-profile-cover">
        <div className="user-cover-img">
          <span className="chk-size">
            <img
              alt=""
              // src={
              //   backgroundLink
              //     ? backgroundLink
              //     : Math.round(Math.random()) > 0
              //     ? imgNoThumb01
              //     : imgNoThumb02
              // }
              src={
                backgroundLink
                  ? backgroundLink
                  : loginUser?.canvas_id % 2 === 0
                  ? imgNoThumb01
                  : imgNoThumb02
              }
            />
          </span>
        </div>
        {/*<div className="user-cover-img"><span className="chk-size"><img src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg"></span></div>*/}
        {/* S : 커버 이미지 바꾸기 */}
        <div className="cover-util">
          <div className="ip-file">
            <input
              type="file"
              className="input-hidden"
              id="ip-file-upload2"
              onChange={uploadBackgroundImage}
            />
            <label htmlFor="ip-file-upload2">
              <i className="ic ic-file-add"></i>
              <span className="offscreen">커버 이미지 등록</span>
            </label>
          </div>
        </div>
        {/* E : 커버 이미지 바꾸기  */}

        <div className="user-pf-thumb">
          <div className="user-pf-thumb-img">
            <img alt="" src={profileLink ? profileLink : ''} />
          </div>
          {/* S : 프로필 이미지 바꾸기 */}
          <div className="ip-file ip-profile">
            <input
              type="file"
              className="input-hidden"
              id="ip-file-upload"
              onChange={uploadProfileImage}
            />
            <label htmlFor="ip-file-upload">
              <i className="ic ic-file-thumb"></i>
              <span className="offscreen">프로필 이미지 등록</span>
            </label>
          </div>
          {/* E : 프로필 이미지 바꾸기 */}
        </div>
      </div>

      <div className="user-name">
        <div className="ip-wrap ip-user ip-birth">
          <div className="ip-item form-date">
            <label htmlFor="ip-birth-date" className="offscreen">
              이름 입력
            </label>
            <input
              className="ip-flatpickr flatpickr"
              id="ip-birth-date"
              type="text"
              placeholder="이름을 입력하세요"
              maxlength="13"
              {...register('title')}
            />
          </div>
        </div>
      </div>

      <div className="user-birth">
        <div className="ip-wrap ip-user ip-birth">
          <div className="ip-item form-date">
            <label htmlFor="ip-birth-date" className="offscreen">
              생년월일 입력
            </label>
            <input
              className="ip-flatpickr flatpickr"
              id="ip-birth-date"
              type="date"
              placeholder="생년월일을 등록해주세요"
              {...register('birthday')}
            />
          </div>
        </div>
      </div>

      <div
        className="user-introduce"
        style={{
          marginTop: '15px',
        }}
      >
        <div className="ip-wrap ip-user">
          <div className="ip-item">
            <label htmlFor="ip-history" className="offscreen">
              좌우명 입력
            </label>
            {motto === '나를 소개하는 한 문장' ? (
              <input
                {...register('motto')}
                value={text}
                type="text"
                id="ip-history"
                placeholder="소개글을 입력하세요."
                onChange={onChange}
                maxlength="100"
              />
            ) : (
              <input
                {...register('motto')}
                type="text"
                id="ip-history"
                placeholder="소개글을 입력하세요."
                maxlength="100"
              />
            )}
          </div>
        </div>
      </div>

      {/* E : 좌우명 */}
      {/* S : 자기소개 */}
      <div className="user-introduce">
        {/* S : 자기소개 등록 전 초기 화면에서는 버튼만 노출 */}
        {/* <button type="button" className="btn btn-text">
          자기소개 등록
        </button> */}
        {/* E : 자기소개 등록 전 초기 화면에서는 버튼만 노출 */}

        {/* S : 자기소개 등록 후 */}
        <div className="user-history">
          <ul>
            <ReactSortable list={letters} setList={setLetters}>
              {letters.map((letter, index) => (
                <li key={letter.uuid}>
                  {letter.letter_contents}
                  <button type="button" onClick={() => removeLetter(letter.uuid)}>
                    <i className="ic ic-delete-history"></i>
                    <span className="offscreen">삭제</span>
                  </button>
                  <button type="button" className="btn-handle">
                    <i className="ic ic-handle"></i>
                    <span className="offscreen">순서 변경하기 </span>
                  </button>
                </li>
              ))}
            </ReactSortable>
          </ul>
        </div>
        <div className="ip-wrap ip-user">
          <div className="ip-item">
            <label htmlFor="ip-history" className="offscreen">
              좌우명 입력
            </label>
            <input
              type="text"
              id="ip-history"
              placeholder="연혁을 기록해주세요."
              maxlength="100"
              value={letterValue}
              onChange={e => setLetterValue(e.target.value)}
            />
          </div>
          <button type="button" className="btn btn-text" onClick={() => addLetter()}>
            추가
          </button>
        </div>
        <div className="btn-wrap">
          <button type="button" className="btn btn-md btn-gray" onClick={() => setEdit(false)}>
            취소
          </button>
          <button
            type="button"
            className="btn btn-md btn-blue"
            onClick={handleSubmit(onSubmit, onError)}
          >
            완료
          </button>
        </div>
        {/* E : 자기소개 등록 후 */}
      </div>
      {/* E : 자기소개 */}
    </div>
  );
}

export default ModifyMyProfile;

import { styled } from '@mui/material/styles';

export default styled('div')(() => {
  return {
    '& .ql-toolbar': {
      display: 'none',
    },

    '& .ql-container': {
      border: 'none',
    },
  };
});

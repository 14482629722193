import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import HistoryTemplateDialog from './components/HistoryTemplateDialog';
// import HistorySampleDialog from './components/HistorySampleDialog';

import useDialog from 'stores/dialog/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useLogin from 'stores/login/hook';

import { modifyCanvasBltApi } from 'apis/canvasBlt';

import LifeCanvasQuillEditor from 'components/editor/LifeCanvasQuillEditor';
import { getCanvasBltApi } from 'apis/canvasBlt';

function HistoryModify() {
  const { id } = useParams();

  const [openHistoryTemplateDialog, setOpenHistoryTemplateDialog] = useState(false);
  const { loginUser } = useLogin();
  // const [openHistorySampleDialog, setOpenHistorySampleDialog] = useState(false);
  const [contents, setContents] = useState('');

  const navigate = useNavigate();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const schema = Yup.object({
    canvas_id: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_type: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_title: Yup.string().required('* 필수 입력 항목입니다.'),
    display_yn: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
    // media_files: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    canvas_id: '',
    blt_type: '2',
    blt_title: '',
    display_yn: '',
    blt_contents: '',
    member_id: '',
    media_files: [],
  };

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    modifyCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const modifyCanvasBlt = async params => {
    const result = await modifyCanvasBltApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', '작성한 기록이 캔버스에 등록되었습니다.', () => successSaveCanvasBlt());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const successSaveCanvasBlt = () => {
    hideLifeAlert();
    navigate('/life/canvas/my', { state: { searchBltType: '2' } });
  };

  const changeContents = e => {
    let replacedValue = removeEmojis(e);

    setContents(replacedValue);
    setValue('blt_contents', replacedValue);
  };

  const handleSelect = e => {
    if (e.target.value === '1') {
      navigate('/life/everyone/bucketlist/register');
    } else if (e.target.value === '2') {
      navigate('/life/everyone/faq/register');
    } else if (e.target.value === '3') {
      navigate('/life/everyone/daily/register');
    }
  };

  const moveLogin = () => {
    hideLifeAlert();
    navigate('/life/login');
  };

  const handleClose = () => {
    showLifeConfirm(
      '확인',
      '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
      () => confirmClose(),
      () => hideLifeConfirm(),
    );
  };

  const confirmClose = () => {
    hideLifeConfirm();
    navigate(-1);
  };

  const removeEmojis = inputValue => {
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // if (regex.test(inputValue)) {
    //   showLifeAlert('알림', '이모티콘은 사용할 수 없습니다.', () => hideLifeAlert());
    //   let strVal = inputValue.replace(regex, ' ');
    //   return strVal;
    // }

    const img = /<img src="data:[^>]*>?/g;
    if (img.test(inputValue)) {
      showLifeAlert(
        '알림',
        '이미지는 복사 붙여넣기가 금지됩니다. \n 오른쪽 상단의 에디터 영역 이미지 첨부 버튼을 눌러 첨부해주세요.',
        () => hideLifeAlert(),
      );
      let strVal = inputValue.replace(img, '');
      return strVal;
    }

    return inputValue;
  };

  // const handleMove = url => {
  //   showLifeConfirm(
  //     '확인',
  //     '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
  //     () => confirmMove(url),
  //     () => hideLifeConfirm(),
  //   );
  // };

  // const confirmMove = url => {
  //   hideLifeConfirm();
  //   navigate(url);
  // };

  const selectedTemplate = template => {
    setOpenHistoryTemplateDialog(false);
    setContents(template.blt_contents);
    setValue('blt_title', template.blt_title);
  };

  const getCanvasBlt = async () => {
    let params = {};
    params.blt_id = id;

    const result = await getCanvasBltApi(params);

    if (result.code === '0000') {
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'blt_contents') setContents(value);
      });
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleGoogle = () => {
    window.gtag('event', '자서전등록', {
      event_category: '버튼클릭',
      event_label: '자서전',
    });
  };

  const init = async () => {
    if (loginUser && !loginUser.member_id)
      showLifeAlert('알림', '로그인 후 이용가능합니다.', () => moveLogin());
    if (loginUser?.member_id) setValue('member_id', loginUser.member_id);
    if (loginUser?.member_name) setValue('member_name', loginUser.member_name);
    if (loginUser?.canvas_id) setValue('canvas_id', loginUser.canvas_id);
  };

  useEffect(() => {
    if (id) getCanvasBlt();
  }, [id]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-write">
          {/* 글쓰기(자서전/문답/일상/버킷리스트) 일때 is-write 추가 */}
          {/* S : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}
          <h2 className="offscreen">자서전</h2>
          <div className="sel-item sel-title-write">
            <label for="sel-write" className="offscreen">
              기록 유형 선택
            </label>
            <select id="sel-write">
              {/* <option value="1">버킷리스트</option>
              <option value="2">문답</option> */}
              <option selected>자서전</option>
              {/* <option value="3">일상</option> */}
            </select>
          </div>
          {/* E : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}
          <ul className="write-util">
            <li>
              <button
                type="button"
                className="btn btn-white"
                data-micromodal-trigger="modal-template-01"
                onClick={() => setOpenHistoryTemplateDialog(true)}
              >
                자서전 템플릿
              </button>
            </li>
            {/* <li>
              <button
                type="button"
                data-micromodal-trigger="modal-sample-01"
                onClick={() => setOpenHistorySampleDialog(true)}
              >
                <i className="ic ic-sample"></i>샘플보기
              </button>
            </li> */}
          </ul>

          {/* S : 자서전 일 경우 제목 추가 */}
          <div className="ip-item is-error">
            <label htmlFor="ip-subject" className="offscreen">
              제목 입력
            </label>
            <input
              {...register('blt_title')}
              type="text"
              id="ip-subject"
              placeholder="제목을 작성해 주세요 (최대 20글자)"
              maxlength="20"
            />
            <div className="editor-util">
              <p class="message">{errors?.blt_title?.message}</p>
            </div>
          </div>

          {/* E : 자서전 일 경우 제목 추가 */}

          <div className="write-form">
            <div className="box-editor has-editor">
              {/* 에디터 입력 경우일 때만 has-editor 추가(내부 여백값 삭제) */}
              <div>
                <LifeCanvasQuillEditor
                  type="text"
                  id="contents"
                  className="input"
                  value={contents}
                  onChange={changeContents}
                  style={{ minHeight: '50vh' }}
                />
              </div>
            </div>

            <div className="editor-util">
              <div className="select-public-type">
                <select id="sel-public" {...register('display_yn')}>
                  <option value="Y">전체 공개</option>
                  <option value="S">구독자 공개</option>
                  <option value="N">비공개</option>
                </select>
              </div>
              <p class="message">{errors?.blt_contents?.message}</p>
            </div>
          </div>

          <div className="btn-wrap">
            <a href={() => false} className="btn btn-white" onClick={() => handleClose()}>
              취소
            </a>
            <button
              type="button"
              className="btn btn-green"
              // onClick={() => {
              //   handleSubmit(onSubmit, onError);
              //   handleGoogle();
              // }}
              onClick={handleSubmit(onSubmit, onError)}
            >
              올리기
            </button>
          </div>
        </div>

        {/* S : 글쓰기 하단 */}
        {/* <div className="write-bottom">
          <ul className="contents-width is-write">
            <li>
              <a
                href={() => false}
                onClick={() => handleMove('/life/everyone/bucketlist/register')}
              >
                <i className="ic ic-write-bucket"></i>버킷리스트
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/faq/register')}>
                <i className="ic ic-write-faq"></i>문답
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/daily/register')}>
                <i className="ic ic-write-life"></i>일상
              </a>
            </li>
          </ul>
        </div> */}
        {/* E : 글쓰기 하단 */}
      </div>
      {/* E : CONTENT */}
      {openHistoryTemplateDialog && (
        <HistoryTemplateDialog
          isOpen={openHistoryTemplateDialog}
          selectedTemplate={selectedTemplate}
          onClose={() => setOpenHistoryTemplateDialog(false)}
        />
      )}
      {/* {openHistorySampleDialog && (
        <HistorySampleDialog
          isOpen={openHistorySampleDialog}
          onClose={() => setOpenHistorySampleDialog(false)}
        />
      )} */}
    </section>
  );
}

export default HistoryModify;

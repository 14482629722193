import Dialog from '@mui/material/Dialog';
import useDialog from 'stores/dialog/hook';
import copy from 'copy-to-clipboard';
import configs from '@configs/configs';
import { useLocation } from 'react-router-dom';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import useScript from '../../../../../utils/useScript';

import { getMessageTemplateViewApi } from 'apis/messageTemplate';
import { getCommentCategoryApi } from '../../../../../apis/comment';
import { current } from '@reduxjs/toolkit';

function RegisterCommentCategoryDialog({ isOpen, onClose, memorialId, currentLastUrl }) {
  const [message, setMessage] = useState(' ');

  const { showAlert, hideAlert } = useDialog();
  const { location } = useLocation();
  // const [currentLastUrl, setCurrentLastUrl] = useState('');
  const [pathName, setPathName] = useState('');
  const [messageTemplateView, setMessageTemplateView] = useState([]);
  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');
  // const currentUrl = `${configs.BASEURL}/memorial/memorial/view/${memorial.memorial_id}`;

  const currentUrl = `${configs.BASEURL}` + pathName + '/' + currentLastUrl.category_id;

  const shreadKakaoLink = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'text',
      text: message
        .replace('#추모모임#', currentLastUrl.category_name)
        .replace('#바로가기#', currentUrl)
        .replace(
          '#비밀번호#',
          currentLastUrl.blt_password ? '입장 비밀번호 : ' + currentLastUrl.blt_password : ' ',
        ),
      link: {
        webUrl: currentUrl,
        mobileWebUrl: currentUrl,
      },
    });
  };

  const shreadLineLink = () => {
    const sns_title = messageTemplateView[0].title;
    const sns_summary = messageTemplateView[0].contents
      .replace('#추모모임#', currentLastUrl.category_name)
      .replace('#바로가기#', currentUrl)
      .replace(
        '#비밀번호#',
        currentLastUrl.blt_password ? '입장 비밀번호 : ' + currentLastUrl.blt_password : ' ',
      );
    const sns_br = '\n';
    //const sns_link = currentUrl;
    const sns_img = '';
    const url = 'http://line.me/R/msg/text/?' + encodeURIComponent(sns_summary);
    window.location.href = url;
  };

  const shreadSms = () => {
    if (isMobile) {
      if (isAndroid) {
        window.location.href = `sms:?body=${message
          .replace(/(\n|\r\n)/g, '%0a')
          .replace('#추모모임#', currentLastUrl.category_name)
          .replace('#바로가기#', currentUrl)
          .replace(
            '#비밀번호#',
            currentLastUrl.blt_password ? '입장 비밀번호 : ' + currentLastUrl.blt_password : ' ',
          )}`;
      } else if (isIOS) {
        window.location.href = `sms:&body=${message
          .replace(/(\n|\r\n)/g, '%0a')
          .replace('#추모모임#', currentLastUrl.category_name)
          .replace('#바로가기#', currentUrl)
          .replace(
            '#비밀번호#',
            currentLastUrl.blt_password ? '입장 비밀번호 : ' + currentLastUrl.blt_password : ' ',
          )}`;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const getMessageTemplateView = async params => {
    const result = await getMessageTemplateViewApi(params);
    // alert(JSON.stringify(result));
    setMessageTemplateView(result.result);
    setMessage(result.result[0].contents);
  };

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  useEffect(() => {
    if (window.location.pathname.indexOf(`/memorial/memorial/view/${memorialId}` + '/') > -1) {
      const splitUrl = window.location?.pathname?.split('/');
      const location = splitUrl?.length > 1 ? splitUrl[splitUrl.length - 1] : null;
      setPathName(window.location.pathname.replace('/' + location, ''));
    } else if (
      window.location.pathname.indexOf(`/memorial/obituary/view/${memorialId}` + '/') > -1
    ) {
      const splitUrl = window.location?.pathname?.split('/');
      const location = splitUrl?.length > 1 ? splitUrl[splitUrl.length - 1] : null;
      setPathName(window.location.pathname.replace('/' + location, ''));
    } else {
      setPathName(window.location.pathname.replace('/board', ''));
    }
  }, [location]);

  useEffect(() => {
    getMessageTemplateView({ memorial_id: memorialId, message_type: 'G' });
    //alert('dd' + categoryName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (memorialId) getMessageTemplateView({ memorial_id: memorialId, message_type: '0' });
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [memorialId]);

  const shreadLink = async () => {
    try {
      copy(currentUrl);
      showAlert('알림', '주소 복사가 완료되었습니다.', () => hideAlert());
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '주소 복사에 실패하였습니다.', () => hideAlert());
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxwidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal is-notice" id="modal-meeting-open-confirm" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모모임 등록이 완료되었습니다.</h2>
                <p className="desc">공유하기</p>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <ul className="list-obituary-share">
                  <li>
                    <a href={() => false} onClick={() => shreadSms()}>
                      <span className="share-sms">
                        <i className="ic ic-share-sms"></i>
                      </span>
                      문자
                    </a>
                  </li>
                  <li>
                    <a href={() => false} onClick={() => shreadKakaoLink()}>
                      <span className="share-kakao">
                        <i className="ic ic-share-kakao"></i>
                      </span>
                      카카오톡
                    </a>
                  </li>
                  <li>
                    <a href={() => false} onClick={() => shreadLineLink()}>
                      <span className="share-line">
                        <i className="ic ic-share-line"></i>
                      </span>
                      라인
                    </a>
                  </li>
                  <li>
                    <a href={() => false} onClick={() => shreadLink()}>
                      <span className="share-link">
                        <i className="ic ic-share-link"></i>
                      </span>
                      링크 복사
                    </a>
                  </li>
                </ul>
                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn-blue"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
export default RegisterCommentCategoryDialog;

import { useState, useEffect, forwardRef } from 'react';
import { getCanvasBltsSubApi, getCanvasBltApi } from 'apis/canvasBlt';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

let selectedValue = null;

function OpenJoinHistoryDialog({
  isOpen,
  getSelectedValue,
  setBltId,
  canvasId,
  setResponseId,
  onClose,
}) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

  const { loginUser } = useLogin();
  const { setGnbType } = useLayout();

  const { showLifeAlert, hideLifeAlert } = useDialog();

  const [canvasBlts, setCanvasBlts] = useState('');
  const [canvasBltId, setCanvasBltId] = useState();

  const getCanvasBltsSub = async () => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };

    if (loginUser?.member_id) params.member_id = setResponseId;

    params.blt_type = '2';
    params.title_use = 'Y';
    params.canvas_id = canvasId;

    const result = await getCanvasBltsSubApi(params);

    if (result.code === '0000') {
      setCanvasBlts(result.data);
      console.log(canvasBlts);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  // const getCanvasBlt = async () => {
  //   let params = {};
  //   params.blt_id = canvasBltId;

  //   const result = await getCanvasBltApi(params);

  //   if (result.code === '0000') {
  //     Object.entries(result.data).forEach(([key, value]) => {
  //       //setValue(key, value);
  //       if (key === 'blt_contents') setBltcontent(value);
  //     });
  //   } else {
  //     showLifeAlert('알림', result.message, () => hideLifeAlert());
  //   }
  // };

  const handleList = (value, id) => {
    selectedValue = value;
    setBltId(id);
  };

  const handleSelected = () => {
    if (selectedValue) {
      getSelectedValue(selectedValue);
      //setCanvasBltId()
      //getCanvasBlt();
    } else {
      alert('내 자서전 제목을 선택하세요.');
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getCanvasBltsSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (loginUser?.canvas_id) init();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loginUser]);

  // const init = () => {
  //   // await getCanvas();

  // };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        {/* S : 내 자서전 글 불러오기 */}
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-sample-02" aria-hidden="true">
            <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">내 자서전 글 불러오기</h2>
                  <p className="modal__desc">* 조문보는 최대 5개까지 등록 가능합니다</p>
                  <button
                    className="modal__close"
                    aria-label="Close modal"
                    data-micromodal-close=""
                    onClick={onClose}
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  {/* S : 템플릿 리스트 */}
                  <ul>
                    <li>
                      {canvasBlts &&
                        canvasBlts.length > 0 &&
                        canvasBlts.map(canvasBlt => (
                          <div className="rc-item" key={canvasBlt.blt_id}>
                            <input
                              type="radio"
                              name="sample-101"
                              id={canvasBlt.blt_id}
                              value={canvasBlt.blt_title}
                              onChange={e => handleList(e.target.value, e.target.id)}
                            />
                            <label htmlFor={canvasBlt.blt_id}>{canvasBlt.blt_title}</label>
                          </div>
                        ))}
                    </li>
                  </ul>
                  {/* E : 템플릿 리스트 */}
                </div>

                <div className="modal__footer">
                  <button type="button" className="btn btn-blue" onClick={() => handleSelected()}>
                    확인
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-micromodal-close=""
                    onClick={onClose}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default OpenJoinHistoryDialog;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLayout from 'stores/layout/hook';
import Login from 'layouts/memorial-canvas/Login';
import useLogin from 'stores/login/hook';
import useSearch from 'stores/search/hook';

function Gnb() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('loginUser'));
  const { searchAllValue, setSearchAllValue } = useSearch();

  const { loginUser, logout, openLogin, setOpenLogin } = useLogin();

  const breakPoint = window.matchMedia('(max-width: 1024px)');

  const { gnbType } = useLayout();
  const [isFixOn, setFixOn] = useState(false);
  const [isMobileMenuOn, setMobileMenuOn] = useState(false);

  const [isActive, setActive] = useState(false);

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const onChange = event => {
    setSearchAllValue(event.target.value);
  };

  const handleSearch = () => {
    navigate('/memorial/search');
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleLogout = () => {
    logout();
    navigate('/pet');
  };

  const handleMobileMenu = path => {
    navigate(path);
    handleMobileMenuOn();
  };

  const handleMobileMenuOn = () => {
    setMobileMenuOn(prevState => !prevState);
  };

  const moveMenu = url => {
    setActive(false);
    // navigate(url);
    window.location.replace(url);
  };

  useEffect(() => {
    function handleFixOn() {
      setFixOn(window.scrollY > 0);
    }

    window.addEventListener('scroll', handleFixOn);
    handleFixOn();

    return () => {
      window.removeEventListener('scroll', handleFixOn);
    };
  }, []);

  return (
    <>
      <header
        className={`is-memorial ${isFixOn ? 'is-fixed' : ''} ${
          loginUser?.member_id ? 'is-member' : ''
        } ${isActive ? (breakPoint.matches ? 'is-open' : 'is-open is-active') : ''}`}
      >
        <div className="contents-width">
          <h1>
            <a href={() => false} onClick={() => moveMenu('/pet')}>
              <span>PET MEMORIAL CANVAS</span>
            </a>
          </h1>

          <div className="header-util">
            <div className="box-user">
              {/* S : (로그인 후) 회원 프로필 이미지, 닉네임, 버튼  */}
              {loginUser?.member_id ? (
                <>
                  <div className="user-profile">
                    <p>{`${userInfo?.member_name} 님`}</p>
                    <p className="desc">전하고 싶은 마음을 전달하세요</p>
                    <ul>
                      <li className="mypage">
                        <a href={() => false} onClick={() => moveMenu('/pet/mypage/obituary')}>
                          마이페이지
                        </a>
                      </li>
                      {/* <li>
                        <a href={() => false} onClick={() => alert('디지털 화환 준비중')}>
                          디지털 화환
                        </a>
                      </li>
                      <li>
                        <a href={() => false} onClick={() => alert('활동내역 준비중')}>
                          활동내역
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  {/* E : (로그인 후) 회원 프로필 이미지, 닉네임, 버튼 */}
                  {/* S : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                  <ul>
                    {/* <li className="d-block d-md-none">
                      <a href={() => false}>
                        <i className="ic ic-wreath"></i>
                        <span>디지털 화환</span>
                      </a>
                    </li> */}
                    {/* S : 알림 없을 때 */}
                    {/* <li>
                      <a href={() => false}>
                        <i className="ic ic-notice"></i>
                        <span className="offscreen">알림</span>
                      </a>
                    </li> */}
                    {/* E : 알림 없을 때 */}
                    {/* S : 알림 있을 때 */}
                    {/* <li>
                      <a href={() => false}>
                        <i className="ic ic-notice"></i>
                        <span className="offscreen">알림</span>
                        <i className="ic-new">
                          <span className="offscreen">새로운 알림</span>
                        </i>
                      </a>
                    </li> */}
                    {/* E : 알림 있을 때 */}
                    <li className="mypage">
                      <a href={() => false} onClick={() => moveMenu('/pet/mypage/obituary')}>
                        <i className="ic ic-profile"></i>
                        <span className="offscreen">마이페이지</span>
                      </a>
                    </li>
                    {/* S : 22-09-23 관리자 링크 추가 */}
                    {loginUser.role === 'ADMIN' ? (
                      <li className="d-block d-md-none">
                        <a href="/admin/obituary">관리자</a>
                      </li>
                    ) : null}
                    {/* E : 22-09-23 관리자 링크 추가 */}
                  </ul>
                  {/* E : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                </>
              ) : (
                <>
                  <div className="user-profile">
                    <p>MEMORIAL CANVAS</p>
                    <p className="desc">사랑하는 사람을 추억합니다.</p>
                    <ul>
                      <li>
                        {/* <a href={() => false} onClick={handleOpenLogin}> */}
                        <a href={() => false} onClick={() => moveMenu('/pet/login')}>
                          로그인
                        </a>
                      </li>
                      <li>
                        <a href={() => false} onClick={() => moveMenu('/pet/login/register')}>
                          회원가입
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* E : (로그인 전) 멘트  */}

                  {/* S : 버튼 영역(회원가입/로그인) */}
                  <ul>
                    <li className="d-block d-md-none">
                      <a href={() => false} onClick={() => moveMenu('/pet/login/register')}>
                        회원가입
                      </a>
                    </li>
                    <li className="d-block d-md-none">
                      <a href={() => false} onClick={() => moveMenu('/pet/login')}>
                        로그인
                      </a>
                    </li>
                  </ul>
                  {/* E : 버튼 영역(회원가입/로그인) */}
                  {/* S : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                  {/*
					<ul>
						<li className="d-block d-md-none"><a href={()=>false}><i className="ic ic-write"></i><span>기록하기</span></a></li>
						&lt;!&ndash; S : 알림 없을 때 &ndash;&gt;
						<li className="d-block d-md-none"><a href={()=>false}><i className="ic ic-notice"></i><span className="offscreen">알림</span></a></li>
						&lt;!&ndash; E : 알림 없을 때 &ndash;&gt;
						&lt;!&ndash; S : 알림 있을 때 &ndash;&gt;
						<li className="d-block d-md-none"><a href={()=>false}><i className="ic ic-notice"></i><span className="offscreen">알림</span><i className="ic-new"><span className="offscreen">새로운 알림</span></i></a></li>
						&lt;!&ndash; E : 알림 있을 때 &ndash;&gt;
						<li className="mypage"><a href={()=>false}><i className="ic ic-profile"></i><span className="offscreen">마이페이지</span></a></li>
						<li className="d-block d-md-none"><a href={()=>false}><i className="ic ic-setting"></i><span className="offscreen">설정</span></a></li>
					</ul>
					*/}
                  {/* E : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                </>
              )}
            </div>
          </div>
          {/* E : 기록하기/알림/마이페이지/설정/로그인/회원가입 버튼  */}
          {/* S : 메뉴 */}
          <nav id="gnbMenuWrap">
            <ul>
              <li>
                <a href={() => false} onClick={() => moveMenu('/pet/obituary')}>
                  부고
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveMenu('/pet/memorial')}>
                  추모관
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveMenu('/pet/community')}>
                  캔버스소식
                </a>
              </li>
            </ul>
            <ul className="extra-menu">
              <li>
                <a href={() => false} onClick={() => moveMenu('/pet/service')}>
                  펫 메모리얼 캔버스 소개
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveMenu('/pet/faq')}>
                  자주 묻는 질문
                </a>
              </li>
              <li className="go-mylifecanvas">
                <a href="/">마이라이프캔버스로 이동</a>
              </li>
            </ul>
          </nav>
          {/* E : 메뉴 */}
          <button className="toggle-gnb btn-toggle-gnb" onClick={() => setActive(!isActive)}>
            <span className="bar"></span>
            <span className="offscreen">전체메뉴</span>
          </button>
          <span className="nav-bg"></span>
          <span className="overlay"></span>
        </div>
      </header>
      <Login isOpen={openLogin} onClose={() => setOpenLogin(false)} />
      {/* E: HEADER */}
    </>
  );
}

export default Gnb;

import Dialog from '@mui/material/Dialog';
import { deleteCommentCategoryApi, getCommentCategoryApi } from 'apis/comment';

import useDialog from 'stores/dialog/hook';
import 'css/popup.css';
import { useEffect, useState } from 'react';

function DeleteommentCategoryDialog({
  isOpen,
  onClose,
  handleChangeSelectedCategory,
  callBack,
  categoryId,
}) {
  const { showAlert, hideAlert } = useDialog();

  const handleDelete = async () => {
    const result = await deleteCommentCategoryApi(categoryId);
    showAlert('알림', result.message, () => handleClose());
  };

  const handleClose = () => {
    callBack(1);
    handleChangeSelectedCategory({ category_id: '' });
    hideAlert();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal is-notice" id="modal-remembrance-04" aria-hidden="false">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close="">
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">
                  <i className="ic ic-warning"></i>삭제 확인
                </h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close=""
                >
                  <i className="ic ic-close" onClick={onClose}></i>
                </button>
              </div>
              <div className="modal__content">
                <p>
                  해당 추모 모임 글이 모두 삭제 됩니다. <br />
                  삭제를 원하시면 '확인' 버튼을 클릭해주세요.
                </p>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close=""
                  onClick={handleDelete}
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close=""
                  onClick={onClose}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteommentCategoryDialog;

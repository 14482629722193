import useDialog from '../../../../../stores/dialog/hook';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  deleteCommentApi,
  deleteCommentVerifyApi,
  getAllCommentApi,
  getCommentApi,
  getCommentCategoryApi,
  getCommentsApi,
  getCommentsExceldownloadApi,
  modifyCommentApi,
  saveCommentApi,
} from 'apis/comment';

import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import useLogin from '../../../../../stores/login/hook';
import LoginDialog from '../LoginDialog';
import SaveCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useCodes from '../../../../../stores/code/hook';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import VerifyPasswordDialog from '../VerifyPasswordDialog';
import ModifyCommentCategoryDialog from '../CommentCategoryDialog/modify';
import DeleteCommentCategoryDialog from '../CommentCategoryDialog/delete';
import VerifyCommentCategoryPasswordDialog from '../VerifyCommentCategoryPassword';

import * as FileSaver from 'file-saver';
import loading_test from '../../../../../css/static/media/loading.gif';

function CommentGroupDialog({
  isOpen,
  onClose,
  memorialId,
  ownMemberId,
  handleChangeSelectedCategory,
}) {
  const { commentTemplateCodes } = useCodes();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { loginUser } = useLogin();
  // const userInfo = JSON.parse(localStorage.getItem('loginUser'));
  const { pageEnd } = useRef(null);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(0);
  const [ref, inView] = useInView();
  const [tabIndex, setTabIndex] = useState('');
  const [url, setUrl] = useState('');

  const schema = Yup.object({
    writer_name: Yup.string()
      .max(30, '* 작성자명은 최대 30글자까지 입력이 가능합니다.')
      .required('* 작성자명을 입력하세요.'),
    blt_password: Yup.string()
      .max(30, '* 비밀번호는 최대 30글자까지 입력이 가능합니다.')
      .required('* 비밀번호를 입력하세요.'),
    blt_contents: Yup.string()
      .max(3000, '* 추모글은 최대 3000글자까지 입력이 가능합니다.')
      .required('* 추모글을 입력하세요.'),
  }).required();

  const defaultValues = {
    memorial_id: memorialId,
    member_id: loginUser && loginUser.member_id ? loginUser.member_id : 'guest',
    writer_name: loginUser && loginUser.member_id ? loginUser.member_name : '',
    blt_password: loginUser && loginUser.member_id ? loginUser.member_id : '',
    blt_contents: '',
    display_yn: 'Y',
    medias: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [page, setPage] = useState(1);
  const [listSize] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [openVerifyPasswordDialog, setOpenVerifyPasswordDialog] = useState(false);
  const [openModPasswordDialog, setOpenModPasswordDialog] = useState(false);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [media, setMedia] = useState(null);
  const [openMediaAlbumDialog, setOpenMediaAlbumDialog] = useState(false);
  const [displayYn, setDisplayYn] = useState(true);

  //페이지 state 1:글목록 0:글쓰기
  const [pageName, setPageName] = useState(1);
  //수정할 commentId
  const [modCommentId, setModCommentId] = useState(0);
  const [modCommentIdTemp, setModCommentIdTemp] = useState(0);
  //수정할 글
  const [contentMod, setContentMod] = useState(null);
  const [contentModTemp, setContentModTemp] = useState(null);
  const [contentPassword, setContentPassword] = useState('');
  //답글 commentId
  const [repCommentId, setRepCommentId] = useState('');
  //검색 타입 작성자:writer 내용:content
  const [searchType, setSearchType] = useState('writer');
  //검색 타입 작성자:writer 내용:content
  const [searchText, setSearchText] = useState('');
  //선택다운로드 체크박스
  const [checkedList, setCheckedList] = useState([]);
  //전체선택 체크박스
  const [allChecked, setAllChecked] = useState(false);
  const [itemIndex, setitemIndex] = useState(0);

  const [content, setContent] = useState(null);
  const [openContentViewDialog, setOpenContentViewDialog] = useState(false);

  const [contentValChk, setContentValChk] = useState('');

  // 비밀번호 체크 (추가 0503)
  const [check, setCheck] = useState(0);
  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  const handleViewCommentInfo = commentId => {
    getComment(commentId);
    setOpenContentViewDialog(true);
  };

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    saveComment(data);
  };

  const saveComment = async params => {
    if (pageName === 0) {
      params.parent_comment_id = '0';
      params.category_id = selectedCommentCategory.category_id;
    } else {
      params.category_id = selectedCommentCategory.category_id;
    }
    params.comment_yn = 'Y';
    const result = await saveCommentApi(params);
    if (result.code === '0000') {
      showAlert('알림', '추모글 등록이 완료되었습니다', () => handleClose());
    } else {
      showAlert('알림', result.message, () => handleClose());
    }

    categoryCnt(1);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const [comments, setComments] = useState(null);
  const [commentsExcel, setCommentsExcel] = useState(null);
  const [medias, setMedias] = useState([]);

  const getComments = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.comment_yn = 'Y';
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.search_type = searchType;
    params.search_text = searchText;
    params.category_id = selectedCommentCategory.category_id;

    setOpenLoadingDialog(true);
    const result = await getCommentsApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setComments(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  // const getCommentsExcel = async () => {
  //   if (checkedList.length === 0) {
  //     showAlert('알림', '다운로드할 글을 선택해 주세요.', () => hideAlert());
  //   } else {
  //     let params = {};
  //     params.memorial_id = memorialId;
  //     params.category_id = selectedCommentCategory.category_id;
  //     params.comment_ids = checkedList;
  //     params.search_type = searchType;
  //     params.search_text = searchText;
  //
  //     setOpenLoadingDialog(true);
  //     await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
  //     setOpenLoadingDialog(false);
  //   }
  // };

  // const getAllCommentsExcel = async () => {
  //   if (comments.length === 0) {
  //     showAlert('알림', '다운로드할 게시글이 없습니다.', () => hideAlert());
  //   } else {
  //     let params = {};
  //     params.memorial_id = memorialId;
  //     params.category_id = selectedCommentCategory.category_id;
  //     params.search_type = searchType;
  //     params.search_text = searchText;
  //
  //     setOpenLoadingDialog(true);
  //     await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
  //     setOpenLoadingDialog(false);
  //   }
  // };

  // const getCommentsMore = async pageNum => {
  //   let params = {};
  //   params.memorial_id = memorialId;
  //   //params.orderBy = { direction: 'desc', field: 'comment_id' };
  //   params.page = pageNum;
  //   params.itemInPage = listSize;
  //   params.search_type = searchType;
  //   params.search_text = searchText;
  //   params.category_id = selectedCommentCategory.category_id;
  //
  //   setOpenLoadingDialog(true);
  //   const result = await getCommentsApi(params);
  //   setOpenLoadingDialog(false);
  //   if (result.code === '0000') {
  //     setComments([...comments, ...result.data]);
  //     setTotalCount(result.data_cnt);
  //   } else {
  //     showAlert('알림', result.message, () => hideAlert());
  //   }
  // };

  const getComment = async comment_id => {
    let params = {};
    // 필수
    params.memorial_id = memorialId;
    params.comment_id = comment_id + '';
    params.category_id = selectedCommentCategory.category_id;
    // 검색
    params.search_type = searchType;
    params.search_text = searchText;

    const result = await getCommentApi(params);
    // showAlert('알림', result.message, () => hideAlert());
    setCommentInfo(result.data);
  };

  // 추모의 글 상세
  const [commentInfo, setCommentInfo] = useState({});

  // 추모 카테고리 state 홍순규
  const [commentCategory, setCommentCategory] = useState([]);
  const [catPage, setCatPage] = useState(1);
  const [catListSize] = useState(5);
  const [catTotalPage, setCatTotalPage] = useState(0);
  const [catTotalCount, setCatTotalCount] = useState(0);
  const [selectedCommentCategory, setSelectedCommentCategory] = useState({ category_id: '22' });
  const [showModify, setShowModify] = useState(false);
  const [CategorySearchText, setCategorySearchText] = useState('');
  const [CategorySearchType, setCategorySearchType] = useState('category_name');
  const [currCategory, setCurrCategory] = useState({});

  // 추모 모임 팝업(저장,수정,삭제) + 로그인 체크 팝업 + 비밀방 비밀번호 체크 팝업
  const [selectedCommentCategoryName, setSelectedCommentCategoryName] = useState('추모글 남기기');
  const [selectedCommentCategoryDesc, setSelectedCommentCategoryDesc] = useState('');
  const [openCommentGroupDialog, setOpenCommentGroupDialog] = useState(false);
  const [openSaveCommentCategoryDialog, setOpenSaveCommentCategoryDialog] = useState(false);
  const [openModifyCommentCategoryDialog, setOpenModifyCommentCategoryDialog] = useState(false);
  const [openDeleteCommentCategoryDialog, setOpenDeleteCommentCategoryDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openVerifyCommentCategoryDialog, setOpenVerifyCommentCategoryDialog] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [currentMedia, setCurrentMedia] = useState({});
  // 추모카테고리
  const getCommentCategory = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;

    if (isMobile) {
      // 모바일은 전체 로드
      params.itemInPage = 1000;
    } else {
      // 변경해야함 0503
      params.itemInPage = catListSize;
    }

    if (loginUser.member_id) {
      params.create_member_id = loginUser.member_id;
    }
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    const result = await getCommentCategoryApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory(result.data);
      setCatPage(1);
      setCatTotalPage(parseInt(Math.ceil((result.data_cnt + 1) / catListSize)));

      setCatTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getCommentCategoryMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = catListSize;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    setLoading(true);
    const result = await getCommentCategoryApi(params);

    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory([...commentCategory, ...result.data]);
      setCatTotalCount(result.data_cnt);
    } else {
      //showAlert('알림', result.message, () => hideAlert());
    }
    setLoading(false);
  };

  const handleOpenSaveCommentCategory = () => {
    // 로그인 체크
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    setOpenSaveCommentCategoryDialog(true);
  };

  const handleOpenModifyCommentCategory = () => {
    setShowModify(false);
    setOpenModifyCommentCategoryDialog(true);
  };

  const handleOpenDeleteCommentCategory = () => {
    setShowModify(false);
    setOpenDeleteCommentCategoryDialog(true);
  };

  // const handleChangeSelectedCategory_bak = category => {
  //   // 로그인 체크
  //   if (category.category_id != '' && !loginUser.member_id) {
  //     handleOpenLoginDialog();
  //     return;
  //   }
  //   //console.log(category);
  //
  //   setSelectedCommentCategoryName(category.category_name);
  //   setSelectedCommentCategoryDesc(category.category_desc);
  //
  //   // 비밀방 체크
  //   if (category.display_yn === 'N' && !(loginUser.member_id === ownMemberId)) {
  //     setCurrCategory(() => category);
  //     setOpenVerifyCommentCategoryDialog(true);
  //   } else {
  //     // active 처리
  //     setSelectedCommentCategory(category);
  //   }
  // };

  const handleShowModify = category => {
    //alert(JSON.stringify(category));
    setSelectedCommentCategory(category);
    setShowModify(true);
  };

  // const handleMoreCommentCategory = pageNum => {
  //   const nextPage = pageNum + 1;
  //   if (nextPage > catTotalPage) {
  //     showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
  //   } else {
  //     setCatPage(nextPage);
  //     getCommentCategoryMore(nextPage);
  //   }
  // };

  const handleMoreCommentCategory = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > catTotalPage) {
      if (catPage === 1) {
        return;
      }
      //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
      setFinish(1);
    } else {
      setCatPage(nextPage);
      getCommentCategoryMore(nextPage);
    }
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  // const handleChangeCategorySearchText = event => {
  //   setCategorySearchText(event.target.value);
  // };
  //
  // const handleCategorySearch = () => {
  //   getCommentCategory(1);
  // };
  //
  // const handleKeyPressCategorySearch = event => {
  //   if (event.key === 'Enter') {
  //     getCommentCategory(1);
  //   }
  // };
  //
  // const handleOpenAlbumDialog = (media, index) => {
  //   setCurrentMedia(media);
  //   setInitialSlide(index);
  //   setOpenMediaAlbumDialog(true);
  // };

  // 추모의 방 선택 로직 수정
  const handleSelectedCategory = category => {
    //alert('카테고리' + JSON.stringify(category));
    if (category.category_id === '') {
      handleChangeSelectedCategory({ category_name: '추모글 남기기' });
      onClose();
    }
    // else if (
    //   category.display_yn === 'N' &&
    //   !(loginUser.member_id === ownMemberId) &&
    //   check === 0
    // ) {
    //   setCurrCategory(() => category);
    //   alert('들어왔?..' + JSON.stringify(currCategory));
    //   setOpenVerifyCommentCategoryDialog(true);
    //   // handleChangeSelectedCategory(category);
    // }
    else {
      //alert(JSON.stringify(category));
      handleChangeSelectedCategory(category);
      onClose();
    }
  };

  useEffect(() => {
    if (inView && !loading) {
      handleMoreCommentCategory(catPage);
    }
  }, [inView, loading, catPage]);

  // useEffect(() => {
  //   setShowModify(() => false);
  //   // 댓글 호출 api
  //   getCommentCategory(1);
  //
  //   setValue('category_id', selectedCommentCategory.category_id);
  //
  //   setAllChecked(false);
  // }, [selectedCommentCategory]);

  useEffect(() => {
    setCheckedList([]);
  }, [allChecked]);
  // end 홍순규
  // const handlePageChange = selectedPage => {
  //   setPage(selectedPage);
  //   getComments(selectedPage);
  // };

  const handleClose = () => {
    reset();
    setMedias([]);
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    getComments(1);

    hideAlert();

    //추모글 리스트 페이지로 이동
    movePage(1);
    //추모글 리스트 페이지로 수정 textarea 숨김
    setModCommentId(0);
    //추모글 답글 input 숨김
    setRepCommentId('');
  };

  const [selectedCommentId, setSelectedCommentId] = useState(null);

  // const handleDelete = (commentId, depth, categoryId) => {
  //   if (depth === 1) {
  //     if (categoryId !== null && categoryId !== '') {
  //       showConfirm(
  //         '삭제 확인',
  //         '해당 추모의 글의 답글이 모두 삭제 됩니다. \n 삭제 하시겠습니까?',
  //         () => handleConfirm(commentId),
  //         () => hideConfirm(),
  //       );
  //     } else {

  //       showConfirm(
  //         '삭제 확인',
  //         '해당 추모의 글을 삭제 하시겠습니까?',
  //         () => handleConfirm(commentId),
  //         () => hideConfirm(),
  //       );
  //     }
  //   } else {
  //     showConfirm(
  //       '삭제 확인',
  //       '정말 삭제 하시겠습니까?',
  //       () => handleConfirm(commentId),
  //       () => hideConfirm(),
  //     );
  //   }
  // };

  // const handleChangeDiaplayYn_ = () => {
  //   setDisplayYn(!displayYn);
  // };

  // const handleChangeDiaplayYn = event => {
  //   // const isChecked = event.target.checked ? 'Y' : 'N';
  //   //alert(event.target.checked);
  //   setDisplayYn(event.target.checked);
  // };
  //
  // const handleChangeContentMod = event => {
  //   setContentMod(event.target.value);
  //   if (event.target.value === '' || event.target.value === null) {
  //     setContentValChk('* 추모글을 입력하세요.');
  //   } else {
  //     setContentValChk('');
  //   }
  // };
  //
  // /**
  //  * 글수정 이벤트
  //  */
  // const handleModifySubmit = () => {
  //   if (contentMod === '' || contentMod === null) {
  //     setContentValChk('* 추모글을 입력하세요.');
  //   } else {
  //     let data = {};
  //     data.comment_id = modCommentId;
  //     data.blt_contents = contentMod;
  //     modComment(data);
  //     setContentValChk('');
  //   }
  // };

  // /**
  //  * 글수정
  //  * @param {입력 param} params
  //  */
  // const modComment = async params => {
  //   const result = await modifyCommentApi(params);
  //   showAlert('알림', result.message, () => handleClose());
  // };

  /**
   * 답글 노출
   * @param {글번호} commentId
   */
  // const handleReply = commentId => {
  //   if (repCommentId === commentId) {
  //     setRepCommentId('');
  //   } else {
  //     setRepCommentId(commentId);
  //   }
  //   setModCommentId(0);
  // };
  //
  // const onCheckedElement = (checked, item) => {
  //   if (checked) {
  //     setCheckedList([...checkedList, item]);
  //   } else if (!checked) {
  //     setCheckedList(checkedList.filter(el => el !== item));
  //   }
  // };
  //
  // const isChecked = commentId => {
  //   return checkedList.includes(commentId);
  // };
  //
  // const onAllChecked = async checked => {
  //   setAllChecked(() => !allChecked);
  //
  //   if (checked) {
  //     // 전체 추모의 글 요청
  //     let params = {};
  //     params.memorial_id = memorialId;
  //     params.category_id = selectedCommentCategory.category_id;
  //     params.search_type = searchType;
  //     params.search_text = searchText;
  //
  //     setOpenLoadingDialog(true);
  //     const result = await getAllCommentApi(params);
  //
  //     setOpenLoadingDialog(false);
  //     if (result.code === '0000') {
  //       setCheckedList(
  //         // 삭제된 글 제외
  //         // result.data.filter(comment => comment.use_yn === 'Y').map(comment => comment.comment_id)
  //
  //         // 삭제된 글 포함
  //         result.comment_ids,
  //       );
  //     }
  //
  //     // 전체체크
  //   } else {
  //     // 전체체크 해제
  //     setCheckedList(() => []);
  //   }
  // };

  const handleConfirm = commentId => {
    hideConfirm();
    deleteComment(commentId);
  };

  // const handleVerifyDelete = commentId => {
  //   setSelectedCommentId(commentId);
  //   setOpenVerifyPasswordDialog(true);
  // };

  const deleteCommentVerify = async params => {
    setOpenVerifyPasswordDialog(false);
    const result = await deleteCommentVerifyApi({
      comment_id: selectedCommentId,
      blt_password: params.password,
    });

    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  const deleteComment = async params => {
    const result = await deleteCommentApi(params);
    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  /**
   * 글 추가/삭제 시 category 표기 숫자 변경
   * @param {계산 숫자} arg
   */
  const categoryCnt = arg => {
    let selectedCateIndex = commentCategory.findIndex(
      cate => cate.category_id === selectedCommentCategory.category_id,
    );
    commentCategory[selectedCateIndex].cnt = commentCategory[selectedCateIndex].cnt + arg;
  };

  // const addMedia = media => {
  //   setMedias(prevState => [...prevState, media]);
  // };
  //
  // const removeMedia = index => {
  //   medias.splice(index, 1);
  //   setMedias([...medias]);
  // };
  //
  // const handleCommentTemplate = event => {
  //   const commentTemplate = event.target.value;
  //   setValue('blt_contents', commentTemplate);
  // };
  //
  // const handleSearchType = event => {
  //   setSearchType(event.target.value);
  // };
  //
  // const handleSearchText = event => {
  //   setSearchText(event.target.value);
  // };
  //
  // const handleSearch = e => {
  //   if (e.key === 'Enter') getComments(1);
  // };
  //
  // const handleMore = pageNum => {
  //   const nextPage = pageNum + 1;
  //   if (nextPage > totalPage) {
  //     //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
  //   } else {
  //     setPage(nextPage);
  //     getCommentsMore(nextPage);
  //   }
  // };

  /**
   * 글쓰기/목록 페이지 이동
   * @param {0:글쓰기 1:글목록} pageName
   */
  const movePage = pageName => {
    setPageName(pageName);
  };

  /**
   * 수정 버튼 이벤트
   */
  // const handleModify = (commentId, contents, password, memberId) => {
  //   if (memberId) {
  //     setModCommentId(commentId);
  //     setContentMod(contents);
  //   } else {
  //     setOpenModPasswordDialog(true);
  //     setModCommentIdTemp(commentId);
  //     setContentModTemp(contents);
  //     setContentPassword(password);
  //     setRepCommentId('');
  //   }
  // };
  const handleModifyView = params => {
    setOpenModPasswordDialog(false);
    if (contentPassword === params.password) {
      setModCommentId(modCommentIdTemp);
      setContentMod(contentMod);
    } else {
      showAlert('알림', '비밀번호가 일치하지않습니다. \n비밀번호를 확인해주시기 바랍니다.', () =>
        hideAlert(),
      );
    }
  };
  // const handleModifyClose = () => {
  //   setModCommentIdTemp(0);
  //   setContentModTemp(null);
  //   setContentPassword('');
  //   setModCommentId(0);
  //   setContentMod(null);
  //   setRepCommentId('');
  //   setContentValChk('');
  // };

  // const handleChangeCategorySearchType = e => {
  //   setCategorySearchType(e.target.value);
  // };
  //
  // const excelFileType =
  //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // const excelFileExtension = '.xlsx';
  // /**
  //  * excel download
  //  */
  // const excelDownload = excelData => {
  //   const ws = XLSX.utils.aoa_to_sheet([[selectedCommentCategoryName], [], ['작성자', '내용']]);
  //   excelData.map(data => {
  //     XLSX.utils.sheet_add_aoa(ws, [[data.writer_name, data.blt_contents]], {
  //       origin: -1,
  //     });
  //     ws['!cols'] = [
  //       //<-- 행 사이즈
  //       { wpx: 100 },
  //       { wpx: 500 },
  //     ];
  //     return false;
  //   });
  //   const wb = { Sheets: { sheet: ws }, SheetNames: ['sheet'] };
  //   const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const excelFile = new Blob([excelButter], { type: excelFileType });
  //   FileSaver.saveAs(excelFile, selectedCommentCategoryName + excelFileExtension);
  // };
  const saveCategory = page => {
    getComments(1);
    getCommentCategory(1);
    setFinish(0);
  };

  useEffect(() => {
    setValue('display_yn', displayYn ? 'Y' : 'N');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayYn]);

  useEffect(() => {
    setValue('medias', medias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  useEffect(() => {
    setValue('parent_comment_id', repCommentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repCommentId]);

  useEffect(() => {
    reset();
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    setValue(repCommentId);
    setValue('category_id', selectedCommentCategory.category_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    //alert('모임팝업 확인 데이터' + memorialId);
    if (memorialId) {
      getComments(1);
      getCommentCategory(1);
    }
    getComments(1);
    getCommentCategory(1);
    setFinish(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [pageName]);

  useEffect(() => {
    getComments(1);
    getCommentCategory(1);
    setFinish(0);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal " id="modal-meeting-list" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2
                  className="modal__title"
                  // onClick={() =>
                  //   //alert(memorialId + 'ddd' + ownMemberId + 'aaaa' + JSON.stringify(loginUser))
                  // }
                >
                  <i className="ic ic-flower"></i>추모 모임
                </h2>
                <p className="desc">
                  고인을 추모하고자 하는 분들이 모임을 생성하여
                  <br />
                  함께 추모하는 공간입니다.
                </p>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                {/* S : 전체 글로 가기 */}

                {/* E : 전체 글로 가기 */}
                {/* S : 추모 모임 목록 */}
                {commentCategory && commentCategory.length > 0
                  ? commentCategory.map((category, idx) =>
                      category.category_name === '추모의 글' ? (
                        ''
                      ) : (
                        // <div className="box-memorial-meeting is-all">
                        //   <button
                        //     type="button"
                        //     className="box-text"
                        //     onClick={() => handleSelectedCategory(category)}
                        //   >
                        //     <span className="title">
                        //       <span className="icon">
                        //         <i className="ic ic-arrow-prev-white">
                        //           <span className="offscreen">이전으로 돌아가기</span>
                        //         </i>
                        //       </span>
                        //       추모의 글
                        //     </span>
                        //   </button>
                        //   <div className="number">
                        //     <i className="ic ic-message-white"></i> {category.cnt}
                        //   </div>
                        // </div>
                        <div
                          className="box-memorial-meeting"
                          // onClick={
                          //   selectedCommentCategory.category_id === category.category_id
                          //     ? ''
                          //     : () => handleChangeSelectedCategory(category)
                          // }
                        >
                          <button
                            type="button"
                            className="box-text"
                            onClick={() => handleSelectedCategory(category)}
                          >
                            <span className="title" title={category.category_name}>
                              {category.category_name}
                              {category.display_yn === 'N' ? (
                                <i className="ic ic-secret">
                                  <span className="offscreen">비밀글</span>
                                </i>
                              ) : null}
                            </span>
                            {category.category_desc ? (
                              <span className="desc">{category.category_desc}</span>
                            ) : null}
                          </button>

                          <div className="number">
                            <i className="ic ic-message"></i> {category.cnt}
                          </div>

                          {category.category_id &&
                          (loginUser.member_id === ownMemberId || category.is_writer === 'Y') !=
                            '' ? (
                            <>
                              <button
                                type="button"
                                className="btn-manage"
                                onClick={() => handleShowModify(category)}
                              >
                                <i className="ic ic-manage"></i>
                                <span className="offscreen">설정</span>
                              </button>
                              {/*<div className="box-manage">*/}
                              {/*  <ul>*/}
                              {/*    <li onClick={handleOpenModifyCommentCategory}>*/}
                              {/*      <button type="button">수정</button>*/}
                              {/*    </li>*/}
                              {/*    {loginUser.member_id === ownMemberId ? (*/}
                              {/*      <li onClick={handleOpenDeleteCommentCategory}>*/}
                              {/*        <button type="button">삭제</button>*/}
                              {/*      </li>*/}
                              {/*    ) : (*/}
                              {/*      ''*/}
                              {/*    )}*/}
                              {/*  </ul>*/}
                              {/*</div>*/}
                              {category.category_id === selectedCommentCategory.category_id &&
                              showModify === true ? (
                                <div className="box-manage">
                                  <ul>
                                    <li onClick={handleOpenModifyCommentCategory}>
                                      <button type="button">수정</button>
                                    </li>
                                    {loginUser.member_id === ownMemberId ? (
                                      <li onClick={handleOpenDeleteCommentCategory}>
                                        <button type="button">삭제</button>
                                      </li>
                                    ) : (
                                      ''
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : null}
                        </div>
                      ),
                    )
                  : null}

                {/* E : 추모 모임 목록 */}
                {/* S : 등록 버튼 */}
                <div className="btn-memorial-metting-register">
                  <button type="button" onClick={handleOpenSaveCommentCategory}>
                    <i className="ic ic-plus"></i>
                    모임을 등록합니다.
                  </button>
                </div>
                {/* E : 등록 버튼 */}
                {/*  S : 페이징 */}
                <div className="btn-wrap" ref={ref}>
                  {/*<button type="button" className="btn btn-more" onClick={() => handleMore(page)}>*/}
                  {/*  더보기 {page}/{totalPage}*/}
                  {/*</button>*/}
                  {loading === true ? <img src={loading_test} alt="" /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VerifyPasswordDialog
        isOpen={openVerifyPasswordDialog}
        onClose={() => setOpenVerifyPasswordDialog(false)}
        callBack={deleteCommentVerify}
      />
      <VerifyPasswordDialog
        isOpen={openModPasswordDialog}
        onClose={() => setOpenModPasswordDialog(false)}
        callBack={handleModifyView}
      />
      <LoginDialog isOpen={openLoginDialog} onClose={() => setOpenLoginDialog(false)} />
      <SaveCommentCategoryDialog
        isOpen={openSaveCommentCategoryDialog}
        onClose={() => setOpenSaveCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
      />
      <ModifyCommentCategoryDialog
        isOpen={openModifyCommentCategoryDialog}
        onClose={() => setOpenModifyCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        category={selectedCommentCategory}
        commentCategory={commentCategory}
        setCommentCategory={setCommentCategory}
      />

      <DeleteCommentCategoryDialog
        isOpen={openDeleteCommentCategoryDialog}
        onClose={() => setOpenDeleteCommentCategoryDialog(false)}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        callBack={getCommentCategory}
        categoryId={selectedCommentCategory.category_id}
      />

      <VerifyCommentCategoryPasswordDialog
        isOpen={openVerifyCommentCategoryDialog}
        onClose={() => setOpenVerifyCommentCategoryDialog(false)}
        callBack={setSelectedCommentCategory}
        //handleSelectedCategory={handleSelectedCategory}
        //category={selectedCommentCategory}
        currCategory={currCategory}
        check={setCheck}
      />
    </Dialog>
  );
}
export default CommentGroupDialog;

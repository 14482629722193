import { useEffect, useRef, useState } from 'react';
import QuillEditor from 'components/editor/QuillEditor';
import { isAllOf } from '@reduxjs/toolkit';

function TabBltContent({ bltContents, deceasedName, xy }) {
  const [tabIndex, setTabIndex] = useState(0);

  const prev = tabIndex => {
    if (tabIndex === 0) {
      setTabIndex(0);
    } else {
      setTabIndex(tabIndex - 1);
    }
  };

  const next = tabIndex => {
    if (tabIndex === bltContents.length - 1) {
      setTabIndex(bltContents.length - 1);
    } else {
      setTabIndex(tabIndex + 1);
    }
  };

  const onHomeClick = () => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
  };

  const scroll = useRef([]);

  const onClickTab = index => {
    // scroll.current[index]?.scrollIntoView({
    //   block: 'center',
    //   behavior: 'smooth',
    // });
    window.scrollTo({ top: scroll.current[index].offsetTop - 150, behavior: 'smooth' });
  };

  return bltContents && bltContents.length > 0 ? (
    <>
      <div className="wrap-biography" ref={xy}>
        <div className="contents-width">
          <div className="box-title has-crown">
            <h4 className="title">
              {deceasedName}
              <span>발자국</span>
            </h4>
          </div>
          <div className="tabs tabs-list">
            <ul>
              {bltContents.map((blt, index) => (
                <li
                  className={tabIndex === index && 'is-active'}
                  key={index}
                  onClick={() => setTabIndex(index)}
                  // onClick={() => onClickTab(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <button type="button">{blt.blt_title}</button>
                </li>
              ))}
            </ul>
          </div>

          <div className="tab-contents">
            {/*{bltContents.map((blt, index) => (*/}
            {/*  <div ref={el => (scroll.current[index] = el)}>*/}
            <QuillEditor value={bltContents[tabIndex].blt_contents} viewerMode />
          </div>
          {/*))}*/}
          {/* S : 이전/다음 조문보(모바일에서만 노출) */}
          <div className="pagination">
            {tabIndex === 0 ? (
              <button type="button" onClick={() => prev(tabIndex)} disabled>
                <span>
                  <i className="ic ic-arrow-prev"></i>
                </span>
                이전글
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  prev(tabIndex);
                  onHomeClick();
                }}
              >
                <span>
                  <i className="ic ic-arrow-prev"></i>
                </span>
                이전글
              </button>
            )}

            {tabIndex === bltContents.length - 1 ? (
              <button type="button" onClick={() => next(tabIndex)} disabled>
                다음글
                <span>
                  <i className="ic ic-arrow-next"></i>
                </span>
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  next(tabIndex);
                  onHomeClick();
                }}
              >
                다음글
                <span>
                  <i className="ic ic-arrow-next"></i>
                </span>
              </button>
            )}
          </div>
          {/* E : 이전/다음 조문보(모바일에서만 노출) */}
        </div>
      </div>
    </>
  ) : (
    <div className="no_data">등록된 조문보가 없습니다.</div>
  );
}

export default TabBltContent;

import { Suspense, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme';
import AdminRoutes from './routes/admin-routes';
import UserRoutes from './routes/user-routes';
import useCode from 'stores/code/hook';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCommon from 'stores/common/hook';
import AlertDialog from 'components/common/AlertDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';

import LifeAlertDialog from 'components/common/LifeAlertDialog';
import LifeConfirmDialog from 'components/common/LifeConfirmDialog';

import ContentLoader from 'react-content-loader';
import { useDispatch } from 'react-redux';
import { setLifeAlert } from 'stores/dialog/reducer';
import { getCanvasAlarmCountApi } from 'apis/canvas';
import { logoutRequest } from 'stores/login/reducer';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'css/swiper.min.css';
// import 'css/swiper.custom.css';
// import 'css/life/common.css';

// import 'css/common.css';
// import 'css/style.css';
// import 'css/custom.css';
import 'css/static/common.css';

function App() {
  const { alert, confirm, lifeAlert, lifeConfirm, showLifeAlert, hideLifeAlert } = useDialog();
  const { setAlarmCnt } = useCommon();

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { getCodes } = useCode();
  const { checkUserAuth, loginUser, logout } = useLogin();
  const location = useLocation();
  const [adminMode, setAdminMode] = useState(false);

  const getCanvasAlarm = useCallback(async () => {
    if (loginUser?.member_id) {
      const result = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      result.data_cnt && setAlarmCnt(result.data_cnt);
    }
  }, [loginUser, setAlarmCnt]);

  useEffect(() => {
    setAdminMode(location.pathname.indexOf('/admin') > -1 ? true : false);

    if (location.pathname.indexOf('/admin') > -1) {
      import('css/common.css');
    }

    // if (location.pathname.indexOf('/memorial') > -1) {
    //   import('css/memorial/common.css');
    //   //import('css/common.memorial.css');
    //   // import('css/custom.css');
    //   // import('css/style.css');
    // } else if (location.pathname.indexOf('/admin') > -1) {
    //   import('css/common.css');
    // } else {
    //   import('css/life/common.css');
    //   // import('swiper/css');
    //   // import('swiper/css/navigation');
    //   // import('css/swiper.min.css');
    //   // import('css/swiper.custom.css');
    // }

    location.pathname === '/'
      ? document.body.classList.add('main')
      : document.body.classList.remove('main');

    window.scrollTo({ top: 0, behavior: 'smooth' });

    // css가 로드 되는 동안 로딩바 노출 (로딩바 시간이 짧을 경우 딜레이 시간 변경)
    // setTimeout(() => setLoading(false), 500);
    // dispatch(setLifeAlert({ isOpen: false, title: null, message: null, callback: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getCodes();
    checkUserAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loginUser && getCanvasAlarm();
  }, [getCanvasAlarm, loginUser, location]);

  useEffect(() => {
    // 회원가입 후 member_id와 canvas_id가 없을때
    if (loginUser?.member_id && !loginUser?.canvas_id && location.pathname.indexOf('/') > -1) {
      showLifeAlert('알림', '재 로그인 후 이용가능합니다.', () => moveLogin());
    }
  }, [location, loginUser]);

  useEffect(() => {
    // 현재 경로 추출
    const path = location.pathname;
    console.log('path : ' + path);
    if (path !== '/memorial/login' && path !== '/life/login/naver') {
      console.log('path Save!! ' + path);
      // 로그인 페이지가 아닌 현재 경로를 저장
      localStorage.setItem('previous_path', path);
    } else {
      // 로그인 페이지로 접근했을 때 이전 경로가 없다면 기본값 설정
      const storedPath = localStorage.getItem('previous_path');
      if (!storedPath) {
        localStorage.setItem('previous_path', '/'); // 기본 홈 경로 설정
      }
    }
  }, [location]);

  // useEffect(() => {
  //   if (loginUser) {
  //     // 로그인 성공 시 특정 경로로 이동
  //     if (location.pathname === '/memorial') {
  //       // 로그인 화면에서 실행되었을 때 이동
  //       // navigate(`/memorial/obituary/view/${memorialId}`);
  //     }
  //   }
  // }, [getCanvasAlarm, loginUser, location, navigate]);

  useEffect(() => {
    // 회원가입 후 member_id와 canvas_id가 없을때
    if (
      loginUser?.member_id &&
      !loginUser?.canvas_id &&
      location.pathname.indexOf('/memorial') > -1
    ) {
      showLifeAlert('알림', '재 로그인 후 이용가능합니다.', () => moveLogin());
    }
  }, [location, loginUser]);

  useEffect(() => {
    // 회원가입 후 member_id와 canvas_id가 없을때
    if (loginUser?.member_id && !loginUser?.canvas_id && location.pathname.indexOf('/pet') > -1) {
      showLifeAlert('알림', '재 로그인 후 이용가능합니다.', () => moveLogin());
    }
  }, [location, loginUser]);

  const moveLogin = () => {
    hideLifeAlert();
    logout();
    navigate('/');
  };
  return (
    <ThemeProvider theme={theme}>
      {adminMode ? (
        <Suspense fallback={<div>loading</div>}>
          <AdminRoutes />
        </Suspense>
      ) : (
        <Suspense fallback={<div>loading</div>}>
          <UserRoutes />
        </Suspense>
      )}
      <AlertDialog
        isOpen={alert.isOpen}
        title={alert.title}
        message={alert.message}
        callback={alert.callback}
      />
      <ConfirmDialog
        isOpen={confirm.isOpen}
        title={confirm.title}
        message={confirm.message}
        confirmCallback={confirm.confirmCallback}
        cancelCallback={confirm.cancelCallback}
      />
      <LifeAlertDialog
        isOpen={lifeAlert.isOpen}
        title={lifeAlert.title}
        message={lifeAlert.message}
        callback={lifeAlert.callback}
      />
      <LifeConfirmDialog
        isOpen={lifeConfirm.isOpen}
        title={lifeConfirm.title}
        message={lifeConfirm.message}
        confirmCallback={lifeConfirm.confirmCallback}
        cancelCallback={lifeConfirm.cancelCallback}
      />
    </ThemeProvider>
  );
}

export default App;

import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';

function Header() {
  const navigate = useNavigate();

  const { loginUser, logout } = useLogin();

  // const handleMain = () => {
  //   navigate('/');
  // };

  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  return (
    <header>
      <h1>
        <a href="/">
          <span className="offscreen">MY LIFE CANVAS</span>
        </a>
      </h1>

      <div className="header-util">
        <ul>
          <li>
            <a href="/" className="btn" target="_blank">
              사용자 메인
            </a>
          </li>
          <li>
            <a href={() => false} onClick={handleLogout} className="btn btn-red">
              로그아웃
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;

import { useState, useEffect, useRef } from 'react';
import { Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useScript from 'utils/useScript';
import configs from '@configs/configs';

import WreathResult from '../../wreathResult';

function KiccPcDialog({ isOpen, onClose, memorial_id, wreathId, getValues }) {
  const formRef = useRef();
  const navigate = useNavigate();

  const status = useScript(configs.KICC_PC_SCRIPT);

  const KICC_PC_ACTION_URL = configs.KICC_PC_ACTION_URL;
  const KICC_PC_RETURN_URL = `${configs.KICC_PC_RETURN_URL}?wreath_id=${wreathId}`;
  const EP_MALL_ID = '05571685';
  //개발
  // const EP_MALL_ID = 'T0011126';
  const EP_MALL_NM = encodeURIComponent('엠서클 메모리얼 캔버스');
  const EP_ORDER_NO = wreathId;
  const EP_PAY_TYPE = getValues('pay_type');
  const EP_USER_ID = getValues('register_id');
  const EP_USER_NM = encodeURIComponent(getValues('register'));

  let EP_PRODUCT_NM = encodeURIComponent('디지털화환 일반');
  let EP_PRODUCT_AMT = 50000;

  if (getValues('type') === 'N') {
    EP_PRODUCT_NM = encodeURIComponent('디지털화환 일반');
    EP_PRODUCT_AMT = 1000;
  } else if (getValues('type') === 'R') {
    EP_PRODUCT_NM = encodeURIComponent('디지털화환 고급');
    EP_PRODUCT_AMT = 50000;
  } else if (getValues('type') === 'P') {
    EP_PRODUCT_NM = encodeURIComponent('디지털화환 프리미엄');
    EP_PRODUCT_AMT = 100000;
  }

  const formRefSubmit = () => {
    if (formRef && formRef.current) {
      formRef.current.target = 'pg_area';
      formRef.current.submit();
    }
  };
  useEffect(() => {
    if (status === 'ready' && isOpen === true) {
      formRefSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <div className="layer_pop">
        <div className="pop_header">
          <h2 className="top_title">디지털화환</h2>
          <button
            onClick={() =>
              navigate('/memorial/obituary/wreath/result', {
                state: {
                  pay_type: getValues('pay_type'),
                  type: getValues('type'),
                  memorial_id: memorial_id,
                },
              })
            }
          >
            결제완료
          </button>
          <button className="close" onClick={onClose}>
            닫기
          </button>
        </div>

        <div style={{ backgroundColor: 'white' }}>
          <div>
            <iframe title="kiccFrame" name="pg_area" width="700px" height="560px" />
            <form name="frm" method="post" action={KICC_PC_ACTION_URL} ref={formRef}>
              <input type="hidden" name="EP_return_url" value={KICC_PC_RETURN_URL} />
              <input type="hidden" name="EP_order_no" value={EP_ORDER_NO} />
              <input type="hidden" name="EP_mall_id" value={EP_MALL_ID} />
              <input type="hidden" name="EP_mall_nm" value={EP_MALL_NM} />
              <input type="hidden" name="EP_pay_type" value={EP_PAY_TYPE} />
              <input type="hidden" name="EP_product_nm" value={EP_PRODUCT_NM} />
              <input type="hidden" name="EP_product_amt" value={EP_PRODUCT_AMT} />
              <input type="hidden" name="EP_user_id" value={EP_USER_ID} />
              <input type="hidden" name="EP_user_nm" value={EP_USER_NM} />
              <input type="hidden" name="EP_product_type" value="1" />
              <input type="hidden" name="EP_currency" value="00" />
              <input type="hidden" name="EP_charset" value="UTF-8" />
              <input type="hidden" name="EP_lang_flag" value="KOR" />
              <input type="hidden" name="EP_noinst_flag" value="N" />
              <input type="hidden" name="EP_set_point_card_yn" value="N" />
              <input type="hidden" name="EP_ci_url" value="" />
              <input type="hidden" name="EP_memb_user_no" value="" />
              <input type="hidden" name="EP_user_mail" value="" />
              <input type="hidden" name="EP_user_phone1" value="" />
              <input type="hidden" name="EP_user_phone2" value="" />
              <input type="hidden" name="EP_user_addr" value="" />
              <input type="hidden" name="EP_user_define1" value="" />
              <input type="hidden" name="EP_user_define2" value="" />
              <input type="hidden" name="EP_user_define3" value="" />
              <input type="hidden" name="EP_user_define4" value="" />
              <input type="hidden" name="EP_user_define5" value="" />
              <input type="hidden" name="EP_user_define6" value="" />
              <input type="hidden" name="EP_product_expr" value="" />
              <input type="hidden" name="EP_usedcard_code" value="029:031:045:047" />
              <input type="hidden" name="EP_quota" value="" />
              <input type="hidden" name="EP_os_cert_flag" value="" />
              <input type="hidden" name="EP_noinst_term" value="" />
              <input type="hidden" name="EP_point_card" value="" />
              <input type="hidden" name="EP_join_cd" value="" />
              <input type="hidden" name="EP_kmotion_useyn" value="N" />
              <input type="hidden" name="EP_vacct_bank" value="" />
              <input type="hidden" name="EP_vacct_end_date" value="" />
              <input type="hidden" name="EP_vacct_end_time" value="" />
              <input type="hidden" name="EP_prepaid_cp" value="" />
              <input type="hidden" name="EP_disp_cash_yn" value="N" />
              <input type="hidden" name="EP_cert_type" value="" />
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default KiccPcDialog;

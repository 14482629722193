import api from '@apis/api';
import configs from '@configs/configs';

// 구독 리스트
export async function getCalculateListApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/getWreathCalculate`, params);
  return response.data;
}

export async function postCalculateApi(params) {
  const response = await api.put(`${configs.API_BASEURL}/wreath/updateWreathCalculate`, params);
  return response.data;
}

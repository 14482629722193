function PremiumService() {
  return (
    <section>
      <div className="visual_area">
        <div className="visual style2"></div>
        <h2 className="title">
          03
          <br />
          조문보 프리미엄 서비스
        </h2>
      </div>

      <div className="service_content">
        <p className="text">
          조문보 프리미엄 서비스는 조문보를 직접 작성하는데 어려움이 있거나 고인의 삶을 보다 깊이
          있게, 의미 있게 담아내고자 할 경우, 에버영피플의 조문보 웹진 작가의 도움을 받아 제작
          의뢰하는 서비스 입니다.
        </p>
        <p className="text">
          조문보 전문 웹진 작가가 유족과 인터뷰를 통해 고인의 삶을 관통하는 핵심 주제어를 중심으로
          글로 정리하고 사진들을 덧붙여 압축한 고품격 조문보입니다.
        </p>
        <p className="text">
          작가는 고인의 삶의 의미와 지혜 등을 추출하기 위한 구조화된 질문지를 사용한 유족과의 인터뷰
          과정에서 유족의 마음에 남아있는 기억들을 하나 하나 풀어냅니다. 이 때 슬픔 속에 고인의 삶에
          대해 미처 깊이 생각해보지 못했던 유족은 고인이 남기신 유훈을 어느 순간 새롭게 깨닫게
          됩니다.
        </p>
        <h3 className="guide_title">조문보 프리미엄 버전</h3>
        <ul className="guide_list">
          <li>- 유족으로 하여금 고인의 남기신 지혜와 유훈을 새롭게 깨닫는 과정을 돕습니다. </li>
          <li>- 고인의 삶을 관통하는 핵심 주제어를 발견하는데 도움을 드립니다. </li>
          <li>
            - 디자인 요소가 더 해진 페이지 레이아웃 사진 보정으로 조문보의 표현을 아름답게 합니다.
          </li>
          <li>- 고인의 삶에 관한 이야기를 주제별 배치하여 편집, 교정 및 윤색합니다. </li>
          <li>- 고인의 삶을 관통하는 핵심 주제어가 흐르는 동영상을 제작하여 드립니다.</li>
        </ul>
        <table className="info_table">
          <caption>조문보 프리미엄 제작 비용 안내</caption>
          <tbody>
            <tr>
              <th scope="row">가격</th>
              <td>380,000원</td>
            </tr>
            <tr>
              <th scope="row">조문보 구성</th>
              <td>5개의 대표주제</td>
            </tr>
            <tr>
              <th scope="row">사진편집</th>
              <td rowSpan="9">에버영 조분보 전문 웹진작가</td>
            </tr>
            <tr>
              <th scope="row">페이지 레이아웃 디자인</th>
            </tr>
            <tr>
              <th scope="row">구성 및 내용 기획 편집, 윤문, 교정</th>
            </tr>
            <tr>
              <th scope="row">작가 인터뷰(30분)</th>
            </tr>
            <tr>
              <th scope="row">검수</th>
            </tr>
            <tr>
              <th scope="row">웹진 제작/등록</th>
            </tr>
            <tr>
              <th scope="row">내용 업데이트</th>
            </tr>
            <tr>
              <th scope="row">제작 및 감수</th>
            </tr>
            <tr>
              <th scope="row">고인의 삶 고인의 삶에 대한 동영상 제작</th>
            </tr>
            <tr>
              <th scope="row">의뢰 이후 제작 기간</th>
              <td>약식 버전 24시간 이내</td>
            </tr>
            <tr>
              <th scope="row">제작 기한-납품 기간</th>
              <td>최종 버전 한 달 이내 (※작가와 협의 가능)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default PremiumService;

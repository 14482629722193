import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useDialog from 'stores/dialog/hook';
import WreathResultDialog from '../components/WreathResultDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWreathApi, getWreathsApi, postCalculationApi } from 'apis/wreathCalculation';

//excel download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function WreathResult() {
  const navigate = useNavigate();

  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memorialId } = useParams();
  const [wreath, setWreath] = useState([]);
  const [wreathList, setWreathList] = useState([]);
  const [showCommission, setShowCommission] = useState(false);
  const [guideList, setGuideList] = useState([false, false, false, false]);
  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [accountCheck, setAccountCheck] = useState(false);
  const [depositCheck, setDepositCheck] = useState(false);
  const [openWreathResultDialog, setOpenWreathResultDialog] = useState(false);

  const getWreath = async () => {
    let params = {};
    params.memorial_id = memorialId;
    // api 요청
    const result = await getWreathApi(params);
    if (result.code === '0000') {
      setWreath(result.data[0]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreathList = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = listSize;

    // api 요청
    const result = await getWreathsApi(params);

    if (result.code === '0000') {
      setWreathList(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreateListMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = listSize;

    // api 요청
    const result = await getWreathsApi(params);
    if (result.code === '0000') {
      setWreathList([...wreathList, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreateListExcel = async () => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = 1;
    params.itemInPage = 9999;

    // api 요청
    const result = await getWreathsApi(params);
    if (result.code === '0000') {
      excelDownload(result.data);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const submitCalculation = async () => {
    let params = {};
    params.memorial_id = memorialId;
    // api 요청
    const result = await postCalculationApi(params);
    if (result.code === '0000') {
      setOpenWreathResultDialog(() => true);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleClickMoreWreate = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getWreateListMore(nextPage);
    }
  };

  const handleClickCommission = () => {
    setShowCommission(() => !showCommission);
  };

  const handleShowGuide = index => {
    let temp = [...guideList];
    temp[index] = true;
    setGuideList(temp);
  };

  const handleHideGuide = () => {
    setGuideList([false, false, false, false]);
  };

  const handleDownloadExcel = () => {
    getWreateListExcel();
  };

  const handleSubmit = () => {
    if (depositCheck && accountCheck) {
      // submit api
      submitCalculation();
    } else {
      showAlert('알림', '정산 금액 확인 후 위 항목에 모두 체크하셔야 정산 신청이 가능합니다.', () =>
        hideAlert(),
      );
    }
  };

  const handleChangeAccountCheck = () => {
    setAccountCheck(() => !accountCheck);
  };

  const handleChangeDepositCheck = () => {
    setDepositCheck(() => !depositCheck);
  };

  // excel
  const excelFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';
  /**
   * excel download
   */
  const excelDownload = excelData => {
    const ws = XLSX.utils.aoa_to_sheet([
      ['날짜', '보낸분 성함', '회사명/모임명', '화환 종류', '금액'],
    ]);
    excelData.map(data => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            data.reg_date,
            data.send_name,
            data.send_group,
            data.flower_type_name,
            data.flower_price,
          ],
        ],
        {
          origin: -1,
        },
      );
      ws['!cols'] = [
        //<-- 행 사이즈
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
      ];
      return false;
    });
    const wb = { Sheets: { sheet: ws }, SheetNames: ['sheet'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, '디지털 화환 상세 내역' + excelFileExtension);
  };

  // 콤마 안찍혀서 날아오면 사용할 것
  const numberFormatter = num => {
    return Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    if (memorialId) {
      // 디지털 화환 내역
      getWreath(memorialId);
      // 디지털 화환 상세 내역(리스트)
      getWreathList(1);
    }
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">디지털 화환 </h2>
            <p>
              디지털 화환은 상주께 전해지며 허례의식을 줄이고
              <br />
              유가족 위로와 기부문화에 이바지합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          {/* S : 디지털 화한 정산 내역 */}
          <div className="wrap-wreath-calculate">
            {/* S : 타이틀 */}
            <div className="box-title">
              <h3 className="title">디지털 화환 내역</h3>
              <p className="total-price">
                총 정산금액
                <em>{numberFormatter(wreath.flower_price_final)}원</em>
              </p>
            </div>
            {/* E : 타이틀 */}
            {/* S : 이름 */}
            <div className="box-chief-mourner">
              <p className="deceased">{wreath.deceased_name}</p>
              <p className="name">{wreath.chief_mourner_desc + ' ' + wreath.chief_mourner}</p>
            </div>
            {/* E : 이름 */}
            {/* S : 세부 내역 */}
            <div className="wrap-collection">
              <div className="total-collection">
                <p className="title">디지털 화환 총 모금액</p>
                <p className="price">{numberFormatter(wreath.flower_price)}원</p>
              </div>

              {/*
							***** DESC *****
							수수료 텍스트가 포함된 button을 클릭 했을 때, className="wrap-commission"에 className="is-active"가 추가되게 해주세요.
						*/}
              <div className={showCommission ? 'wrap-commission is-active' : 'wrap-commission'}>
                <div className="box-commission">
                  <p className="title">
                    <button type="button" onClick={handleClickCommission}>
                      수수료
                    </button>
                  </p>
                  <p className="price">{numberFormatter(wreath.flower_fee)}원</p>
                </div>
                <div className="list-commission">
                  <dl>
                    {/*
										***** DESC *****
										className="btn-popup-guide" 버튼을 클릭(혹은 마우스 오버) 했을 때, <dt>에 className="is-active"가 부여되게 해주세요.
									*/}
                    <dt className={guideList[0] ? 'is-active' : ''}>
                      부가세
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(0)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">부가세는 총 모금액-(총 모금액/1.1) 입니다.</div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_surtax)}원</dd>
                    <dt className={guideList[1] ? 'is-active' : ''}>
                      PG 수수료
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(1)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">
                        PG 수수료는 신용카드인 경우 (총 모금액 + 부가세) * 2.3% 이고, 무통장입금인
                        경우 (총 모금액 + 부가세) * 1.2% 입니다.
                      </div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_pg_fee)}원</dd>
                    <dt className={guideList[2] ? 'is-active' : ''}>
                      법인세
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(2)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">
                        법인세는 (총 모금액+부가세+PG수수료)*0.2 입니다.
                      </div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_corporate_tax)}원</dd>
                    <dt className={guideList[3] ? 'is-active' : ''}>
                      이용 수수료
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(3)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">이용 수수료는 총 모금액 * 0.11 입니다.</div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_use_fee)}원</dd>
                  </dl>
                </div>
              </div>
              <div className="calculate-collection">
                <p className="title">
                  <span>정산금액</span>
                </p>
                <p className="price">
                  <em>{numberFormatter(wreath.flower_price_final)}원</em>
                </p>
              </div>
            </div>
            {/* E : 세부 내역 */}
            {/* S : 목록 */}
            <div className="box-title between">
              <h4 className="title sm">상세 내역 리스트</h4>
              <a onClick={() => handleDownloadExcel()} className="btn-download">
                <i className="ic ic-download"></i>엑셀 다운로드
              </a>
            </div>
            <div className="list-wreath-calculate">
              {/* S : 상세 내역 반복 시작 */}

              {wreathList && wreathList.length > 0
                ? wreathList.map((wreath, index) => {
                    return (
                      <div className="box-wreath-calculate">
                        <div className="box-content">
                          <p className="title">{wreath.flower_type_name}</p>
                          <p className="price">{numberFormatter(wreath.flower_price)}원</p>
                        </div>
                        <div className="info">
                          <span className="group">{wreath.send_group}</span>
                          <span className="name">{wreath.send_name}</span>
                          <span className="date">{wreath.reg_date.split(' ')[0]}</span>
                        </div>
                      </div>
                    );
                  })
                : null}

              {/* E : 상세 내역 반복 시작 */}
            </div>
            {/* E : 목록  */}
            {/* S : 페이징 */}
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-more"
                onClick={() => handleClickMoreWreate(page)}
              >
                더보기 {page}/{totalPage}
              </button>
            </div>
            {/* E : 페이징 */}

            {/* S : 디지털 화환 정산 */}
            <div className="wrap-form">
              <div className="section-form">
                <h4 className="label">디지털 화환 총 정산 금액은 대표 상주에게 전달됩니다.</h4>
                <div className="ip-wrap ip-group ip-account">
                  <div className="ip-item">
                    <label for="ip-chief-mourner-name1" className="offscreen">
                      이름
                    </label>
                    {/* <input type="text" id="ip-chief-mourner-name1" placeholder="이름" /> */}
                    <input
                      type="text"
                      id="ip-chief-mourner-name1"
                      value={wreath.account_name}
                      readOnly
                    />
                  </div>
                  <div className="ip-item">
                    <label for="ip-chief-mourner-relation1" className="offscreen">
                      은행
                    </label>
                    {/* <select id="ip-chief-mourner-relation1">
                      <option>은행</option>
                      <option>기업</option>
                      <option>국민</option>
                      <option>하나</option>
                    </select> */}
                    <input
                      type="text"
                      id="ip-chief-mourner-name1"
                      value={wreath.account_bank}
                      readOnly
                    />
                  </div>
                  <div className="ip-item">
                    <label for="ip-chief-mourner-mobile1" className="offscreen"></label>
                    {/* <input
                      type="number"
                      id="ip-chief-mourner-mobile1"
                      placeholder="계좌번호를 입력하세요"
                    /> */}
                    <input
                      type="number"
                      id="ip-chief-mourner-mobile1"
                      value={wreath.account_num}
                      readOnly
                    />
                  </div>
                </div>
                <p className="desc">※ 부고 등록시 입력한 계좌 정보로 입금됩니다.</p>
              </div>

              <div className="section-form">
                <h5 className="label sm">정산 입금액</h5>
                <p className="total-price">{numberFormatter(wreath.flower_price_final)}원</p>

                <ul className="rc-list">
                  <li>
                    <div className="rc-item">
                      <input type="checkbox" id="chk-sample-1" checked={accountCheck} />
                      <label for="chk-sample-1" onClick={() => handleChangeAccountCheck()}>
                        정산 받을 계좌 정보를 확인 하였으며, 총 정산 입금액을 확인하였습니다.
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="rc-item">
                      <input type="checkbox" id="chk-sample-2" checked={depositCheck} />
                      <label for="chk-sample-2" onClick={() => handleChangeDepositCheck()}>
                        매달 -일과 -일에 입금 되는 것을 확인했습니다.
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* E : 디지털 화환 정산 */}
          </div>
          {/* E : 디지털 화한 정산 내역 */}

          {/* S : 페이징 */}
          {wreath && wreath.calculate_request_yn === 'Y' ? (
            <div className="btn-wrap">
              <a
                onClick={() => navigate('/memorial/mypage/obituary')}
                className="btn btn-pos-white"
              >
                디지털 화환 금액 정산 신청이 완료되었습니다
              </a>
            </div>
          ) : (
            <div className="btn-wrap">
              <a onClick={() => handleSubmit()} className="btn btn-pos">
                디지털 화환 금액 정산을 신청합니다
              </a>
            </div>
          )}

          {/* E : 페이징 */}
        </div>
      </div>
      {/* E : CONTENT */}
      <WreathResultDialog
        isOpen={openWreathResultDialog}
        onClose={() => setOpenWreathResultDialog(false)}
        memorialId={memorialId}
      />
    </section>
  );
}

export default WreathResult;

import { useEffect, useState } from 'react';

import ChudosaTemplateDialog from '../ChudosaTemplateDialog';
import useDialog from '../../../../../stores/dialog/hook';
import useCode from 'stores/code/hook';
import { Controller, useFieldArray } from 'react-hook-form';
import QuillEditor from '../../../../../components/editor/QuillEditor';

function Chudosas({ control, setValue, getValues, errors }) {
  const { showAlert, hideAlert } = useDialog();
  const { chudosaTitleCodes } = useCode();

  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [openChudosaTemplateDialog, setOpenChudosaTemplateDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [contents, setContents] = useState('');
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'chudosa_datas',
  });

  const handleAppend = () => {
    if (fields.length > 9) {
      showAlert('알림', '추도사는 최대 10개까지 작성할 수 있습니다.', () => hideAlert());
      return;
    }
    if (getValues('chudosa_datas').filter(it => it.display_yn === 'Y').length > 4) {
      append({ blt_title: '', blt_contents: '', display_yn: 'N' });
      return;
    }
    append({ blt_title: '', blt_contents: '', display_yn: 'Y' });
  };

  const handleRemove = index => {
    remove(index);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleChangeDisplayYn = (name, value) => {
    setValue(name, value);
    const isCheck = getValues('chudosa_datas').filter(it => it.display_yn === 'Y').length;

    if (isCheck > 5) {
      showAlert('알림', '추도사는 최대 5개까지 공개됩니다.', () => hideAlert());
      setValue(name, 'N');
    } else if (isCheck === 0) {
      showAlert('알림', '추도사는 1개 이상 공개해 주세요.', () => hideAlert());
      setValue(name, 'Y');
    }
    move(0, 0);
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleOpenTemplate = index => {
    setSelectedIndex(index);
    setOpenChudosaTemplateDialog(true);
  };

  const selectedTemplate = template => {
    // setValue(`chudosa_datas.${selectedIndex}.blt_title`, template.blt_title);
    // setValue(`chudosa_datas.${selectedIndex}.blt_contents`, template.blt_contents);
    setValue(`chudosa_datas.${selectedIndex}.blt_title`, '');
    setValue(`chudosa_datas.${selectedIndex}.blt_contents`, template);
    setOpenChudosaTemplateDialog(false);
  };

  // const changeChudosaContents = (e, index) => {
  //   let replacedValue = removeEmojis(e);
  //
  //   setValue(`chudosa_datas.${index}.blt_contents`, replacedValue);
  // };

  const changeChudosaContents = (e, index) => {
    // const { value } = e.target;

    setValue(`chudosa_datas.${index}.blt_contents`, e);
  };

  const removeEmojis = inputValue => {
    const img = /<img src="data:[^>]*>?/g;
    if (img.test(inputValue)) {
      showAlert(
        '알림',
        '이미지는 복사 붙여넣기가 금지됩니다. \n 오른쪽 상단의 에디터 영역 이미지 첨부 버튼을 눌러 첨부해주세요.',
        () => hideAlert(),
      );
      let strVal = inputValue.replace(img, '');
      return strVal;
    }

    return inputValue;
  };

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleCheckBox = indexNum => {
    handleChangeDisplayYn(
      `chudosa_datas.${indexNum}.display_yn`,
      getValues(`chudosa_datas.${indexNum}.display_yn`) === 'Y' ? 'N' : 'Y',
    );
  };

  return (
    <div className="contents-form">
      <div className="box-title">
        <h3>추도사 작성</h3>
        <p className="desc">추도사는 최대 5개까지 등록할 수 있습니다.</p>
      </div>
      {/* S : 추도사 */}
      {fields &&
        fields.length > 0 &&
        fields.map((item, index) => (
          <div className="form-group">
            <div className="box-stitle">
              <h5>
                <span className="number">{index + 1}</span>추도사{' '}
                <span className="required">
                  *<span className="offscreen">필수입력항목</span>
                </span>
              </h5>
              <div className="form-util">
                <button
                  type="button"
                  className="btn btn-sm-blue"
                  onClick={() => handleOpenTemplate(index)}
                >
                  추도사 템플릿
                </button>
                <div className="rc-item rc-switch">
                  <input
                    type="checkbox"
                    id="switch5"
                    switch="none"
                    name={`chudosa_datas.${index}.display_yn`}
                    checked={getValues(`chudosa_datas.${index}.display_yn`) === 'Y'}
                  />
                  <label
                    htmlFor="switch5"
                    data-on-label="공개"
                    data-off-label="비공개"
                    onClick={() => {
                      handleCheckBox(index);
                    }}
                  ></label>
                </div>
              </div>
            </div>
            <div
              className={`ip-wrap ${errors.chudosa_datas?.[index]?.blt_title ? 'is-error' : ''}`}
            >
              <div className="ip-item">
                <label htmlFor="ip-chief-mourner-name" className="offscreen">
                  제목 입력
                </label>
                <Controller
                  control={control}
                  name={`chudosa_datas.${index}.blt_title`}
                  render={({ field }) => (
                    <input
                      type="text"
                      id="ip-chief-mourner-name"
                      {...field}
                      aria-invalid={errors.chudosa_datas?.[index]?.blt_title ? 'true' : 'false'}
                      placeholder="제목을 작성하세요 (최대 20글자)"
                      maxLength="20"
                    />
                  )}
                />
                {/*{errors.chudosa_datas?.[index]?.blt_title && (*/}
                {/*  <p className="message">{errors.chudosa_datas?.[index]?.blt_title.message}</p>*/}
                {/*)}*/}
              </div>
              <div className="ip-item">
                <label htmlFor="ip-chief-mourner-name" className="offscreen">
                  내용 입력
                </label>
                <Controller
                  control={control}
                  name={`chudosa_datsa.${index}.blt_contents`}
                  render={({ field }) => (
                    // <textarea
                    //   name=""
                    //   id=""
                    //   cols="30"
                    //   rows="10"
                    //   placeholder="추도사를 작성하세요"
                    //   value={getValues(`chudosa_datas.${index}.blt_contents`)}
                    //   onChange={e => {
                    //     changeChudosaContents(e, index);
                    //   }}
                    //   {...field}
                    //   aria-invalid={errors.chudosa_datas?.[index]?.blt_title ? 'true' : 'false'}
                    // ></textarea>
                    <QuillEditor
                      {...field}
                      label="추도사 내용"
                      value={getValues(`chudosa_datas.${index}.blt_contents`)}
                      onChange={e => {
                        changeChudosaContents(e, index);
                      }}
                      aria-invalid={errors.chudosa_datas?.[index]?.blt_contents ? 'true' : 'false'}
                    />
                  )}
                />
                {/*{errors.chudosa_datas?.[index]?.blt_contents && (*/}
                {/*  <p className="message">{errors.chudosa_datas?.[index]?.blt_contents.message}</p>*/}
                {/*)}*/}
              </div>
              {errors.chudosa_datas?.[index]?.blt_title && (
                <p className="message">{errors.chudosa_datas?.[index]?.blt_title.message}</p>
              )}
              {/*<p className="message">제목과 내용을 모두 작성하세요.</p>*/}
            </div>
            <div className="ip-btn">
              <button
                type="button"
                className="btn-contents-del"
                onClick={() => handleRemove(index)}
              >
                <span className="offscreen">삭제</span>
              </button>
              <button
                type="button"
                className="btn-contents-up"
                onClick={() => handleOrderUp(index)}
              >
                <span className="offscreen">순서 위로</span>
              </button>
              <button
                type="button"
                className="btn-contents-down"
                onClick={() => handleOrderDown(index)}
              >
                <span className="offscreen">순서 아래로</span>
              </button>
            </div>
          </div>
        ))}
      {/* E : 추도사 */}

      {/* S : 추가 버튼 */}
      <div className="btn-wrap">
        <button type="button" className="btn btn-md-white" onClick={handleAppend}>
          추도사 추가
        </button>
      </div>
      {/* E : 추가 버튼 */}
      <ChudosaTemplateDialog
        isOpen={openChudosaTemplateDialog}
        onClose={() => setOpenChudosaTemplateDialog(false)}
        // selectedTemplate={template => selectedTemplate(template)}
        value={selectedTemplate}
      />
    </div>
  );
}

export default Chudosas;

import { Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function VerifyPasswordDialog({ isOpen, onClose, callBack }) {
  const schema = Yup.object({
    password: Yup.string().required('필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    password: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    reset();
    callBack(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-chk-password" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">비밀번호 확인</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label className="offscreen">비밀번호 입력</label>
                    <input
                      {...register('password')}
                      aria-invalid={errors.password ? 'true' : 'false'}
                      type="password"
                      className="input"
                      placeholder="비밀번호를 입력하세요."
                    />
                    <p className="message">{errors.password && errors.password.message}</p>
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleSubmit(onSubmit, onError)}
                  data-micromodal-close
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onClose}
                  data-micromodal-close
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default VerifyPasswordDialog;

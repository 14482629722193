function Setp() {
  return (
    <section>
      <div className="service_content">
        <h2 className="title">
          04
          <br />
          서비스 절차
        </h2>
        <ul className="guide_list">
          <li>
            1. 프리미엄 서비스 신청 (10분 이내 연결)
            <p>① 홈페이지에서 간단한 개인정보와 내용 등 신청서 작성 후 '상담 신청' </p>
            <p>② SNS로 알림 수신 후 고객과 상담한 후 기록</p>
          </li>
          <li>
            2. 프리미엄 서비스 결재(30분)
            <p>① 회원가입 & 계좌 입금 완료(무통장)</p>
            <p>② 조문보 작가 배정 & 기초상담내용 공유</p>
          </li>
          <li>
            3. 인터뷰 및 자료준비(60분)
            <p>① 조문보 작가와의 인터뷰 진행</p>
            <p>② 인터뷰를 통해 필요한 글감과 사진, 동영상 등 자료 준비하여 작가에게 보내기 </p>
          </li>
          <li>
            4. 조문보 제작(약식본 24시간 내/최종본 한 달 내)
            <p>① 의례 후 24시간내 조문보 약식 버전 수신</p>
            <p> ② 내용 확인 후 수정 및 보완 요청 (※2회 수정 가능)</p>
            <p>③ 한달 이내 최종 조문보 수신 (※작가와 협의 가능, 2회 수정 가능)</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Setp;

import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import {
  getMemorialsApi,
  deleteMemorialApi,
  memorialTransApi,
  memorialOwnerTransApi,
} from 'apis/memorial';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IconModify from '@mui/icons-material/Edit';
import IconDelete from '@mui/icons-material/DeleteForever';
import IconChange from '@mui/icons-material/PublishedWithChanges';
import IconChangeOwner from '@mui/icons-material/ManageAccounts';
import SearchMemberDialog from 'pages/memorial-canvas/admin/obituary/components/SearchMemberDialog';

import Pagination from 'react-js-pagination';

function AdminMemorialPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { displayYnCodes } = useCode();
  const [searchType, setSearchType] = useState('');

  const getMemorials = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'N';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.page = pageNum;
    params.searchType = searchType;
    params.itemInPage = listSize;
    params.path = 'pet';
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getMemorialsApi(params);
    setMemorials(result.data);
    setTotalCount(result.data_cnt);
  };

  const deleteMemorial = async params => {
    const result = await deleteMemorialApi(params);

    showAlert('알림', result.message, () => hideAlert());
    getMemorials(1);
  };

  const memorialTrans = async params => {
    const result = await memorialTransApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getMemorials(1);
  };

  const memorialOwnerTrans = async params => {
    const result = await memorialOwnerTransApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getMemorials(1);
  };

  const [memorials, setMemorials] = useState(null);
  const [openSearchMemberDialog, setOpenSearchMemberDialog] = useState(false);
  const [selectedMemorialId, setSelectedMemorialId] = useState(0);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const handleModify = memorialId => {
    navigate(`${pathname}/modify/${memorialId}`);
  };

  const handleDelete = memorialId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(memorialId),
      () => hideConfirm(),
    );
  };

  const handleConfirm = memorialId => {
    hideConfirm();
    deleteMemorial(memorialId);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate(`${pathname}/register`);
  };

  const handleChangeStatus = memorialId => {
    showConfirm(
      '부고 전환 확인',
      '선택한 추모관을 부고로 정말 전환하시겠습니까?',
      () => confirmChangeStatus(memorialId),
      () => hideConfirm(),
    );
  };

  const confirmChangeStatus = memorialId => {
    hideConfirm();
    memorialTrans({ memorial_id: memorialId, status: '1' });
  };

  const selectedMemberId = memberId => {
    setOpenSearchMemberDialog(false);
    memorialOwnerTrans({ memorial_id: selectedMemorialId, own_member_id: memberId });
  };

  const handleOpenSearchMemberDialog = memorialId => {
    setSelectedMemorialId(memorialId);
    setOpenSearchMemberDialog(true);
  };
  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getMemorials(selectedPage);
  };

  const handleSearchMemorials = () => {
    getMemorials(1);
  };

  const time = epochTime => {
    const date = new Date(epochTime);

    // Date 객체를 사용하여 원하는 날짜 및 시간 형식으로 변환
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getMemorials(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>추모관 관리</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02" onChange={e => setSearchType(e.target.value)}>
                    <option value={'DECEASE'}>고인 성함</option>
                    <option value={'OWN'}>작성자</option>
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    placeholder="검색어를 입력하세요"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  ref={searchRef}
                  onClick={handleSearchMemorials}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn btn-download">
              엑셀 다운로드
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">추모관 목록</caption>
            <colgroup>
              <col width="5%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">고인 이름</th>
                <th scope="col">생년월일</th>
                <th scope="col">소유자</th>
                <th scope="col">공개여부</th>
                <th scope="col">등록일</th>
                <th scope="col">기능</th>
              </tr>
            </thead>
            <tbody>
              {memorials && memorials.length > 0
                ? memorials.map((row, index) => (
                    <tr key={row.memorial_id}>
                      <td>{(page - 1) * listSize + index + 1}</td>
                      <td>{row.deceased_name}</td>
                      <td>{row.deceased_birthday}</td>

                      <td>{row.own_member_nm}</td>
                      <td>
                        {row.display_yn === 'Y' ? (
                          <span className="tc-green">공개</span>
                        ) : row.display_yn === 'N' ? (
                          <span className="tc-red">비공개</span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{row.createdtime ? time(row.createdtime) : ''}</td>
                      <td>
                        <div className="btn-wrap">
                          <Tooltip title="수정" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="수정"
                              onClick={() => handleModify(row.memorial_id)}
                            >
                              <i className="ic ic-modify"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="삭제" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="삭제"
                              onClick={() => handleDelete(row.memorial_id)}
                            >
                              <i className="ic ic-delete"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="부고 전환" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="부고 전환"
                              onClick={() => handleChangeStatus(row.memorial_id)}
                            >
                              <i className="ic ic-change-memorial"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="소유자 변경" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="소유자 변경"
                              onClick={() => handleOpenSearchMemberDialog(row.memorial_id)}
                            >
                              <i className="ic ic-change-owner"></i>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}
      <SearchMemberDialog
        isOpen={openSearchMemberDialog}
        onClose={() => setOpenSearchMemberDialog(false)}
        selectedMemberId={selectedMemberId}
      />
    </div>
  );
}

export default AdminMemorialPage;

import KakaoMap from 'components/KakaoMap';
function TabFuneralContent({ hall, funeralAddr }) {
  return hall && funeralAddr === 'Y' ? (
    <div className="tab-contents">
      <div className="funeral-hall-info">
        <dl>
          <dt>주소</dt>
          <dd>{hall.hall_addr}</dd>
          <dt>전화번호</dt>
          <dd>{hall.hall_contact}</dd>
        </dl>
      </div>
      <div className="funeral-hall-map">
        <div style={{ height: '100%' }}>
          <KakaoMap location={hall.hall_addr} />
        </div>
      </div>
    </div>
  ) : (
    <div className="tab-contents">
      <div className="funeral-hall-info">
        <p>상주의 뜻에 따라 장례식장은 공개되지 않습니다.</p>
      </div>
      <div className="funeral-hall-map is-secret">
        <p>
          메모리얼 캔버스는 고인의 삶과 <br className="d-none d-md-block" />
          메시지에 초점을 맞추어 <br />
          오롯이 고인을 추모하는 <br className="d-none d-md-block" />
          장례문화를 만들어 갑니다.
        </p>
      </div>
    </div>
  );
}

export default TabFuneralContent;

import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

function ChudosaSampleDialog({ isOpen, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-sample-01" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">추도사 샘플보기</h2>
                  <button className="modal__close" aria-label="Close modal" data-micromodal-close>
                    <i className="ic ic-close" onClick={onClose}></i>
                  </button>
                </div>
                <div className="modal__content">
                  <div className="box-scroll">
                    <p>사랑하는 어머니께(18PX 행간25PX)……</p>
                    <p>
                      어머니 저는 아직 준비가 안 되었는데, 이렇게 홀연히 저희들 남겨두고 떠나시면
                      어떻게 하나요? 아직 저희 옆에 어머님께서 더 계셔 주셔야 하는데…
                    </p>
                    <p>
                      어머님의 “뒤 돌아 봐라”, “끼니 거르지 마라” 잔소리 더 들어야 하는데…… 그 사랑
                      안에서 누렸던 안정감과 풍요로움 대신 할 수 있는 것 아직 못 찾았는데 어떻게
                      하나요 ……
                    </p>
                    <p>
                      2007년 파킨슨 씨 병의 진단을 받으시고 신체 기능이 서서히 저하 되셔서 걸음을 못
                      떼시고 2017년 여름부터는 음식도 못 드시며 말씀도 못하시기에 이르렀습니다. 중증
                      환자가 되신 어머님을 아버지는 시설 기관에 보내지 않고 집에서 당신이
                      돌보시겠다고 하셨습니다.
                    </p>
                    <p>
                      돌봄을 받아야 할 연세에 아버님은 직접 요양 보호사 자격증까지 따시고 수호천사가
                      되어 어머님을 돌보셨습니다. 타인의 돌봄이 없으면 아무 것도 스스로 하실 수 없는
                      아내를 섬기는 것은 당신의 능력과 힘으로는 감당 할 수 없는 일임을 아버님은
                      깨닫게 되셨습니다. 성경 말씀을 매일 쓰시며 자신의 모든 자랑을 다 비우고 온
                      마음을 어머님께 집중하셨습니다. 매일 위루관을 통해 영양 액과 약을 챙겨드리는
                      것은 물론, 욕창을 방지하기 위해 요양 보호사의 도움을 받아 하루 2번씩 의자에
                      앉혀 거실로 나와 TV를 시청하실 수 있게 하셨습니다.
                    </p>
                    <p>
                      눈을 껌벅거리는 것 외에는 의사표현을 못하시는 어머님에게 조금이라도 불편한
                      점이 있을까 봐 세밀하게 어머님을 관찰하시고 대책을 세우셨습니다. 하루 식사량과
                      소변량을 매일 기록 하셨습니다.
                    </p>
                    <p>
                      그렇게 한 없이 연약한 아내를 사랑으로 돌보시며 아버님의 얼굴에는 조금씩 빛이
                      나기 시작하셨으며, 평소 늘 무뚝뚝하시고 잔정이 없으시던 아버님이 이제 자식이
                      오면 손수 커피를 끓여 주시고 사과를 깎아 먹으라고 하십니다.
                    </p>
                    <p>
                      어머님께서 아버님을 변화시키고 계셨습니다. 어머님을 바라보시는 아버지의 눈빛은
                      봄날 햇살만큼이나 따사롭습니다.
                    </p>
                    <p>
                      그렇게 두 분은 세상에서 가장 서로를 아끼고 사랑하는 부부가 되어 가셨습니다.
                      자녀와 손주들에게 어떻게 살며 서로 사랑하여야 하는지 가르쳐 주셨습니다.
                    </p>
                    <p>2021. 10. 17 장남 올림</p>
                  </div>
                </div>
                <div className="modal__footer">
                  <button className="btn btn-blue" onClick={onClose}>
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default ChudosaSampleDialog;

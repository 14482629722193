import { useNavigate } from 'react-router-dom';

import imgIntro01 from 'assets/memorial/images/img_premium.png';
import imgIntro02 from 'assets/memorial/images/img_premium_mo.png';

function ServicePremiumPage({ setMode }) {
  const navigate = useNavigate();

  return (
    <section className="container is-about" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>서비스 소개</h3>

          <div className="tabs tabs-about">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('memorial')}>
                  메모리얼 캔버스란?
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('premuim')}>
                  조문보 프리미엄
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h4 className="title">조문보는</h4>
            <p className="desc">
              고인의 삶과 정신적 유산을 조문객에게 알리며 추모하는 문서로 고인의 인생을 압축적으로
              정리한 글입니다.
            </p>
          </div>

          <div className="wrap-premium">
            <div className="about-premium-service">
              <div className="img-premium">
                <img alt="" src={imgIntro01} className="d-block d-md-none" />
                <img alt="" src={imgIntro02} className="d-none d-md-block" />
              </div>
              <div className="text-premium">
                <h5>조문보 프리미엄 서비스는</h5>
                <p>
                  <em>
                    고인의 삶을 보다 깊이 있게, <br className="d-none d-md-block" />
                    의미 있게 담아내고자 할 경우
                  </em>
                  <br />
                  에버영피플의 조문보 전문 작가의 도움을 받아 <br className="d-none d-md-block" />
                  제작하는 서비스 입니다.
                </p>
                <p className="desc">
                  조문보를 제작하며 고인의 삶이 가지는 의미와 지혜를 추출하게 되고,{' '}
                  <br className="d-block d-md-none" />
                  고인과의 기억들을 하나 하나 풀어내며 고인이 남기신 유훈을{' '}
                  <br className="d-block d-md-none" />
                  어느 순간 새롭게 깨닫게 됩니다.
                </p>
                <div className="btn-wrap">
                  <a
                    href={() => false}
                    onClick={() => navigate('/memorial/service/premium')}
                    className="btn btn-pos"
                  >
                    신청하기
                  </a>
                </div>
              </div>

              {/* S : 신청 페이지 바로가기*/}
              <div className="wrap-quick-btn">
                <button
                  type="button"
                  onClick={() => navigate('/memorial/service/premium')}
                  className="btn-quick-link"
                >
                  <span>신청</span>
                </button>
              </div>
              {/* E : 신청 페이지 바로가기*/}
            </div>

            <div className="wrap-process">
              <h5>제작방법</h5>

              <div className="contents-process">
                <p>인터뷰와 질문지를 기반으로 조문보 전문 작가가 제작합니다.</p>
                <div className="box-process">
                  <div className="row">
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">1</span>고객
                        <br />
                        상담
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">2</span>작가
                        <br />
                        배정
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">3</span>작가
                        <br />
                        인터뷰
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">4</span>조문보
                        <br />
                        제작
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">5</span>결과물
                        <br />
                        완성
                      </div>
                    </div>
                  </div>
                </div>
                <ol className="box-process-detail">
                  <li>
                    1. 고객 상담
                    <ul>
                      <li>신청 버튼을 클릭하여 상담 신청 양식을 제출합니다.</li>
                      <li>입력하신 E-mail로 조문보 작성 준비물을 안내 받습니다.</li>
                    </ul>
                  </li>
                  <li>
                    2. 작가 배정
                    <ul>
                      <li>조문보 전문 작가를 배정 받습니다.</li>
                      <li>조문보에 필요한 질문 답변지와 사진을 전달합니다.</li>
                    </ul>
                  </li>
                  <li>
                    3. 작가 인터뷰
                    <ul>
                      <li>전화/줌/대면인터뷰를 진행하며 조문보 내용을 구체화합니다.</li>
                    </ul>
                  </li>
                  <li>
                    4. 조문보 제작
                    <ul>
                      <li>조문보 주제, 핵심어를 고객과 협의하고 사진과 글을 구성합니다.</li>
                      <li>초안 검토 후 2회 수정 가능합니다.</li>
                    </ul>
                  </li>
                  <li>
                    5. 결과물 완성
                    <ul>
                      <li>메모리얼 캔버스에 완성된 조문보가 게시됩니다.</li>
                      <li>소책자 3부가 컬러 인쇄로 제공됩니다.</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>

            <div className="wrap-process">
              <h5>서비스 문의</h5>
              <div className="contents-process">
                <div className="box-gray">
                  <dl>
                    <dt>제작 회사</dt>
                    <dd>㈜에버영피플</dd>
                    <dt>상담 시간</dt>
                    <dd>
                      월요일~금요일 08:00~15:00 <br className="d-none d-md-block" />
                      (점심시간 12:30 ~ 13:30)
                    </dd>
                    <dt>빠른 상담</dt>
                    <dd className="num">02-2138-8381 , 010-5483-1965</dd>
                    <dt>이메일</dt>
                    <dd>kyunghyukchang@everyoungpeople.com</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default ServicePremiumPage;

import { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import { getMemorialApi } from 'apis/memorial';

import parse from 'html-react-parser';
import ContentLoader from 'react-content-loader';

import TabBltContent from 'pages/memorial-canvas/obituary/components/TabBltContent';
import TabCondolencesContent from 'pages/memorial-canvas/obituary/components/TabCondolencesContent';
import TabAlbumContent from 'pages/memorial-canvas/memorial/components/TabAlbumContent';
import TabVideoContent from 'pages/memorial-canvas/memorial/components/TabVideoContent';
import SharedDialog from 'pages/memorial-canvas/memorial/components/SharedDialog';

import noDeceasedPhoto from 'assets/images/no_img_memorial.jpg';

import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';
import TabChudosasContent from '../obituary/components/TabChudosasContent';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import TabCondolencesGroupContent from '../obituary/components/TabCondolencesGroupContent';

function MemorialViewPage() {
  const navigate = useNavigate();

  const { state, location } = useLocation();

  const tabElement = useRef(null);

  const { memorialId } = useParams();

  const { setGnbType } = useLayout();
  const { showAlert, hideAlert } = useDialog();
  const { loginUser } = useLogin();

  const [loading, setLoading] = useState(true);
  const [memorial, setMemorial] = useState(null);
  const [deceasedName, setDeceasedName] = useState(null);
  const [ownMemberId, setOwnMemberId] = useState(null);
  const [blts, setBlts] = useState(null);
  const [chudosas, setChudosas] = useState(null);
  const [currentLastUrl, setCurrentLastUrl] = useState(null);

  const [bltContents, setBltContents] = useState(null);
  const [chudosaContents, setChudosaContents] = useState(null);
  const [url, setUrl] = useState('');

  const [tabIndex, setTabIndex] = useState(1);
  const [tabBlt, setTabBlt] = useState(false);
  const [tabState, setTabState] = useState(false);

  const [openSharedDialog, setOpenSharedDialog] = useState(false);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

  const [isActive, setActive] = useState(false);

  const getMemorial = async params => {
    setOpenLoadingDialog(true);
    const result = await getMemorialApi(params);
    setOpenLoadingDialog(false);
    //console.log(JSON.stringify(result.data));
    if (
      result.type === 'error' ||
      result.data.display_yn === 'N' ||
      (result.data.status === '1' && result.data.multy_yn !== 'Y')
    ) {
      if (!loginUser || loginUser.member_id !== result.data.own_member_id) {
        showAlert('알림', '존재하지 않는 추모관입니다.', () => handleClose());
        return null;
      }
    }

    setMemorial(result.data);
    setBlts(result.blt_datas);
    setChudosas(result.chudosa_datas);
    setLoading(false);
    setUrl(result.data.deceased_video_link);
  };

  const handleClose = () => {
    hideAlert();
    // navigate('/');
  };

  const handleModify = () => {
    navigate(`/memorial/memorial/modify/${memorialId}`);
  };

  const media = {
    media_type: 'V',
    media_link: url,
  };

  // const handleTabCondolencesContent = own_member_id => {
  //   navigate(`/memorial/letter/view/${memorialId}`, {
  //     memorialId: 'memorialId',
  //     ownMemberId: 'own_member_id',
  //   });
  // };

  const handleMediaView = () => {
    setOpenMediaViewDialog(true);
  };

  const homeRef = useRef();
  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const moveUrl = () => {
    navigate(`/memorial/letter/${memorialId}`, {
      state: {
        memorialId: memorialId,
        ownMemberId: memorial.own_member_id,
        deceasedName: deceasedName,
      },
    });
  };

  const onClickTab = index => {
    if (tabIndex === index && index !== 1) {
      setTabState(!tabState);
      return;
    }
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
    setTabIndex(index);
  };

  useEffect(() => {
    setBltContents(
      blts && blts.length > 0
        ? blts.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [blts]);

  useEffect(() => {
    setChudosaContents(
      chudosas && chudosas.length > 0
        ? chudosas.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [chudosas]);

  useEffect(() => {
    setDeceasedName(memorial && memorial.deceased_name ? memorial.deceased_name : null);
    setOwnMemberId(memorial && memorial.own_member_id ? memorial.own_member_id : null);
  }, [memorial]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getMemorial(memorialId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.viewTab) {
      setTabIndex(state.viewTab);
      tabElement.current?.scrollIntoView({ block: 'center' });
      console.log('scroll into ...');
    }
  });

  useEffect(() => {
    if (window.location.pathname.indexOf(`/memorial/memorial/view/${memorialId}` + '/') > -1) {
      const splitUrl = window.location?.pathname?.split('/');
      const location = splitUrl?.length > 1 ? splitUrl[splitUrl.length - 1] : null;
      setCurrentLastUrl(location);
      setTabIndex(4);
      setTabBlt(false);
    }
  }, [location]);

  const xy = useRef(null);

  useEffect(() => {
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
  }, [tabBlt]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">{deceasedName}님 추모관</h2>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        {/* S : 23-04-21 메모리얼 캔버스 개편에 따른 마크업 수정 */}
        {/*
         *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
         */}
        <div className="fixed-list is-remembrance">
          <ul>
            <li className={tabIndex === 1 && tabBlt === true ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(1);
                  setTabBlt(true);
                }}
              >
                일대기
              </button>
            </li>
            <li className={tabIndex === 2 ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(2);
                  setTabBlt(false);
                }}
              >
                추도사
              </button>
            </li>
            <li className={tabIndex === 3 ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(3);
                  setTabBlt(false);
                }}
              >
                추억앨범
              </button>
            </li>
            <li className={tabIndex === 4 ? 'is-active' : ''}>
              {/*<button type="button" onClick={() => moveUrl()}>*/}
              {/*  추모의 글*/}
              {/*</button>*/}
              <button
                type="button"
                onClick={() => {
                  onClickTab(4);
                  setTabBlt(false);
                }}
              >
                추모의 글
              </button>
            </li>
          </ul>
        </div>
        <div className="wrap-detail">
          {/* S : 영정 사진 */}
          {/* S : 사진 없을 때 */}
          {/*
           *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
           */}
          {memorial ? (
            <>
              {memorial.deceased_photo ? (
                <div className="wrap-portrait is-remembrance has-portrait">
                  <div className="box-title">
                    <h3 className="title">세상에 남겨진 오직 한 사람의 이야기</h3>
                    <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                  </div>
                  <div className="box-portrait">
                    <img
                      src={memorial.deceased_photo ? memorial.deceased_photo : noDeceasedPhoto}
                      alt=""
                    />
                    {/*<img src="https://images.unsplash.com/photo-1648183185045-7a5592e66e9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1084&q=80" />*/}
                    {/*<img src="../../static/media/user_no_portrait_obituary.png" />*/}
                  </div>
                </div>
              ) : (
                <div className="wrap-portrait is-remembrance">
                  <div className="box-title">
                    <h3 className="title">
                      세상에 남겨진 <br className="d-md-block d-none" /> 오직 한 사람의 이야기
                    </h3>
                    <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                  </div>
                </div>
              )}

              {/* S : 고인 정보 */}
              <div className="wrap-deceased contents-width">
                <div className="box-name">
                  <h4 className="name">
                    {memorial.deceased_name} {memorial.deceased_job_title}
                  </h4>
                  {loginUser &&
                  loginUser.member_id === memorial.own_member_id &&
                  memorial.status === '2' ? (
                    <a href={() => false} className="btn-modify" onClick={() => handleModify()}>
                      수정
                    </a>
                  ) : null}
                  {memorial.display_yn === 'N' ? (
                    <span className="icon">
                      <i className="ic ic-secret"></i>
                      <span className="offscreen">비공개 글</span>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                {memorial.deceased_birthday || memorial.deceased_date ? (
                  <p className="birth">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</p>
                ) : (
                  ''
                )}
                {/* S : 버튼 */}
                <div className="btns">
                  <ul>
                    <li>
                      <button type="button" onClick={() => setOpenSharedDialog(true)}>
                        <i className="ic ic-send-obituary"></i>추모관 전송
                      </button>
                    </li>
                  </ul>
                </div>
                {/* E : 버튼 */}
              </div>
              {/* E : 고인 정보 */}

              {/* S : 소개글 */}
              <div className="wrap-info">
                <div className="contents-width">
                  <p>
                    {memorial.deceased_introduction &&
                      parse(memorial.deceased_introduction.replace(/(\n|\r\n)/g, '<br />'))}
                  </p>
                </div>
              </div>
              {/* E : 소개글 */}

              {/* S : 추모 영상 */}
              {memorial.deceased_video_yn === 'Y' && memorial.deceased_video_link !== '' ? (
                <div className="wrap-remembrance-vod">
                  <div className="contents-width">
                    <div className="box-title">
                      <h4 className="title">추모 영상</h4>
                      <p className="desc">고인의 삶을 영상으로 담아 추억합니다.</p>
                    </div>
                    <div className="box-vod">
                      <div className="inner-item">
                        {memorial.video_thumbnail ? (
                          <video className="video" poster={memorial.video_thumbnail} />
                        ) : (
                          <video
                            className="video"
                            poster="https://plus.unsplash.com/premium_photo-1675826774817-fe983ceb0475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                          />
                        )}
                        <button
                          type="button"
                          className="btn-vod-play is-show"
                          onClick={() => handleMediaView()}
                        >
                          <span className="offscreen">추모영상 재생하기</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ' '
              )}
              {/* E : 추모 영상 */}

              {/* S : 부고/추모관 공통 컨텐츠 */}
              {/* S : 일대기 */}
              {tabIndex === 1 && (
                <TabBltContent
                  bltContents={bltContents}
                  deceasedName={deceasedName}
                  xy={xy}
                  tabState={tabState}
                />
              )}
              {tabIndex === 2 && (
                <TabChudosasContent chudosaContents={chudosaContents} tabState={tabState} />
              )}
              {tabIndex === 3 && (
                <TabAlbumContent
                  memorialId={memorialId}
                  deceasedName={deceasedName}
                  ownMemberId={ownMemberId}
                  tabState={tabState}
                />
              )}
              {tabIndex === 4 && (
                <TabCondolencesGroupContent currentLastUrl={currentLastUrl} tabState={tabState} />
              )}
              <MediaViewDialog
                isOpen={openMediaViewDialog}
                onClose={() => setOpenMediaViewDialog(false)}
                media={media}
              />
              <SharedDialog
                isOpen={openSharedDialog}
                onClose={() => setOpenSharedDialog(false)}
                memorial={memorial}
              />
            </>
          ) : (
            ''
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={openLoadingDialog}
          //onClick={() => setOpenLoadingDialog(false)}
          backdrop="static"
        >
          <Stack gap={1} justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography>Loading...</Typography>
          </Stack>
        </Backdrop>
      </div>
    </section>
  );
}

export default MemorialViewPage;

export const getDate = day => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return `${date.getFullYear()}-${('0' + (1 + date.getMonth())).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
};

export const timestampToDate = timestamp => {
  const date = new Date(Number(timestamp));

  return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
};

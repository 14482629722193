import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getCalculateListApi, deleteMemberApi } from 'apis/calculate';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IconModify from '@mui/icons-material/Edit';
import IconDelete from '@mui/icons-material/DeleteForever';

import Pagination from 'react-js-pagination';
import { yellow } from '@mui/material/colors';

function AdminMemberPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();

  //선택 체크박스
  const [checkedList, setCheckedList] = useState([]);

  /**
   * 체크박스 선택 시 usestate에 담기
   * @param {체크여부} checked
   * @param {item} item
   */
  const onCheckedElement = (checked, item) => {
    if (checked) {
      setCheckedList([...checkedList, item]);
    } else if (!checked) {
      setCheckedList(checkedList.filter(el => el !== item));
    }
  };

  const getCalculateList = async pageNum => {
    let params = {};
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getCalculateListApi(params);
    //console.log(JSON.stringify(result));
    setCalculateList(result.data);
    //setTotalCount(20);
    setTotalCount(result.data_cnt);
  };

  const deleteMemorial = async params => {
    //const result = await deleteMemberApi(params);
    //showAlert('알림', result.message, () => hideAlert());
    getCalculateList(1);
  };

  const [calculateList, setCalculateList] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const handleModify = seq => {
    navigate(`${pathname}/detail/${seq}`);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const isChecked = memorialId => {
    return checkedList.includes(memorialId);
  };

  const handleRegister = () => {
    navigate(`${pathname}/batch`, { state: checkedList });
  };

  const handleDelete = memberId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(memberId),
      () => hideConfirm(),
    );
  };

  const handleConfirm = memberId => {
    hideConfirm();
    deleteMemorial(memberId);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getCalculateList(selectedPage);
  };

  const handleSearchMembers = () => {
    getCalculateList(1);
    setPage(1);
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getCalculateList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  const yellow = {
    backgroundColor: 'yellow',
  };

  const white = {
    backgroundColor: '',
  };

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>정산 관리</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02">
                    <option>전체</option>
                    {/* <option>부고 대상</option>
                    <option>소유자</option> */}
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    placeholder="검색어를 입력하세요"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  ref={searchRef}
                  onClick={handleSearchMembers}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-download"
              onClick={() => alert('엑셀 다운로드 준비 중')}
            >
              엑셀 다운로드
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">정산 관리</caption>
            <colgroup>
              {/* <col width="15%" /> */}
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                {/* <th scope="col">선택</th> */}
                <th scope="col">상태</th>
                <th scope="col">대표상주명</th>
                <th scope="col">고인명</th>
                <th scope="col">정산신청</th>
                <th scope="col">정산완료</th>
                <th scope="col">은행명</th>
                <th scope="col">계좌명</th>
                <th scope="col">금액</th>
                <th scope="col">상세</th>
              </tr>
            </thead>
            <tbody>
              {calculateList && calculateList.length > 0
                ? calculateList.map(row => (
                    <tr key={row.memorial_id} style={row.calculate_yn === 'N' ? yellow : white}>
                      {/* <td>
                        <input
                          type="checkbox"
                          value={row.memorial_id}
                          onChange={e => {
                            onCheckedElement(e.target.checked, e.target.value);
                          }}
                          disabled={row.calculate_yn === 'Y' ? true : false}
                          defaultChecked={isChecked(row.memorial_id)}
                        />
                      </td> */}
                      <td>
                        {row.calculate_yn === 'Y' ? (
                          <span className="tc-green">정산완료</span>
                        ) : (
                          <span className="tc-red">미정산</span>
                        )}
                      </td>
                      <td>{row.chief_mourner}</td>
                      <td>{row.deceased_name}</td>
                      <td>{row.request_date}</td>
                      <td>{row.deposit_date}</td>
                      <td>{row.account_bank}</td>
                      <td>{row.account_num}</td>
                      <td>
                        {' '}
                        {row.flower_price_final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td>
                        <div className="btn-wrap">
                          <Tooltip title="정산 페이지 이동" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="수정"
                              onClick={() => handleModify(row.memorial_id)}
                            >
                              <i className="ic ic-modify"></i>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}
    </div>
  );
}

export default AdminMemberPage;

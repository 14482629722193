import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  alertState,
  confirmState,
  lifeAlertState,
  lifeConfirmState,
  setAlert,
  setConfirm,
  setLifeAlert,
  setLifeConfirm,
} from './reducer';

export default function useDialog() {
  const alert = useSelector(alertState);
  const confirm = useSelector(confirmState);

  const lifeAlert = useSelector(lifeAlertState);
  const lifeConfirm = useSelector(lifeConfirmState);

  const dispatch = useDispatch();

  const showAlert = useCallback(
    (title, message, callback) => {
      const params = { isOpen: true, title: title, message: message, callback: callback };
      dispatch(setAlert(params));
    },
    [dispatch],
  );

  const hideAlert = useCallback(() => {
    const params = { isOpen: false, title: null, message: null, callback: null };
    dispatch(setAlert(params));
  }, [dispatch]);

  const showConfirm = useCallback(
    (title, message, confirmCallback, cancelCallback) => {
      const params = {
        isOpen: true,
        title: title,
        message: message,
        confirmCallback: confirmCallback,
        cancelCallback: cancelCallback,
      };
      dispatch(setConfirm(params));
    },
    [dispatch],
  );

  const hideConfirm = useCallback(() => {
    const params = {
      isOpen: false,
      title: null,
      message: null,
      confirmCallback: null,
      cancelCallback: null,
    };
    dispatch(setConfirm(params));
  }, [dispatch]);

  const showLifeAlert = useCallback(
    (title, message, callback) => {
      const params = { isOpen: true, title: title, message: message, callback: callback };
      dispatch(setLifeAlert(params));
    },
    [dispatch],
  );

  const hideLifeAlert = useCallback(() => {
    const params = { isOpen: false, title: null, message: null, callback: null };
    dispatch(setLifeAlert(params));
  }, [dispatch]);

  const showLifeConfirm = useCallback(
    (title, message, confirmCallback, cancelCallback) => {
      const params = {
        isOpen: true,
        message: message,
        confirmCallback: confirmCallback,
        cancelCallback: cancelCallback,
      };
      dispatch(setLifeConfirm(params));
    },
    [dispatch],
  );

  const hideLifeConfirm = useCallback(() => {
    const params = {
      isOpen: false,
      message: null,
      confirmCallback: null,
      cancelCallback: null,
    };
    dispatch(setLifeConfirm(params));
  }, [dispatch]);

  return {
    alert,
    confirm,
    lifeAlert,
    lifeConfirm,
    showAlert,
    hideAlert,
    showConfirm,
    hideConfirm,
    showLifeAlert,
    hideLifeAlert,
    showLifeConfirm,
    hideLifeConfirm,
  };
}

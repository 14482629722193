import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useCode from 'stores/code/hook';
import useDialog from 'stores/dialog/hook';

import { getFaqBoardApi, modifyFaqBoardApi } from 'apis/faqBoard';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function AdminFaqLifeModifyPage() {
  {
    const navigate = useNavigate();
    const { id } = useParams();
    const { loginUser } = useLogin();
    const { displayYnCodes, faqBltTypeCodes, myfaqBltTypeCodes, canvasTypeCodes } = useCode();
    const { showAlert, hideAlert } = useDialog();

    const [canvas, setCanvas] = useState('');

    const schema = Yup.object({
      blt_type: Yup.string().required('필수 선택 항목입니다.'),
      title: Yup.string()
        .required('필수 입력 항목입니다.')
        .max(50, '최대 입력은 50자까지 가능합니다.'),
      order_num: Yup.string().required('필수 입력 항목입니다.'),
      canvas_type: Yup.string().required('* 필수 입력 항목입니다.'),
      contents: Yup.string()
        .required('필수 입력 항목입니다.')
        .max(1000, '최대 입력은 1000자까지 가능합니다.'),
    }).required();

    const defaultValues = {
      title: '',
      order_num: 0,
      blt_type: 1,
      display_yn: 'Y',
      canvas_type: 'L',
      contents: '',
    };

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    });
    const getFaqBoard = async params => {
      const result = await getFaqBoardApi(params);
      setFaqBoard(result.data);
    };

    const modifyFaqBoard = async params => {
      const result = await modifyFaqBoardApi(params);
      result.code === '0000'
        ? showAlert('알림', result.message, () => handleClose())
        : showAlert('알림', result.message, () => hideAlert());
    };

    const [faqBoard, setFaqBoard] = useState(null);

    const onChangeCanvas = event => {
      setCanvas(event.target.value);
    };

    const onSubmit = data => {
      delete data.createdtime;
      modifyFaqBoard(data);
    };

    const onError = error => {
      console.log('error > ', error);
    };

    const handleClose = () => {
      hideAlert();
      navigate('/admin/faq/life');
    };

    useEffect(() => {
      if (faqBoard) {
        Object.entries(faqBoard).forEach(([key, value]) => {
          setValue(key, value);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqBoard]);

    useEffect(() => {
      if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
        showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
        navigate('/');
      }
      if (loginUser) getFaqBoard(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginUser]);

    return (
      <div className="container" id="containerWrap">
        <div className="box-title">
          <h2>FAQ등록</h2>
        </div>

        <div className="content-box wrap-detail">
          {/* S : 정산정보 입력 */}
          <div className="content-detail">
            <h3>FAQ정보</h3>
            <div className="tbl-wrap">
              <table className="tbl-row">
                <caption></caption>
                <colgroup>
                  <col width="25%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <label for="selSample1">캔버스구분</label>
                    </th>
                    <td className="input">
                      <div className="ip-item">
                        <select
                          {...register('canvas_type')}
                          id="canvas_type"
                          aria-invalid={errors.canvas_type ? 'true' : 'false'}
                          className="input"
                          onChange={onChangeCanvas}
                        >
                          {canvasTypeCodes && canvasTypeCodes.length > 0
                            ? canvasTypeCodes.map(code =>
                                code.code === 'L' ? (
                                  <option value={code.code} key={code.code}>
                                    {code.value}
                                  </option>
                                ) : (
                                  ''
                                ),
                              )
                            : null}
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="selSample2">FAQ구분</label>
                    </th>
                    <td className="input">
                      <div className="ip-item is-error">
                        <select
                          {...register('blt_type')}
                          id="blt_type"
                          aria-invalid={errors.blt_type ? 'true' : 'false'}
                          className="input"
                        >
                          {myfaqBltTypeCodes &&
                            myfaqBltTypeCodes.length > 0 &&
                            myfaqBltTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))}
                        </select>
                        {errors.blt_type && <p className="message">{errors.blt_type.message}</p>}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="ipSample1">공개여부</label>
                    </th>
                    <td className="input">
                      {/* S : 에러 케이스 */}
                      <div className="ip-item is-error">
                        <select
                          {...register('display_yn')}
                          id="display_yn"
                          aria-invalid={errors.display_yn ? 'true' : 'false'}
                          className="input"
                        >
                          {displayYnCodes && displayYnCodes.length > 0
                            ? displayYnCodes.map(code => (
                                <option value={code.code} key={code.code}>
                                  {code.value}
                                </option>
                              ))
                            : null}
                        </select>
                        {errors.display_yn && (
                          <p className="message">{errors.display_yn.message}</p>
                        )}
                      </div>
                      {/* E : 에러 케이스 */}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="ipSample2">우선순위</label>
                    </th>
                    <td className="input">
                      <div className="ip-item is-error">
                        <input
                          {...register('order_num')}
                          aria-invalid={errors.order_num ? 'true' : 'false'}
                          type="text"
                          id="ipSample2"
                        />
                        {errors.order_num && <p className="message">{errors.order_num.message}</p>}
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">
                      <label for="ipSample3">제목</label>
                    </th>
                    <td className="input">
                      <div className="ip-item is-error">
                        <input
                          {...register('title')}
                          aria-invalid={errors.title ? 'true' : 'false'}
                          type="text"
                          id="ipSample3"
                        />
                        {errors.title && <p className="message">{errors.title.message}</p>}
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          {/* E : 정산정보 입력 */}

          {/* S : 디지털 화환 총 모금액 */}
          <div className="content-detail">
            <h3>내용</h3>
            <div className="tbl-wrap">
              <table className="tbl-row">
                <caption></caption>
                <tbody>
                  <tr>
                    <th scope="row">
                      <label for="ipSample5">제목</label>
                    </th>
                    <td className="input">
                      <div className="ip-item is-error">
                        <input
                          type="text"
                          id="ipSample5"
                          {...register('title')}
                          aria-invalid={errors.title ? 'true' : 'false'}
                        />
                        {errors.title && <p className="message">{errors.title.message}</p>}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <label for="ipSample5">내용</label>
                    </th>
                    <td className="input">
                      <div className="ip-item is-error">
                        <textarea
                          {...register('contents')}
                          aria-invalid={errors.contents ? 'true' : 'false'}
                          cols="30"
                          rows="10"
                          id="selSampleTxt1"
                        ></textarea>
                        {errors.contents && <p className="message">{errors.contents.message}</p>}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* E : 디지털 화환 총 모금액 */}
        </div>

        {/* S : 버튼 */}
        <div className="btn-wrap center">
          <button
            type="button"
            className="btn btn-lg btn-pos"
            onClick={handleSubmit(onSubmit, onError)}
          >
            저장
          </button>
          <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
            취소
          </button>
        </div>
        {/* E : 버튼 */}
      </div>
    );
  }
}
export default AdminFaqLifeModifyPage;

import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';
import useDialog from 'stores/dialog/hook';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, Lazy } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'css/swiper.min.css';
import 'swiper/css/lazy';

import 'css/popup.css';
import MediaAlbumDialog from 'pages/memorial-canvas/obituary/components/MediaAlbumDialog';

function ContentViewDialog({
  isOpen,
  onClose,
  commentInfo,
  getComment,
  deleteComment,
  setCommentInfo,
}) {
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const [openMediaAlbumDialog, setOpenMediaAlbumDialog] = useState(false);

  const [initialSlide, setInitialSlide] = useState(0);

  const handleDeleteComment = commentId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleDeleteConfirm(commentId),
      () => hideConfirm(),
    );
  };

  const handleDeleteConfirm = commentId => {
    hideConfirm();
    deleteComment(commentId);
    onClose();
  };

  const handleOpenAlbumDialog = index => {
    setInitialSlide(index);
    setOpenMediaAlbumDialog(true);
  };

  const handleClose = () => {
    onClose();
    setCommentInfo();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="mg" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-remembrance-07" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모의 글</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={handleClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                {/* S */}
                {commentInfo ? (
                  <div className="wrap-memorial-article">
                    <div className="btns">
                      <button
                        type="button"
                        className="btn btn-sm-ln-blue"
                        onClick={() => handleDeleteComment(commentInfo.comment_id)}
                      >
                        삭제
                      </button>
                      <button
                        type="button"
                        className="btn-arrow-prev"
                        onClick={() => {
                          if (commentInfo.idx === 0) {
                            showAlert('알림', '첫 번째 글 입니다.', () => hideAlert());
                          } else {
                            getComment(commentInfo.previous_comment_id);
                          }
                        }}
                      >
                        <i className="ic ic-arrow-prev"></i>
                        <span className="offscreen">이전</span>
                      </button>
                      <button
                        type="button"
                        className="btn-arrow-next"
                        onClick={() => {
                          if (commentInfo.idx + 1 === commentInfo.size) {
                            showAlert('알림', '마지막 글 입니다.', () => hideAlert());
                          } else {
                            getComment(commentInfo.next_comment_id);
                          }
                        }}
                      >
                        <i className="ic ic-arrow-next"></i>
                        <span className="offscreen">다음</span>
                      </button>
                    </div>
                    <div className="content-memorial-article">
                      <div className="article-text">
                        <div className="article-info">
                          {commentInfo.createdtime ? (
                            <span className="date">{commentInfo.createdtime.split(' ')[0]}</span>
                          ) : null}
                          {commentInfo.writer_name ? (
                            <span className="name">{commentInfo.writer_name}</span>
                          ) : null}
                        </div>
                        <div className="article-detail">
                          <p style={{ wordBreak: 'break-all' }}>{commentInfo.blt_contents}</p>
                        </div>

                        {/* <div className="btn-wrap d-md-none">
                        <button type="button" className="btn btn-more">
                          더보기
                        </button>
                      </div> */}
                      </div>
                      {commentInfo.medias ? (
                        <div className="article-media">
                          <p className="title">사진/동영상</p>
                          <div className="list_video">
                            <ul>
                              {commentInfo.medias.map((media, index) => {
                                return (
                                  <li
                                    onClick={() => handleOpenAlbumDialog(index)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <img src={media.thumbnail_link} alt="" />
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="text-count">
                      <em>{commentInfo.idx + 1}</em> / {commentInfo.size}
                    </div>
                  </div>
                ) : null}
                {/* E */}
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close
                  onClick={handleClose}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>

        {commentInfo ? (
          <MediaAlbumDialog
            isOpen={openMediaAlbumDialog}
            onClose={() => setOpenMediaAlbumDialog(false)}
            medias={commentInfo.medias}
            initialSlide={initialSlide}
          />
        ) : null}
      </div>
    </Dialog>
  );
}

export default ContentViewDialog;

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Login from 'layouts/memorial-canvas/Login';
import useLogin from 'stores/login/hook';

function Navgation() {
  const navigate = useNavigate();
  const { loginUser, logout, openLogin, setOpenLogin } = useLogin();

  const handleOpenLogin = () => {
    navigate('/memorial/login');
  };

  const handleLogout = () => {
    logout();
    navigate('/memorial');
  };

  const [isFixOn, setFixOn] = useState(false);
  const [isMobileMenuOn, setMobileMenuOn] = useState(false);
  const [isMemberMenuOn, setMemberMenuOn] = useState(false);
  const [isObituaryMenuOn, setObituaryMenuOn] = useState(false);
  const [isMemorialMenuOn, setMemorialMenuOn] = useState(false);
  const [isFaqMenuOn, setFaqMenuOn] = useState(false);
  const [isTemplateMenuOn, setTemplateMenuOn] = useState(false);

  const handleMobileMenuOn = () => {
    setMobileMenuOn(prevState => !prevState);
  };

  useEffect(() => {
    function handleFixOn() {
      setFixOn(window.scrollY > 0);
    }

    window.addEventListener('scroll', handleFixOn);
    handleFixOn();

    return () => {
      window.removeEventListener('scroll', handleFixOn);
    };
  }, []);

  return (
    <aside>
      <nav>
        <ul>
          <li>
            <a href={() => false} onClick={() => alert('홈 준비중')}>
              홈
            </a>
          </li>
          <li
            className={`${isMemberMenuOn === true ? 'is-active' : ''}`}
            onClick={() => setMemberMenuOn(!isMemberMenuOn)}
          >
            <strong>회원관리</strong>
            {/* 현재 선택 된 메뉴일 경우 className="is-active" 추가 */}
            <ul>
              <li className="is-active">
                <a href={() => false} onClick={() => navigate('/admin/member')}>
                  회원 조회 및 변경
                </a>
              </li>
              {/* 현재 선택 된 메뉴일 경우 className="is-active" 추가// 2뎁스 일 경우 1뎁스+2뎁스 모두 active 주기*/}
              <li>
                <a href={() => false} onClick={() => navigate('/admin/member/detail')}>
                  휴면 회원 관리
                </a>
              </li>
            </ul>
          </li>
          {/*<li>*/}
          {/*  <a href={() => false} onClick={() => navigate('/admin/manager')}>*/}
          {/*    <strong>관리자관리</strong>*/}
          {/*  </a>*/}
          {/*</li>*/}
          <li>
            <a href={() => false} onClick={() => navigate('/admin/consulting')}>
              <strong>상담관리</strong>
            </a>
          </li>
          <li
            onClick={() => setObituaryMenuOn(!isObituaryMenuOn)}
            className={`${isObituaryMenuOn === true ? 'is-active' : ''}`}
          >
            <strong>부고관리</strong>
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/obituary')}>
                  <strong>메모리얼 캔버스</strong>
                </a>
              </li>

              <li>
                <a href={() => false} onClick={() => navigate('/admin/obituary/pet')}>
                  펫 메모리얼 캔버스
                </a>
              </li>
            </ul>
          </li>

          <li
            onClick={() => setMemorialMenuOn(!isMemorialMenuOn)}
            className={`${isMemorialMenuOn === true ? 'is-active' : ''}`}
          >
            <strong>추모관관리</strong>
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/condolence')}>
                  <strong>메모리얼 캔버스</strong>
                </a>
              </li>

              <li>
                <a href={() => false} onClick={() => navigate('/admin/condolence/pet')}>
                  펫 메모리얼 캔버스
                </a>
              </li>
            </ul>
          </li>

          {/*<li>*/}
          {/*  <a href={() => false} onClick={() => navigate('/admin/calculate')}>*/}
          {/*    <strong>정산관리</strong>*/}
          {/*  </a>*/}
          {/*</li>*/}
          <li
            onClick={() => setFaqMenuOn(!isFaqMenuOn)}
            className={`${isFaqMenuOn === true ? 'is-active' : ''}`}
          >
            <strong>FAQ관리</strong>
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/faq')}>
                  메모리얼 캔버스
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/faq/life')}>
                  라이프 캔버스
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/faq/pet')}>
                  펫 메모리얼 캔버스
                </a>
              </li>
            </ul>
          </li>
          <li
            onClick={() => setTemplateMenuOn(!isTemplateMenuOn)}
            className={`${isTemplateMenuOn === true ? 'is-active' : ''}`}
          >
            <strong>템플릿관리</strong>
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/template')}>
                  메모리얼 캔버스
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/template/life')}>
                  라이프 캔버스
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => navigate('/admin/template/pet')}>
                  펫 메모리얼 캔버스
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href={() => false} onClick={() => navigate('/admin/canvas/main')}>
              <strong>메인관리</strong>
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Navgation;

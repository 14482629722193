import { useEffect, useState } from 'react';
import configs from '@configs/configs';
import { useLocation } from 'react-router-dom';

import { getCanvasApi } from 'apis/canvas';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';
import copy from 'copy-to-clipboard';

import ImagesDialog from '../ImagesDialog';

function MyProfile({ setEdit }) {
  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { pathname } = useLocation();

  const [canvas, setCanvas] = useState();
  const [linkCount, setLinkCount] = useState(0);
  const [bltCount, setBltCount] = useState(0);

  const currentUrl = `${configs.BASEURL}/life/canvas/user/${loginUser.canvas_id}`;

  const [image, setImage] = useState(null);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);

  const getCanvas = async () => {
    let params = {};
    params.canvas_id = loginUser.canvas_id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getCanvasApi(params);

    if (result.code === '0000') {
      setCanvas(result.data);
      setBltCount(result.blt_cnt);
      setLinkCount(result.link_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const shreadLink = async () => {
    try {
      copy(currentUrl);
      showLifeAlert('알림', '주소 복사가 완료되었습니다.', () => hideLifeAlert());
    } catch (error) {
      console.log('error > ', error);
      showLifeAlert('알림', '주소 복사에 실패하였습니다.', () => hideLifeAlert());
    }
  };

  const handleImageView = image => {
    setImage(image);
    setOpenMediaViewDialog(true);
  };

  const handleBackgroundImageView = (image, id) => {
    if (image === null || image === '' || image === undefined) {
      if (id % 2 === 0) {
        setOpenMediaViewDialog(false);
      } else {
        setOpenMediaViewDialog(false);
      }
    } else {
      setImage(image);
      setOpenMediaViewDialog(true);
    }
  };

  const init = async () => {
    getCanvas();
  };

  useEffect(() => {
    if (loginUser?.member_id) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="cover-canvas">
      {/* S : 프로필 이미지, 이름, 생년월일 */}
      <div className="user-profile-cover">
        <div className="user-cover-img">
          <span className="chk-size">
            <img
              alt=""
              // src={
              //   canvas?.background_link
              //     ? canvas.background_link
              //     : Math.round(Math.random()) > 0
              //     ? imgNoThumb01
              //     : imgNoThumb02
              // }
              src={
                canvas?.background_link
                  ? canvas.background_link
                  : canvas?.canvas_id % 2 === 0
                  ? imgNoThumb01
                  : imgNoThumb02
              }
              onClick={() => handleBackgroundImageView(canvas.background_link, canvas.canvas_id)}
              style={{ cursor: 'pointer' }}
            />
          </span>
        </div>
        <div className="user-pf-thumb">
          <div className="user-pf-thumb-img">
            <img
              alt=""
              src={canvas?.profile_link ? canvas.profile_link : ''}
              onClick={() => handleImageView(canvas.profile_link)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>

      {/* S : 22-07-21 공유하기 버튼 추가 */}
      <div className="share-wrap">
        <a href={() => false()} onClick={() => shreadLink()}>
          <i class="ic ic-share-url"></i>
          <span className="offscreen">공유하기</span>
        </a>
      </div>
      {/* E : 22-07-21 공유하기 버튼 추가 */}

      <div className="user-name">{canvas?.title}</div>

      {canvas?.birthday === '0000.00.00' || canvas?.birthday === '' ? (
        ''
      ) : (
        <div className="user-birth">
          {/* S : 생년월일 */}
          <div className="user-birth-date">
            <p>{canvas?.birthday}</p>
          </div>
          {/* E : 생년월일 */}
        </div>
      )}
      {/* E : 프로필 이미지, 이름, 생년월일 */}
      {/* S : 좌우명 */}
      <div className="user-motto">
        <p>{canvas?.motto}</p>
      </div>
      {/* E : 좌우명 */}

      {/* S : 구독자 수, 작성글 갯수 */}
      <div className="user-count">
        구독자 수 {linkCount}명 · 작성글 {bltCount}개
      </div>
      {/* E : 구독자 수, 작성글 갯수 */}

      {/* S : 자기소개 */}
      {canvas?.letter_contents && canvas.letter_contents.length > 0 && (
        <div className="user-introduce">
          <div className="user-history has-bullet">
            {/* 타인 계정일 경우 has-bullet 추가하여 리스트에 디자인 입힘 */}
            <ul>
              {canvas.letter_contents.map(letter => (
                <li key={letter.letter_num}>{letter.letter_contents}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="btn-wrap">
        <button type="button" className="btn btn-md btn-blue" onClick={() => setEdit(true)}>
          수정
        </button>
      </div>
      {/* E : 자기소개 */}
      {openMediaViewDialog && (
        <ImagesDialog
          isOpen={openMediaViewDialog}
          onClose={() => setOpenMediaViewDialog(false)}
          image={image}
        />
      )}
    </div>
  );
}

export default MyProfile;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gnbTypeState, setGnbTypeState } from './reducer';

export default function useLayout() {
  const gnbType = useSelector(gnbTypeState);

  const dispatch = useDispatch();

  const setGnbType = useCallback(
    gnbType => {
      dispatch(setGnbTypeState(gnbType));
    },
    [dispatch],
  );

  return {
    gnbType,
    setGnbType,
  };
}

import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Progress({ value }) {
  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1, backgroundColor: 'lightGray' }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={value}
            sx={{
              transform: `translateX(-${100 - value}%)`,
              transitionProperty: 'transform',
              transitionDuration: '2s, 2s',
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${value}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

import imgServiceSample from 'assets/images/service_sample.png';

function WritingProcess() {
  return (
    <section>
      <div className="service_content">
        <h2 className="title">
          02
          <br />
          작성과정
        </h2>
        <p>※샘플 대로 따라하면 보다 쉽게 조문보를 작성하실 수 있습니다.</p>
        <h3 className="guide_title">자료수집 및 분류하기</h3>
        <ul className="guide_list">
          <li>
            ① 고인과 관련된 자료 수집하기
            <br />
            (사진, 연혁, 일화, 일기장, 메모장, 수첩, SNS 글, 사연이 담긴 물건, 편지, 유족들과의
            인터뷰 글, 추모글 등)
          </li>
          <li>② 모은 자료를 연대순, 비슷한 주제 등으로 모으고 분류하기</li>
        </ul>
        <h3 className="guide_title">대표주제 선택</h3>
        <ul className="guide_list">
          <li>
            ① 분류된 자료를 보고 10가지 대표 주제 중 5가지 대표 주제 선정
            <br />
            (※대표 주제는 선택 후 주제명 변경 가능)
            <div style={{ marginTop: '10px' }}>
              <img alt="" src={imgServiceSample} />
            </div>
          </li>
        </ul>
        <h3 className="guide_title">글쓰기</h3>
        <ul className="guide_list">
          <li>① 각 주제에 맞게 글감과 사진 등의 자료 배치하고 정리하기</li>
          <li>② 각 주제에 맞게 글과 사진 검토하고 글을 추가하거나 수정하기</li>
        </ul>
        <h3 className="guide_title">내용확인 및 퇴고하기</h3>
        <ul className="guide_list">
          <li>
            ① 오타는 없는지, 주제와 답변이 맞게 되었는지, 추가할 내용은 없는 지 소리 내어 읽어보고
            확인하기
            <br />
            (※글쓰기 중간 중간에 '저장하기')
          </li>
          <li>② 조문보 화면에서 미리보기 한 후 다시 수정하기</li>
        </ul>
      </div>
    </section>
  );
}

export default WritingProcess;

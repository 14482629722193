import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLogin from 'stores/login/hook';
import useCode from 'stores/code/hook';
import useDialog from 'stores/dialog/hook';

import { getCanvasApi, modifyCanvasApi } from 'apis/canvas';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function AdminCanvasMainModifyPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loginUser } = useLogin();

  const { displayStatusCodes } = useCode();

  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    canvas_id: Yup.string().required('필수 선택 항목입니다.'),
    display_status: Yup.string().required('필수 선택 항목입니다.'),
    canvas_num: Yup.string().required('필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    canvas_id: 0,
    display_status: 0,
    canvas_num: 0,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    let params = {};

    params.canvas_id = data.canvas_id;
    params.display_status = data.display_status;
    params.canvas_num = data.canvas_num;

    modifyCanvas(params);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const getCanvas = async id => {
    let params = {};
    params.canvas_id = id;
    const result = await getCanvasApi(params);
    setCanvas(result.data);
  };

  const modifyCanvas = async params => {
    const result = await modifyCanvasApi(params);
    result.code === '0000'
      ? showAlert('알림', result.message, () => handleClose())
      : showAlert('알림', result.message, () => hideAlert());
  };

  const [canvas, setCanvas] = useState(null);

  const handleClose = () => {
    hideAlert();
    navigate('/admin/canvas/main');
  };

  useEffect(() => {
    if (canvas) {
      Object.entries(canvas).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getCanvas(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>메인수정</h2>
      </div>

      <div className="content-box wrap-detail">
        {/* S : 정산정보 입력 */}
        <div className="content-detail">
          <h3>기본정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <colgroup>
                <col width="25%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">ID.</label>
                  </th>
                  <td className="input">
                    {/* S : 에러 케이스 */}
                    <div className="ip-item is-error">
                      <input type="text" id="ipSample1" value={canvas?.canvas_id} readOnly />
                    </div>
                    {/* E : 에러 케이스 */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample2">이름</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample2" value={canvas?.title} readOnly />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample3">생년월일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample3" value={canvas?.birthday} readOnly />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample4">가입일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample4" value={canvas?.createdtimeStr10} readOnly />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 정산정보 입력 */}

        {/* S : 디지털 화환 총 모금액 */}
        <div className="content-detail">
          <h3>상담 내용</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="selSample3">공개여부</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <select {...register('display_status')} id="selSample3">
                        {displayStatusCodes && displayStatusCodes.length > 0
                          ? displayStatusCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <label for="ipSample6">우선순위</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample6" {...register('canvas_num')} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 디지털 화환 총 모금액 */}
      </div>

      {/* S : 버튼 */}
      <div className="btn-wrap center">
        <button
          type="button"
          className="btn btn-lg btn-pos"
          onClick={handleSubmit(onSubmit, onError)}
        >
          저장
        </button>
        <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
          취소
        </button>
      </div>
      {/* E : 버튼 */}
    </div>
  );
}

export default AdminCanvasMainModifyPage;

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';
import noDeceasedPhoto1 from 'css/static/media/user_no_portrait_rememberance.png';

// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'css/swiper.min.css';
// import 'css/swiper.custom.css';

// import 'css/custom.css';

import { getMemorialsApi } from 'apis/memorial';

import ContentLoader from 'react-content-loader';
import { breakpoints } from '@mui/system';

function MemorialSection() {
  const navigate = useNavigate();

  const breakPoint = window.matchMedia('(max-width: 1024px)');

  const [isLoading, setLoading] = useState(true);
  const [memorials, setMemorials] = useState(null);
  const [remembers, setRemebers] = useState(null);
  const [isFixOn, setFixOn] = useState(false);

  const getMemorials = async params => {
    const result = await getMemorialsApi(params);
    if (result.code == '0000') {
      setMemorials(result.data.length > 0 ? result.data : null);
      //console.log(JSON.stringify(result));
    } else setMemorials(null);

    setLoading(false);
  };

  const getRemembers = async params => {
    const result = await getMemorialsApi(params);
    if (result.code == '0000') setRemebers(result.data.length > 0 ? result.data : null);
    else setRemebers(null);

    setLoading(false);
  };

  const handleMore = () => {
    navigate('/pet/obituary');
  };

  useEffect(() => {
    getMemorials({
      //status: 1,
      //multy_type: 'Y',
      display_yn: 'Y',
      // orderBy: { direction: 'desc', field: 'memorial_id' },
      ordercol: 'status asc, createdtime desc',
      page: 1,
      itemInPage: 8,
    });

    // getRemembers({
    //   status: 2,
    //   multy_type: 'Y',
    //   display_yn: 'Y',
    //   orderBy: { direction: 'desc', field: 'memorial_id' },
    //   page: 1,
    //   itemInPage: 4,
    // });
  }, []);

  return (
    <>
      <div class="wrap is-main">
        {/* S : 추모관 목록 */}
        {breakPoint.matches ? (
          // <div className="wrap-memorial">
          <Swiper
            className="wrap-memorial"
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
            centeredSlides={true}
            watchSlidesProgress={true}
            loop={true}
            autoHeight={true}
            autoplay={{ delay: 2000 }}
            simulateTouch={true}
            observer={true}
            navigation={{
              nextEl: ' .swiper-button-next',
              prevEl: ' .swiper-button-prev',
            }}
            pagination={{ el: '.swiper-pagination', type: 'progressbar' }}
            breakpoints={{
              0: {
                spaceBetween: 27,
              },
              480: {
                spaceBetween: 54,
              },
            }}
          >
            <div className="list-memorial swiper-wrapper">
              {/* S : 추모글 반복 시작 */}
              {/* S : 부고 알림일 경우 */}
              {memorials &&
                memorials.map(memorial =>
                  memorial.status === '1' ? (
                    <SwiperSlide
                      className="box-memorial swiper-slide swiper-slide-duplicate"
                      key={memorial.memorial_id}
                      onClick={() => navigate(`/pet/obituary/view/${memorial.memorial_id}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* <div className=""> */}
                      <div className="img" style={{ cursor: 'pointer' }}>
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              memorial.deceased_photo
                                ? memorial.deceased_photo
                                : memorial.memorial_id.substring(
                                    memorial.memorial_id.length - 1,
                                    memorial.memorial_id.length,
                                  ) %
                                    2 ===
                                  0
                                ? noDeceasedPhoto
                                : noDeceasedPhoto1
                            }
                          />
                        </span>
                      </div>
                      <div className="badge">
                        <span>부고</span>
                      </div>
                      <div className="info">
                        <p className="name">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</p>
                        {memorial.deceased_birthday !== '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</span>
                        ) : memorial.deceased_birthday !== '' && memorial.deceased_date === '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~ `}</span>
                        ) : memorial.deceased_birthday === '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`~  ${memorial.deceased_date}`}</span>
                        ) : (
                          <span className="date"> &nbsp;</span>
                        )}
                      </div>
                      <div className="btn-wrap">
                        <a
                          href={() => false}
                          onClick={() =>
                            navigate(`/memorial/obituary/view/${memorial.memorial_id}`)
                          }
                          className="btn btn-orange"
                        >
                          조문하기
                        </a>
                      </div>
                      {/* </div> */}
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide
                      className="swiper-slide swiper-slide-duplicate"
                      key={memorial.memorial_id}
                      onClick={() => navigate(`/pet/memorial/view/${memorial.memorial_id}`)}
                    >
                      <div className="box-memorial swiper-slide">
                        <div className="img">
                          <span className="chk-size">
                            <img
                              alt=""
                              src={
                                memorial.deceased_photo
                                  ? memorial.deceased_photo
                                  : memorial.memorial_id.substring(
                                      memorial.memorial_id.length - 1,
                                      memorial.memorial_id.length,
                                    ) %
                                      2 ===
                                    0
                                  ? noDeceasedPhoto
                                  : noDeceasedPhoto1
                              }
                            />
                          </span>
                        </div>
                        <div className="info">
                          <p className="name">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</p>
                          {memorial.deceased_birthday !== '' && memorial.deceased_date !== '' ? (
                            <span className="date">{`${memorial.deceased_birthday} ~  ${memorial.deceased_date}`}</span>
                          ) : memorial.deceased_birthday !== '' && memorial.deceased_date === '' ? (
                            <span className="date">{`${memorial.deceased_birthday} ~ `}</span>
                          ) : memorial.deceased_birthday === '' && memorial.deceased_date !== '' ? (
                            <span className="date">{`~  ${memorial.deceased_date}`}</span>
                          ) : (
                            <span className="date"> &nbsp;</span>
                          )}
                        </div>
                        <div className="btn-wrap">
                          <a
                            href={() => false}
                            onClick={() => navigate(`/pet/memorial/view/${memorial.memorial_id}`)}
                            className="btn btn-blue"
                          >
                            추모관
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  ),
                )}
              {/* E : 부고 알림일 경우 */}
              {/* E : 추모글 반복 종료 */}

              {/* S : 추모글 반복 시작 */}
              {/* {remembers &&
                remembers.map(remember => (
                  <SwiperSlide
                    className="swiper-slide swiper-slide-duplicate"
                    key={remember.memorial_id}
                    onClick={() => navigate(`/memorial/memorial/view/${remember.memorial_id}`)}
                  >
                    <div className="box-memorial swiper-slide">
                      <div className="img">
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              remember.deceased_photo ? remember.deceased_photo : noDeceasedPhoto
                            }
                          />
                        </span>
                      </div>
                      <div className="info">
                        <p className="name">{`${remember.deceased_name} ${remember.deceased_job_title}`}</p>
                        <span className="date">{`${remember.deceased_birthday} ~  ${remember.deceased_date}`}</span>
                      </div>
                      <div className="btn-wrap">
                        <a
                          href={() => false}
                          onClick={() =>
                            navigate(`/memorial/memorial/view/${remember.memorial_id}`)
                          }
                          className="btn btn-blue"
                        >
                          추모관
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                ))} */}
              {/* E : 추모글 반복 종료 */}
            </div>

            {/* E : 추모관 목록 */}
            {/* E : 추모관 */}
          </Swiper>
        ) : (
          // </div>
          <div className="wrap-memorial">
            <div className="list-memorial swiper-wrapper">
              {/* S : 추모글 반복 시작 */}
              {/* S : 부고 알림일 경우 */}
              {memorials &&
                memorials.map(memorial =>
                  memorial.status === '1' ? (
                    <div
                      className="box-memorial swiper-slide"
                      onClick={() => navigate(`/pet/obituary/view/${memorial.memorial_id}`)}
                    >
                      <div className="img">
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              memorial.deceased_photo
                                ? memorial.deceased_photo
                                : memorial.memorial_id.substring(
                                    memorial.memorial_id.length - 1,
                                    memorial.memorial_id.length,
                                  ) %
                                    2 ===
                                  0
                                ? noDeceasedPhoto
                                : noDeceasedPhoto1
                            }
                          />
                        </span>
                      </div>
                      <div className="badge">
                        <span>부고</span>
                      </div>
                      <div className="info">
                        <p className="name">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</p>
                        {memorial.deceased_birthday !== '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~  ${memorial.deceased_date}`}</span>
                        ) : memorial.deceased_birthday !== '' && memorial.deceased_date === '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~ `}</span>
                        ) : memorial.deceased_birthday === '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`~  ${memorial.deceased_date}`}</span>
                        ) : (
                          <span className="date"> &nbsp;</span>
                        )}
                      </div>
                      <div className="btn-wrap">
                        <a
                          href={() => false}
                          onClick={() => navigate(`/pet/obituary/view/${memorial.memorial_id}`)}
                          className="btn btn-orange"
                        >
                          조문하기
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="box-memorial swiper-slide"
                      onClick={() => navigate(`/pet/memorial/view/${memorial.memorial_id}`)}
                    >
                      <div className="img">
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              memorial.deceased_photo
                                ? memorial.deceased_photo
                                : memorial.memorial_id.substring(
                                    memorial.memorial_id.length - 1,
                                    memorial.memorial_id.length,
                                  ) %
                                    2 ===
                                  0
                                ? noDeceasedPhoto
                                : noDeceasedPhoto1
                            }
                          />
                        </span>
                      </div>
                      <div className="info">
                        <p className="name">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</p>
                        {memorial.deceased_birthday !== '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~  ${memorial.deceased_date}`}</span>
                        ) : memorial.deceased_birthday !== '' && memorial.deceased_date === '' ? (
                          <span className="date">{`${memorial.deceased_birthday} ~ `}</span>
                        ) : memorial.deceased_birthday === '' && memorial.deceased_date !== '' ? (
                          <span className="date">{`~  ${memorial.deceased_date}`}</span>
                        ) : (
                          <span className="date"> &nbsp;</span>
                        )}
                      </div>
                      <div className="btn-wrap">
                        <a
                          href={() => false}
                          onClick={() => navigate(`/pet/memorial/view/${memorial.memorial_id}`)}
                          className="btn btn-blue"
                        >
                          추모관
                        </a>
                      </div>
                    </div>
                  ),
                )}
              {/* E : 부고 알림일 경우 */}
              {/* E : 추모글 반복 종료 */}

              {/* S : 추모글 반복 시작 */}
              {/* {remembers &&
                remembers.map(remember => (
                  <div
                    className="box-memorial swiper-slide"
                    onClick={() => navigate(`/memorial/memorial/view/${remember.memorial_id}`)}
                  >
                    <div className="img">
                      <span className="chk-size">
                        <img
                          alt=""
                          src={remember.deceased_photo ? remember.deceased_photo : noDeceasedPhoto}
                        />
                      </span>
                    </div>
                    <div className="info">
                      <p className="name">{`${remember.deceased_name} ${remember.deceased_job_title}`}</p>
                      <span className="date">{`${remember.deceased_birthday} ~  ${remember.deceased_date}`}</span>
                    </div>
                    <div className="btn-wrap">
                      <a
                        href={() => false}
                        onClick={() => navigate(`/memorial/memorial/view/${remember.memorial_id}`)}
                        className="btn btn-blue"
                      >
                        추모관
                      </a>
                    </div>
                  </div>
                ))} */}
              {/* E : 추모글 반복 종료 */}
            </div>
          </div>
        )}
        {/* E : 추모관 목록 */}
        {/* E : 추모관 */}
      </div>
    </>
  );
}

export default MemorialSection;

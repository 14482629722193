import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';

import 'css/popup.css';

function LoginDialog({ isOpen, onClose }) {
  const navigate = useNavigate();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div
          className="modal micromodal-slide is-alert js-modal is-open"
          id="modal-remembrance-05"
          aria-hidden="false"
        >
          <div className="modal__overlay" tabindex="-1" data-micromodal-close="">
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">로그인 필요 확인 안내</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close=""
                >
                  <i className="ic ic-close" onClick={onClose}></i>
                </button>
              </div>
              <div className="modal__content">
                <p>로그인이 필요합니다.</p>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close=""
                  onClick={() => {
                    navigate('/pet/login');
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LoginDialog;

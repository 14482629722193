import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getCalculateListApi, postCalculateApi } from 'apis/calculate';

import Pagination from 'react-js-pagination';

function AdminMemberPage() {
  const navigate = useNavigate();

  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();

  const { state } = useLocation();
  //선택 체크박스
  const [checkedList, setCheckedList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const [calculateList, setCalculateList] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  /**
   * 체크박스 선택 시 usestate에 담기
   * @param {체크여부} checked
   * @param {item} item
   */
  const onCheckedElement = (checked, item, item_price) => {
    if (checked) {
      setCheckedList([...checkedList, item]);
      setTotalPrice(() => totalPrice + Number(item_price));
    } else if (!checked) {
      setTotalPrice(() => totalPrice - item_price);
      setCheckedList(checkedList.filter(el => el !== item));
    }
  };

  const getCalculateList = async pageNum => {
    let params = {};
    params.page = pageNum;
    params.itemInPage = listSize;
    params.memorial_ids = state;

    const result = await getCalculateListApi(params);
    setCalculateList(result.data);
    setTotalCount(result.data_cnt);

    return result.flower_price;
  };

  const isChecked = memorialId => {
    return checkedList.includes(memorialId);
  };

  // 일괄 정산 api
  const batchDeposit = async () => {
    if (checkedList.length === 0) {
      showAlert('알림', '선택된 항목이 없습니다.', () => {
        hideAlert();
      });
      return;
    }
    let param = {};
    param.memorial_ids = checkedList;
    param.deposit_yn = 'Y';
    const result = await postCalculateApi(param);
    if (result.code === '0000') {
      showAlert('', '일괄 정산이 완료되었습니다.', () => {
        hideAlert();
        navigate('/memorial/admin/calculate');
      });
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getCalculateList(selectedPage);
  };

  const handleSubmit = () => {
    batchDeposit();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const numberFormatter = num => {
    return Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    if (loginUser) {
      getCalculateList(1).then(price => {
        // 페이지 로드될때 값 설정
        setTotalPrice(price);
      });
      // 체크 리스트(memorial_id) 이전 페이지로부터 받아옴
      setCheckedList(() => state);
    }
  }, []);
  return (
    <div>
      <div className="content_wrap bg_type">
        <div className="content_inner">
          <div className="inquiry_wrap">
            <div className="title_area">
              <h2 className="content_title">
                일괄<span className="block">정산</span>
              </h2>
            </div>
            <div className="user_manage">
              <table className="manage_tb">
                <colgroup>
                  <col width="*" />
                  <col width="20%" />
                  <col width="15%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="15%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">선택</th>
                    <th scope="col">신청 날짜</th>
                    <th scope="col">상주명</th>
                    <th scope="col">상주명</th>
                    <th scope="col">고인명</th>
                    <th scope="col">은행명</th>
                    <th scope="col">계좌명</th>
                    <th scope="col">정산액</th>
                  </tr>
                </thead>
                <tbody>
                  {calculateList && calculateList.length > 0
                    ? calculateList.map(row => (
                        <tr key={row.memorial_id}>
                          <td>
                            <input
                              type="checkbox"
                              defaultChecked={isChecked(row.memorial_id)}
                              key={row.memorial_id}
                              onChange={e => {
                                onCheckedElement(
                                  e.target.checked,
                                  row.memorial_id,
                                  row.flower_price_final,
                                );
                              }}
                            />
                          </td>
                          <td>{row.request_date}</td>
                          <td>{row.own_member_id}</td>
                          <td>{row.chief_mourner}</td>
                          <td>{row.deceased_name}</td>
                          <td>{row.account_bank}</td>
                          <td>{row.account_num}</td>
                          <td>{numberFormatter(row.flower_price_final)}원</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>

              <Pagination
                activePage={page}
                itemsCountPerPage={listSize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                firstPageText={''}
                prevPageText={''}
                nextPageText={''}
                lastPageText={''}
                onChange={handlePageChange}
                itemClassFirst={'first'}
                itemClassPrev={'prev'}
                itemClassNext={'next'}
                itemClassLast={'last'}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0 20px',
                  border: '1px solid #0c1622',
                  padding: '50px',
                }}
              >
                <h3 style={{ fontSize: '24px', fontWeight: '500', color: '#0c1622' }}></h3>
                <p
                  style={{ display: 'flex', alignItems: 'center', gap: '0 15px', fontSize: '18px' }}
                >
                  총 정산금액{' '}
                  <em style={{ fontWeight: '500', fontSize: '26px' }}>
                    {numberFormatter(totalPrice)}원
                  </em>
                </p>
              </div>

              <div className="btn-wrap">
                <button type="button" className="btn-form btn-navy" onClick={() => handleSubmit()}>
                  일괄정산
                </button>
                <button type="button" className="btn-form btn-ln-gray" onClick={handleCancel}>
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMemberPage;

import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import useDialog from 'stores/dialog/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { modifyCanvasBltApi } from 'apis/canvasBlt';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { getDate } from 'utils/date';

function BucketlistGoaledDialog({ isOpen, canvasBlt, getGoalendData, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const schema = Yup.object({
    blt_id: Yup.string().required('* 필수 입력 항목입니다.'),
    goaldtime: Yup.string().required('* 필수 입력 항목입니다.'),
    goalenddtime: Yup.string().required('* 필수 입력 항목입니다.'),
    goalstatus: Yup.string().required('* 필수 입력 항목입니다.'),
    goalcomment: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    blt_id: '',
    goaldtime: '',
    goalenddtime: getDate(0),
    goalstatus: '',
    goalcomment: '',
  };

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    modifyCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const modifyCanvasBlt = async params => {
    const result = await modifyCanvasBltApi(params);

    console.log('result > ', result);
    if (result.code === '0000') {
      getGoalendData(params);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValue('blt_id', canvasBlt.blt_id);
    setValue('goaldtime', canvasBlt.goaldtime);
    setValue('goalstatus', 'Y');
  }, [canvasBlt]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <div className="wrap" id="lifeCanvas">
        <div className="modal " id="modal-sample-01" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">달성 완료로 상태 변경</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={onClose}
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="bucket-sucess">
                  <div className="bucket-date">
                    <label for="ip-bucket-date" className="offscreen">
                      버킷 달성 날짜
                    </label>
                    <input
                      {...register('goalenddtime')}
                      className="ip-flatpickr"
                      id="ip-bucket-date"
                      type="date"
                    />
                    {errors?.goalenddtime?.message}
                  </div>
                  <div className="ip-item">
                    <label for="user-funeral" className="offscreen">
                      달성소감
                    </label>
                    <textarea
                      {...register('goalcomment')}
                      id="user-funeral"
                      placeholder="달성소감을 입력하세요"
                    ></textarea>
                    {errors?.goalcomment?.message}
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close
                  onClick={onClose}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default BucketlistGoaledDialog;

import { Outlet } from 'react-router-dom';

import Navgation from 'layouts/memorial-canvas/admin/Navgation';
import Header from 'layouts/memorial-canvas/admin/Header';

function AdminMemorialCanvasLayout() {
  return (
    <>
      <section className="wrap" id="wrap">
        {/* <Gnb /> */}
        <Header />
        <section class="container-wrap">
          <Navgation />
          <Outlet />
          {/* <Footer /> */}
        </section>
      </section>
    </>
  );
}

export default AdminMemorialCanvasLayout;

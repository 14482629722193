import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import parse from 'html-react-parser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LifeConfirmDialog({ isOpen, title, message, confirmCallback, cancelCallback }) {
  return (
    <Dialog
      open={isOpen}
      onClose={cancelCallback}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
    >
      <div className="wrap" id="lifeCanvas">
        <div className="modal is-alert" id="modal-sample-02" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">{title}</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <p>{message && parse(message.replace(/(\n|\r\n)/g, '<br />'))}</p>
              </div>

              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close
                  onClick={cancelCallback}
                >
                  취소
                </button>
                <button type="button" className="btn btn-blue" onClick={confirmCallback}>
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LifeConfirmDialog;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from 'stores/login/hook';

function Gnb() {
  const breakPoint = window.matchMedia('(max-width: 1024px)');

  const [isActive, setActive] = useState(false);
  const [hasBorder, setHasBorder] = useState(false);
  const navigate = useNavigate();
  const { loginUser } = useLogin();

  const moveMenu = url => {
    setActive(false);
    navigate(url);
  };

  useEffect(() => {
    function handleHasBorder() {
      setHasBorder(window.scrollY > 5);
    }

    window.addEventListener('scroll', handleHasBorder);
    handleHasBorder();

    return () => {
      window.removeEventListener('scroll', handleHasBorder);
    };
  }, []);

  return (
    <>
      {/* S: HEADER */}
      <header>
        <div className="contents-width">
          <h1>
            <a href={() => false}>
              <span>MEMORIAL CANVAS</span>
            </a>
          </h1>
          {/* S : 메뉴 */}
          <nav id="gnbMenuWrap">
            <ul>
              <li>
                <a href={() => false} onClick={() => moveMenu('/life')}>
                  라이프 캔버스
                </a>
              </li>
              <li>
                <a href="/memorial">메모리얼 캔버스</a>
              </li>
              <li>
                <a href="/pet">펫 메모리얼 캔버스</a>
              </li>
            </ul>
          </nav>
          {/* E : 메뉴 */}
          <span className="nav-bg"></span>
        </div>
      </header>
      {/* E: HEADER */}
    </>
  );
}

export default Gnb;

import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Lazy } from 'swiper';

import 'swiper/css/pagination';
import 'swiper/css';

import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';

function ImagesDialog({ isOpen, onClose, image }) {
  const [currentMedia, setCurrentMedia] = useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <div className="wrap" id="lifeCanvas">
        <div class="modal is-image " id="modal-sample-04" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title offscreen">이미지 레이어(슬라이드) 팝업</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div class="modal__content">
                <div class="swiper preview-swiper">
                  {image && image.length > 0 ? (
                    <div className="swiper-wrapper">
                      {/* S : LOOP */}
                      <Swiper
                        modules={[Navigation, Pagination, Lazy]}
                        slidesPerView={1}
                        spaceBetween={23}
                        centeredSlides={false}
                        //loop={true}
                        // autoHeight={true}
                        autoWidth={true}
                        //calculateHeight={true}
                        simulateTouch={true}
                        //autoplay={{ delay: 2000 }}
                        onActiveIndexChange={swiper => {
                          setCurrentMedia(swiper.activeIndex);
                          // console.log(swiper.activeIndex);
                        }}
                        navigation={{
                          nextEl: '.box-swiper-arrow .swiper-button-next',
                          prevEl: '.box-swiper-arrow .swiper-button-prev',
                        }}
                        //navigation={true}
                        pagination={{ clickable: true, type: 'fraction' }}
                        breakpoints={{
                          0: {
                            autoHeight: false,
                          },
                          992: {
                            autoHeight: false,
                          },
                        }}
                        lazy={true}
                        preloadImages={false}
                        lazyLoading={true}
                        lazyLoadingInPrevNext={true}
                        watchSlidesProgress={true}
                        watchSlidesVisibility={true}
                      >
                        {/* <div className="swiper-wrapper"> */}

                        <SwiperSlide className="swiper-slide">
                          {/* <div className="swiper-slide chk-size"> */}

                          <img
                            src={
                              image
                                ? image
                                : image === imgNoThumb01
                                ? imgNoThumb01
                                : image === imgNoThumb02
                                ? imgNoThumb02
                                : ''
                            }
                            alt=""
                            // className="swiper-lazy swiper-slide-name"
                          />
                        </SwiperSlide>

                        {/* </div> */}
                      </Swiper>
                    </div>
                  ) : (
                    ' '
                  )}
                  <div className="swiper-pagination swiper-pagination-fraction swiper-pagination-horizontal">
                    <span className="swiper-pagination-current">{currentMedia} </span>
                    <span className="swiper-pagination-total">{1} </span>
                  </div>
                </div>
                <div class="modal__footer">
                  {/*<button type="button" class="btn btn-blue" data-micromodal-close><i class="ic ic-close"><span class="offscreen">닫기</span></i></button>*/}
                </div>
              </div>

              <div class="box-swiper-arrow">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>

              {/* <script>
			// 이미지 swiper
			var swiper = new Swiper(".preview-swiper", {
				slidesPerView: "1",
				pagination: {
					el: ".swiper-pagination",
					type: "fraction",
				},
				navigation: {
					nextEl: ".box-swiper-arrow .swiper-button-next",
					prevEl: ".box-swiper-arrow .swiper-button-prev",
				},
				breakpoints: {
					0: {
						autoHeight: false,
					},
					992: {
						autoHeight: true,
					},
				},
			});
			</script> */}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
export default ImagesDialog;

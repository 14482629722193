import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { saveMemberApi } from 'apis/member';
import { notiAuthSendApi, notiAuthVerifyApi } from 'apis/openapi';

import useDialog from 'stores/dialog/hook';
import PrivacyDialog from './components/PrivacyDialog';
import ServiceDialog from './components/ServiceDialog';

import KakaoLogin from './components/KakaoLogin';
import NaverLogin from './components/NaverLogin';

function LifeLoginRegister() {
  const navigate = useNavigate();

  const [minutes, setMinutes] = useState(parseInt(0));
  const [seconds, setSeconds] = useState(parseInt(0));

  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const moveMenu = url => {
    navigate(url);
  };

  // const schema = Yup.object({
  //   email: Yup.string()
  //     .required('* 필수 입력 항목입니다.')
  //     .matches(
  //       '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
  //       '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
  //     ),
  //   member_name: Yup.string()
  //     .required('* 필수 입력 항목입니다.')
  //     .max(50, '* 최대 입력은 50자까지 가능합니다.'),
  //   passwd: Yup.string()
  //     .required('* 필수 입력 항목입니다.')
  //     .matches(
  //       '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
  //       '* 패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
  //     ),
  //   passwd_confirm: Yup.string().oneOf([Yup.ref('passwd'), null], '비밀번호가 일치하지 않습니다'),
  //   mobile: Yup.string()
  //     .required('* 필수 입력 항목입니다.')
  //     .matches(
  //       '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
  //       '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
  //     ),
  //   code: Yup.string()
  //     .required('* 필수입력 항목입니다.')
  //     .min(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.')
  //     .max(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.'),
  //   agreement_privacy: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
  //   agreement_service: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
  // }).required();

  // const defaultValues = {
  //   email: '',
  //   member_name: '',
  //   mobile: '',
  //   passwd: '',
  //   passwd_confirm: '',
  //   login_type: 'E',
  //   id: 0,
  //   code: '',
  //   verified: 'N',
  //   agreement_privacy: false,
  //   agreement_service: false,
  // };

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  //   getValues,
  // } = useForm({
  //   mode: 'onBlur',
  //   resolver: yupResolver(schema),
  //   defaultValues: defaultValues,
  // });

  // const onSubmit = data => {
  //   if (getValues('verified') !== 'Y') {
  //     showLifeAlert('알림', '인증코드를 확인하세요.', () => hideLifeAlert());
  //   } else {
  //     saveMember(data);
  //   }
  // };

  // const onError = error => {
  //   console.log('error > ', error);
  // };
  // const handleNotiAuthSend = () => {
  //   if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
  //     showLifeAlert('알림', '휴대폰 번호를 확인하세요.', () => hideLifeAlert());
  //   } else {
  //     notiAuthSend();
  //   }
  // };

  // const notiAuthSend = async () => {
  //   const params = { send_type: 'S', phone_num: getValues('mobile') };
  //   const result = await notiAuthSendApi(params);

  //   if (result.code === '0000') {
  //     resetTimer();
  //     setValue('id', result.id);
  //     showLifeAlert('알림', '문자로 발송된 인증번호를 입력하세요.', () => hideLifeAlert());
  //   } else {
  //     showLifeAlert('알림', result.message, () => hideLifeAlert());
  //   }
  // };

  // const handleNotiAuthVerify = () => {
  //   if (!getValues('code') || getValues('code') === '' || errors.code) {
  //     showLifeAlert('알림', '인증번호를 확인하세요.', () => hideLifeAlert());
  //   } else {
  //     notiAuthVerify();
  //   }
  // };

  // const notiAuthVerify = async () => {
  //   const params = { id: getValues('id'), code: getValues('code') };
  //   const result = await notiAuthVerifyApi(params);
  //   if (result.code === '0000') setValue('verified', 'Y');
  //   showLifeAlert('알림', result.message, () => hideLifeAlert());
  // };

  // const saveMember = async parmas => {
  //   const result = await saveMemberApi(parmas);

  //   if (result.code === '0000') {
  //     showLifeAlert('알림', result.message, () => successSaveMember());
  //   } else {
  //     showLifeAlert('알림', result.message, () => hideLifeAlert());
  //   }
  // };

  // const successSaveMember = () => {
  //   hideLifeAlert();
  //   navigate(-1);
  // };

  // const resetTimer = () => {
  //   setMinutes(3);
  //   setSeconds(0);
  // };

  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     if (parseInt(seconds) > 0) {
  //       setSeconds(parseInt(seconds) - 1);
  //     }
  //     if (parseInt(seconds) === 0) {
  //       if (parseInt(minutes) === 0) {
  //         clearInterval(countdown);
  //       } else {
  //         setMinutes(parseInt(minutes) - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);
  //   return () => clearInterval(countdown);
  // }, [minutes, seconds]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width">
          {/* S : 회원가입 */}
          <div className="member-form">
            <h2 className="offscreen">JOIN US</h2>
            <h3 className="title">회원가입 방법</h3>

            {/* S : 간편 로그인 버튼 */}
            <ul className="login-btn-wrap">
              <KakaoLogin />
              <NaverLogin />
              {/* <li>
                <button type="button" className="btn-naver">
                  네이버 아이디로 로그인
                </button>
              </li> */}
              <li>
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => moveMenu('/life/login/register/info')}
                >
                  일반 회원가입
                </button>
              </li>
            </ul>
            {/* E : 간편 로그인 버튼 */}

            <p className="desc">
              마이라이프캔버스 계정이 이미 있으신가요?
              <a href={() => false} onClick={() => moveMenu('/life/login')}>
                로그인
              </a>
            </p>
          </div>
          {/* E : 회원가입 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default LifeLoginRegister;

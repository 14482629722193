import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loginState,
  setOpenLoginRequest,
  emailLoginRequest,
  kakaoLoginRequest,
  naverLoginRequest,
  logoutRequest,
  getLoginUserRequest,
  checkUserAuthRequest,
  checkAdminUserAuthRequest,
  notiAuthSendRequest,
  notiAuthVerifyRequest,
} from './reducer';

/**
 * GitHub 정보조회 CustomHook 생성
 *
 * @export
 * @return {*}  {({
 *   loading: any;
 *   error: Error | null;
 *   data: IGithubProfile | null;
 *   gitUser: (username: string) => void;
 * })}
 */
export default function useLogin() {
  const { loading, error, data, loginUser, openLogin } = useSelector(loginState);
  const dispatch = useDispatch();

  const emailLogin = useCallback(
    params => {
      dispatch(emailLoginRequest(params));
    },
    [dispatch],
  );

  const kakaoLogin = useCallback(
    params => {
      dispatch(kakaoLoginRequest(params));
      // },
      // (accessToken, path) => {
      //   //alert('hook' + path);
      //   dispatch(kakaoLoginRequest(accessToken, path));
    },
    [dispatch],
  );

  const naverLogin = useCallback(
    params => {
      dispatch(naverLoginRequest(params));
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(logoutRequest());
  }, [dispatch]);

  const setOpenLogin = useCallback(
    params => {
      dispatch(setOpenLoginRequest(params));
    },
    [dispatch],
  );

  const getLoginUser = useCallback(() => {
    dispatch(getLoginUserRequest());
  }, [dispatch]);

  const checkUserAuth = useCallback(() => {
    dispatch(checkUserAuthRequest());
  }, [dispatch]);

  const checkAdminUserAuth = useCallback(() => {
    dispatch(checkAdminUserAuthRequest());
  }, [dispatch]);

  const notiAuthSend = useCallback(() => {
    dispatch(notiAuthSendRequest());
  }, [dispatch]);

  const notiAuthVerify = useCallback(() => {
    dispatch(notiAuthVerifyRequest());
  }, [dispatch]);

  return {
    loading,
    error,
    data,
    emailLogin,
    kakaoLogin,
    naverLogin,
    logout,
    loginUser,
    openLogin,
    setOpenLogin,
    getLoginUser,
    checkUserAuth,
    checkAdminUserAuth,
    notiAuthSend,
    notiAuthVerify,
  };
}

import { useState, useEffect, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useForm } from 'react-hook-form';
import { getCanvasJoinApi } from 'apis/canvas';

let canvasId = null;
let responseId = null;
let nameId = null;
let birthdayId = null;
let profileImage = null;

function OpenJoinCanvasDialog({
  isOpen,
  setCanvasId,
  setResponseId,
  setNameId,
  setBirthdayId,
  setDeceasedPhoto,
  reflash,
  onClose,
}) {
  const navigate = useNavigate();

  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const searchRef = useRef();

  const { setGnbType } = useLayout();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();

  const { setValue } = useForm();

  const [joinLinkAccepts, setJoinLinkAccepts] = useState();

  const [searchWord, setSearchWord] = useState();
  const [page, setPage] = useState(1);
  const [listSize] = useState(2);
  const [totalPage, setTotalPage] = useState(0);

  const joinCanvas = (canvas, response, name, birthday, image) => {
    canvasId = canvas;
    responseId = response;
    nameId = name;
    birthdayId = birthday;
    profileImage = image;

    setValue('deceased_name', nameId);
    setCanvasId(canvasId);
    setResponseId(responseId);
    setNameId(nameId);
    setBirthdayId(birthdayId);
    setDeceasedPhoto(profileImage);
    // alert(canvasId + responseId + nameId + birthdayId + ' 확인');

    hideLifeAlert();

    reflash();
  };

  const getCanvasJoinListAll = async pageNum => {
    let params = {};
    params.page = pageNum;
    params.itemInPage = listSize;
    params.canvas_id_my = loginUser.canvas_id;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasJoinApi(params);
    console.log('확인', result);
    if (result.code === '0000') {
      setJoinLinkAccepts(result.data_list_A);
      //setJoinLinkWaits(result.data_list_W);
      //setLoginId(loginUser.member_id);
      setPage(1);

      setTotalPage(parseInt(Math.ceil(result.data_cnt_A / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasJoinListAllMore = async pageNum => {
    let params = {};
    params.page = pageNum;
    params.itemInPage = listSize;
    params.canvas_id_my = loginUser.canvas_id;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasJoinApi(params);
    console.log('확인', result);
    if (result.code === '0000') {
      setJoinLinkAccepts([...joinLinkAccepts, ...result.data_list_A]);
      //setJoinLinkWaits(result.data_list_W);
      //setLoginId(loginUser.member_id);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleSearch = () => {
    getCanvasJoinListAll(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasJoinListAllMore(nextPage);
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getCanvasJoinListAll(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <div className="wrap" id="lifeCanvas">
        <div className="modal" id="modal-sample-03" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">연결 캔버스 리스트 </h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                {/* S : 검색 */}
                <div className="box-search">
                  <label for="ip-search" className="offscreen">
                    검색하기
                  </label>
                  <input
                    type="search"
                    id="ip-search"
                    placeholder="이름을 검색해주세요"
                    value={searchWord}
                    onChange={e => setSearchWord(e.target.value)}
                    onKeyPress={onKeyPress}
                  />
                  <button ref={searchRef} type="button" onClick={() => handleSearch()}>
                    <i className="ic ic-search"></i>
                    <span className="offscreen">검색하기</span>
                  </button>
                </div>
                {/* E : 검색 */}

                {/* S : 검색결과가 있을 때 */}
                {joinLinkAccepts && joinLinkAccepts.length > 0 ? (
                  joinLinkAccepts.map(joinLinkAccept => (
                    <ul className="relation-list">
                      <li>
                        <p>
                          <strong>{joinLinkAccept.response_name}</strong>
                        </p>
                        <dl>
                          <dt>관계 :</dt>
                          <dd>{joinLinkAccept.relation}</dd>
                          <dt>생년월일 :</dt>
                          <dd>{joinLinkAccept.birthday_key}</dd>
                        </dl>
                        <div className="util-relation">
                          <a
                            href={() => false}
                            onClick={() =>
                              navigate(`/life/canvas/user/${joinLinkAccept.canvas_id}`)
                            }
                          >
                            라이프캔버스로 이동
                          </a>
                          <button
                            type="button"
                            onClick={() =>
                              joinCanvas(
                                joinLinkAccept.canvas_id,
                                joinLinkAccept.response_id,
                                joinLinkAccept.response_name,
                                joinLinkAccept.birthday_key,
                                joinLinkAccept.profile_link,
                              )
                            }
                          >
                            선택
                          </button>
                        </div>
                      </li>
                    </ul>
                  ))
                ) : (
                  <ul className="relation-list">
                    <li className="no-data">검색결과가 없습니다.</li>
                  </ul>
                )}
              </div>
              <div className="modal__footer">
                {joinLinkAccepts && joinLinkAccepts.length > 0 ? (
                  <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
                    더보기 {page}/{totalPage}
                  </button>
                ) : (
                  <button type="button" className="btn btn-blue" data-micromodal-close="">
                    확인
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default OpenJoinCanvasDialog;

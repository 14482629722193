import { deleteMemberApi, checkPasswordApi } from 'apis/member';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

function Withdrawal({ setMode }) {
  const { loginUser, logout } = useLogin();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const navigate = useNavigate();

  const schema = Yup.object({
    passwd: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
        '* 패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
      ),
    agreement_service: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
  }).required();

  const defaultValues = {
    passwd: '',
    agreement_service: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    checkPassword(data.passwd);
  };

  const checkPassword = async passwd => {
    let params = {};
    params.member_pwd = passwd;
    params.email = loginUser.email;

    const result = await checkPasswordApi(params);

    if (result.code === '0000') {
      handleDelete(loginUser.member_id);
    } else {
      showLifeAlert('알림', '비밀번호가 일치하지 않습니다.', () => hideLifeAlert());
    }
  };

  const handleDelete = memberId => {
    showLifeConfirm(
      '알림',
      '정말 탈퇴 하시겠습니까?',
      () => handleConfirm(memberId),
      () => hideLifeConfirm(),
    );
  };

  const handleConfirm = memberId => {
    hideLifeAlert();
    deleteMemorial(memberId);
  };

  const deleteMemorial = async params => {
    const result = await deleteMemberApi(params);
    showLifeAlert('알림', result.message, () => hideLifeAlert());
    handleLogout();
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          <div className="contents-width is-setting">
            <div className="tabs tabs-setting">
              <ul>
                <li className="is-active">
                  <a href={() => false} onClick={() => setMode('setting')}>
                    회원설정
                  </a>
                </li>
                <li>
                  <a href={() => false} onClick={() => setMode('canvas')}>
                    캔버스 관리
                  </a>
                </li>
              </ul>
            </div>

            <h2 className="offscreen">회원설정</h2>
            <h3>회원탈퇴</h3>

            <div className="setting-form">
              <div className="box-withdrawal">
                <h4>탈퇴 시 주의사항</h4>
                <div className="inner">
                  원활한 회원 탈퇴를 위해 아래 내용을 모두 꼼꼼히 확인하신 후 탈퇴 진행
                  부탁드립니다.
                  <br />
                  <br />* 마이라이프캔버스의 계정은 [라이프캔버스][메모리얼캔버스] 두서비스를 이용할
                  수 있는 통합계정입니다. 본 계정 탈퇴 시 두 서비스 모두 사용할 수 없습니다.
                  <br />
                  <br />
                  상세 사항은 아래 항목을 읽어주세요.
                  <br /> [라이프캔버스]
                  <br />
                  1. 회원탈퇴 시 회원님의 캔버스는 삭제 됩니다. <br /> 2. 회원님이 라이프캔버스에
                  남긴 [일상, 자서전, 문답, 버킷리스트] 게시물은 탈퇴 시 삭제 됩니다. <br />
                  3. 회원님이 다른 캔버스에 남긴 댓글은 삭제되지 않습니다. 댓글이 남아 있는 것을
                  원치 않으신다면 삭제 후 탈퇴 진행해 주세요. <br /> 4. 탈퇴 후 데이터를 복구 할 수
                  없습니다. <br /> 5. 회원정보 데이터는 파기됩니다. <br />
                  <br />
                  [메모리얼캔버스] <br />
                  1. 회원님이 등록한 부고와 추모관은 탈퇴 시에도 삭제 되지 않습니다. 삭제를 원할
                  시에는 삭제 후 탈퇴 진행해 주세요. <br />
                  2. 회원님이 남긴 추모의 글은 탈퇴 시에도 삭제 되지 않습니다. 삭제를 원할 시에는
                  삭제 후 탈퇴 진행해 주세요. <br />
                  3. 디지털화환 정산은 탈퇴 전 꼭 진행 해 주세요. 탈퇴 시에는 회원 정보 및 데이터가
                  모두 삭제 되어 정산 처리가 불가합니다. <br />
                  4. 회원 탈퇴 이후에는 등록한 부고와 추모관을 수정 및 삭제할 수 없습니다. <br />
                  <br />위 사항을 꼼꼼히 확인하고 탈퇴를 진행하세요.
                </div>
              </div>

              <div className="rc-item is-error">
                <input
                  {...register('agreement_service')}
                  aria-invalid={errors.agreement_service ? 'true' : 'false'}
                  type="checkbox"
                  id="chk-confirm"
                />
                <label for="chk-confirm">위 주의사항을 확인하였으며 동의합니다</label>
                {errors.agreement_service && (
                  <p class="message">{errors.agreement_service.message}</p>
                )}
              </div>

              <div className="ip-item is-error">
                <label for="user-re-password">비밀번호 입력</label>
                <input
                  {...register('passwd')}
                  aria-invalid={errors.passwd ? 'true' : 'false'}
                  type="password"
                  id="user-re-password"
                  placeholder="비밀번호를 한번 더 입력해주세요"
                />
                {errors.passwd && <p class="message">{errors.passwd.message}</p>}
              </div>
            </div>
            <div className="btn-wrap column">
              <button
                type="button"
                className="btn btn-blue"
                data-micromodal-trigger="modal-sample-01"
                onClick={handleSubmit(onSubmit, onError)}
              >
                탈퇴하기
              </button>
            </div>
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
    </>
  );
}
export default Withdrawal;

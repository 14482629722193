import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import useDialog from 'stores/dialog/hook';
import { getWreathApi } from 'apis/wreath';

function KiccPaymentResult() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showAlert, hideAlert } = useDialog();

  const getWreath = async () => {
    const result = await getWreathApi(id);
    if (result.code === '0000' && result.data.status === '1') {
      showAlert('알림', '정상 처리되었습니다.', () => successFunction(result.data.memorial_id));
    } else if (result.code === '0000' && result.data.status === '2') {
      showAlert('알림', '정상 처리되었습니다.', () => successFunction(result.data.memorial_id));
    } else {
      showAlert('알림', '결제에 실패하였습니다.', () => failureFunction(result.data.memorial_id));
    }
  };

  const successFunction = memorialId => {
    hideAlert();
    navigate(`/memorial/obituary/view/${memorialId}`);
  };

  const failureFunction = memorialId => {
    hideAlert();
    navigate(`/memorial/obituary/view/${memorialId}`);
  };

  useEffect(() => {
    getWreath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default KiccPaymentResult;

import api from '@apis/api';
import configs from '@configs/configs';

export async function getWreathApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/getWreathDetail`, params);
  return response.data;
}

export async function getWreathsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/getWreathDetailInfo`, params);
  return response.data;
}

export async function postCalculationApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/insertWreathCalculate`, params);
  return response.data;
}

export async function saveCalculateApi(params) {
  const response = await api.put(`${configs.API_BASEURL}/wreath/updateWreathCalculate`, params);
  return response.data;
}

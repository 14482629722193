import api from '@apis/api';
import configs from '@configs/configs';

// 캔버스 댓글 목록
export async function getCanvasBltCommentApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_comment`, params);
  return response.data;
}

// 캔버스 댓글 등록
export async function saveCanvasBltCommentApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_comment/ins`, params);
  return response.data;
}

// 캔버스 댓글 삭제
export async function deleteCanvasBltCommentApi(commentId) {
  const response = await api.delete(
    `${configs.API_BASEURL}/canvas_comment?comment_id=${commentId}`,
  );
  return response.data;
}

// 좋아요 (설정/해제)
export async function toggleCanvasBltLikeApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_like/ins`, params);
  return response.data;
}

// 우리의이야기 리스트
export async function getCanvasBltsStoryApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_blt/story`, params);
  return response.data;
}

// 캔버스 (본인/타인)
export async function getCanvasBltsSubApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_blt/sub`, params);
  return response.data;
}

export async function getCanvasBltsAllApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_blt/all`, params);
  return response.data;
}

export async function getCanvasBltsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_blt`, params);
  return response.data;
}

export async function getCanvasBltApi(params) {
  const response = await api.get(`${configs.API_BASEURL}/canvas_blt`, { params: params });
  return response.data;
}

export async function saveCanvasBltApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_blt/ins`, params);
  return response.data;
}

export async function modifyCanvasBltApi(params) {
  try {
    const response = await api.put(`${configs.API_BASEURL}/canvas_blt`, params);
    return response.data;
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function deleteCanvasBltApi(id) {
  const response = await api.delete(`${configs.API_BASEURL}/canvas_blt?id=${id}`);
  return response.data;
}

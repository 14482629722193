import { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import { getCanvasApi, getCanvasLinksApi } from 'apis/canvas';

import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';

function Subscribe({ canvasMemberId, canvas }) {
  const searchRef = useRef();
  const navigate = useNavigate();
  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const [subscribes, setSubscribes] = useState();
  const [actionType, setActionType] = useState('1');
  const [searchWord, setSearchWord] = useState();
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(1);
  const [listSize] = useState(2);
  const [totalPage, setTotalPage] = useState(0);

  const getCanvasLinks = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'modifydtime' };

    params.page = pageNum;
    params.itemInPage = listSize;

    params.action = actionType;
    params.canvas_member_id = canvasMemberId;
    if (loginUser.member_id) params.member_id = loginUser.member_id;
    if (searchWord) params.searchWord = searchWord;
    //alert(JSON.stringify(params));
    const result = await getCanvasLinksApi(params);

    if (result.code === '0000') {
      setSubscribes(result.data);
      setPage(1);
      setTotalCount(result.data_cnt);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasLinksMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'modifydtime' };
    params.page = pageNum;
    params.itemInPage = listSize;

    params.action = actionType;
    params.canvas_member_id = canvasMemberId;
    params.member_id = loginUser.member_id;
    if (searchWord) params.searchWord = searchWord;

    const result = await getCanvasLinksApi(params);

    if (result.code === '0000') {
      setSubscribes([...subscribes, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleSearch = () => {
    getCanvasLinks(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasLinksMore(nextPage);
    }
  };

  const init = async () => {
    if (actionType === '1') {
      if (canvasMemberId !== loginUser.member_id) {
        if (
          canvas.display_subscribe === '0' ||
          (canvas.display_subscribe === '1' && canvas.link_yn === 'Y')
        ) {
          if (loginUser) getCanvasLinks(1);
        } else {
          setSubscribes(null);
          setTotalCount(0);
          setPage(1);
          setTotalPage(1);
        }
      } else if (canvasMemberId === loginUser.member_id) {
        if (loginUser) getCanvasLinks(1);
      }
    } else if (actionType === '2') {
      if (canvasMemberId !== loginUser.member_id) {
        if (
          canvas.display_subscribers === '0' ||
          (canvas.display_subscribers === '1' && canvas.link_yn === 'Y')
        ) {
          if (loginUser) getCanvasLinks(1);
        } else {
          setSubscribes(null);
          setTotalCount(0);
          setPage(1);
          setTotalPage(1);
        }
      } else if (canvasMemberId === loginUser.member_id) {
        if (loginUser) getCanvasLinks(1);
      }
    }
  };

  useEffect(() => {
    init();
  }, [loginUser, actionType]);

  return (
    <>
      <div className="tabs tabs-scroll-sm">
        <ul>
          <li className={actionType === '1' ? 'is-active' : ''}>
            <a href={() => false} onClick={() => setActionType('1')}>
              내가 구독
            </a>
          </li>
          <li className={actionType === '2' ? 'is-active' : ''}>
            <a href={() => false} onClick={() => setActionType('2')}>
              나를 구독
            </a>
          </li>
        </ul>
      </div>
      {/* E : 탭(2차) */}

      {/* S : 검색 */}
      <div className="search-wrap">
        {/* <div className="select-public-type">
              <select id="sel-public">
                <option value="1">전체 공개</option>
                <option value="2">연결 공개</option>
                <option value="3">비공개</option>
              </select>
            </div> */}

        <div className="box-search" style={{ width: '100%' }}>
          <label for="ip-search" className="offscreen">
            검색하기
          </label>
          <input
            type="search"
            id="ip-search"
            placeholder="이름을 검색하세요"
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onKeyPress={onKeyPress}
          />
          <button ref={searchRef} type="button" onClick={() => handleSearch()}>
            <i className="ic ic-search"></i>
            <span className="offscreen">검색하기</span>
          </button>
        </div>
      </div>
      {/* E : 검색 */}

      {/* S : 구독/구독자 숫자 안내 */}
      <div className="list-head">{totalCount}명의 구독 목록</div>
      {/* E  : 구독/구독자 숫자 안내 */}

      {/* S : 캔버스 목록 */}
      <div className="list-card canvas">
        {/*캔버스 > 구독일 때 canvas 추가 */}

        {/* S : CARD */}
        {subscribes && subscribes.length > 0 ? (
          subscribes.map(subscribe => (
            <div className="box-card" key={subscribe.canvas_id}>
              <div
                className="card-thumb chk-size"
                onClick={() =>
                  navigate(`/life/canvas/user/${subscribe.canvas_id}`, {
                    state: { searchBltType: '0' },
                  })
                }
                style={{ cursor: 'pointer' }}
              >
                <img
                  // src={
                  //   subscribe.background_link
                  //     ? subscribe.background_link
                  //     : Math.round(Math.random()) > 0
                  //     ? imgNoThumb01
                  //     : imgNoThumb02
                  // }
                  src={
                    subscribe?.background_link
                      ? subscribe.background_link
                      : subscribe?.canvas_id % 2 === 0
                      ? imgNoThumb01
                      : imgNoThumb02
                  }
                  alt=""
                />
              </div>
              <div className="card-info">
                <span
                  className="user-img chk-size"
                  onClick={() =>
                    Number(loginUser?.canvas_id) !== Number(subscribe.canvas_id)
                      ? navigate(`/life/canvas/user/${subscribe.canvas_id}`, {
                          state: { searchBltType: '0' },
                        })
                      : navigate('/life/canvas/my')
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <img src={subscribe.profile_link ? subscribe.profile_link : ''} alt="" />
                </span>
                <span
                  className="user-name"
                  onClick={() =>
                    Number(loginUser?.canvas_id) !== Number(subscribe.canvas_id)
                      ? navigate(`/life/canvas/user/${subscribe.canvas_id}`, {
                          state: { searchBltType: '0' },
                        })
                      : navigate('/life/canvas/my')
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {subscribe.title}
                </span>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-lngreen"
                  onClick={() => navigate(`/life/canvas/user/${subscribe.canvas_id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  구독{subscribe?.link_yn}
                </button> */}

                {Number(loginUser?.canvas_id) !== Number(subscribe.canvas_id) ? (
                  subscribe.link_yn === 'Y' ? (
                    <button
                      type="button"
                      class="btn btn-sm btn-lngray"
                      onClick={() =>
                        navigate(`/life/canvas/user/${subscribe.canvas_id}`, {
                          state: { searchBltType: '0' },
                        })
                      }
                    >
                      구독 중
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-lngreen"
                      onClick={() =>
                        navigate(`/life/canvas/user/${subscribe.canvas_id}`, {
                          state: { searchBltType: '0' },
                        })
                      }
                    >
                      + 구독
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-lngreen"
                    onClick={() => navigate('/life/canvas/my')}
                  >
                    본인
                  </button>
                )}
              </div>
              {/* <div className="card-content">{JSON.stringify(subscribe)}</div> */}
              {/* <div className="card-content">
                <a href={() => false}>
                  <p className="motto">
                    한번사는 나의 소중한 인생을 헛되이 쓰지말고 하루하루 소중히 느려도 꾸준히
                    하자느려도 꾸준히 하자느려도 꾸준히 하자
                  </p>
                  <p className="article">
                    끝까지 공자는 낙원을 위하여 간에 하는 있다. 우리 구하지 오아이스도 보배를 그들의
                    날카로우나 물방아 철환하였는가? 것이다.보라, 과실이 살 가장 봄바람을 대한
                    생생하며, 귀는 이것이다. 어디 부패를 실로 새가 인간의 그림자는 봄날의 그들의
                    것이다. 방지하는 그들의 앞이 든 어디 것이다. 힘차게 수 많이 인류의 얼음 그들은
                    사는가 공자는 쓸쓸하랴? 피가 풀이 몸이 운다. 우리 인도하겠다는 내려온 끓는다.
                    우리 속에 원대하고, 설산에서 풍부하게 그리하였는가?
                  </p>
                  <span className="date">2022. 05. 23 13:27</span>
                </a>
              </div> */}
            </div>
          ))
        ) : (
          <div>목록이 없습니다.</div>
        )}
        {/* E : CARD */}
      </div>
      {/* E : 캔버스 목록 */}

      {/* S : 더보기 버튼 */}
      <div className="btn-wrap">
        <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
          더보기 {page}/{totalPage}
        </button>
      </div>
      {/* E : 더보기 버튼 */}
    </>
  );
}

export default Subscribe;

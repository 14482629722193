import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getFaqBoardsApi, deleteFaqBoardApi } from 'apis/faqBoard';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IconModify from '@mui/icons-material/Edit';
import IconDelete from '@mui/icons-material/DeleteForever';

import Pagination from 'react-js-pagination';
import { stringify } from 'stylis';

function AdminFaqPetPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const {
    displayYnCodes,
    canvasTypeCodes,
    faqBltTypeCodes,
    myfaqBltTypeCodes,
    faqPetBltTypeCodes,
  } = useCode();
  const [bltType, setBltType] = useState('');

  const getFaqBoards = async pageNum => {
    let params = {};
    params.canvas_type = 'P';
    params.orderBy = { direction: 'asc', field: 'blt_type' };
    params.page = pageNum;
    params.blt_type = bltType;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getFaqBoardsApi(params);
    //console.log(JSON.stringify(result));
    setFaqBoards(result.data);
    setTotalCount(result.data_cnt);
  };

  const deleteFaqBoard = async params => {
    const result = await deleteFaqBoardApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getFaqBoards(1);
  };

  const [faqBoards, setFaqBoards] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const handleModify = id => {
    navigate(`${pathname}/modify/${id}`);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate(`${pathname}/register`);
  };

  const handleDelete = id => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(id),
      () => hideConfirm(),
    );
  };

  const handleConfirm = id => {
    hideConfirm();
    deleteFaqBoard(id);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getFaqBoards(selectedPage);
  };

  const handleSearchMembers = () => {
    getFaqBoards(1);
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getFaqBoards(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>FAQ 관리_펫 메모리얼캔버스</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02" onChange={e => setBltType(e.target.value)}>
                    <option value={''}>전체</option>
                    <option value={'1'}>장례식</option>
                    <option value={'2'}>부고</option>
                    <option value={'3'}>추모관</option>
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    placeholder="제목을 입력하세요"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  ref={searchRef}
                  onClick={handleSearchMembers}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn btn-download">
              엑셀 다운로드
            </button>
            <button type="button" className="btn btn-pos" onClick={handleRegister}>
              FAQ 등록
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">FAQ관리_메모리얼캔버스 목록</caption>
            <colgroup>
              <col width="15%" />
              <col width="10%" />
              <col width="*" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">캔버스구분</th>
                <th scope="col">FAQ구분</th>
                <th scope="col">제목</th>
                <th scope="col">우선순위</th>
                <th scope="col">공개여부</th>
                <th scope="col">기능</th>
              </tr>
            </thead>
            <tbody>
              {faqBoards && faqBoards.length > 0
                ? faqBoards.map(row => (
                    <tr key={row.id}>
                      <td>펫 메모리얼</td>
                      <td>
                        {faqPetBltTypeCodes
                          .filter(code => code.code === row.blt_type)
                          .map(code => (
                            <span className="tc-gray">{code.value}</span>
                          ))}
                      </td>
                      <td>{row.title}</td>
                      <td>{row.order_num}</td>
                      <td>
                        {row.display_yn === 'Y' ? (
                          <span className="tc-green">공개</span>
                        ) : (
                          <span className="tc-red">비공개</span>
                        )}
                      </td>
                      <td>
                        <div className="btn-wrap">
                          <Tooltip title="수정" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="수정"
                              onClick={() => handleModify(row.id)}
                            >
                              <i className="ic ic-modify"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="삭제" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="삭제"
                              onClick={() => handleDelete(row.id)}
                            >
                              <i className="ic ic-delete"></i>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'first'}
        itemClassPrev={'prev'}
        itemClassNext={'next'}
        itemClassLast={'last'}
      />
      {/* E : 페이징 */}
    </div>
  );
}

export default AdminFaqPetPage;

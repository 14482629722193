import { useRef, useState, useEffect } from 'react';
import QuillEditor from 'components/editor/QuillEditor';

function TabChudosasContent({ chudosaContents, tabState }) {
  const [tabIndex, setTabIndex] = useState(0);

  const prev = tabIndex => {
    if (tabIndex === 0) {
      setTabIndex(0);
    } else {
      setTabIndex(tabIndex - 1);
    }
  };

  const next = tabIndex => {
    if (tabIndex === chudosaContents.length - 1) {
      setTabIndex(chudosaContents.length - 1);
    } else {
      setTabIndex(tabIndex + 1);
    }
  };

  // const homeRef = useRef();
  const xy = useRef(null);
  const onHomeClick = () => {
    // homeRef.current?.scrollIntoView({ top: homeRef.current.offsetTop - 100, behavior: 'smooth' });
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    //xy.current?.scrollIntoView({ behavior: 'smooth' });
    //document.getElementsByName('wrap-funeral').scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
  }, [tabState]);

  // 추도사 탭 추가 부분(작업 예정)
  return chudosaContents && chudosaContents.length > 0 ? (
    <>
      <div className="wrap-funeral" ref={xy}>
        <div className="contents-width">
          <div className="box-title has-crown">
            <h4 className="title">추도사</h4>
          </div>
          {/* S : 탭 */}
          <div className="tabs tabs-list">
            <ul>
              {chudosaContents.map((chudosa, index) => (
                <li
                  className={tabIndex === index && 'is-active'}
                  key={index}
                  onClick={() => setTabIndex(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <button type="button">{chudosa.blt_title}</button>
                </li>
              ))}
            </ul>
          </div>
          {/* E : 탭 */}
          {/* S : 탭 컨텐츠 */}
          <div className="tab-contents">
            {/*<div style="height:300px;background:rgba(204, 204, 204, 1)">TAB CONTENTS</div>*/}
            <QuillEditor value={chudosaContents[tabIndex].blt_contents} viewerMode />
          </div>
          {/* E : 탭 컨텐츠 */}
          {/* S : 이전/다음 버튼 */}
          <div className="pagination">
            {tabIndex === 0 ? (
              ''
            ) : (
              // <button type="button" onClick={() => prev(tabIndex)} disabled>
              //   <span>
              //     <i className="ic ic-arrow-prev"></i>
              //   </span>
              //   이전글
              // </button>
              <button
                type="button"
                onClick={() => {
                  prev(tabIndex);
                  onHomeClick();
                }}
              >
                <span>
                  <i className="ic ic-arrow-prev"></i>
                </span>
                이전글
              </button>
            )}
            {/* 혹시 몰라서 추가한 비활성화 케이스 */}
            {tabIndex === chudosaContents.length - 1 && tabIndex !== 0 ? (
              <button type="button" onClick={() => next(tabIndex)} disabled>
                다음글
                <span>
                  <i className="ic ic-arrow-next"></i>
                </span>
              </button>
            ) : tabIndex === 0 ? (
              ''
            ) : (
              <button
                type="button"
                onClick={() => {
                  next(tabIndex);
                  onHomeClick();
                }}
              >
                다음글
                <span>
                  <i className="ic ic-arrow-next"></i>
                </span>
              </button>
            )}
          </div>
          {/* E : 이전/다음 버튼 */}
        </div>
      </div>
    </>
  ) : (
    <div className="wrap-list is-album">
      <div className="no-data">
        <div className="box-text">
          <p className="title">등록된 추도사가 없습니다.</p>
          {/*<p className="desc">{deceasedName}님과의 소중한 추억 사진을 올려주세요.</p>*/}
        </div>
      </div>
    </div>
  );
}
export default TabChudosasContent;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useLayout from 'stores/layout/hook';
import useCode from 'stores/code/hook';

import { getHallApi } from 'apis/hall';
import { uploadApi } from 'apis/openapi';
import { saveMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SearchHallDialog from 'pages/pet-memorial-canvas/obituary/components/SearchHallDailog';
import Relations from 'pages/pet-memorial-canvas/obituary/components/Relations';
import Condolatorys from 'pages/pet-memorial-canvas/obituary/components/Condolatorys';
import Blts from 'pages/pet-memorial-canvas/obituary/components/Blts';
import Chudosas from '../obituary/components/Chudosas';
import PreviewDialog from 'pages/pet-memorial-canvas/obituary/components/PreviewDialog';
import MediaUploader from 'pages/pet-memorial-canvas/obituary/components/MediaUploader';

function ObituaryRegisterPage() {
  const navigate = useNavigate();
  const textRef = useRef();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { relationPetDetailCodes } = useCode();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const schema = Yup.object({
    // chief_mourner_desc: Yup.string().required('* 고인과의 관계를 선택하세요.'),
    // chief_mourner: Yup.string()
    //   .required('* 상주명을 입력하세요.')
    //   .max(50, '* 최대 입력은 20자까지 가능합니다.'),
    // chief_mourner_phone: Yup.string()
    //   .required('* 휴대폰 번호를 입력하세요.')
    //   .matches(
    //     '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
    //     '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
    //   ),
    deceased_name: Yup.string()
      .required('* 이름을 작성하세요.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    // deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    // deceased_date: Yup.string().required('* 임종일자를 입력하세요.'),
    // deceased_hour: Yup.string().required('* 임종시간을 입력하세요.'),
    // birth_date: Yup.string().required('* 발인일자를 입력하세요.'),
    // birth_hour: Yup.string().required('* 발인시간을 입력하세요.'),
    //funeral_place: Yup.string().required('* 장지주소를 입력하세요.'),

    // funeral_addr: Yup.string().when('funeral_addr_yn', {
    //   is: 'N',
    //   then: schema => schema.notRequired(),
    //   otherwise: schema => schema.required('* 등록된 장례식장 정보가 없습니다.'),
    // }),

    // funeral_addr_yn: Yup.string().notRequired(),

    // condolatory_datas: Yup.array().of(
    //   Yup.object().shape({
    //     applicant_name: Yup.string().required('* 이름을 입력하세요.'),
    //     bank_name: Yup.string().required('* 은행을 선택하세요.'),
    //     account_number: Yup.string().required('* 계좌번호를 입력하세요.'),
    //   }),
    // ),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 발자국 주제를 등록 하세요.')
          .max(20, '* 발자국 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 제목과 내용을 모두 작성하세요.'),
      }),
    ),
    chudosa_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 떠나보내는 글 주제를 등록 하세요.')
          .max(20, '* 떠나보내는 글 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 제목과 내용을 모두 작성하세요.'),
      }),
    ),
    deceased_introduction: Yup.string().required('* 알림글을 입력하세요.'),
    // chudosa: Yup.string().required('* 추도사를 입력하세요.'),
  }).required();

  const defaultValues = {
    chief_mourner: '',
    chief_mourner_desc: '',
    chief_mourner_phone: '',
    digital_wreath_yn: 'Y',
    chief_funeral_yn: 'N',
    birth_yn: 'N',
    // funeral_yn: 'N',
    // funeral_addr_yn: 'Y',
    deceased_video_yn: 'Y',
    delivery_yn: 'Y',
    condolatory_yn: 'Y',
    chief_funeral_nm: '',
    deceased_name: '',
    deceased_birthday: '',
    deceased_job_title: '',
    deceased_date: '',
    deceased_hour: '00:00',
    birth_date: '',
    birth_hour: '',
    // funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    deceased_photo: '',
    deceased_introduction: '',
    // chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    donation_yn: 'N',
    status: '1',
    relations_datas: [],
    condolatory_datas: [{ applicant_name: '', bank_name: '', account_number: '' }],
    chudosa_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    blt_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    medias: [],
    deceased_video_link: '',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [openSearchHallDialog, setOpenSearchHallDialog] = useState(false);
  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [videoPhoto, setVideoPhoto] = useState(null);
  const [displayYn, setDisplayYn] = useState(true);
  const [birthYn, setBirthYn] = useState(false);
  const [funeralYn, setFuneralYn] = useState(false);
  const [funeralAddrYn, setFuneralAddrYn] = useState(true);
  const [videoYn, setVideoYn] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [deliveryYn, setDeliveryYn] = useState(false);
  const [chiefFuneralYn, setChiefFuneralYn] = useState(false);
  const [condolatoryYn, setCondolatoryYn] = useState(false);
  const [donateYn, setDonateYn] = useState(false);

  const [hall, setHall] = useState(null);

  const [medias, setMedias] = useState([]);

  const [visual, setVisual] = useState(false);
  const [message, setMessage] = useState('');
  const [condolatoryVisual, setCondolatoryVisual] = useState(false);

  const getHall = async hallId => {
    const result = await getHallApi(hallId);
    // console.log(JSON.stringify(result.data));
    setHall(result.data);
  };

  const saveMemorial = async params => {
    const result = await saveMemorialApi(params);
    if (result.type === 'success') {
      if (getValues('display_yn') === 'Y') {
        showAlert('알림', '부고가 공개된 후 일주일이 지나면 추모관으로 자동 전환 됩니다.', () =>
          successSaveMemorial(result.id),
        );
      } else {
        showAlert('알림', '비공개 등록이 완료되었습니다.', () =>
          successSavePrivateMemorial(result.id),
        );
      }
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const selectedHall = hallId => {
    setValue('funeral_addr', hallId);
    getHall(hallId);
    setOpenSearchHallDialog(false);
  };

  const handleChangeDisplayYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('display_yn', isChecked);
    //setDisplayYn(value);
  };

  const handleBirthYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('birth_yn', isChecked);
  };

  const handleFuneralYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('funeral_yn', isChecked);
  };

  const handleFuneralAddrYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('funeral_addr_yn', isChecked);
    // setFuneralAddrYn(value);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const handleDeliveryYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('delivery_yn', isChecked);
    //setDeliveryYn(value);
  };

  const handleChiefFuneralYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('chief_funeral_yn', isChecked);
    // setChiefFuneralYn(value);
  };

  const handleCondolatoryYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('condolatory_yn', isChecked);
    setCondolatoryYn(event.target.checked);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    hideConfirm();
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };

  const changeVideoImageFile = event => {
    const { files } = event.target;
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    uploadVideoImageFile(files[0]);
  };

  const uploadVideoImageFile = async params => {
    const result = await uploadApi(params);

    setVideoPhoto(result.uploadUrl);
    setValue('video_thumbnail', result.uploadUrl);
  };

  const removeVideoImageFile = () => {
    hideConfirm();
    setVideoPhoto(null);
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    setValue('video_thumbnail', '');
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };

  const handleClose = () => {
    showConfirm(
      ' 확인',
      '입력한 부고 내용이 저장되지 않습니다. \n 취소 하시겠습니까?',
      () => {
        navigate('/pet/obituary');
        hideConfirm();
      },
      () => hideConfirm(),
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleMessageChange = event => {
    const { value } = event.target;
    setMessage(value);
  };

  //대표 계좌에 계좌 등록 안되어 있을 경우 [대표 계좌 정보를 입력해주세요] 라고 팝업 뜨면서 대표계좌 입력으로 커서 이동함
  const handleDigitalWreathYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('digital_wreath_yn', isChecked);

    // if (
    //   (isChecked === 'Y' && getValues('condolatory_datas.0.applicant_name') === '') ||
    //   getValues('condolatory_datas.0.account_number') === ''
    // ) {
    //   showAlert('알림', '대표 계좌 정보를 입력해주세요.', () => hideAlert());
    // } else {
    //   setValue('digital_wreath_yn', isChecked);
    // }
  };

  const handleDigitalWreathDonateYn = event => {
    setDonateYn(event.target.checked);
  };
  // const resultaa = () => {
  //   hideAlert();
  //   textRef.current.focus();
  // };
  const handleIntroductionChange = event => {
    const { value } = event.target;
    setMessage(value);
  };

  const onSubmit = data => {
    saveMemorial(data);
  };

  const imageDelete = () => {
    showConfirm(
      '삭제 확인',
      '첨부한 파일을 삭제하시겠습니까?',
      () => removeImageFile(),
      () => hideConfirm(),
    );
  };

  const onError = error => {
    // showAlert('알림', '잘못된 입력된 정보가 존재합니다. 입력값을 확인하세요.', () => hideAlert());
    console.log('error > ', error);
  };

  const successSaveMemorial = memorialId => {
    hideAlert();
    showAlert('알림', '등록이 완료되었습니다.', () => hideAlert());
    navigate(`/pet/obituary/view/${memorialId}`);
  };

  const successSavePrivateMemorial = memorialId => {
    hideAlert();
    navigate(`/pet/obituary/view/${memorialId}`);
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }

    if (loginUser && loginUser.member_id) {
      setValue('own_member_id', loginUser.member_id);
      setValue('create_member_id', loginUser.member_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          {/* S : 서브 비주얼 + 타이틀 */}
          <div className="visual-wrap">
            <div className="contents-width">
              <h2 className="title">부고 등록</h2>
            </div>
          </div>
          {/* E : 서브 비주얼 + 타이틀 */}
          <div className="contents-width">
            {/* S : 부고 등록 */}
            <div className="wrap-form">
              {/* S : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
              {/* S : 고인정보 */}
              <div className="contents-form">
                <div className="box-title">
                  <h3>반려동물 정보</h3>
                  <p className="required">* 표시는 필수값입니다.</p>
                </div>
                {/* S : 고인 성함, 직위 */}
                <div className="form-group">
                  <div className="ip-wrap gap-10">
                    <div className="ip-item is-error">
                      <label htmlFor="ip-deceased-name">
                        이름{' '}
                        <span className="required">
                          *<span className="offscreen">필수입력</span>
                        </span>
                      </label>
                      <input
                        type="text"
                        id="ip-deceased-name"
                        placeholder="이름을 작성하세요"
                        aria-invalid={errors.deceased_name ? 'true' : 'false'}
                        {...register('deceased_name')}
                      />
                      {errors.deceased_name && (
                        <p className="message">{errors.deceased_name.message}</p>
                      )}
                    </div>
                    {/*<div className="ip-item is-error">*/}
                    {/*  <label htmlFor="ip-deceased-pos">직위</label>*/}
                    {/*  <input*/}
                    {/*    type="text"*/}
                    {/*    id="ip-deceased-pos"*/}
                    {/*    placeholder="직위를 작성하세요"*/}
                    {/*    {...register('deceased_job_title')}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                </div>
                {/* E : 고인 성함, 직위 */}
                {/* S : 생년월일 */}
                <div className="form-group">
                  <div className="box-stitle">
                    <h5>태어난 날</h5>
                  </div>
                  <div className="ip-wrap">
                    <div className="ip-item">
                      <label htmlFor="ip-deceased-birth" className="offscreen">
                        태어난 날
                      </label>
                      <input
                        type="date"
                        id="ip-deceased-birth"
                        placeholder="연도-월-일"
                        defaultValue=""
                        {...register('deceased_birthday')}
                      />
                    </div>
                  </div>
                </div>

                {/* E : 생년월일 */}
                {/* S : 임종일자 */}
                <div className="form-group">
                  <div className="box-stitle">
                    <h5>하늘의 별이된 날</h5>
                  </div>
                  <div className="ip-wrap">
                    <div className="ip-item">
                      <label htmlFor="ip-deceased-dying-day" className="offscreen">
                        하늘의 별이된 날
                      </label>
                      <input
                        type="date"
                        id="ip-deceased-dying-day"
                        placeholder="연도-월-일"
                        {...register('deceased_date')}
                      />
                    </div>
                  </div>
                </div>
                {/* E : 임종일자 */}
                {/* S : 영정 사진 */}
                <div className="form-group">
                  <div className="box-stitle">
                    <h5>사진</h5>
                  </div>

                  {/* S : 파일첨부 영역 */}
                  <div className="ip-wrap ip-file-wrap">
                    <div className="ip-item">
                      <label className="file" htmlFor="imageFile">
                        {/* for 값과 관련하여 css 설정되어 있으니 변경시 반드시 공지해주세요 */}
                        <span
                          className="offscreen"
                          onClick={() => document.getElementById('imageFile').click()}
                        >
                          파일선택
                        </span>
                        여기를 클릭하여 사진을 등록할 수 있습니다.
                        <br />* 이미지 파일만 등록할 수 있습니다.
                      </label>
                      <input
                        type="file"
                        className="offscreen"
                        id="imageFile"
                        onChange={changeImageFile}
                      />
                    </div>
                    {/* S : 첨부 이미지 등록 후 */}
                    {deceasedPhoto ? (
                      <div className="file-output-wrap">
                        <div className="file-output">
                          <div className="file-area is-image">
                            <img
                              alt=""
                              src={deceasedPhoto}
                              // src="https://d3lzegcz38cz56.cloudfront.net/photo/41092f61-c8a8-4b57-8a39-e505942cb9953d9a47a286411ddbb2060b7d2ecef49e4bef53c9.jpg"
                            />
                          </div>
                          <button className="file-del" onClick={removeImageFile}>
                            <span className="offscreen">파일 삭제</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="file_output default" onChange={changeImageFile}></div>
                    )}
                    {/* E : 첨부 이미지 등록 후 */}
                  </div>
                  {/* E : 파일첨부 영역 */}
                </div>
                {/* E : 영정 사진 */}
              </div>
              {/* E : 고인정보 */}
              {/* S : 장례정보 : 상주 정보 */}
              <div className="contents-form">
                {/* S : 장례정보 타이틀 */}
                <div className="box-title">
                  <h3>보호자 정보</h3>
                  <div className="rc-item is-reverse">
                    <input
                      type="checkbox"
                      id="chk-agree1"
                      defaultChecked={true}
                      onChange={handleDeliveryYn}
                    />
                    <label htmlFor="chk-agree1">부고 공유하기를 사용합니다.</label>
                  </div>
                </div>
                {/*<div className="box-title">*/}
                {/*  <h4>보호자</h4>*/}
                {/*</div>*/}
                {/* E : 장례정보 타이틀 */}
                {/* S : 대표상주 */}
                <div className="form-group">
                  <div className="box-stitle">
                    <h5>보호자</h5>
                  </div>
                  <div className="ip-wrap">
                    <div className="ip-item">
                      <select
                        id="ip-chief-mourner-relation"
                        name={'chief_mourner_desc'}
                        aria-invalid={errors.chief_mourner_desc ? 'true' : 'false'}
                        onChange={handleChange}
                      >
                        {relationPetDetailCodes && relationPetDetailCodes.length > 0
                          ? relationPetDetailCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    <div className="ip-item">
                      <label htmlFor="ip-chief-mourner-name" className="offscreen">
                        보호자
                      </label>
                      <input
                        type="text"
                        id="ip-chief-mourner-name"
                        placeholder="보호자 성함을 작성하세요"
                        {...register('chief_mourner')}
                      />
                    </div>
                  </div>
                </div>
                {/* E : 대표상주 */}
                {/* S : 추가상주 */}
                {visual === true ? (
                  <Relations
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    setVisual={setVisual}
                    errors={errors}
                  />
                ) : (
                  ''
                )}
                {/* E : 추가상주 */}

                {/* S : 추가 버튼 */}
                {visual === false ? (
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-md-white"
                      onClick={() => setVisual(true)}
                    >
                      보호자 추가
                    </button>
                  </div>
                ) : (
                  ''
                )}
                {/* E : 추가 버튼 */}
              </div>
              {/* E : 장례정보 : 상주 정보 */}
              {/* S : 장례정보 : 장례식장 정보 */}
              <div className="contents-form">
                {/* S : 빈소 */}
                {/*<div className="form-group">*/}
                {/*  <div className="box-stitle">*/}
                {/*    <h5>빈소(장례식장)</h5>*/}
                {/*    /!* S : 공개여부 *!/*/}
                {/*    <div className="rc-item rc-switch">*/}
                {/*      <input*/}
                {/*        type="checkbox"*/}
                {/*        id="switch1"*/}
                {/*        switch="none"*/}
                {/*        defaultChecked={true}*/}
                {/*        onChange={handleFuneralAddrYn}*/}
                {/*      />*/}
                {/*      <label htmlFor="switch1" data-on-label="공개" data-off-label="비공개"></label>*/}
                {/*    </div>*/}
                {/*    /!* E : 공개여부 *!/*/}
                {/*  </div>*/}

                {/*  <div className="ip-wrap gap-10">*/}
                {/*    <div className="ip-item has-btn is-error">*/}
                {/*      <label htmlFor="ip-deceased-name" className="offscreen">*/}
                {/*        빈소(장례식장)*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        id="ip-deceased-name"*/}
                {/*        placeholder="장례식장을 검색하세요"*/}
                {/*        value={hall ? hall.hall_name : ''}*/}
                {/*        aria-invalid={errors.funeral_addr ? 'true' : 'false'}*/}
                {/*      />*/}
                {/*      <button*/}
                {/*        type="button"*/}
                {/*        className="btn-search-funeral"*/}
                {/*        onClick={() => setOpenSearchHallDialog(true)}*/}
                {/*      >*/}
                {/*        검색*/}
                {/*      </button>*/}
                {/*      {errors.funeral_addr && (*/}
                {/*        <p className="message">{errors.funeral_addr.message}</p>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*    <div className="ip-item">*/}
                {/*      <label htmlFor="ip-deceased-pos" className="offscreen">*/}
                {/*        장례식장 호실 입력*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        id="ip-deceased-pos"*/}
                {/*        placeholder="호실"*/}
                {/*        {...register('funeral_room')}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* E : 빈소 */}
                {/* S : 발인일자 */}
                {/*<div className="form-group">*/}
                {/*  <div className="box-stitle">*/}
                {/*    <h5>발인일자</h5>*/}
                {/*    /!* S : 공개여부 *!/*/}
                {/*    <div className="rc-item rc-switch">*/}
                {/*      <input*/}
                {/*        type="checkbox"*/}
                {/*        id="switch2"*/}
                {/*        switch="none"*/}
                {/*        defaultChecked={false}*/}
                {/*        onChange={handleBirthYn}*/}
                {/*        // onClick={() => handleBirthYn(birthYn ? false : true)}*/}
                {/*      />*/}
                {/*      <label htmlFor="switch2" data-on-label="공개" data-off-label="비공개"></label>*/}
                {/*    </div>*/}
                {/*    /!* E : 공개여부 *!/*/}
                {/*  </div>*/}
                {/*  <div className="ip-wrap">*/}
                {/*    <div className="ip-item">*/}
                {/*      <label htmlFor="ip-deceased-death-day" className="offscreen">*/}
                {/*        발인일자*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="date"*/}
                {/*        id="ip-deceased-death-day"*/}
                {/*        placeholder="년-월-일"*/}
                {/*        {...register('birth_date')}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="ip-item">*/}
                {/*      <label htmlFor="ip-deceased-death-time" className="offscreen">*/}
                {/*        발인시간*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="time"*/}
                {/*        id="ip-deceased-death-time"*/}
                {/*        placeholder="시-분"*/}
                {/*        {...register('birth_hour')}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* E : 발인일자 */}
                {/* S : 장지 */}
                {/*<div className="form-group">*/}
                {/*  <div className="box-stitle">*/}
                {/*    <h5>장지</h5>*/}
                {/*    /!* S : 공개여부 *!/*/}
                {/*    <div className="rc-item rc-switch">*/}
                {/*      <input*/}
                {/*        type="checkbox"*/}
                {/*        id="switch3"*/}
                {/*        switch="none"*/}
                {/*        defaultChecked={false}*/}
                {/*        onChange={handleFuneralYn}*/}
                {/*      />*/}
                {/*      <label htmlFor="switch3" data-on-label="공개" data-off-label="비공개"></label>*/}
                {/*    </div>*/}
                {/*    /!* E : 공개여부 *!/*/}
                {/*  </div>*/}
                {/*  <div className="ip-wrap">*/}
                {/*    <div className="ip-item">*/}
                {/*      <label htmlFor="ip-commissioner" className="offscreen">*/}
                {/*        장지 주소 입력*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        id="ip-commissioner"*/}
                {/*        placeholder="장지 주소를 작성하세요"*/}
                {/*        {...register('funeral_place')}*/}
                {/*        // disabled*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* E : 장지 */}
                {/* S : 장례위원장 */}
                {/*<div className="form-group">*/}
                {/*  <div className="box-stitle">*/}
                {/*    <h5>장례위원장</h5>*/}
                {/*    /!* S : 공개여부 *!/*/}
                {/*    <div className="rc-item rc-switch">*/}
                {/*      <input*/}
                {/*        type="checkbox"*/}
                {/*        id="switch4"*/}
                {/*        switch="none"*/}
                {/*        defaultChecked={false}*/}
                {/*        onChange={handleChiefFuneralYn}*/}
                {/*      />*/}
                {/*      <label htmlFor="switch4" data-on-label="공개" data-off-label="비공개"></label>*/}
                {/*    </div>*/}
                {/*    /!* E : 공개여부 *!/*/}
                {/*  </div>*/}
                {/*  <div className="ip-wrap">*/}
                {/*    <div className="ip-item">*/}
                {/*      <label htmlFor="ip-commissioner" className="offscreen">*/}
                {/*        장례위원장 입력*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        id="ip-commissioner"*/}
                {/*        placeholder="장례위원장명을 작성하세요"*/}
                {/*        {...register('chief_funeral_nm')}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* E : 장례위원장 */}
                {/* S : 알림글 */}
                <div className="form-group">
                  <div className="box-stitle">
                    <h5>
                      미리 알림글{' '}
                      <span className="required">
                        *<span className="offscreen">필수입력항목</span>
                      </span>
                    </h5>
                  </div>
                  <div className="ip-wrap">
                    <div className="ip-item is-error">
                      <label htmlFor="text-tribute" className="offscreen">
                        알림글 입력
                      </label>
                      <textarea
                        id="text-tribute"
                        placeholder="아이와 생에 인연이 있던 지인에게 부고 소식을 알리는 짧은 메시지를 작성해 주세요."
                        {...register('deceased_introduction')}
                        aria-invalid={errors.deceased_introduction ? 'true' : 'false'}
                        onChange={handleMessageChange}
                        value={message}
                        maxLength={500}
                      ></textarea>
                      <div className="count-text">
                        <span>{message.length}</span> / 500자
                      </div>
                      {errors.deceased_introduction && (
                        <p className="message">{errors.deceased_introduction.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                {/* E : 알림글 */}
              </div>
              {/* E : 장례정보 : 장례식장 정보 */}
              {/* S : 조의금계좌 등록 */}
              <div className="contents-form">
                <div className="box-title">
                  <h3>조의금 계좌 등록 </h3>
                  <div className="rc-item is-reverse">
                    <input
                      type="checkbox"
                      id="chk-agree2"
                      defaultChecked={true}
                      onChange={handleCondolatoryYn}
                    />
                    <label htmlFor="chk-agree2">조의금계좌를 공개합니다</label>
                  </div>
                </div>

                <Condolatorys
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  condolatoryYn={condolatoryYn}
                  errors={errors}
                  ref={textRef}
                />
              </div>
              {/* E : 조의금계좌 등록 */}
              {/* S : 디지털 화환 */}
              <div className="contents-form">
                <div className="box-title">
                  <h3>디지털화환</h3>
                  <div className="rc-group">
                    <div className="rc-item is-reverse">
                      <input
                        type="checkbox"
                        id="chk-agree3"
                        defaultChecked={false}
                        onChange={handleDigitalWreathYn}
                      />
                      <label htmlFor="chk-agree3">디지털화환을 받습니다</label>
                    </div>
                    {/*펫 메모리얼 추가*/}
                    {/*<div className="rc-item is-reverse">*/}
                    {/*  <input*/}
                    {/*    type="checkbox"*/}
                    {/*    id="chk-agree3-2"*/}
                    {/*    defaultChecked={false}*/}
                    {/*    onChange={handleDigitalWreathDonateYn}*/}
                    {/*  />*/}
                    {/*  <label htmlFor="chk-agree3-2">디지털화환을 기부합니다</label>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="form-group">
                  <p className="desc">
                    디지털 화환을 받으시면 방문자들이 추모관에 디지털 화환으로 추모 메시지를 남길 수
                    있습니다.
                    {/*<br />*/}
                    {/*방문자가 구매한 화환은 추모관에 장식되며 구매 금액은 조의금 대표계좌로*/}
                    {/*입금됩니다.*/}
                  </p>
                  {/*<p className="desc">*/}
                  {/*  * 수수료 5% 제외됩니다.*/}
                  {/*  <br />* 기부를 선택하시면 기부 대상을 입력하실 수 있습니다. 기부는 자발적으로*/}
                  {/*  진행한 이후, 기부 소식을 통해 알릴 수 있습니다.*/}
                  {/*</p>*/}
                </div>
                {/*{donateYn === true ? (*/}
                {/*  <div className="form-group">*/}
                {/*    <div className="ip-wrap">*/}
                {/*      <div className="ip-item">*/}
                {/*        <label htmlFor="ip-deceased-name">기부대상</label>*/}
                {/*        <input*/}
                {/*          type="text"*/}
                {/*          id="ip-deceased-name"*/}
                {/*          placeholder="방문자들에게 알릴 수 있는 기부대상을 작성하세요"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  ''*/}
                {/*)}*/}
              </div>
              {/* E : 디지털 화환 */}
              {/* S : 추모 영상 */}
              <div className="contents-form">
                <div className="box-title">
                  <h3>추모 영상</h3>
                </div>
                <div className="form-group">
                  {/* S : 파일첨부 영역 */}
                  <div className="ip-wrap ip-file-wrap">
                    <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
                    {/* E : 첨부 이미지 등록 후 */}

                    <div className="file-output-wrap">
                      <div className="ip-item is-type-btn">
                        <label className="file" htmlFor="thumbFile">
                          <span className="btn btn-sm-blue">영상 미리보기 사진 첨부</span>
                          <span
                            className="desc"
                            onClick={() => document.getElementById('imageFile').click()}
                          >
                            클릭하여 영상 미리보기 사진을 첨부할 수 있습니다.
                            <br />* 이미지 파일만 첨부 가능하며 사진을 첨부하지 않으시면 기본
                            사진으로 설정됩니다.
                          </span>
                        </label>
                        <input
                          type="file"
                          className="offscreen"
                          id="thumbFile"
                          onChange={changeVideoImageFile}
                        />
                      </div>
                    </div>

                    {/* S : 첨부 이미지 등록 후 */}
                    {videoPhoto ? (
                      <div className="file-output-wrap">
                        <div className="file-output">
                          <div className="file-area is-video">
                            <img alt="" src={videoPhoto} />
                          </div>
                          <button className="file-del" onClick={removeVideoImageFile}>
                            <span className="offscreen">파일 삭제</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="file_output default" onChange={changeVideoImageFile}></div>
                    )}
                    {/* E : 첨부 이미지 등록 후 */}
                  </div>
                  {/* E : 파일첨부 영역 */}
                </div>
              </div>
              {/* E : 추모 영상 */}
              {/* S : 추도사 작성*/}
              <Chudosas
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
              />
              {/* E : 추도사 작성 */}
              {/* S : 일대기 작성 */}
              <Blts control={control} setValue={setValue} getValues={getValues} errors={errors} />
              {/* E : 일대기 작성  */}

              {/* S : 부고 공개 여부  */}
              <div className="contents-form">
                <div className="box-title">
                  <h3>부고를 공개로 등록하시겠습니까?</h3>
                  <div className="rc-item rc-switch">
                    <input
                      type="checkbox"
                      id="switch11"
                      switch="none"
                      defaultChecked={true}
                      onChange={handleChangeDisplayYn}
                    />
                    <label htmlFor="switch11" data-on-label="공개" data-off-label="비공개"></label>
                  </div>
                  <p className="desc">
                    비공개로 등록하더라도 추후 공개로 수정하고 공유할 수 있습니다.
                  </p>
                </div>
              </div>
              {/* E : 부고 공개 여부  */}

              {/* S : 버튼 */}
              <div className="btn-wrap">
                <button type="button" className="btn btn-ln-gray " onClick={handleClose}>
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-navy"
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  등록
                </button>
                {/*<button type="button" className="btn btn-ln-blue" onClick={() => handlePreview()}>*/}
                {/*  미리보기*/}
                {/*</button>*/}
              </div>
              {/* E : 버튼 */}
              {/* E : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
            </div>
            {/* E : 부고 등록 */}
          </div>
        </div>
        {/*<ChudosaSampleDialog*/}
        {/*  isOpen={openChudosaSampleDialog}*/}
        {/*  onClose={() => setOpenChudosaSampleDialog(false)}*/}
        {/*/>*/}
        <SearchHallDialog
          isOpen={openSearchHallDialog}
          onClose={() => setOpenSearchHallDialog(false)}
          selectedHall={selectedHall}
          // hallName={hallName}
        />
        {openPreviewDialog && (
          <PreviewDialog
            isOpen={openPreviewDialog}
            onClose={() => setOpenPreviewDialog(false)}
            getValues={getValues}
          />
        )}
        {/* E : CONTENT */}
      </section>
    </>
  );
}
export default ObituaryRegisterPage;

import { all, fork } from 'redux-saga/effects';

import { loginSaga } from './login';

/**
 * Root Saga 생성
 *
 * @export
 * @return {*}  {Generator<AllEffect<ForkEffect<void>>, void, unknown>}
 */
export default function* rootSaga() {
  yield all([fork(loginSaga)]);
}

import { useState, useEffect, useRef } from 'react';

import { getCanvasBltsStoryApi } from 'apis/canvasBlt';
import useLogin from 'stores/login/hook';
import useCode from 'stores/code/hook';

import useDialog from 'stores/dialog/hook';
import StoryLife from './components/StoryLife';
import StoryHistory from './components/StoryHistory';
import StoryQna from './components/StoryQna';
import StoryBucketlist from './components/StoryBucketlist';

function StoryCanvas() {
  const searchRef = useRef();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();

  const { canvasStoryTypeCodes } = useCode();

  const [canvasBlts, setCanvasBlts] = useState();
  const [searchBltType, setSearchBltType] = useState();
  const [searchType, setSearchType] = useState();
  const [searchWord, setSearchWord] = useState();

  const [page, setPage] = useState(1);
  const [listSize] = useState(4);
  const [totalPage, setTotalPage] = useState(0);

  const getCanvasBltsStory = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.goalstatus = 'N';
    if (searchType) params.searchType = searchType;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    if (searchBltType) params.blt_type = searchBltType;

    const result = await getCanvasBltsStoryApi(params);
    if (result.code === '0000') {
      setCanvasBlts(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasBltsStoryMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.goalstatus = 'N';
    if (searchType) params.searchType = searchType;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    if (searchBltType) params.blt_type = searchBltType;

    const result = await getCanvasBltsStoryApi(params);

    if (result.code === '0000') {
      setCanvasBlts([...canvasBlts, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleChange = value => {
    setSearchType(value);
  };

  const handleSearch = () => {
    getCanvasBltsStory(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasBltsStoryMore(nextPage);
    }
  };

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const init = async () => {
    getCanvasBltsStory(1);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBltType]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-mycanvas">
          {/* 마이 캔버스 페이지일 경우 is-mycanvas */}

          <h2>우리 이야기</h2>

          {/* S : 검색 */}
          <div className="box-search">
            <label for="sel-category" className="offscreen">
              검색 분류 선택
            </label>
            <select id="sel-category" onChange={e => handleChange(e.target.value)}>
              {canvasStoryTypeCodes && canvasStoryTypeCodes.length > 0
                ? canvasStoryTypeCodes.map(code => (
                    <option value={code.code} key={code.code}>
                      {code.value}
                    </option>
                  ))
                : null}
            </select>
            {/* <label for="ip-search" className="offscreen">
              검색하기
            </label> */}
            <input
              type="search"
              id="ip-search"
              placeholder="검색어를 입력하세요"
              value={searchWord}
              onChange={e => setSearchWord(e.target.value)}
              onKeyPress={onKeyPress}
            />
            <button ref={searchRef} type="button" onClick={() => handleSearch()}>
              <i className="ic ic-search"></i>
              <span className="offscreen">검색하기</span>
            </button>
          </div>
          {/* E : 검색 */}

          {/* S : */}
          <div className="filter-wrap">
            <div className="tabs tabs-scroll">
              <ul>
                <li className={!searchBltType ? 'is-active' : ''}>
                  <a href={() => false} onClick={() => setSearchBltType()}>
                    전체
                  </a>
                </li>
                <li className={searchBltType === '0' ? 'is-active' : ''}>
                  <a href={() => false} onClick={() => setSearchBltType('0')}>
                    구독한 글
                  </a>
                </li>
              </ul>

              <div className="box-filter">
                <ul>
                  <li className={searchBltType === '4' ? 'is-active' : ''}>
                    <a href={() => false} onClick={() => setSearchBltType('4')}>
                      #버킷리스트
                    </a>
                  </li>
                  <li className={searchBltType === '3' ? 'is-active' : ''}>
                    <a href={() => false} onClick={() => setSearchBltType('3')}>
                      #문답
                    </a>
                  </li>
                  <li className={searchBltType === '2' ? 'is-active' : ''}>
                    <a href={() => false} onClick={() => setSearchBltType('2')}>
                      #자서전
                    </a>
                  </li>
                  <li className={searchBltType === '1' ? 'is-active' : ''}>
                    <a href={() => false} onClick={() => setSearchBltType('1')}>
                      #일상
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* E : */}

          {canvasBlts && canvasBlts.length > 0 ? (
            canvasBlts.map(canvasBlt => (
              <>
                {canvasBlt.blt_type === '1' && <StoryLife canvasBlt={canvasBlt} />}
                {canvasBlt.blt_type === '2' && <StoryHistory canvasBlt={canvasBlt} />}
                {canvasBlt.blt_type === '3' && <StoryQna canvasBlt={canvasBlt} />}
                {canvasBlt.blt_type === '4' && <StoryBucketlist canvasBlt={canvasBlt} />}
              </>
            ))
          ) : (
            <div
              style={{
                minHeight: '40vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              게시글이 없습니다.
            </div>
          )}
        </div>

        {/* S : 더보기 버튼 */}
        <div className="btn-wrap">
          <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
            더보기 {page}/{totalPage}
          </button>
        </div>
        {/* E : 더보기 버튼 */}
        <div class="quick-btn-wrap">
          {/* S : 22-12-14 모바일 top 버튼 추가 */}
          <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
            <span className="offscreen">위로가기</span>
            <i className="ic ic-arrow-up"></i>
          </button>
          {/* E : 22-12-14 모바일 top 버튼 추가 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default StoryCanvas;

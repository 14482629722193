import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { routeParse } from '@utils';

import AdminMemorialCanvasLayout from 'layouts/memorial-canvas/admin/Layout';

import AdminMember from 'pages/memorial-canvas/admin/member';
import AdminMemberRegister from 'pages/memorial-canvas/admin/member/register';
import AdminMemberModify from 'pages/memorial-canvas/admin/member/modify';
import AdminMemberDetailPage from '../pages/memorial-canvas/admin/member/status';

import AdminManager from 'pages/memorial-canvas/admin/manager';
import AdminManagerModify from 'pages/memorial-canvas/admin/manager/modify';

import AdminObituary from 'pages/memorial-canvas/admin/obituary';
import AdminObituaryRegister from 'pages/memorial-canvas/admin/obituary/register';
import AdminObituaryModify from 'pages/memorial-canvas/admin/obituary/modify';
import AdminPetObituary from 'pages/memorial-canvas/admin/obituary/pet';
import AdminPetObituaryRegister from 'pages/memorial-canvas/admin/obituary/petRegister';
import AdminPetObituaryModify from 'pages/memorial-canvas/admin/obituary/petModify';

import AdminMemorial from 'pages/memorial-canvas/admin/memorial';
import AdminMemorialRegister from 'pages/memorial-canvas/admin/memorial/register';
import AdminMemorialModify from 'pages/memorial-canvas/admin/memorial/modify';
import AdminPetMemorial from 'pages/memorial-canvas/admin/memorial/pet';
import AdminPetMemorialRegister from 'pages/memorial-canvas/admin/memorial/petRegister';
import AdminPetMemorialModify from 'pages/memorial-canvas/admin/memorial/petModify';

import AdminConsulting from 'pages/memorial-canvas/admin/consulting';
import AdminConsultingRegister from 'pages/memorial-canvas/admin/consulting/register';
import AdminConsultingModify from 'pages/memorial-canvas/admin/consulting/modify';
import AdminConsultingBltRegister from 'pages/memorial-canvas/admin/consulting/registerBlt';
import AdminConsultingHistoryRegister from 'pages/memorial-canvas/admin/consulting/registerHistory';

import AdminMemorialFaq from 'pages/memorial-canvas/admin/faq/memorial';
import AdminLifeFaq from 'pages/memorial-canvas/admin/faq/life';
import AdminPetFaq from 'pages/memorial-canvas/admin/faq/pet';
import AdminFaqMemorialRegister from 'pages/memorial-canvas/admin/faq/memorialRegister';
import AdminFaqMemorialModify from 'pages/memorial-canvas/admin/faq/memorialModify';
import AdminFaqLifeRegister from 'pages/memorial-canvas/admin/faq/lifeRegister';
import AdminFaqLifeModify from 'pages/memorial-canvas/admin/faq/lifeModify';
import AdminFaqPetRegister from 'pages/memorial-canvas/admin/faq/PetRegister';
import AdminFaqPetModify from 'pages/memorial-canvas/admin/faq/petModify';

import AdminMemorialTemplate from 'pages/memorial-canvas/admin/template/memorial';
import AdminLifeTemplate from 'pages/memorial-canvas/admin/template/life';
import AdminPetTemplate from 'pages/memorial-canvas/admin/template/pet';
import AdminTemplateMemorialRegister from 'pages/memorial-canvas/admin/template/memorialRegister';
import AdminTemplateMemorialModify from 'pages/memorial-canvas/admin/template/memorialModify';
import AdminTemplateLifeRegister from 'pages/memorial-canvas/admin/template/lifeRegister';
import AdminTemplateLifeModify from 'pages/memorial-canvas/admin/template/lifeModify';
import AdminTemplatePetRegister from 'pages/memorial-canvas/admin/template/petRegister';
import AdminTemplatePetModify from 'pages/memorial-canvas/admin/template/petModify';

import AdminCanvasMain from 'pages/memorial-canvas/admin/canvas/main';
import AdminCanvasMainModify from 'pages/memorial-canvas/admin/canvas/modify';

import AdminCalculate from 'pages/memorial-canvas/admin/calculate';
import AdminCalculateBatch from 'pages/memorial-canvas/admin/calculate/batch';
import AdminCalculateDetail from 'pages/memorial-canvas/admin/calculate/detail';

const adminRoutes = [
  {
    path: '/admin',
    element: AdminMemorialCanvasLayout,
    children: [
      { path: 'member', element: AdminMember },
      { path: 'member/detail', element: AdminMemberDetailPage },
      {
        path: 'member/register',
        element: AdminMemberRegister,
      },
      {
        path: 'member/modify/:memberId',
        element: AdminMemberModify,
      },
      { path: 'manager', element: AdminManager },
      { path: 'manager/modify/:memberId', element: AdminManagerModify },

      { path: 'obituary', element: AdminObituary },
      {
        path: 'obituary/register',
        element: AdminObituaryRegister,
      },
      {
        path: 'obituary/modify/:memorialId',
        element: AdminObituaryModify,
      },

      { path: 'obituary/pet', element: AdminPetObituary },
      {
        path: 'obituary/pet/register',
        element: AdminPetObituaryRegister,
      },
      {
        path: 'obituary/pet/modify/:memorialId',
        element: AdminPetObituaryModify,
      },

      { path: 'condolence', element: AdminMemorial },
      {
        path: 'condolence/register',
        element: AdminMemorialRegister,
      },
      {
        path: 'condolence/modify/:memorialId',
        element: AdminMemorialModify,
      },
      { path: 'condolence/pet', element: AdminPetMemorial },
      {
        path: 'condolence/pet/register',
        element: AdminPetMemorialRegister,
      },
      {
        path: 'condolence/pet/modify/:memorialId',
        element: AdminPetMemorialModify,
      },
      { path: 'consulting', element: AdminConsulting },
      {
        path: 'consulting/register',
        element: AdminConsultingRegister,
      },
      {
        path: 'consulting/modify/:id',
        element: AdminConsultingModify,
      },
      {
        path: 'consulting/register/:id',
        element: AdminConsultingBltRegister,
      },
      {
        path: 'consulting/register/:id',
        element: AdminConsultingHistoryRegister,
      },
      {
        path: 'faq',
        element: AdminMemorialFaq,
      },
      {
        path: 'faq/life',
        element: AdminLifeFaq,
      },
      {
        path: 'faq/pet',
        element: AdminPetFaq,
      },
      {
        path: 'faq/register',
        element: AdminFaqMemorialRegister,
      },
      {
        path: 'faq/modify/:id',
        element: AdminFaqMemorialModify,
      },
      {
        path: 'faq/life/register',
        element: AdminFaqLifeRegister,
      },
      {
        path: 'faq/life/modify/:id',
        element: AdminFaqLifeModify,
      },
      {
        path: 'faq/pet/register',
        element: AdminFaqPetRegister,
      },
      {
        path: 'faq/pet/modify/:id',
        element: AdminFaqPetModify,
      },
      {
        path: 'template',
        element: AdminMemorialTemplate,
      },
      {
        path: 'template/life',
        element: AdminLifeTemplate,
      },
      {
        path: 'template/pet',
        element: AdminPetTemplate,
      },
      {
        path: 'template/register',
        element: AdminTemplateMemorialRegister,
      },
      {
        path: 'template/modify/:id',
        element: AdminTemplateMemorialModify,
      },
      {
        path: 'template/life/register',
        element: AdminTemplateLifeRegister,
      },
      {
        path: 'template/life/modify/:id',
        element: AdminTemplateLifeModify,
      },
      {
        path: 'template/pet/register',
        element: AdminTemplatePetRegister,
      },
      {
        path: 'template/pet/modify/:id',
        element: AdminTemplatePetModify,
      },
      {
        path: 'canvas/main',
        element: AdminCanvasMain,
      },
      {
        path: 'canvas/main/modify/:id',
        element: AdminCanvasMainModify,
      },
      { path: 'calculate', element: AdminCalculate },
      { path: 'calculate/batch', element: AdminCalculateBatch },
      { path: 'calculate/detail/:memorialId', element: AdminCalculateDetail },
    ],
  },
];

const AdminRoutes = () => {
  const elements = useRoutes(routeParse(adminRoutes));
  return <Suspense fallback={<div>로딩</div>}>{elements}</Suspense>;
};

export default AdminRoutes;

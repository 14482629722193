import api from '@apis/api';
import configs from '@configs/configs';

export async function getMemorialsApi(params) {
  if (configs.PATH !== 'admin') {
    params.path = window.location.pathname.split('/')[1];
  }
  try {
    // console.log('params 확인' + JSON.stringify(params));
    const response = await api.post(`${configs.API_BASEURL}/memorial`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getMemorialCountApi(params) {
  params.path = window.location.pathname.split('/')[1];
  try {
    const response = await api.post(`${configs.API_BASEURL}/memorial/count`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getMemorialApi(memorialId) {
  try {
    //const response = await api.get(`${configs.API_BASEURL}/memorial?memorial_id=${memorialId}`);
    const response = await api.get(
      `${configs.API_BASEURL}/memorial?memorial_id=${memorialId}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function saveMemorialApi(params) {
  if (configs.PATH !== 'admin') {
    params.path = window.location.pathname.split('/')[1];
  }
  try {
    const response = await api.post(`${configs.API_BASEURL}/memorial/ins`, params);
    if (response.data.code === '0000')
      return { type: 'success', message: response.data.message, id: response.data.memorial_id };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function modifyMemorialApi(params) {
  if (configs.PATH !== 'admin') {
    params.path = window.location.pathname.split('/')[1];
  }
  try {
    const response = await api.put(`${configs.API_BASEURL}/memorial`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// export async function deleteMemorialApi(memorialId) {
//   try {
//     const response = await api.delete(`${configs.API_BASEURL}/memorial?memorial_id=${memorialId}`);
//     if (response.data.code === '0000') return { type: 'success', message: response.data.message };
//     else return { type: 'failure', message: response.data.message };
//   } catch (error) {
//     console.log(error);
//     return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
//   }
// }

export async function deleteMemorialApi(memorialId) {
  try {
    const response = await api.delete(
      `${configs.API_BASEURL}/memorial/dels?memorial_ids=${memorialId}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getMessageTemplatesApi(params) {
  params.path = window.location.pathname.split('/')[1];
  try {
    const response = await api.post(`${configs.API_BASEURL}/messageTemplate/view`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getCondolatorysApi(params) {
  params.path = window.location.pathname.split('/')[1];
  try {
    const response = await api.post(`${configs.API_BASEURL}/condolatoryMoney`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function memorialTransApi(params) {
  try {
    // const response = await api.get(
    //   `${configs.API_BASEURL}/memorial/trans?memorial_id=${params.memorial_id}&status=${params.status}`,
    // );
    const response = await api.get(
      `${configs.API_BASEURL}/memorial/trans?memorial_id=${params.memorial_id}&status=${params.status}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function memorialOwnerTransApi(params) {
  try {
    // const response = await api.get(
    //   `${configs.API_BASEURL}/memorial/owner/trans?memorial_id=${params.memorial_id}&member_id=${params.own_member_id}`,
    // );
    const response = await api.get(
      `${configs.API_BASEURL}/memorial/owner/trans?memorial_id=${params.memorial_id}&member_id=${params.own_member_id}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

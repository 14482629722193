import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import Login from './Login';
import LoginUser from './LoginUser';
import Register from './Register';
import FindId from './FindId';
import FindPw from './FindPw';
import useLogin from 'stores/login/hook';

function LoginDialog({ isOpen, onClose }) {
  const { loginUser } = useLogin();
  const [mode, setMode] = useState('login');

  const changeMode = value => {
    setMode(value);
  };

  const closeDialog = () => {
    setMode('login');
    onClose();
  };

  useEffect(() => {
    if (loginUser && loginUser.member_id) closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="md" fullWidth>
      <>
        {mode === 'login' ? <Login onChange={changeMode} closeDialog={closeDialog} /> : null}
        {mode === 'loginUser' ? (
          <LoginUser onChange={changeMode} closeDialog={closeDialog} />
        ) : null}
        {mode === 'register' ? <Register onChange={changeMode} closeDialog={closeDialog} /> : null}
        {mode === 'findId' ? <FindId onChange={changeMode} closeDialog={closeDialog} /> : null}
        {mode === 'findPw' ? <FindPw onChange={changeMode} closeDialog={closeDialog} /> : null}
      </>
    </Dialog>
  );
}

export default LoginDialog;

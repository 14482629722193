import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Gnb from 'layouts/memorial-canvas/Gnb';
import RightBanner from 'layouts/memorial-canvas/RightBanner';
import Footer from 'layouts/memorial-canvas/Footer';
import { useEffect, useState } from 'react';

function MemorialCanvasLayout() {
  const location = useLocation();
  const [isNew, setNew] = useState(0);

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    if (
      location.pathname.indexOf('memorial/memorial/register') > -1 ||
      location.pathname.indexOf('/memorial/obituary/register') > -1 ||
      location.pathname.indexOf('memorial/memorial/modify') > -1 ||
      location.pathname.indexOf('memorial/obituary/modify') > -1
    ) {
      setNew(0);
    } else if (location.pathname.indexOf('memorial/memorial/views/M00000001026') > -1) {
      setNew(2);
    } else if (
      location.pathname.indexOf('memorial/obituary/view') > -1 ||
      location.pathname.indexOf('memorial/letter') > -1 ||
      location.pathname.indexOf('memorial/memorial/view') > -1
    ) {
      setNew(1);
    } else {
      setNew(-1);
    }
  }, [location]);

  return (
    <>
      <div
        className={
          isNew === 0
            ? 'wrap is-new'
            : isNew === 1
            ? 'wrap is-new is-detail'
            : isNew === 2
            ? 'wrap is-new is-detail is-cycle'
            : 'wrap'
        }
        id="memorialCanvas"
      >
        {/* S: HEADER */}
        <Gnb />
        {/* E: HEADER */}

        {/* S: CONTENTS */}
        <Outlet />
        {/* E: CONTENTS */}

        {/* S: FOOTER */}
        <Footer />
        {/* E: FOOTER */}
        {/* <RightBanner /> */}
        <div className="wrap-quick-btn">
          {/* S : 22-12-14 모바일 top 버튼 추가 */}
          <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
            <span className="offscreen">위로가기</span>
            <i className="ic ic-arrow-up"></i>
          </button>
          {/* E : 22-12-14 모바일 top 버튼 추가 */}
        </div>
      </div>
    </>
  );
}

export default MemorialCanvasLayout;

/**
 * .env 환경 설정 값
 */
const configs = {
  BASEURL: process.env.REACT_APP_BASEURL || '',
  API_BASEURL: process.env.REACT_APP_API_BASEURL || '',
  KICC_PC_SCRIPT: process.env.REACT_APP_KICC_PC_SCRIPT || '',
  KICC_PC_ACTION_URL: process.env.REACT_APP_KICC_PC_ACTION_URL || '',
  KICC_PC_RETURN_URL: process.env.REACT_APP_KICC_PC_RETURN_URL || '',
  KICC_MOBILE_SCRIPT: process.env.REACT_APP_KICC_MOBILE_SCRIPT || '',
  KICC_MOBILE_ACTION_URL: process.env.REACT_APP_KICC_MOBILE_ACTION_URL || '',
  KICC_MOBILE_RETURN_URL: process.env.REACT_APP_KICC_MOBILE_RETURN_URL || '',
  PATH: window.location.pathname.split('/')[1],
};
export default configs;

import { useState, useEffect, forwardRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import configs from '@configs/configs';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import useScript from 'utils/useScript';
import copy from 'copy-to-clipboard';

function CanvasShareDialog({ isOpen, onClose, canvasId, profileLink, title, motto }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const location = useLocation();
  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();
  const currentUrl = `${configs.BASEURL}/life/canvas/user/${canvasId}`;

  const shreadKakaoLink = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: title,
        description: motto,
        imageUrl: profileLink,
        link: {
          webUrl: currentUrl,
          mobileWebUrl: currentUrl,
        },
      },
      buttons: [
        {
          title: '캔버스 입장',
          link: {
            webUrl: currentUrl,
            mobileWebUrl: currentUrl,
          },
        },
      ],
    });
  };

  const shreadLink = async () => {
    try {
      copy(currentUrl);
      //alert(currentUrl);
      showLifeAlert('알림', '주소 복사가 완료되었습니다.', () => hideLifeAlert());
    } catch (error) {
      console.log('error > ', error);
      showLifeAlert('알림', '주소 복사에 실패하였습니다.', () => hideLifeAlert());
    }
  };

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  useEffect(() => {
    //console.log(profileLink);
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      {/* S : 캔버스 공유하기 팝업 */}
      <div className="wrap" id="lifeCanvas">
        <div className="modal" id="modal-sns" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">캔버스 공유하기</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={onClose}
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="btn-wrap btn-sns">
                  <button type="button" className="btn btn-white" onClick={() => shreadKakaoLink()}>
                    카카오톡 공유하기
                  </button>
                  <button type="button" className="btn btn-white" onClick={() => shreadLink()}>
                    링크복사
                  </button>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close
                  onClick={onClose}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : 캔버스 공유하기 팝업 */}
    </Dialog>
  );
}

export default CanvasShareDialog;

import { useNavigate, useLocation } from 'react-router-dom';

function WreathResult() {
  const location = useLocation();
  const navigate = useNavigate();

  const pay_type = location.state.pay_type;
  const type = location.state.type;
  const memorial_id = location.state.memorial_id;

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">디지털 화환 </h2>
            <p>
              디지털 화환은 상주께 전해지며 허례의식을 줄이고
              <br />
              유가족 위로와 기부문화에 이바지합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          {/* S : 디지털 화환 신청 완료 */}
          <div className="wrap-digital-wreath">
            <div className="box-title">
              <h3 className="title">주문이 완료되었습니다.</h3>
              <p className="desc">이용해 주셔서 감사합니다.</p>
            </div>

            <div className="wreath-complete">
              {/* S : 구매한 화환 */}
              <div className="box-line">
                <div className="wreath-item">
                  {type === 'N' && (
                    <>
                      <div className="box-wreath type-01">
                        <a href={() => false}>
                          <p className="text">국화 1송이</p>
                          <p className="price">30,000원</p>
                        </a>
                      </div>
                    </>
                  )}
                  {type === 'R' && (
                    <>
                      <div className="box-wreath type-02">
                        <a href={() => false}>
                          <p className="text">국화 3송이</p>
                          <p className="price">50,000원</p>
                        </a>
                      </div>
                    </>
                  )}
                  {type === 'P' && (
                    <>
                      <div className="box-wreath type-03">
                        <a href={() => false}>
                          <p className="text">근조화환</p>
                          <p className="price">100,000원</p>
                        </a>
                      </div>
                    </>
                  )}
                </div>
                <div className="order-result">
                  <dl>
                    <dt>결제방법</dt>
                    {pay_type === '11' ? <dd>신용카드</dd> : <dd>무통장입금</dd>}

                    <dt>결제일자</dt>
                    <dd>2022.09.05 09:33</dd>

                    <dt>입금계좌</dt>
                    <dd>우리은행</dd>
                    <dt>입금기한</dt>
                    <dd>2022년 10월 31일까지 </dd>
                  </dl>
                </div>
              </div>
              {/* E : 구매한 화환 */}
            </div>
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-pos"
                onClick={() =>
                  navigate(`/pet/obituary/view/${memorial_id}`, {
                    state: { message: 'wreath-complete' },
                  })
                }
              >
                확인
              </button>
              {/* <button
                type="button"
                className="btn-form btn-ln-gray"
                onClick={() => navigate('/memorial/obituary/wreath')}
              >
                취소
              </button> */}
            </div>
          </div>

          {/* E : 디지털 화환 신청 완료 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default WreathResult;

import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

function MemorialDialog({ isOpen, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-template-01" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">메모리얼 캔버스란?</h2>
                </div>
                <div className="modal__content">
                  <div className="">
                    <p>
                      마이라이프 캔버스의 사후 서비스인 메모리얼 캔버스는 온라인 장례를 지원하며
                      남은 가족들에게 고인의 살아 생전 이야기를 전달합니다. 이를 통해 고인의 삶에
                      대해 애도를 표할 수 있고, 영구적으로 고인을 기리며 추모할 수 있습니다.
                    </p>
                    <hr />
                    <p>
                      <strong>내 캔버스 메모리얼 캔버스로 이동하기</strong>
                    </p>
                    <p>
                      나의 죽음을 준비하며 나의 삶과 지혜를 기록해 두는 것은 나와 사랑하는 가족을
                      위한 일입니다.남은 사람들에게 전달되었으면 하는 메세지를 구성할 때 라이프
                      캔버스에 작성했던 자서전을 활용할 수 있습니다.
                    </p>
                    <hr />
                    <p>
                      <strong>연결 캔버스 메모리얼 캔버스로 이동하기</strong>
                    </p>
                    <p>
                      영원히 남았으면 하는 추억들을 메모리얼 캔버스에 영원히 저장하여 꺼내 볼 수
                      있습니다. 고인 스스로 작성한 자서전을 활용하여 조문보를 제작할 수
                      있습니다.온라인 추모관에 사랑하는 가족을 모시는 건 고인의 죽음을 진심으로
                      애도하고 추모하는 방법입니다.
                    </p>
                  </div>
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-blue" onClick={onClose}>
                      확인
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default MemorialDialog;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import parse from 'html-react-parser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LifeAlertDialog({ isOpen, title, message, callback }) {
  return (
    <Dialog
      open={isOpen}
      onClose={callback}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <div className="wrap" id="lifeCanvas">
        <div className="modal  is-alert " id="modal-sample-03" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">{title}</h2>
              </div>
              <div className="modal__content">
                <p>{message && parse(message.replace(/(\n|\r\n)/g, '<br />'))}</p>
              </div>

              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close
                  onClick={callback}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LifeAlertDialog;

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { saveWreathApi } from 'apis/wreath';

import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import KiccPcDialog from 'pages/pet-memorial-canvas/obituary/components/KiccPcDialog';
import KiccMobile from 'pages/pet-memorial-canvas/obituary/components/KiccMobile';
function WreathPage() {
  const { state } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const location = useLocation();
  const memorial_id = location.state.memorial_id;

  const schema = Yup.object({
    title: Yup.string().required('* 필수 입력 항목입니다.'),
    message: Yup.string().required('* 필수 입력 항목입니다.'),
    pay_type: Yup.string().required('* 결제 수단을 선택하세요.'),
  }).required();

  const defaultValues = {
    receiver: '',
    memorial_id: '',
    title: '',
    message: '',
    type: 'P',
    register: '',
    register_id: '',
    status: '1',
    order_no: '',
    pay_type: '11',
    appr_date: '',
    cno: '',
    deposit_amount: 100000,
  };

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    // showAlert('알림', 'PG 제휴 심사중으로 실제 결제는 진행되지 않습니다.', () =>
    //   onSubmitCallback(data),
    // );
    onSubmitCallback(data);
  };

  const onSubmitCallback = data => {
    hideAlert();
    saveWreath(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const saveWreath = async params => {
    const result = await saveWreathApi(params);
    if (result.code === '0000') {
      setWreathId(result.id);
      setOpenKiccPcDialog(true);
      // 디지털화환 결제 가능하면 하단 주석 풀고 setOpenKiccPcDialog(true) 삭제
      // isMobile ? setOpenKiccMobile(true) : setOpenKiccPcDialog(true);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const { setGnbType } = useLayout();
  const [openKiccPcDialog, setOpenKiccPcDialog] = useState(false);
  const [openKiccMobile, setOpenKiccMobile] = useState(false);
  const [type, setType] = useState('P');
  const [wreathId, setWreathId] = useState(null);

  const handleType = (value, money) => {
    setType(value);
    setValue('type', value);
    setValue('deposit_amount', money);
    setValue('status', 1);
  };

  useEffect(() => {
    if (state) {
      setValue('memorial_id', state.memorial_id);
      setValue('receiver', state.receiver);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (loginUser && loginUser.member_id) {
      setValue('register_id', loginUser.member_id);
      setValue('register', loginUser.member_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">디지털 화환 </h2>
            <p>
              디지털 화환은 상주께 전해지며 허례의식을 줄이고
              <br />
              유가족 위로와 기부문화에 이바지합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          {/* S : 디지털 화환 신청 */}
          <div className="wrap-digital-wreath">
            <div className="box-title">
              <h3 className="title">화환선택</h3>
              {/* <p className="desc">디지털 화환 금액은 상주께 전해집니다.</p> */}
            </div>
            <div className="wreath-list">
              <ul>
                <li>
                  <div className="box-wreath type-03" onClick={() => handleType('P', '100000')}>
                    <p className="text">근조화환</p>
                    <p className="price">100,000원</p>
                    <button
                      type="button"
                      className={`btn btn-gray ${type === 'P' ? 'is-active' : ''}`}
                    >
                      선택
                    </button>
                  </div>
                </li>
                <li>
                  <div className="box-wreath type-02" onClick={() => handleType('R', '50000')}>
                    <p className="text">국화 3송이</p>
                    <p className="price">50,000원</p>
                    <button
                      type="button"
                      className={`btn btn-gray ${type === 'R' ? 'is-active' : ''}`}
                    >
                      선택
                    </button>
                  </div>
                </li>
                <li>
                  <div className="box-wreath type-01" onClick={() => handleType('N', '30000')}>
                    <p className="text">국화 1송이</p>
                    <p className="price">30,000원</p>
                    <button
                      type="button"
                      className={`btn btn-gray ${type === 'N' ? 'is-active' : ''}`}
                    >
                      선택
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="wreath-form">
              <div className="wreath-form-wrap">
                <h4 className="label">
                  구매자 정보 입력
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </h4>
                <div className="wreath-form-item">
                  <div className="ip-item is-error">
                    <label for="ip-name" className="offscreen">
                      성함/회사명/모임명 입력
                    </label>
                    <input
                      {...register('title')}
                      aria-invalid={errors.title ? 'true' : 'false'}
                      type="text"
                      id="ip-name"
                      placeholder="성함/회사명/모임명"
                    />
                    {errors.title && <p className="message">{errors.title.message}</p>}
                  </div>
                  <div className="ip-item is-error">
                    <label for="ip-message" className="offscreen">
                      남겨주실 문구
                    </label>
                    <input
                      {...register('message')}
                      aria-invalid={errors.message ? 'true' : 'false'}
                      type="text"
                      id="ip-message"
                      placeholder="남겨주실 문구"
                    />
                    {errors.message && <p className="message">{errors.message.message}</p>}
                  </div>
                </div>
              </div>
              <div className="wreath-form-wrap">
                <h4 className="label">최종 결제 금액</h4>
                <div className="wreath-form-item">
                  <p className="title">상품명</p>
                  {type === 'N' && (
                    <dl>
                      <dt>국화 1송이</dt>
                      <dd>30,000</dd>
                      <dt className="total">총 결제 금액</dt>
                      <dd className="total">30,000</dd>
                    </dl>
                  )}
                  {type === 'R' && (
                    <dl>
                      <dt>국화 3송이</dt>
                      <dd>50,000</dd>
                      <dt className="total">총 결제 금액</dt>
                      <dd className="total">50,000</dd>
                    </dl>
                  )}
                  {type === 'P' && (
                    <dl>
                      <dt>근조화환</dt>
                      <dd>100,000</dd>
                      <dt className="total">총 결제 금액</dt>
                      <dd className="total">100,000</dd>
                    </dl>
                  )}
                </div>
              </div>
              <div className="wreath-form-wrap">
                <h4 className="label">
                  결제 수단
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </h4>
                <div className="wreath-form-item is-error">
                  <ul className="rc-list row">
                    <li>
                      <div className="rc-item">
                        <input
                          type="radio"
                          id="rdo-pay01"
                          name="rdo-pay"
                          defaultChecked={true}
                          onClick={() => setValue('pay_type', 11)}
                        />
                        <label for="rdo-pay01">신용카드</label>
                      </div>
                    </li>
                    <li>
                      <div className="rc-item">
                        <input
                          type="radio"
                          id="rdo-pay02"
                          name="rdo-pay"
                          onClick={() => setValue('pay_type', 22)}
                        />
                        <label for="rdo-pay02">무통장</label>
                      </div>
                    </li>
                  </ul>
                  <div className="box-line">
                    <ul className="rc-list">
                      <li>
                        <div className="rc-item">
                          <input type="checkbox" id="chk-pay01" />
                          <label for="chk-pay01">
                            주문할 상품의 상품명, 상품가격, 주문처리목적을 확인하였습니다.
                            (전자상거래법 제 8조 2항)위 주의사항을 확인하였으며 동의합니다
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="rc-item">
                          <input type="checkbox" id="chk-pay02" />
                          <label for="chk-pay02">
                            디지털 화환 결제시 환불처리 불가함을 확인하였습니다
                          </label>
                        </div>
                      </li>
                    </ul>
                    {errors.message && <p className="message">{errors.message.message}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* S : 페이징 */}
          <div className="btn-wrap">
            <a href={() => false} className="btn btn-pos" onClick={handleSubmit(onSubmit, onError)}>
              결제하기
            </a>
          </div>
          {/* E : 페이징 */}
          {/* E : 디지털 화환 신청 */}
        </div>
      </div>
      {/* E : CONTENT */}
      {openKiccPcDialog && (
        <KiccPcDialog
          isOpen={openKiccPcDialog}
          onClose={() => setOpenKiccPcDialog(false)}
          memorial_id={memorial_id}
          wreathId={wreathId}
          getValues={getValues}
        />
      )}
      {openKiccMobile && (
        //  <KiccMobile isOpen={openKiccMobile}
        //     onClose={() => setOpenKiccMobile(false)} memorial_id={memorial_id} wreathId={wreathId} getValues={getValues} />
        <KiccMobile memorial_id={memorial_id} wreathId={wreathId} getValues={getValues} />
      )}
    </section>
  );
}

export default WreathPage;

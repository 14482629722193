import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notiAcountApi } from 'apis/openapi';

import useDialog from 'stores/dialog/hook';

function NotiAccountDialog({ isOpen, onClose, selectedCondolatory }) {
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    memorial_id: Yup.string().required('* 필수 입력 항목입니다.'),
    condolatory_money_seq: Yup.number().required('* 필수 입력 항목입니다.'),
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
  }).required();

  const defaultValues = {
    memorial_id: '',
    condolatory_money_seq: '',
    mobile: '',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    notiAccount(data);
  };

  const handleClose = () => {
    hideAlert();
    onClose();
  };

  const notiAccount = async params => {
    const result = await notiAcountApi(params);
    showAlert('알림', result.message, () => handleClose());
  };

  const onError = error => {
    console.log('error > ', error);
  };

  useEffect(() => {
    console.log('selectedCondolatory > ', selectedCondolatory);

    setValue('memorial_id', selectedCondolatory.memorial_id);
    setValue('condolatory_money_seq', selectedCondolatory.condolatory_money_seq);
    setValue('mobile', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondolatory]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <div className="wrap is-new" id="memorialCanvas">
        <div class="modal" id="modal-obituary-11" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title">문자로 계좌받기</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div class="modal__content">
                <p class="title-desc">계좌받을 연락처를 입력해주세요</p>

                <div class="ip-item is-error">
                  <label for="ip-number" class="offscreen">
                    연락처 입력
                  </label>
                  <input
                    type="text"
                    {...register('mobile')}
                    placeholder="연락처를 입력해주세요"
                    id="ip-number"
                  />
                  {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                </div>
              </div>
              <div class="modal__footer">
                <button
                  type="button"
                  class="btn btn-blue"
                  onClick={handleSubmit(onSubmit, onError)}
                  data-micromodal-close
                >
                  전송
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default NotiAccountDialog;

import { useState, useEffect } from 'react';

import useCode from 'stores/code/hook';
import useDialog from 'stores/dialog/hook';

import { useFieldArray, Controller } from 'react-hook-form';

import QuillEditor from 'components/editor/QuillEditor';
import BltSampleDialog from 'pages/memorial-canvas/obituary/components/BltSampleDialog';
import BltTemplateDialog from 'pages/memorial-canvas/obituary/components/BltTemplateDialog';

function Blts({ control, setValue, getValues, errors }) {
  const { showAlert, hideAlert } = useDialog();
  const { bltTitleCodes } = useCode();

  const [openBltSampleDialog, setOpenBltSampleDialog] = useState(false);
  const [openBltTemplateDialog, setOpenBltTemplateDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [contents, setContents] = useState('');
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'blt_datas',
  });

  const handleAppend = () => {
    if (fields.length > 9) {
      showAlert('알림', '일대기는 최대 10개까지 작성할 수 있습니다.', () => hideAlert());
      return;
    }
    if (getValues('blt_datas').filter(it => it.display_yn === 'Y').length > 4) {
      append({ blt_title: '', blt_contents: '', display_yn: 'N' });
      return;
    }
    append({ blt_title: '', blt_contents: '', display_yn: 'Y' });
  };

  const handleRemove = index => {
    remove(index);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleChangeDisplayYn = (name, value) => {
    setValue(name, value);
    const isCheck = getValues('blt_datas').filter(it => it.display_yn === 'Y').length;
    if (isCheck > 5) {
      showAlert('알림', '일대기는 최대 5개까지 공개됩니다.', () => hideAlert());
      setValue(name, 'N');
    } else if (isCheck === 0) {
      showAlert('알림', '일대기는 1개 이상 공개해 주세요.', () => hideAlert());
      setValue(name, 'Y');
    }
    move(0, 0);
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleOpenTemplate = index => {
    setSelectedIndex(index);
    setOpenBltTemplateDialog(true);
  };

  const selectedTemplate = template => {
    setValue(`blt_datas.${selectedIndex}.blt_title`, template.blt_title);
    setValue(`blt_datas.${selectedIndex}.blt_contents`, template.blt_contents);
    setOpenBltTemplateDialog(false);
  };

  const changeContents = (e, index) => {
    //console.log(e, '++++', index);
    let replacedValue = removeEmojis(e);

    //setContents(replacedValue);
    setValue(`blt_datas.${index}.blt_contents`, replacedValue);
    // setValue('blt_contents', replacedValue);
  };

  const removeEmojis = inputValue => {
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // if (regex.test(inputValue)) {
    //   showAlert('알림', '이모티콘은 사용할 수 없습니다.', () => hideAlert());
    //   let strVal = inputValue.replace(regex, ' ');
    //   return strVal;
    // }

    const img = /<img src="data:[^>]*>?/g;
    if (img.test(inputValue)) {
      showAlert(
        '알림',
        '이미지는 복사 붙여넣기가 금지됩니다. \n 오른쪽 상단의 에디터 영역 이미지 첨부 버튼을 눌러 첨부해주세요.',
        () => hideAlert(),
      );
      let strVal = inputValue.replace(img, '');
      return strVal;
    }

    return inputValue;
  };

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleCheckBox = indexNum => {
    handleChangeDisplayYn(
      `blt_datas.${indexNum}.display_yn`,
      getValues(`blt_datas.${indexNum}.display_yn`) === 'Y' ? 'N' : 'Y',
    );
  };

  return (
    <div className="contents-form">
      <div className="box-title">
        <h3>일대기 작성</h3>
        <p className="desc">일대기는 최대 5개까지 등록할 수 있습니다.</p>
        <div className="fixed-btn">
          <button
            type="button"
            className="btn-underline"
            onClick={() => setOpenBltSampleDialog(true)}
          >
            예시보기
          </button>
        </div>
      </div>
      {/* S : 일대기 1*/}
      {fields &&
        fields.length > 0 &&
        fields.map((item, index) => (
          <div className="form-group">
            <div className="box-stitle">
              <h5>
                <span className="number">{index + 1}</span>일대기{' '}
                <span className="required">
                  *<span className="offscreen">필수입력항목</span>
                </span>
              </h5>
              <div className="form-util">
                <button
                  type="button"
                  className="btn btn-sm-blue"
                  onClick={() => handleOpenTemplate(index)}
                >
                  일대기 템플릿
                </button>
                <div className="rc-item rc-switch">
                  <input
                    type="checkbox"
                    id="switch8"
                    switch="none"
                    name={`blt_datas.${index}.display_yn`}
                    checked={getValues(`blt_datas.${index}.display_yn`) === 'Y'}
                  />
                  <label
                    htmlFor="switch8"
                    data-on-label="공개"
                    data-off-label="비공개"
                    onClick={() => {
                      handleCheckBox(index);
                    }}
                    // onClick={() => {
                    //   alert(getValues(`blt_datas.${index}.display_yn`) === 'Y');
                    //   // handleCheckBox(
                    //   //   getValues(`blt_datas.${index}.display_yn`) === 'Y' ? true : false,
                    //   //   index,
                    //   // );
                    // }}
                  ></label>
                </div>
              </div>
            </div>
            <div
              className={`ip-wrap ${
                (errors.blt_datas?.[index]?.blt_title || errors.blt_datas?.[index]?.blt_contents) &&
                errors.blt_datas?.[index]?.blt_title &&
                errors.blt_datas?.[index]?.blt_contents
                  ? 'is-error'
                  : ''
              }`}
            >
              <div className="ip-item">
                <label htmlFor="ip-chief-mourner-name" className="offscreen">
                  제목 입력
                </label>
                <Controller
                  control={control}
                  name={`blt_datas.${index}.blt_title`}
                  render={({ field }) => (
                    <input
                      type="text"
                      id="ip-chief-mourner-name"
                      {...field}
                      aria-invalid={errors.blt_datas?.[index]?.blt_title ? 'true' : 'false'}
                      placeholder="제목을 작성하세요 (최대 20글자)"
                      maxLength="20"
                    />
                  )}
                />
              </div>
              <div className="ip-item">
                <label htmlFor="ip-chief-mourner-name" className="offscreen">
                  내용 입력
                </label>
                <Controller
                  control={control}
                  name={`blt_datas.${index}.blt_contents`}
                  render={({ field }) => (
                    <QuillEditor
                      {...field}
                      label="조문보 내용"
                      value={getValues(`blt_datas.${index}.blt_contents`)}
                      onChange={e => {
                        changeContents(e, index);
                      }}
                      aria-invalid={errors.blt_datas?.[index]?.blt_contents ? 'true' : 'false'}
                    />
                  )}
                />
                {/*{errors.blt_datas?.[index]?.blt_contents && (*/}
                {/*  <p className="message">{errors.blt_datas?.[index]?.blt_contents.message}</p>*/}
                {/*)}*/}
                {/*<textarea*/}
                {/*  name=""*/}
                {/*  id=""*/}
                {/*  cols="30"*/}
                {/*  rows="10"*/}
                {/*  placeholder="고인의 일대기를 작성하세요"*/}
                {/*></textarea>*/}
              </div>
              {errors.blt_datas?.[index]?.blt_title && (
                <p className="message">{errors.blt_datas?.[index]?.blt_title.message}</p>
              )}
            </div>
            <div className="ip-btn">
              <button
                type="button"
                className="btn-contents-del"
                onClick={() => handleRemove(index)}
              >
                <span className="offscreen">삭제</span>
              </button>
              <button
                type="button"
                className="btn-contents-up"
                onClick={() => handleOrderUp(index)}
              >
                <span className="offscreen">순서 위로</span>
              </button>
              <button
                type="button"
                className="btn-contents-down"
                onClick={() => handleOrderDown(index)}
              >
                <span className="offscreen">순서 아래로</span>
              </button>
            </div>
          </div>
        ))}
      {/* E : 일대기1 */}
      {/* S : 추가 버튼 */}
      <div className="btn-wrap">
        <button type="button" className="btn btn-md-white" onClick={handleAppend}>
          일대기 추가
        </button>
      </div>
      {/* E : 추가 버튼 */}
      {/* E : 우측 상단 버튼 */}
      <BltSampleDialog isOpen={openBltSampleDialog} onClose={() => setOpenBltSampleDialog(false)} />
      <BltTemplateDialog
        isOpen={openBltTemplateDialog}
        onClose={() => setOpenBltTemplateDialog(false)}
        selectedTemplate={template => selectedTemplate(template)}
      />
      {/* E : 추도사 작성 */}
    </div>
  );
}

export default Blts;

import { useState, useEffect, forwardRef } from 'react';

import useDialog from 'stores/dialog/hook';

import { notiUploadCsvApi, mGuestsApi, notiMultySendApi } from 'apis/openapi';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconUpload from '@mui/icons-material/FileUpload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SendSmsDialog({ isOpen, onClose, memorialId }) {
  const { showAlert, hideAlert } = useDialog();

  const notiUploadCsv = async params => {
    const result = await notiUploadCsvApi(params);
    showAlert('알림', result.message, () => hideAlert());
    mGuests();
  };

  const mGuests = async () => {
    const result = await mGuestsApi({ memorial_id: memorialId });
    setGuests(result.data);
  };

  const notiMultySend = async () => {
    const result = await notiMultySendApi(memorialId);
    showAlert('알림', result.message, () => hideAlert());
    mGuests();
  };

  const handleNotiMultySend = () => {
    notiMultySend();
  };

  const [guests, setGuests] = useState(null);

  const changeCsvFile = event => {
    const { files } = event.target;

    let params = {};
    params.file = files[0];
    params.memorial_id = memorialId;

    notiUploadCsv(params);
  };

  useEffect(() => {
    mGuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorialId]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <div className="popup_area">
        <div className="header">
          <h2 className="title">부고 일괄 발송</h2>
          <button className="close" onClick={onClose} />
        </div>
        <div className="content">
          <label htmlFor="contained-button-file">
            <input
              id="csvFile"
              accept="text/csv"
              type="file"
              style={{ display: 'none' }}
              onChange={changeCsvFile}
            />
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => document.getElementById('csvFile').click()}
              startIcon={<IconUpload color="secondary" />}
            >
              <Typography color="secondary">CSV UPLOAD</Typography>
            </Button>
          </label>
          <div className="table_area">
            {guests && guests.length > 0 ? (
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>이름</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>전화번호</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>발송여부</TableCell>
                  </TableRow>

                  {guests.map(guest => (
                    <TableRow key={guest.target_id}>
                      <TableCell>{guest.name}</TableCell>
                      <TableCell>{guest.phone_number}</TableCell>
                      <TableCell>{guest.send_yn === 'Y' ? '전송완료' : '전송대기'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </div>
        </div>
        <div className="footer">
          <button className="btn confirm" onClick={() => handleNotiMultySend()}>
            발송
          </button>
          <button className="btn cancel" onClick={onClose}>
            취소
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default SendSmsDialog;

// src/pages/ChumoRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jangBongAeMemorialId } from 'constants';

function ChumoRedirect() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/memorial/obituary/view/${jangBongAeMemorialId}`, { replace: true });
  }, [navigate]);
  return null;
}

export default ChumoRedirect;

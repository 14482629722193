import { useEffect } from 'react';

import useLayout from 'stores/layout/hook';

import imgIntro01 from 'css/static/media/img_service_pet_01.png';
import imgIntro02 from 'css/static/media/img_service_pet_02.png';
import imgIntro03 from 'css/static/media/img_service_pet_03.png';
import imgIntro04 from 'css/static/media/img_service_pet_04.png';

function ServiceMemorialPage({ setMode }) {
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container is-about" id="containerWrap">
      {' '}
      {/* 220926 className 수정 : 서비스 소개일 경우 className="is-about" 추가 */}
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        {/* S : 22-11-02 서비스 소개 페이지 전체 수정 */}
        <div className="contents-width">
          <h3>펫 메모리얼 캔버스 소개</h3>

          <div className="tabs tabs-about">
            <ul>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('memorial')}>
                  펫 메모리얼 캔버스란?
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('premium')}>
                  장례 안내
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('author')}>
                  작가 안내
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h4 className="title">펫 메모리얼 캔버스는</h4>
            <p className="desc">
              반려동물이 우리 곁을 떠날 때 진심어린 마음을 전달하여 올바른 반려동물 장례 문화를
              형성하고자 합니다.
              <br />
              또한 반려동물 보호자들 사이에서 뜻 깊은 커뮤니티를 형성하고 온라인 공간, 상담 및 지원,
              추모 등을 통해 반려동물을 떠나보낸 사람들 간의 연대감을 강화하고 서로의 마음을 위로할
              수 있도록 합니다.
            </p>
          </div>

          <div className="wrap-service">
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro01} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">01.Memories forever</span>
                <h5>
                  우리의 순간을 담아놓은
                  <br />
                  보관함
                </h5>
                <p>추억을 영원히, 소중하게 담아둘 수 있습니다.</p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro02} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">02.Footprints of PET</span>
                <h5>
                  우리만의 특별한 기록
                  <br />
                  ‘발자국’
                </h5>
                <p>
                  반려동물과 함께한 추억을 모아 영상, 앨범, 발자국으로
                  <br className="d-block d-md-none" />
                  남겨놓을 수 있습니다.
                </p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro03} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">03.digital wreath service</span>
                <h5>
                  마음을 전하는 조문과 <br />
                  디지털화환
                </h5>
                <p>
                  반려동물을 떠나보낸 이들끼리 서로 마음을 나누며
                  <br className="d-block d-md-none" />
                  감정을 공유하고 위로를 전합니다
                </p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro04} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">04.online memorial hall</span>
                <h5>
                  언제든지 다시 만날 수 있는
                  <br />
                  온라인 공간
                </h5>
                <p>추모관을 통해 언제나 아이들을 만나고 특별하게 오래도록 기억할 수 있습니다.</p>
              </div>
            </div>
          </div>
        </div>
        {/* E : 22-11-02 서비스 소개 페이지 전체 수정 */}
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default ServiceMemorialPage;

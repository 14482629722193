import { Dialog } from '@mui/material';

function WreathDialog({ isOpen, onClose, obituary, relations, selectedReceiver }) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <>
        <div className="layer_pop">
          <div className="pop_inner">
            <div className="pop_header">
              <h2 className="top_title">디지털화환 선택</h2>
              <button className="close" onClick={onClose}>
                닫기
              </button>
            </div>
            <div className="layer_cont_wrap">
              <div className="layer_cont">
                <div className="layer_cont_inner">
                  <div className="digital_select_area">
                    <p className="text_top">디지털화환을 전달하실 상주를 선택하세요</p>
                    <ul className="list_content">
                      {obituary.chief_mourner_desc && obituary.chief_mourner ? (
                        <li>
                          <button onClick={() => selectedReceiver(obituary.chief_mourner)}>
                            {obituary.chief_mourner_desc} <strong>{obituary.chief_mourner}</strong>
                          </button>
                        </li>
                      ) : (
                        ''
                      )}
                      {relations && relations.length > 0
                        ? relations.map(relation => (
                            <li key={relation.relations_type_index}>
                              <button onClick={() => selectedReceiver(relation.relations_name)}>
                                {relation.relations_detail}{' '}
                                <strong>{relation.relations_name}</strong>
                              </button>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default WreathDialog;

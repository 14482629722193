import { Dialog } from '@mui/material';

import useCodes from 'stores/code/hook';
function ChudosaTemplateDialog({ isOpen, onClose, value }) {
  const { chudosaTitleCodes } = useCodes();

  const handleTemplate = template => {
    value(template);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-memorial-article" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추도사 예시</h2>
                <p className="desc">추모의 글을 남겨 마음을 전달하세요.</p>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="list-article no-title">
                  <ul>
                    {/* S : LOOP */}
                    {chudosaTitleCodes &&
                      chudosaTitleCodes.length > 0 &&
                      chudosaTitleCodes.map(chudosaTitleCode => (
                        <li>
                          <div className="article-detail">
                            <p className="text">{chudosaTitleCode.value}</p>
                          </div>
                          <button
                            type="button"
                            className="btn-sm-ln-navy"
                            onClick={() => handleTemplate(chudosaTitleCode.value)}
                          >
                            선택
                          </button>
                        </li>
                      ))}

                    {/* E : LOOP */}
                  </ul>
                </div>
                <div className="box-loading">
                  {/* 아래 로딩 이미지는 디자인팀 통하여 gif 이미지 전달 받은 후 교체해주세요 */}
                  {/*<img src="../../static/media/ico_loading.png" />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="layer_pop pop_sample">
        <div className="pop_inner">
          <div className="pop_header">
            <h2 className="top_title">추도사 샘플 보기</h2>
            <button className="close" onClick={onClose}>
              닫기
            </button>
          </div>
          <div className="layer_cont_wrap">
            <div className="layer_cont">
              <div className="layer_cont_inner">
                <div className="pop_memorial">
                  사랑하는 어머니께……
                  <br />
                  <br />
                  어머니 저는 아직 준비가 안 되었는데, 이렇게 홀연히 저희들 남겨두고 떠나시면 어떻게
                  하나요? 아직 저희 옆에 어머님께서 더 계셔 주셔야 하는데…
                  <br />
                  <br />
                  2007년 파킨슨병 진단을 받으시고 신체 기능이 서서히 저하 되셔서 걸음을 못 떼시고
                  2017년 여름부터는 음식도 못 드시며 말씀도 못하시기에 이르렀습니다. 중증 환자가
                  되신 어머님을 아버지는 시설 기관에 보내지 않고 집에서 당신께서 돌보셨습니다. 한
                  없이 연약한 아내를 사랑으로 돌보시며 아버님의 얼굴에는 조금씩 빛이 나기
                  시작하셨으며, 평소 늘 무뚝뚝하시고 잔정이 없으시던 아버님이 이제 자식이 오면 손수
                  커피를 끓여 주시고 사과를 깎아 먹으라고 하십니다. 어머님께서 아버님을 변화시키고
                  계셨습니다. 어머님을 바라보시는 아버지의 눈빛은 봄날 햇살만큼이나 따사롭습니다.
                  <br />
                  <br />
                  그렇게 두 분은 세상에서 가장 서로를 아끼고 사랑하는 부부가 되어 가셨습니다. 자녀와
                  손주들에게 어떻게 살며 서로 사랑하여야 하는지 가르쳐 주셨습니다.
                  <br />
                  <br />
                  2022. 02. 11. 장남 OOO 올림
                </div>
                <div className="pop_btn_wrap">
                  <button className="pop_btn" onClick={onClose}>
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Dialog>
  );
}

export default ChudosaTemplateDialog;

import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import useDialog from 'stores/dialog/hook';

import { useForm } from 'react-hook-form';
import { saveCanvasJoinApi } from 'apis/canvas';

import useLogin from 'stores/login/hook';

function OpenJoinRequestDialog({ isOpen, onClose, requestId, reflash }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();

  const { showLifeAlert, hideLifeAlert } = useDialog();

  const defaultValues = {
    relation: '가족',
  };

  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    //resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    saveCanvasJoin(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const confirmClose = () => {
    // onClose();

    // callback 받을 함수
    hideLifeAlert();
    reflash();
  };
  const saveCanvasJoin = async data => {
    let params = {};

    //params = data;

    params.action_type = '2';
    params.canvas_id_my = loginUser.canvas_id;
    params.canvas_id = requestId.canvas_id;
    params.relation = data.relation;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;
    //console.log('dd' + JSON.stringify(params));

    const result = await saveCanvasJoinApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', result.message, () => confirmClose());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  useEffect(() => {
    //init();
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const init = async () => {
  //   if (loginUser?.member_id) await getCanvasJoinList();
  // };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        {/* S : 나와의 관계 선택 팝업 */}
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-connect-req-02" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">
                    {requestId.response_name} 님께
                    <br />
                    연결 요청하시겠습니까?
                  </h2>
                  <button
                    className="modal__close js-modal-close"
                    aria-label="Close modal"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  <div className="sel-item is-relation">
                    <label for="sel-relation">나와의 관계</label>
                    <select id="sel-relation" {...register('relation')}>
                      <option value="가족">가족</option>
                      <option value="친구">친구</option>
                      <option value="친척">친척</option>
                      <option value="동료">동료</option>
                    </select>
                  </div>
                </div>
                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn-blue"
                    onClick={handleSubmit(onSubmit, onError)}
                    data-micromodal-close
                  >
                    연결요청
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* E : 나와의 관계 선택 팝업 */}
      </>
    </Dialog>
  );
}

export default OpenJoinRequestDialog;

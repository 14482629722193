import api from '@apis/api';
import configs from '@configs/configs';

export async function getHallsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/fHall`, params);
  return response.data;
}

export async function getHallApi(hallId) {
  const response = await api.get(`${configs.API_BASEURL}/fHall?hall_id=${hallId}`);
  return response.data;
}

export async function saveHallsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/fHall/ins`, params);
  return response.data;
}

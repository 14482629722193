import { useState } from 'react';

import Life from './components/life';
import Premium from './components/premium';

function ServicePage() {
  const [mode, setMode] = useState('life');

  return <>{mode === 'life' ? <Life setMode={setMode} /> : <Premium setMode={setMode} />}</>;
}
export default ServicePage;

import MainTop from 'pages/memorial-canvas/main/components/MainTop';
import IntroSection from 'pages/memorial-canvas/main/components/IntroSection';
import MemorialSection from 'pages/memorial-canvas/main/components/MemorialSection';
import RememberService from 'pages/memorial-canvas/main/components/RememberService';
// import 'css/main.css';
import useLayout from 'stores/layout/hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MainPage() {
  const { setGnbType } = useLayout();
  const navigate = useNavigate();
  const [isMobile, setisMobile] = useState(false);

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  // 우선 맨 처음 1024이하면 모바일 처리
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }

    window.addEventListener('resize', resizingHandler);

    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler);
    };
  }, []);

  useEffect(() => {
    setGnbType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="container is-main" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          {/* S : 메인 비주얼 */}
          <div className="visual-wrap">
            <div className="contents-width">
              <h2>
                Memorial
                <br />
                Canvas
              </h2>
              <p>
                메모리얼 캔버스는
                <br />
                고인의 삶과 메시지에 초점을 맞추어&nbsp;
                <br />
                오롯이 고인을 추모하는&nbsp;
                <br className="d-none d-md-block" />
                장례문화를 만들어 갑니다.
              </p>
            </div>
          </div>
          {/* E : 메인 비주얼 */}
          <div className="contents-width">
            {/* S : 추모관 */}
            <div className="box-title">
              <h3 className="title">추모관</h3>
              <p className="desc">
                떠난 이가 전달하고자 했던 메시지를 기억하며,
                <br />
                생전에 하지 못한 이야기, 그리운 마음을 전달합니다.
              </p>
              <a
                href={() => false}
                onClick={() => navigate('/memorial/memorial')}
                className="btn-all-view"
              >
                전체 보기
              </a>
            </div>
            <MemorialSection />
            {/* S : Memorial Canvas */}
            <div className="box-title">
              <h3 className="title">Memorial Canvas</h3>
              <p className="desc">
                메모리얼 캔버스는 고인의 삶과 메시지에&nbsp;
                <br className="d-none d-md-block" />
                초점을 맞추어&nbsp;
                <br className="d-block d-md-none" />
                오롯이 고인을 추모하는&nbsp;
                <br className="d-none d-md-block" />
                장례문화를 만들어 갑니다.
              </p>
            </div>
            <div className="memorial-canvas">
              {/* S : 부고 */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/memorial/obituary')}>
                  <i className="ic ic-main-memorial-01"></i>
                  <p className="title">부고</p>
                  <p className="desc">
                    상주가 부고를 만들어
                    <br />
                    유족과 지인들에게
                    <br />
                    알릴 수 있습니다.
                  </p>
                </a>
              </div>
              {/* E : 부고 */}
              {/* S : 추모관 */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/memorial/memorial')}>
                  <i className="ic ic-main-memorial-02"></i>
                  <p className="title">추모관</p>
                  <p className="desc">
                    부고 이후, 추모관으로 자동 전환되어
                    <br />
                    지속적으로 고인을
                    <br />
                    추모할 수 있습니다.
                  </p>
                </a>
              </div>
              {/* E : 추모관 */}
              {/* S : 조문보 */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/memorial/service')}>
                  <i className="ic ic-main-memorial-03"></i>
                  <p className="title">조문보</p>
                  <p className="desc">
                    고인의 삶과 정신적 유산을
                    <br />
                    조문보를 통해 알립니다.
                  </p>
                </a>
              </div>
              {/* E : 조문보 */}
              {/* S : FAQ */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/memorial/faq')}>
                  <i className="ic ic-main-memorial-04"></i>
                  <p className="title">FAQ</p>
                  <p className="desc">
                    부고 및 추모관, 조문보 서비스에
                    <br />
                    대한 사항을 확인하실 수<br />
                    있습니다.
                  </p>
                </a>
              </div>
              {/* E : FAQ */}
            </div>
            {/* E : Memorial Canvas */}
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
    </>
  );
}

export default MainPage;

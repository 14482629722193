import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import parse from 'html-react-parser';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import { toggleCanvasBltLikeApi } from 'apis/canvasBlt';
import useCommon from 'stores/common/hook';
import { getCanvasAlarmCountApi } from 'apis/canvas';

function StoryQna({ canvasBlt }) {
  const [qnas, setQnas] = useState();
  const navigate = useNavigate();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();
  const { setAlarmCnt } = useCommon();

  const [isLike, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const toggleCanvasBltLike = async () => {
    if (!loginUser || !loginUser.member_id) {
      showLifeAlert('알림', '좋아요 기능은 로그인 후 이용가능합니다.', () => hideLifeAlert());
      return;
    }

    let params = {};
    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;

    const result = await toggleCanvasBltLikeApi(params);

    if (result.code === '0000') {
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
      setLikeCount(isLike ? likeCount - 1 : likeCount + 1);
      setLike(!isLike);
      const alarmCount = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      setAlarmCnt(alarmCount.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  useEffect(() => {
    setLike(canvasBlt.like_yn === 'Y' ? true : false);
    setLikeCount(canvasBlt.like_count ? canvasBlt.like_count : 0);
    if (canvasBlt?.blt_contents) {
      const obj = JSON.parse(canvasBlt.blt_contents.replace(/(\n|\r\n)/g, '<br />'));

      setQnas(obj);
    }
  }, [canvasBlt]);
  return (
    <div className="canvas-post">
      <div className="canvas-inner">
        {/* S : 글 작성자 이름 */}
        <div className="canvas-head">
          <div className="user-info">
            <div
              className="user-thumb"
              onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
              style={{ cursor: 'pointer' }}
            >
              {/*<span className="chk-size"><img alt="" src="https://image.shutterstock.com/z/stock-photo-smiling-indian-businessman-working-on-laptop-in-modern-office-lobby-space-young-indian-student-1935739078.jpg" /></span>*/}
              <span className="chk-size">
                <img alt="" src={canvasBlt.profile_link ? canvasBlt.profile_link : ''} />
              </span>
            </div>
            <span
              className="name"
              onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
              style={{ cursor: 'pointer' }}
            >
              {canvasBlt.title}
            </span>

            <span className="date">{canvasBlt.modifydtime}</span>
          </div>
          <button
            type="button"
            className={`btn btn-sm ${canvasBlt.link_yn === 'Y' ? 'btn-lngray' : 'btn-lngreen'}`}
            onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
          >
            {canvasBlt.link_yn === 'Y' ? '구독 중' : '+ 구독'}
          </button>
        </div>
        {/* E : 글 작성자 정보 */}

        {/* S : 글 내용 */}

        <div className="canvas-content">
          {/* S : 분류 */}
          <div className="category">
            <i className="ic ic-cate-faq"></i>문답
          </div>
          {/* E : 분류 */}

          {/* S : 글 */}
          {qnas &&
            qnas.length > 0 &&
            qnas.map((qna, index) => (
              <div className="qna-content" key={index}>
                <p className="qna-q">{qna.questions}</p>
                <p className="qna-a">{parse(qna.answer)}</p>
              </div>
            ))}

          {/* E : 글 */}
        </div>
        {/* E : 글 내용 */}
        {/* S : 댓글 목록 */}
        <div className="canvas-cmt">
          {/* S : 좋아요 버튼 + 갯수 */}
          <div className="canvas-like">
            <button type="button" onClick={() => toggleCanvasBltLike()}>
              <i className={`ic ${isLike ? 'ic-canvas-like-active' : 'ic-canvas-like'}`}></i>
            </button>
            <span>{likeCount} </span>
          </div>
          {/* E : 좋아요 버튼 + 갯수 */}
        </div>
      </div>
    </div>
  );
}

export default StoryQna;

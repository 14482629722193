import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IconModify from '@mui/icons-material/Edit';

import Pagination from 'react-js-pagination';
import { getCanvasesApi } from 'apis/canvas';

function AdminCanvasMainPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const { displayStatusCodes } = useCode();

  const [canvases, setCanvases] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [searchType, setSearchType] = useState('NAME');

  const getCanvases = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'canvas_num' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.searchType = searchType;

    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getCanvasesApi(params);
    setCanvases(result.data);
    setTotalCount(result.data_cnt);
  };

  const handleModify = id => {
    navigate(`${pathname}/modify/${id}`);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getCanvases(selectedPage);
  };

  const handleSearch = () => {
    getCanvases(1);
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getCanvases(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>메인 관리</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02" onChange={e => setSearchType(e.target.value)}>
                    <option value={'NAME'}>이름</option>
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  placeholder="검색어를 입력하세요"
                  ref={searchRef}
                  onClick={handleSearch}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn btn-download">
              엑셀 다운로드
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">메인 목록</caption>
            <colgroup>
              <col width="10%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">ID.</th>
                <th scope="col">이름</th>
                <th scope="col">생년월일</th>
                <th scope="col">가입일</th>
                <th scope="col">공개여부</th>
                <th scope="col">우선순위</th>
                <th scope="col">기능</th>
              </tr>
            </thead>
            <tbody>
              {canvases && canvases.length > 0
                ? canvases.map(row => (
                    <tr key={row.canvas_id}>
                      <td>{row.canvas_id}</td>
                      <td>{row.title}</td>
                      <td>{row.birthday}</td>
                      <td>{row.createdtimeStr10}</td>
                      <td>
                        {displayStatusCodes &&
                          displayStatusCodes.length > 0 &&
                          displayStatusCodes.filter(code => code.code === row.display_status)[0]
                            .value}
                      </td>
                      <td>{row.canvas_num}</td>
                      <td>
                        <Tooltip title="수정" arrow>
                          <button
                            className=""
                            type="button"
                            aria-label="수정"
                            onClick={() => handleModify(row.canvas_id)}
                          >
                            <i className="ic ic-modify"></i>
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}
    </div>
  );
}

export default AdminCanvasMainPage;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchAllValueState, setSearchAllValueState } from './reducer';

export default function useSearch() {
  const searchAllValue = useSelector(searchAllValueState);

  const dispatch = useDispatch();

  const setSearchAllValue = useCallback(
    searchAllValue => {
      dispatch(setSearchAllValueState(searchAllValue));
    },
    [dispatch],
  );

  return {
    searchAllValue,
    setSearchAllValue,
  };
}

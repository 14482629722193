import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import KakaoLogin from './components/KakaoLogin';
import NaverLogin from './components/NaverLogin';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

function LifeLogin() {
  const loginSubmitRef = useRef();
  const navigate = useNavigate();
  const { loginUser, error, emailLogin } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();
  const [savedId, setSavedId] = useState(false);
  const [mode, setMode] = useState(true);

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    member_pwd: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    email: '',
    member_pwd: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    if (savedId) window.localStorage.setItem('savedId', data.email);
    else window.localStorage.removeItem('savedId');
    emailLogin(data);
    //navigate('/life');
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      loginSubmitRef.current.click();
    }
  };

  useEffect(() => {
    if (error) {
      showLifeAlert('알림', error.message, () => hideLifeAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (loginUser?.member_id) navigate('/life');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    const savedId = localStorage.getItem('savedId');
    if (savedId) {
      setSavedId(true);
      setValue('email', savedId);
    } else {
      setSavedId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width">
          {/* S : 로그인 */}
          <div className="member-form">
            <h2 className="offscreen">LOGIN</h2>
            <h3 className="title">간편 로그인</h3>
            <ul class="login-btn-wrap">
              <KakaoLogin />
              <NaverLogin />
              <li>
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => {
                    setMode(!mode);
                  }}
                >
                  일반 로그인
                </button>
              </li>
            </ul>

            <div class={`${mode ? 'box-login' : ''}`}>
              <h3 class="title">일반 로그인</h3>
              <form>
                <fieldset>
                  <legend>로그인</legend>
                  <div className="ip-item is-error">
                    <label htmlFor="user-id" className="offscreen">
                      이메일주소 입력
                    </label>
                    <input
                      {...register('email')}
                      type="email"
                      id="user-id"
                      placeholder="이메일주소"
                    />
                    <p class="message">{errors.email?.message}</p>
                  </div>
                  <div className="ip-item is-error">
                    <label htmlFor="user-pw" className="offscreen">
                      비밀번호 입력
                    </label>
                    <input
                      {...register('member_pwd')}
                      type="password"
                      id="user-pw"
                      placeholder="비밀번호"
                      onKeyPress={onKeyPress}
                    />
                    <p class="message">{errors.member_pwd?.message}</p>
                  </div>

                  {/* <div className="rc-item">
                  <input
                    type="checkbox"
                    id="chk-save-id"
                    checked={savedId}
                    onClick={() => setSavedId(!savedId)}
                  />
                  <label htmlFor="chk-save-id">아이디 저장</label>
                </div> */}
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={handleSubmit(onSubmit, onError)}
                      ref={loginSubmitRef}
                    >
                      로그인
                    </button>
                  </div>
                  <ul className="login-util">
                    <li>
                      <a href={() => false} onClick={() => navigate('/life/login/register')}>
                        회원가입
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/life/login/findid')}>
                        아이디 찾기
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/life/login/resetpw')}>
                        비밀번호 초기화
                      </a>
                    </li>
                  </ul>
                </fieldset>
              </form>
            </div>
          </div>
          {/* E : 로그인 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default LifeLogin;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { saveMemberApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function AdminMemberRegisterPage() {
  const navigate = useNavigate();

  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    member_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    passwd: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
        '* 패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
      ),
    passwd_confirm: Yup.string().oneOf([Yup.ref('passwd'), null], '* 비밀번호가 일치하지 않습니다'),
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    birth: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
        '올바른 생년월일을 입력해주세요. 예) 19900101',
      ),
  }).required();

  const defaultValues = {
    member_id: '',
    member_name: '',
    member_type: 'N',
    passwd: '',
    email: '',
    mobile: '',
    addr: '',
    birth: '',
    use_yn: 'Y',
    login_type: 'E',
    nation_number: 82,
    nation_name: '대한민국',
    del_yn: 'N',
    status: 1,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const saveMember = async params => {
    const result = await saveMemberApi(params);
    result.code === '0000'
      ? showAlert('알림', result.message, () => handleClose())
      : showAlert('알림', result.message, () => hideAlert());
  };

  const onSubmit = data => {
    saveMember(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleClose = () => {
    hideAlert();
    navigate('/admin/member');
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      window.location.href = '/';
    }
    if (loginUser) setValue('create_member_id', loginUser.member_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>회원등록</h2>
      </div>

      <div className="content-box wrap-detail">
        {/* S : 정산정보 입력 */}
        <div className="content-detail">
          <h3>기본정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <colgroup>
                <col width="25%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label htmlFor="ipSample2">이메일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample2"
                        {...register('email')}
                        aria-invalid={errors.email ? 'true' : 'false'}
                      />
                      {errors.email && <p className="message">{errors.email.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">이름</label>
                  </th>
                  <td className="input">
                    {/* S : 에러 케이스 */}
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample1"
                        {...register('member_name')}
                        aria-invalid={errors.member_name ? 'true' : 'false'}
                      />
                      {errors.member_name && (
                        <p className="message">{errors.member_name.message}</p>
                      )}
                    </div>
                    {/* E : 에러 케이스 */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample4">비밀번호</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="password"
                        id="ipSample4"
                        {...register('passwd')}
                        aria-invalid={errors.passwd ? 'true' : 'false'}
                      />
                      {errors.passwd && <p className="message">{errors.passwd.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample5">비밀번호 확인</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="password"
                        id="ipSample5"
                        {...register('passwd_confirm')}
                        aria-invalid={errors.passwd_confirm ? 'true' : 'false'}
                      />
                      {errors.passwd_confirm && (
                        <p className="message">{errors.passwd_confirm.message}</p>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <label for="ipSample3">전화번호</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample3"
                        {...register('mobile')}
                        aria-invalid={errors.mobile ? 'true' : 'false'}
                      />
                      {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label htmlFor="ipSample3">생년월일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample6"
                        {...register('birth')}
                        aria-invalid={errors.birth ? 'true' : 'false'}
                      />
                      {errors.birth && <p className="message">{errors.birth.message}</p>}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 정산정보 입력 */}

        {/* S : 디지털 화환 총 모금액 */}
        <div className="content-detail">
          <h3>추가정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="ipSample7">사용자타입</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <select
                        title="선택"
                        id="selSample7"
                        {...register('member_type')}
                        aria-invalid={errors.member_type ? 'true' : 'false'}
                      >
                        {memberTypeCodes && memberTypeCodes.length > 0
                          ? memberTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.member_type && (
                        <p className="message">{errors.member_type.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample8">로그인타입</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <select
                        title="선택"
                        id="selSample8"
                        {...register('login_type')}
                        aria-invalid={errors.login_type ? 'true' : 'false'}
                      >
                        {loginTypeCodes && loginTypeCodes.length > 0
                          ? loginTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.login_type && <p className="message">{errors.login_type.message}</p>}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 디지털 화환 총 모금액 */}
      </div>

      {/* S : 버튼 */}
      <div className="btn-wrap center">
        <button
          type="button"
          className="btn btn-lg btn-pos"
          onClick={handleSubmit(onSubmit, onError)}
        >
          저장
        </button>
        <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
          취소
        </button>
      </div>
      {/* E : 버튼 */}
    </div>
  );
}

export default AdminMemberRegisterPage;

import { useRef, useEffect, useState } from 'react';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';

import Dialog from '@mui/material/Dialog';
import parse from 'html-react-parser';
import ContentLoader from 'react-content-loader';

import TabBltContent from 'pages/memorial-canvas/obituary/components/TabBltContent';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';

import 'swiper/css';
import 'swiper/css/pagination';
import 'css/swiper.min.css';
import TabChudosasContent from '../../../obituary/components/TabChudosasContent';
import TabAlbumContent from '../TabAlbumContent';
import TabCondolencesGroupContent from '../../../obituary/components/TabCondolencesGroupContent';
import MediaViewDialog from '../../../obituary/components/MediaViewDialog';
import SharedDialog from '../SharedDialog';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

function ObituaryViewPage({ isOpen, onClose, getValues }) {
  const { showAlert, hideAlert } = useDialog();

  const { setGnbType } = useLayout();

  const [bltContents, setBltContents] = useState(null);

  const [tabIndex, setTabIndex] = useState(1);
  const [tabBlt, setTabBlt] = useState(false);
  const [tabState, setTabState] = useState(false);

  const [memorial, setMemorial] = useState(null);
  const [blts, setBlts] = useState(null);
  const [deceasedName, setDeceasedName] = useState(null);

  const [isActive, setActive] = useState(false);

  const handleNoAccess = () => {
    showAlert('알림', '미리보기에서 제한된 기능입니다.', () => hideAlert());
  };

  const homeRef = useRef();
  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const xy = useRef(null);
  const onClickTab = index => {
    if (tabIndex === index && index !== 1) {
      setTabState(!tabState);
      return;
    }
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
    setTabIndex(index);
  };

  useEffect(() => {
    setBltContents(
      blts && blts.length > 0
        ? blts.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [blts]);

  useEffect(() => {
    setMemorial(getValues());
    setBlts(getValues('blt_datas'));
    setDeceasedName(getValues('deceased_name'));
  }, [getValues]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="false" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div class="modal is-full " id="modal-obituary-14" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title">미리보기</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div class="modal__content">
                <div className="content">
                  {/* S : 서브 비주얼 + 타이틀 */}
                  {/*<div className="visual-wrap">*/}
                  {/*  <div className="contents-width">*/}
                  {/*    <h2 className="title">{deceasedName}님 추모관</h2>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/* E : 서브 비주얼 + 타이틀 */}

                  {/* S : 23-04-21 메모리얼 캔버스 개편에 따른 마크업 수정 */}
                  {/*
                   *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
                   */}
                  <div className="fixed-list is-remembrance">
                    <ul>
                      <li className={tabIndex === 1 && tabBlt === true ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            onClickTab(1);
                            //setTabBlt(true);
                          }}
                        >
                          일대기
                        </button>
                      </li>
                      <li className={tabIndex === 2 ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                          }}
                        >
                          추도사
                        </button>
                      </li>
                      <li className={tabIndex === 3 ? 'is-active' : ''}>
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                          }}
                        >
                          추억앨범
                        </button>
                      </li>
                      <li className={tabIndex === 4 ? 'is-active' : ''}>
                        {/*<button type="button" onClick={() => moveUrl()}>*/}
                        {/*  추모의 글*/}
                        {/*</button>*/}
                        <button
                          type="button"
                          onClick={() => {
                            handleNoAccess();
                          }}
                        >
                          추모의 글
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="wrap-detail">
                    {/* S : 영정 사진 */}
                    {/* S : 사진 없을 때 */}
                    {/*
                     *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
                     */}
                    {memorial ? (
                      <>
                        {memorial.deceased_photo ? (
                          <div className="wrap-portrait is-remembrance has-portrait">
                            <div className="box-title">
                              <h3 className="title">세상에 남겨진 오직 한 사람의 이야기</h3>
                              <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                            </div>
                            <div className="box-portrait">
                              <img
                                src={
                                  memorial.deceased_photo
                                    ? memorial.deceased_photo
                                    : noDeceasedPhoto
                                }
                                alt=""
                              />
                              {/*<img src="https://images.unsplash.com/photo-1648183185045-7a5592e66e9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1084&q=80" />*/}
                              {/*<img src="../../static/media/user_no_portrait_obituary.png" />*/}
                            </div>
                          </div>
                        ) : (
                          <div className="wrap-portrait is-remembrance">
                            <div className="box-title">
                              <h3 className="title">
                                세상에 남겨진 <br className="d-md-block d-none" /> 오직 한 사람의
                                이야기
                              </h3>
                              <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                            </div>
                          </div>
                        )}

                        {/* S : 고인 정보 */}
                        <div className="wrap-deceased contents-width">
                          <div className="box-name">
                            <h4 className="name">
                              {memorial.deceased_name} {memorial.deceased_job_title}
                            </h4>
                          </div>
                          {memorial.deceased_birthday || memorial.deceased_date ? (
                            <p className="birth">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</p>
                          ) : (
                            ''
                          )}
                          {/* S : 버튼 */}
                          <div className="btns">
                            <ul>
                              <li>
                                <button type="button" onClick={() => handleNoAccess()}>
                                  <i className="ic ic-send-obituary"></i>추모관 전송
                                </button>
                              </li>
                            </ul>
                          </div>
                          {/* E : 버튼 */}
                        </div>
                        {/* E : 고인 정보 */}

                        {/* S : 소개글 */}
                        <div className="wrap-info">
                          <div className="contents-width">
                            <p>
                              {memorial.memorial_introduction &&
                                parse(
                                  memorial.memorial_introduction.replace(/(\n|\r\n)/g, '<br />'),
                                )}
                            </p>
                          </div>
                        </div>
                        {/* E : 소개글 */}

                        {/* S : 추모 영상 */}
                        {memorial.deceased_video_yn === 'Y' &&
                        memorial.deceased_video_link !== '' ? (
                          <div className="wrap-remembrance-vod">
                            <div className="contents-width">
                              <div className="box-title">
                                <h4 className="title">추모 영상</h4>
                                <p className="desc">고인의 삶을 영상으로 담아 추억합니다.</p>
                              </div>
                              <div className="box-vod">
                                <div className="inner-item">
                                  {memorial.video_thumbnail ? (
                                    <video className="video" poster={memorial.video_thumbnail} />
                                  ) : (
                                    <video
                                      className="video"
                                      poster="https://plus.unsplash.com/premium_photo-1675826774817-fe983ceb0475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                                    />
                                  )}
                                  <button
                                    type="button"
                                    className="btn-vod-play is-show"
                                    onClick={() => handleNoAccess()}
                                  >
                                    <span className="offscreen">추모영상 재생하기</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ' '
                        )}
                        {/* E : 추모 영상 */}

                        {/* S : 부고/추모관 공통 컨텐츠 */}
                        {/* S : 일대기 */}
                        {tabIndex === 1 && (
                          <TabBltContent
                            bltContents={bltContents}
                            deceasedName={deceasedName}
                            // xy={xy}
                            // tabState={tabState}
                          />
                        )}
                        {/*{tabIndex === 2 && (*/}
                        {/*    <TabChudosasContent chudosaContents={chudosaContents} tabState={tabState} />*/}
                        {/*)}*/}
                        {/*{tabIndex === 3 && (*/}
                        {/*    <TabAlbumContent*/}
                        {/*        memorialId={memorialId}*/}
                        {/*        deceasedName={deceasedName}*/}
                        {/*        ownMemberId={ownMemberId}*/}
                        {/*        tabState={tabState}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/*{tabIndex === 4 && (*/}
                        {/*    <TabCondolencesGroupContent currentLastUrl={currentLastUrl} tabState={tabState} />*/}
                        {/*)}*/}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div class="modal__footer">
                <button type="button" class="btn btn-blue" onClick={onClose} data-micromodal-close>
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ObituaryViewPage;

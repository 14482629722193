import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { modifyCommentCategoryApi, getCommentCategoryApi } from 'apis/comment';

import useDialog from 'stores/dialog/hook';
import 'css/popup.css';
import { useEffect, useState } from 'react';

function ModifyCommentCategoryDialog({
  isOpen,
  onClose,
  memorialId,
  memberId,
  callBack,
  handleChangeSelectedCategory,
  category,
  commentCategory,
  setCommentCategory,
}) {
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    category_name: Yup.string()
      .max(10, '* 추모 모임 이름은 최대 10글자까지 입력이 가능합니다.')
      .required('* 추모 모임 이름을 입력해 주세요.'),
    category_desc: Yup.string().max(15, '* 설명글은 최대 15글자까지 입력이 가능합니다.'),
    blt_password: Yup.string().test('required', '* 필수 입력 항목입니다.', val => {
      if (!displayYn) {
        return val.length > 0;
      } else {
        return true;
      }
    }),
  }).required();

  const defaultValues = {
    category_name: category.category_name,
    category_desc: '',
    display_yn: category.display_yn,
    category_id: category.category_id,
    blt_password: category.blt_password,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [categoryName, setCategoryname] = useState('');
  const [categoryText, setCategoryText] = useState('');
  const [displayYn, setDisplayYn] = useState(true);
  const [bltPassword, setBltPassword] = useState('');
  const [showPswd, setShowPswd] = useState(false);

  const modifyCommentCategory = async params => {
    const result = await modifyCommentCategoryApi(params);
    showAlert('알림', result.message, () => handleClose());
  };

  const handleChangeCategoryName = event => {
    setCategoryname(event.target.value);
  };

  const handleChangeCategoryText = event => {
    setCategoryText(event.target.value);
  };

  const handleChangeDisplayYn = () => {
    setDisplayYn(!displayYn);
  };

  const handleChangePassword = event => {
    setBltPassword(event.target.value);
  };

  const handleClose = () => {
    reset();
    hideAlert();
    onClose();
    clearErrors();
  };

  const handleCancel = () => {
    onClose();
    clearErrors();
  };

  const onSubmit = data => {
    modifyCommentCategory(data);

    let modifyData = commentCategory.map(v => {
      if (v.category_id === data.category_id) {
        v.category_name = data.category_name;
        v.category_desc = data.category_desc;
        v.display_yn = data.display_yn;
        v.blt_password = data.blt_password;
      }
      return v;
    });

    setCommentCategory(modifyData);
  };

  const handleShowPswd = event => {
    if (event.target.checked === true) {
      setShowPswd(true);
    } else {
      setShowPswd(false);
    }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  useEffect(() => {
    setValue('category_id', category.category_id);
    setValue('category_name', category.category_name);
    setValue('category_desc', category.category_desc);
    setValue('display_yn', category.display_yn);
    setValue('blt_password', category.blt_password);
    setDisplayYn(category.display_yn === 'Y' ? true : false);
  }, [isOpen]);

  useEffect(() => {
    setValue('category_name', categoryName);
  }, [categoryName]);

  useEffect(() => {
    setValue('category_desc', categoryText);
  }, [categoryText]);

  useEffect(() => {
    setValue('display_yn', displayYn ? 'Y' : 'N');
  }, [displayYn]);

  useEffect(() => {
    setValue('blt_password', bltPassword);
  }, [bltPassword]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal " id="modal-remembrance-03" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모 모임 수정</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={handleCancel}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-meeting-open1">추모 모임 이름</label>
                    <input
                      type="text"
                      id="ip-meeting-open1"
                      placeholder="모임 이름(최대 10글자)"
                      onChange={handleChangeCategoryName}
                      defaultValue={category.category_name}
                      key={category.category_name}
                      maxLength="10"
                      {...register('category_name')}
                    />
                    {errors.category_name && (
                      <p className="message">{errors.category_name.message}</p>
                    )}
                  </div>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-meeting-open2">설명글</label>
                    <input
                      type="text"
                      id="ip-meeting-open2"
                      placeholder="추모 모임에 대한 설명글(최대 15글자)"
                      maxLength="15"
                      onChange={handleChangeCategoryText}
                      defaultValue={category.category_desc}
                      {...register('category_desc')}
                    />
                    {errors.category_desc && (
                      <p className="message">{errors.category_desc.message}</p>
                    )}
                  </div>
                </div>
                <div className="ip-wrap">
                  <div className={!displayYn ? 'ip-item is-error' : 'offscreen'}>
                    <label htmlFor="ip-meeting-open3">비밀번호 설정</label>
                    <input
                      type={showPswd ? 'text' : 'password'}
                      id="ip-meeting-open3"
                      placeholder="비밀번호를 입력하세요"
                      onChange={handleChangePassword}
                      {...register('blt_password')}
                    />

                    <div className="rc-item">
                      <input type="checkbox" id="chk-agree1" onClick={handleShowPswd} />
                      <label htmlFor="chk-agree1">비밀번호 보기</label>
                    </div>
                  </div>
                </div>

                {/* S : 공개여부 */}
                <div className="ip-wrap">
                  <div className="rc-item rc-switch">
                    <input
                      type="checkbox"
                      id="switch1"
                      switch="none"
                      onClick={handleChangeDisplayYn}
                      defaultChecked={category.display_yn === 'Y' ? true : false}
                    />
                    <label htmlFor="switch1" data-on-label="공개" data-off-label="비공개"></label>
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close=""
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  수정
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close=""
                  onClick={handleCancel}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ModifyCommentCategoryDialog;

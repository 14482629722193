import useDialog from 'stores/dialog/hook';

import { notiAuthSendApi, notiAuthVerifyApi } from 'apis/openapi';
import { resetPwdApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function ResetPw() {
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    code: Yup.string()
      .required('* 필수입력 항목입니다.')
      .min(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.')
      .max(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.'),
  }).required();

  const defaultValues = {
    email: '',
    mobile: '',
    id: 0,
    code: '',
    verified: 'N',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    if (getValues('verified') !== 'Y') {
      showLifeAlert('알림', '인증코드를 확인하세요.', () => hideLifeAlert());
    } else {
      resetPwd(data);
    }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleNotiAuthSend = () => {
    if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
      showLifeAlert('알림', '휴대폰 번호를 확인하세요.', () => hideLifeAlert());
    } else {
      notiAuthSend();
    }
  };

  const notiAuthSend = async () => {
    const params = { send_type: 'S', phone_num: getValues('mobile') };
    const result = await notiAuthSendApi(params);
    setValue('id', result.id);
    showLifeAlert('알림', '문자로 발송된 인증코드를 입력하세요.', () => hideLifeAlert());
  };

  const handleNotiAuthVerify = () => {
    if (!getValues('code') || getValues('code') === '' || errors.code) {
      showLifeAlert('알림', '인증번호를 확인하세요.', () => hideLifeAlert());
    } else {
      notiAuthVerify();
    }
  };

  const notiAuthVerify = async () => {
    const params = { id: getValues('id'), code: getValues('code') };
    const result = await notiAuthVerifyApi(params);
    if (result.code === '0000') setValue('verified', 'Y');
    showLifeAlert('알림', result.message, () => hideLifeAlert());
  };

  const resetPwd = async () => {
    const params = { email: getValues('email'), mobile: getValues('mobile'), send_type: 'S' };
    const result = await resetPwdApi(params);
    if (result.code === '0000') {
      showLifeAlert('알림', '입력하신 핸드폰으로 임시 비밀번호가 발급되었습니다.', () =>
        hideLifeAlert(),
      );
      reset();
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  return (
    <>
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          <div className="contents-width">
            {/* S : 회원가입 */}
            <div className="member-form">
              <h2 className="offscreen">FIND PASSWORD</h2>
              <h3 className="title">비밀번호 찾기</h3>
              <form>
                <fieldset>
                  <legend>비밀번호 찾기</legend>
                  {/* S : 인증 전 */}
                  <div className="ip-item is-error">
                    <label for="user-email" className="offscreen">
                      이메일 입력
                    </label>
                    <input
                      {...register('email')}
                      type="email"
                      id="user-email"
                      placeholder="이메일"
                      aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.email && <p className="message">{errors.email.message}</p>}
                  </div>
                  <div className="ip-wrap has-btn">
                    <div className="ip-item is-error">
                      <label for="user-mobile" className="offscreen">
                        휴대폰 번호 입력
                      </label>
                      <input
                        {...register('mobile')}
                        type="number"
                        id="user-mobile"
                        placeholder="휴대폰 번호"
                        aria-invalid={errors.mobile ? 'true' : 'false'}
                      />
                      <button
                        type="button"
                        className="btn btn-sm-gray"
                        onClick={handleNotiAuthSend}
                      >
                        인증번호 전송
                      </button>
                      {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                    </div>
                  </div>
                  {/* E : 인증 전 */}

                  {/* S : 인증 후 */}
                  <div className="ip-wrap ip-certify has-btn">
                    <div className="ip-wrap">
                      <div className="ip-item  is-error">
                        {/* 에러 메세지 출력시 class="is-error" 추가 */}
                        <label for="user-certify" className="offscreen">
                          인증번호 입력
                        </label>
                        <input
                          {...register('code')}
                          type="text"
                          id="user-certify"
                          placeholder="인증번호"
                          aria-invalid={errors.code ? 'true' : 'false'}
                        />
                        {/* <span className="timer">남은시간 : 2분 32초</span> */}
                        <button
                          type="button"
                          className="btn btn-sm-blue"
                          onClick={handleNotiAuthVerify}
                        >
                          인증코드 확인
                        </button>
                        {errors.code && <p className="message">{errors.code.message}</p>}
                      </div>
                    </div>
                  </div>
                  {/* E : 인증 후 */}

                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-blue"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      비밀번호 설정
                    </button>
                    {/* 필수값 생략 등일 때는 disabled 추가 */}
                  </div>
                </fieldset>
              </form>
            </div>
            {/* E : 회원가입 */}
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
    </>
  );
}

export default ResetPw;

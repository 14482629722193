import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';

import { modifyWreathApi, saveWreathExt1Api } from 'apis/wreath';

import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

function WreathCustomDialog({ isOpen, onClose, obituary, handleReload, data }) {
  const { showAlert, hideAlert } = useDialog();
  const { loginUser } = useLogin();

  const [values, setValues] = useState({ title: '', message: '' });
  const [isChecked, setchecked] = useState(false);
  const { id, title, message } = data || {};

  useEffect(() => {
    if (data) {
      setValues({
        title: data.title,
        message: data.message,
      });
    }
  }, [data]);

  const handleClose = () => {
    setchecked(false); // 체크박스 초기화
    setValues({ title: '', message: '' });
    onClose();
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  };

  const saveWreathExt1 = async params => {
    const result = await saveWreathExt1Api(params);
    if (result.code === '0000') {
      handleReload();
      setchecked(false); // 체크박스 초기화
      setValues({ title: '', message: '' });
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const modifyWreathExt1 = async params => {
    const result = await modifyWreathApi(params);
    console.log('modifyWreathExt1 : ', result);
    showAlert('알림', result.message, () => hideAlert());
    handleReload();
    setchecked(false); // 체크박스 초기화
    setValues({ title: '', message: '' });
  };

  const handleSubmit = () => {
    console.log('isChecked:', isChecked);
    let params = {};

    if (!values.title) {
      showAlert('알림', '회사명/모임명을 입력해주세요', () => hideAlert());
      return;
    }
    if (!values.message) {
      showAlert('알림', '남겨주실 문구를 입력해주세요', () => hideAlert());
      return;
    }
    if (!isChecked) {
      showAlert('알림', '동의 여부를 확인해 주세요', () => hideAlert());
      return;
    }

    params.id = id;
    params.title = values.title;
    params.title = values.title;
    params.message = values.message;
    params.memorial_id = obituary.memorial_id;
    params.register = loginUser.member_name;
    params.register_id = loginUser.member_id;

    if (id) {
      modifyWreathExt1(params);
    } else {
      saveWreathExt1(params);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <>
        <div className="wrap is-new" id="memorialCanvas">
          <div className="modal" id="modal-obituary-01" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">디지털 화환</h2>

                  <button
                    className="modal__close js-modal-close"
                    aria-label="Close modal"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    <i class="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  <div className="layer_cont_inner">
                    <div className="digital_select_area">
                      <div className="register">
                        <div className="input_area" style={{ marginTop: '20px' }}>
                          <input
                            type="text"
                            placeholder="회사명/모임명을 입력해주세요.(최대 10자)"
                            name="title"
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            maxLength={10}
                            value={values.title}
                          />
                        </div>
                        <div className="input_area" style={{ marginTop: '20px' }}>
                          <input
                            type="text"
                            placeholder="남겨주실 문구를 입력해주세요.(최대 20자)"
                            name="message"
                            maxlength="30"
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            maxLength={20}
                            value={values.message}
                          />
                        </div>
                        <div className="check_item" style={{ marginTop: '20px' }}>
                          <input
                            type="checkbox"
                            name="isCheck"
                            className="check agree_chk"
                            id="agree_chk2"
                            onChange={event => setchecked(event.target.checked)}
                          />
                          <label htmlFor="agree_chk2" className="text">
                            {' '}
                            작성한 내용을 확인하였으며, 작성된 정보와 함께 디지털 화환이 등록되는
                            것에 동의합니다.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn-blue"
                    onClick={handleSubmit}
                    data-micromodal-close
                  >
                    등록
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default WreathCustomDialog;

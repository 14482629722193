import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import { useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import parse from 'html-react-parser';

// import 'swiper/css';
// import 'swiper/css/navigation';

// import 'css/swiper.min.css';
// import 'css/swiper.custom.css';

import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import BucketlistGoaledDialog from '../BucketlistGoaledDialog';

import { toggleCanvasBltLikeApi } from 'apis/canvasBlt';
import useCommon from 'stores/common/hook';
import { getCanvasAlarmCountApi } from 'apis/canvas';

import MediaViewSwiperDialog from '../MediaViewSwiperDialog';

function StoryBucketlist({ canvasBlt }) {
  const navigate = useNavigate();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();
  const { setAlarmCnt } = useCommon();
  const targetRef = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isLike, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [goalendData, setGoalendData] = useState();

  const [openBucketlistGoaledDialog, setOpenBucketlistGoaledDialog] = useState(false);

  const [media, setMedia] = useState(null);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [itemIndex, setitemIndex] = useState(0);

  const toggleCanvasBltLike = async () => {
    if (!loginUser || !loginUser.member_id) {
      showLifeAlert('알림', '좋아요 기능은 로그인 후 이용가능합니다.', () => hideLifeAlert());
      return;
    }

    let params = {};
    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;

    const result = await toggleCanvasBltLikeApi(params);

    if (result.code === '0000') {
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
      setLikeCount(isLike ? likeCount - 1 : likeCount + 1);
      setLike(!isLike);
      const alarmCount = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      setAlarmCnt(alarmCount.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getGoalendData = data => {
    setGoalendData(data);
    setOpenBucketlistGoaledDialog(false);
  };

  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  useEffect(() => {
    setLike(canvasBlt.like_yn === 'Y' ? true : false);
    setLikeCount(canvasBlt.like_count ? canvasBlt.like_count : 0);
    setGoalendData(canvasBlt);
  }, [canvasBlt]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [targetRef.current]);

  return (
    <div className="canvas-post">
      <div className="canvas-inner">
        {/* S : 글 작성자 이름 */}
        <div className="canvas-head">
          <div className="user-info">
            <div
              className="user-thumb"
              onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
              style={{ cursor: 'pointer' }}
            >
              <span className="chk-size">
                <img alt="" src={canvasBlt.profile_link ? canvasBlt.profile_link : ''} />
              </span>
              {/*<span className="chk-size"><img alt="" src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg" /></span>*/}
            </div>
            <span
              className="name"
              onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
              style={{ cursor: 'pointer' }}
            >
              {canvasBlt.title}
            </span>
            <span className="date">{canvasBlt.modifydtime}</span>
          </div>

          <button
            type="button"
            className={`btn btn-sm ${canvasBlt.link_yn === 'Y' ? 'btn-lngray' : 'btn-lngreen'}`}
            onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
          >
            {canvasBlt.link_yn === 'Y' ? '구독 중' : '+ 구독'}
          </button>
        </div>
        {/* E : 글 작성자 정보 */}

        {/* S : 글 내용 */}

        <div className="canvas-content">
          {/* S : 분류 */}
          <div className="category">
            <i className="ic ic-cate-bucketlist"></i>버킷리스트
          </div>
          {/* E : 분류 */}

          {/* S : 이미지+글 */}

          {canvasBlt.media_files && canvasBlt.media_files.length > 0 && (
            <Swiper navigation={true} modules={[Navigation]} className="swiper canvas-swiper">
              <div className="swiper-wrapper">
                {canvasBlt.media_files.map((media, index) => (
                  <SwiperSlide
                    className={`swiper-slide chk-size ${
                      dimensions.width <= dimensions.height ? 'vertical' : ' '
                    }`}
                    key={index}
                  >
                    {media.media_type === 'V' ? (
                      <ReactPlayer
                        className="react-player"
                        url={media.media_link} // 플레이어 url
                        width="100%" // 플레이어 크기 (가로)
                        height="100%" // 플레이어 크기 (세로)
                        playing={false} // 자동 재생 on
                        muted={false} // 자동 재생 on
                        controls={true} // 플레이어 컨트롤 노출 여부
                        light={false} // 플레이어 모드
                        pip={true} // pip 모드 설정 여부
                      />
                    ) : (
                      <img
                        ref={targetRef}
                        alt=""
                        src={media.media_link}
                        onClick={() => handleMediaView(canvasBlt.media_files, index)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          )}

          <p>{parse(canvasBlt.blt_contents.replace(/(\n|\r\n)/g, '<br />'))}</p>
          {/* E : 이미지+글 */}

          {/* S : 버킷리스트 달성 상태 */}
          <div className="bucket-status">
            {/* S : 버킷리스트 달성 전 */}
            {goalendData?.goalstatus !== 'Y' && (
              <>
                <div className="bucket-date">
                  <span className="circle-blue">
                    <i className="ic ic-arrow-check"></i>
                  </span>
                  목표날짜 : {goalendData?.goaldtime}
                  {loginUser?.member_id === goalendData?.member_id &&
                    goalendData?.goalstatus === 'N' && (
                      <button
                        type="button"
                        className="btn btn-xs btn-lightBlue"
                        onClick={() => setOpenBucketlistGoaledDialog(true)}
                      >
                        달성 완료하기
                      </button>
                    )}
                </div>
              </>
            )}
            {/* E : 버킷리스트 달성 전 */}
            {/* S : 버킷리스트 달성 후 */}
            {goalendData?.goalstatus === 'Y' && (
              <>
                <div className="bucket-date">
                  <span className="circle-blue">
                    <i className="ic ic-arrow-check"></i>
                  </span>
                  달성완료 : {goalendData?.goalenddtime}
                </div>
                <span className="btn btn-xs btn-lnblue">달성소감 한마디</span>
                <p>{goalendData?.goalcomment}</p>
              </>
            )}
            {/* E : 버킷리스트 달성 후 */}
          </div>
          {/* E : 버킷리스트 달성 상태 */}
        </div>
        {/* E : 글 내용 */}
        {/* S : 댓글 목록 */}
        <div className="canvas-cmt">
          {/* S : 좋아요 버튼 + 갯수 */}
          <div className="canvas-like">
            <button type="button" onClick={() => toggleCanvasBltLike()}>
              <i className={`ic ${isLike ? 'ic-canvas-like-active' : 'ic-canvas-like'}`}></i>
            </button>
            <span>{likeCount} </span>
          </div>
          {/* E : 좋아요 버튼 + 갯수 */}
        </div>
      </div>
      {openBucketlistGoaledDialog && (
        <BucketlistGoaledDialog
          isOpen={openBucketlistGoaledDialog}
          canvasBlt={canvasBlt}
          getGoalendData={getGoalendData}
          onClose={() => setOpenBucketlistGoaledDialog(false)}
        />
      )}
      {openMediaViewDialog && (
        <MediaViewSwiperDialog
          isOpen={openMediaViewDialog}
          onClose={() => setOpenMediaViewDialog(false)}
          media={media}
          itemIndex={itemIndex}
        />
      )}
    </div>
  );
}

export default StoryBucketlist;

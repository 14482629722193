import { useNavigate } from 'react-router-dom';

function PremiumServicePage() {
  const navigate = useNavigate();

  const register = service => {
    navigate('/memorial/service/premium/register', { state: { service: service } });
  };

  return (
    <section className="container is-premium" id="containerWrap">
      {/* 조문보 프리미엄 서비스일 때 className="is-premium" */}
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>조문보 프리미엄</h3>

          <div className="box-title">
            <h4 className="title">[인터뷰 제작]</h4>
            <p className="desc">
              인터뷰와 질문지를 기반으로 작가가 <br className="d-none d-md-block" />
              조문보를 제작합니다.
            </p>
          </div>
          <div className="wrap-premium-list">
            <div className="box-premium is-basic">
              <span className="desc">Premium</span>
              <p className="title">프리미엄</p>
              <p className="price">60만원</p>
              <div className="detail">
                <dl>
                  <dt>작가</dt>
                  <dd>내부 작가</dd>
                </dl>
                <dl>
                  <dt>최대 분량</dt>
                  <dd>10 Page</dd>
                </dl>
                <dl>
                  <dt>작가 인터뷰</dt>
                  <dd>2시간(비대면, 1회)</dd>
                </dl>
                <dl>
                  <dt>원고 수정 / 제작 기간</dt>
                  <dd>2회 / 2달</dd>
                </dl>
                <dl>
                  <dt>동영상</dt>
                  <dd>
                    *3분 앨범 슬라이드형 영상
                    <br />
                    *5분 동영상 제작의뢰 가능하며
                    <br />
                    별도 비용 청구
                  </dd>
                </dl>
              </div>
              <div className="btn-wrap">
                <a href={() => false} onClick={() => register('001')} className="btn btn-pos">
                  상담 신청
                </a>
              </div>
            </div>
            <div className="box-premium is-gold">
              <span className="desc">Premium Gold</span>
              <p className="title">프리미엄 골드</p>
              <p className="price">200만원</p>
              <div className="detail">
                <dl>
                  <dt>작가</dt>
                  <dd>외부 전문 작가</dd>
                </dl>
                <dl>
                  <dt>최대 분량</dt>
                  <dd>20 Page</dd>
                </dl>
                <dl>
                  <dt>작가 인터뷰</dt>
                  <dd>2시간(비대면, 1회)</dd>
                </dl>
                <dl>
                  <dt>원고 수정 / 제작 기간</dt>
                  <dd>2회 / 2달</dd>
                </dl>
                <dl>
                  <dt>동영상</dt>
                  <dd>
                    *3분 앨범 슬라이드형 영상
                    <br />
                    *5분 동영상 제작의뢰 가능하며
                    <br />
                    별도 비용 청구
                  </dd>
                </dl>
              </div>
              <div className="btn-wrap">
                <a href={() => false} onClick={() => register('002')} className="btn btn-pos">
                  상담 신청
                </a>
              </div>
            </div>
            <div className="box-premium is-deluxe">
              <span className="desc">Premium Deluxe</span>
              <p className="title">프리미엄 디럭스</p>
              <p className="price">500만원 이상</p>
              <div className="detail">
                <dl>
                  <dt>작가</dt>
                  <dd>외부 전문 작가</dd>
                </dl>
                <dl>
                  <dt>최대 분량</dt>
                  <dd>50 Page</dd>
                </dl>
                <dl>
                  <dt>작가 인터뷰</dt>
                  <dd>8시간(대면, 2~4회)</dd>
                </dl>
                <dl>
                  <dt>원고 수정 / 제작 기간</dt>
                  <dd>4회 / 3달</dd>
                </dl>
                <dl>
                  <dt>동영상</dt>
                  <dd>
                    *3분 앨범 슬라이드형 영상
                    <br />
                    *5분 동영상 제작의뢰 가능하며
                    <br />
                    별도 비용 청구
                  </dd>
                </dl>
              </div>
              <div className="btn-wrap">
                <a href={() => false} onClick={() => register('003')} className="btn btn-pos">
                  상담 신청
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default PremiumServicePage;

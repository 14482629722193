import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import KakaoLogin from 'pages/life-canvas/login/components/KakaoLogin';
import NaverLogin from 'pages/life-canvas/login/components/NaverLogin';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import { modifyMemberApi } from '../../../apis/member';
import configs from 'configs/configs';

function LoginPage() {
  const loginSubmitRef = useRef();
  const navigate = useNavigate();
  const { loginUser, error, emailLogin, logout } = useLogin();
  const { showAlert, hideAlert } = useDialog();
  const [savedId, setSavedId] = useState(false);
  const [mode, setMode] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [authStatus, setAuthStatus] = useState(0);

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    member_pwd: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    email: '',
    member_pwd: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    console.log(JSON.stringify(data));
    if (savedId) window.localStorage.setItem('savedId', data.email);
    // navigate('/memorial');
    else window.localStorage.removeItem('savedId');
    //emailLogin(data);
    emailLogin(data);

    // if(result.data.status === '2'){
    //   alert('휴면처리상태');
    // }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      loginSubmitRef.current.click();
    }
  };

  const modifyMemorial = async memberId => {
    let params = {};
    params.member_id = memberId;
    params.status = '1';
    const result = await modifyMemberApi(params);
    showAlert(
      '휴면 해제 완료',
      '휴면 해제가 완료되었습니다.\n' + '재 로그인 후 이용해주세요.',
      () => hideAlert(),
    );
    handleLogout();
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleNotiAuthSend = () => {
    // if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
    //   showAlert('알림', '휴대폰 번호를 확인하세요.', () => hideAlert());
    // } else {
    //   notiAuthSend();
    // }
    window.open(
      'https://mylifecanvas.co.kr/apiserver/authVerify',
      'auth_popup',
      'width=430,height=640,scrollbars=yes',
    );
    hideAlert();
  };

  useEffect(() => {
    // if (window.innerWidth <= 1024) {
    //   setisMobile(true);
    // }

    // 본인인증 서비스
    window.addEventListener('message', function (e) {
      const result = e.data;
      // RSLT_CD: "B000"면 성공
      if (result.RSLT_CD === 'B000') {
        setAuthStatus(1);
        // setBirth(result.RSLT_BIRTHDAY);
        // setAuthCode(result.TX_SEQ_NO);
        // setName(result.RSLT_NAME);
        // setPhone(result.TEL_NO);
      }
      console.log(result);
    });

    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('message', function (e) {
        const result = e.data;
        // RSLT_CD: "B000"면 성공

        console.log(result);
      });
    };
  }, []);

  useEffect(() => {
    if (authStatus === 1) {
      modifyMemorial(loginUser.member_id);
    }
  }, [authStatus]);

  useEffect(() => {
    if (error) {
      showAlert('알림', error.message, () => hideAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    // 로그인 했을때 휴면상태인지 체크하기
    if (
      (loginUser?.member_id && loginUser?.status === '1') ||
      loginUser?.login_type === 'N' ||
      loginUser?.login_type === 'K'
    ) {
      const previous_path = localStorage.getItem('previous_path'); // 저장된 previous_path 확인
      if (previous_path) {
        navigate(previous_path); // 이전 경로로 이동
      } else {
        navigate('/'); // 기본 경로로 이동
      }
    } else if (loginUser?.status === '2') {
      window.localStorage.removeItem('savedId');
      showAlert(
        '알림',
        '만 1년동안 서비스를 사용하지 않아\n' +
          '개인정보 보호를 위해 휴면 처리되었습니다.\n' +
          '계정을 활성화 하기 위해서는\n' +
          '본인 인증이 필요합니다.',
        () => handleNotiAuthSend(),
      );
    } else if (loginUser?.status === '0') {
      window.localStorage.removeItem('savedId');
      showAlert('알림', '탈퇴한 계정입니다.', () => hideAlert());
    }
    //console.log(JSON.stringify(loginUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    const savedId = localStorage.getItem('savedId');
    if (savedId) {
      setSavedId(true);
      setValue('email', savedId);
    } else {
      setSavedId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">간편 로그인 </h2>
          </div>
        </div>
        <div className="contents-width">
          {/* S : 로그인 */}
          <div className="member-form">
            <h2 className="offscreen">LOGIN</h2>
            <h3 className="title">간편 로그인</h3>
            <ul class="login-btn-wrap">
              <KakaoLogin />
              <NaverLogin />
              <li>
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => {
                    setMode(!mode);
                  }}
                >
                  일반 로그인
                </button>
              </li>
            </ul>

            <div class={`${mode ? 'box-login' : ''}`}>
              <h3 class="title">일반 로그인</h3>
              <form>
                <fieldset>
                  <legend>로그인</legend>
                  <div className="ip-item is-error">
                    <label htmlFor="user-id" className="offscreen">
                      이메일주소 입력
                    </label>
                    <input
                      {...register('email')}
                      type="email"
                      id="user-id"
                      placeholder="이메일주소"
                    />
                    <p class="message">{errors.email?.message}</p>
                  </div>
                  <div className="ip-item is-error">
                    <label htmlFor="user-pw" className="offscreen">
                      비밀번호 입력
                    </label>
                    <input
                      {...register('member_pwd')}
                      type="password"
                      id="user-pw"
                      placeholder="비밀번호"
                      onKeyPress={onKeyPress}
                    />
                    <p class="message">{errors.member_pwd?.message}</p>
                  </div>

                  {/* <div className="rc-item">
                  <input
                    type="checkbox"
                    id="chk-save-id"
                    checked={savedId}
                    onClick={() => setSavedId(!savedId)}
                  />
                  <label htmlFor="chk-save-id">아이디 저장</label>
                </div> */}
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={handleSubmit(onSubmit, onError)}
                      ref={loginSubmitRef}
                    >
                      로그인
                    </button>
                  </div>
                  <ul className="login-util">
                    <li>
                      <a href={() => false} onClick={() => navigate('/memorial/login/register')}>
                        회원가입
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/memorial/login/findid')}>
                        아이디 찾기
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/memorial/login/findpw')}>
                        비밀번호 찾기
                      </a>
                    </li>
                  </ul>
                </fieldset>
              </form>
            </div>
          </div>
          {/* E : 로그인 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default LoginPage;

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getConsultBoardApi, modifyConsultBoardApi } from 'apis/consultBoard';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function AdminConsultingModifyPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { consultingTypeCodes, consultingWriteTypeCodes, consultingPremiumCodes } = useCode();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    type: Yup.string().required('필수 입력 항목입니다.'),
    req_name: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    req_phone: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    req_email: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    deceased_name: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    relation_desc: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    item_type: Yup.string().required('필수 입력 항목입니다.'),
    basic_contents: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(1000, '최대 입력은 1000자까지 가능합니다.'),
  }).required();

  const defaultValues = {
    type: '001',
    req_name: '',
    req_phone: '',
    req_email: '',
    member_name: '',
    deceased_name: '',
    relation_desc: '',
    basic_contents: '',
    consult_contents: '',
    createddatetime: '',
    item_type: '001',
    status: 1,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getConsultBoard = async params => {
    const result = await getConsultBoardApi(params);
    setConsultBoard(result.data);
  };

  const modifyConsultBoard = async params => {
    const result = await modifyConsultBoardApi(params);
    result.code === '0000'
      ? showAlert('알림', result.message, () => handleClose())
      : showAlert('알림', result.message, () => hideAlert());
  };

  const [consultBoard, setConsultBoard] = useState(null);

  const onSubmit = data => {
    modifyConsultBoard(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleClose = () => {
    hideAlert();
    navigate('/admin/consulting');
  };

  useEffect(() => {
    if (consultBoard) {
      Object.entries(consultBoard).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultBoard]);

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getConsultBoard(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>상담등록</h2>
      </div>

      <div className="content-box wrap-detail">
        {/* S : 정산정보 입력 */}
        <div className="content-detail">
          <h3>기본정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <colgroup>
                <col width="25%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="ipSample6">신청일자</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input
                        {...register('createddatetime')}
                        aria-invalid={errors.createddatetime ? 'true' : 'false'}
                        type="text"
                        id="ipSample6"
                        readOnly
                      />
                    </div>

                    {/* <div className="rc-item">
                      <input type="checkbox" id="chk-agree-term0" />
                      <label for="chk-agree-term0">
                        라이프캔버스 문답 기반 프리미엄 자서전 신청
                      </label>
                    </div> */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample7">계정정보</label>
                  </th>
                  <td className="input">
                    {/* S : 에러 케이스 */}
                    <div className="ip-item is-error">
                      <input
                        {...register('member_name')}
                        aria-invalid={errors.member_name ? 'true' : 'false'}
                        type="text"
                        id="ipSample7"
                        readOnly
                      />
                    </div>
                    {/* E : 에러 케이스 */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="selSample1">구분</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <select {...register('type')} title="선택" id="selSample1">
                        {consultingWriteTypeCodes && consultingWriteTypeCodes.length > 0
                          ? consultingWriteTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.type && <p className="message">{errors.type.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="selSample3">종류</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <select
                        {...register('item_type')}
                        aria-invalid={errors.item_type ? 'true' : 'false'}
                        title="선택"
                        id="selSample3"
                      >
                        {consultingPremiumCodes && consultingPremiumCodes.length > 0
                          ? consultingPremiumCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.item_type && <p className="message">{errors.item_type.message}</p>}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <label for="ipSample1">이름</label>
                  </th>
                  <td className="input">
                    {/* S : 에러 케이스 */}
                    <div className="ip-item is-error">
                      <input
                        {...register('req_name')}
                        aria-invalid={errors.req_name ? 'true' : 'false'}
                        type="text"
                        id="ipSample1"
                      />
                      {errors.req_name && <p className="message">{errors.req_name.message}</p>}
                    </div>
                    {/* E : 에러 케이스 */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample2">휴대폰번호</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        {...register('req_phone')}
                        aria-invalid={errors.req_phone ? 'true' : 'false'}
                        type="text"
                        id="ipSample2"
                      />
                      {errors.req_phone && <p className="message">{errors.req_phone.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample3">이메일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        {...register('req_email')}
                        aria-invalid={errors.req_email ? 'true' : 'false'}
                        type="text"
                        id="ipSample3"
                      />
                      {errors.req_email && <p className="message">{errors.req_email.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample4">주인공</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        {...register('deceased_name')}
                        aria-invalid={errors.deceased_name ? 'true' : 'false'}
                        type="text"
                        id="ipSample4"
                      />
                      {errors.deceased_name && (
                        <p className="message">{errors.deceased_name.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample5">주인공과의 관계</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        {...register('relation_desc')}
                        aria-invalid={errors.relation_desc ? 'true' : 'false'}
                        type="text"
                        id="ipSample5"
                      />
                      {errors.relation_desc && (
                        <p className="message">{errors.relation_desc.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample5">상담 내용</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <textarea
                        {...register('basic_contents')}
                        aria-invalid={errors.basic_contents ? 'true' : 'false'}
                        cols="30"
                        rows="10"
                        id="selSampleTxt1"
                      ></textarea>
                      {errors.basic_contents && (
                        <p className="message">{errors.basic_contents.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 정산정보 입력 */}

        {/* S : 디지털 화환 총 모금액 */}
        <div className="content-detail">
          <h3>상담 내용</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="selSample2">상담상태</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <select
                        {...register('status')}
                        aria-invalid={errors.status ? 'true' : 'false'}
                        title="선택"
                        id="selSample2"
                      >
                        {consultingTypeCodes && consultingTypeCodes.length > 0
                          ? consultingTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.status && <p className="message">{errors.status.message}</p>}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <label for="ipSample5">상담 내역 기록</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <textarea
                        {...register('consult_contents')}
                        aria-invalid={errors.consult_contents ? 'true' : 'false'}
                        cols="30"
                        rows="10"
                        id="selSampleTxt1"
                      ></textarea>
                      {errors.consult_contents && (
                        <p className="message">{errors.consult_contents.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 디지털 화환 총 모금액 */}
      </div>

      {/* S : 버튼 */}
      <div className="btn-wrap center">
        <button
          type="button"
          className="btn btn-lg btn-pos"
          onClick={handleSubmit(onSubmit, onError)}
        >
          저장
        </button>
        <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
          취소
        </button>
      </div>
      {/* E : 버튼 */}
    </div>
  );
}

export default AdminConsultingModifyPage;

import { useEffect } from 'react';
import useLayout from 'stores/layout/hook';

function PolicyPrivacyPage() {
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content_wrap bg_type">
      <div className="content_inner">
        <h2 className="content_title">
          개인정보<span className="block">처리방침</span>
        </h2>
        <div className="terms_content">
          <div className="terms_top">
            <p>
              주식회사 Mcircle (이하 “회사”)는 개인정보보호를 매우 중요시하며, 『정보통신망 이용촉진
              및 정보보호 등에 관한 법률』 상의 개인정보보호규정을 준수하고 있습니다. 회사는
              개인정보처리방침의 공개를 통하여 고객의 개인정보가 어떤 용도와 방식으로 이용되고
              있으며 개인정보를 효과적이고 적극적으로 보호하기 위해 항상 노력하고 있음에 대해
              알려드립니다. 본 개인정보처리방침은 관련법령의 개정이나 당사 내부방침에 의해 변경될 수
              있으며 개인정보처리방침을 개정하는 경우 홈페이지 공지사항 (또는 개별공지)을 통하여
              공지할 것입니다.
            </p>
            <div className="index_list flex_style">
              <div className="index">
                <ul>
                  <li>제1조(개인정보 처리 목적)</li>
                  <li>제2조(개인정보의 처리 및 보유기간)</li>
                  <li>제3조(개인정보의 제3자 제공)</li>
                  <li> 제4조(개인정보처리의 위탁)</li>
                  <li>제5조(정보주체와 법적대리인의 권리.의무 및 행사방법</li>
                  <li>제6조(처리하는 개인정보 항목)</li>
                  <li>제7조(개인정보의 파기)</li>
                </ul>
              </div>
              <div className="index right">
                <ul>
                  <li>제8조(개인정보의 안전성 확보조치)</li>
                  <li>제9조(개인정보 자동 수집 장치의 설치.운영 및 거부에 관한 사항)</li>
                  <li>제10조(개인정보 보호책임자)</li>
                  <li>제11조(개인정보 열람청구)</li>
                  <li>제12조(권익침해 구제방법)</li>
                  <li>제13조(개인정보 처리방침 변경)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="terms">
            <section>
              <h2 className="terms_tit">제1조(개인정보의 처리 목적)</h2>
              <p className="terms_txt">
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
                목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 ‘개인정보 보호법
                제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </p>
              <ul className="terms_list type1">
                <li>
                  1. 홈페이지 회원가입 및 관리
                  <br />
                  회원 가입의사 확인, 회원 서비스 제공에 따른 본인 인증, 회원 자격 유지.관리, 서비스
                  부정이용 방지, 각종 고지통지, 고충처리 목적으로 개인정보를 처리합니다.{' '}
                </li>
                <li>
                  2. 부고 및 추모관 서비스
                  <br />
                  회원에게 부고 및 추모관 서비스를 제공하기 위한 목적으로 개인정보를 처리합니다.
                </li>
                <li>
                  3. 조문보 프리미엄 서비스 <br />
                  회원이 조문보 프리미엄 서비스를 이용하기 원하여 게시판 또는 전화 등으로 신청했을
                  경우에 개인정보를 처리합니다.
                </li>
                <li>
                  4. 재화 또는 서비스 제공
                  <br />
                  회원이 디지털화환 구매, 조문보 프리미엄 서비스 제공, 컨텐츠 제공 등을 목적으로
                  개인정보를 처리합니다.{' '}
                </li>
                <li>
                  5. 고충처리
                  <br />
                  민원인의 신원 확인, 민원사항 확인, 사실 조사를 위한 연락통지, 처리결과 통보의
                  목적으로 개인정보를 처리합니다.
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제2조(개인정보의 처리 및 보유기간)</h2>
              <p className="terms_txt">
                회사는 회원의 서비스 가입일로부터 회원께 서비스를 제공하는 기간 동안에 한하여 회원의
                개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에
                대한 동의를 철회하는 경우, 수집/이용목적을 달성하거나 보유/이용기간이 종료한 경우,
                사업폐지 등의 사유발생 시 당해 개인정보를 지체 없이 파기합니다. 단,
                전자상거래등에서의 소비자 보호에 관한 법률 등 관련법령에 의해 보존할 의무가 있는
                경우에는 법령에서 정한 최소한의 기간 동안 별도 보관되며, 보관목적으로만 이용합니다.
                회원이 보관기간을 별도 요청하는 경우 그 기간만큼(다만 법령의 최소보존 기간 미만으로
                지정할 수 없습니다.) 보관할 수 있습니다.
              </p>
              <ul className="terms_list type2">
                <li>(1) 계약 또는 청약철회 등에 관한 기록 : 5년</li>
                <li>(2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
                <li>(3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                <li>(4) 표시 광고에 관한 기록 : 6개월</li>
                <li>
                  (5) 회원 관련 기록 : 탈퇴 시 까지(다만, 다음의 사유에 해당하는 경우에는 해당 사유
                  종료 시 까지)
                  <br />
                  A.관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료
                  시까지
                  <br />
                  B.서비스 이용에 따른 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지
                </li>
                <li>(6) 서비스 이용기록, 접속 로그, 접속 IP 정보 : 3개월</li>
                <li>(7) 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년</li>
                <li>(8) 부정사용 방지를 위한 회사 내부 방침으로 부정사용 기록 : 10년</li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제3조(개인정보의 제3자 제공)</h2>
              <p className="terms_txt">
                회사는 회원의 사전 동의 없이는 회원의 개인정보를 사전에 밝힌 목적 이외로 이용하거나
                제 3자에게 공개하지 않습니다
              </p>
            </section>
            <section>
              <h2 className="terms_tit">제4조(개인정보처리의 위탁)</h2>
              <ul className="terms_list type1">
                <li>
                  ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
                  위탁하고 있습니다.
                  <ul className="terms_list type3 mar_14">
                    <li>
                      {' '}
                      1. 조문보 프리미엄 서비스 운영
                      <br />- 위탁받는자 : 에버영 코리아
                      <br />- 위탁하는 업무의 내용 : 고객의 조문보 및 동영상 제작, 고객 상담 응대 등
                    </li>
                    <li>
                      2. 디지털화환 결제 시스템
                      <br />- 위탁받는자 : KICC
                      <br />- 위탁하는 업무의 내용 : 결제(신용카드 및 무통장입금, 간편결제),
                      삼성페이 결제 서비스 대행, 카카오페이 결제 서비스 대행, 네이버페이 결제 서비스
                      대행
                    </li>
                    <li>
                      {' '}
                      3. 알림톡, 문자 발송
                      <br />- 위탁받는자 : LGU+
                      <br />- 위탁하는 업무의 내용 : 기일알림 서비스 제공
                    </li>
                  </ul>
                </li>
                <li>
                  ② 회사는 위탁계약 체결 시, ‘개인정보 보호법 제26조에 따라 위탁업무 수행목적 외
                  개인정보 처리금지, 기술적.관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리.감독,
                  손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고 수탁자가 개인정보를
                  안전하게 처리하는지를 감독하고 있습니다.
                </li>
                <li>
                  ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을
                  통하여 공개하도록 하겠습니다.
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제5조(정보주체와 법적대리인의 권리.의무 및 행사방법)</h2>
              <ul className="terms_list type1">
                <li>
                  ① 정보주체는 회사에 대해 언제든지 개인정보 열람.정정.삭제.처리정지 요구 등의
                  권리를 행사할 수 있습니다.
                </li>
                <li>
                  ② 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라
                  서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이
                  조치하겠습니다.
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제6조(처리하는 개인정보 항목)</h2>
              <p className="terms_txt">회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
              <ul className="terms_list type3">
                <li>
                  {' '}
                  1. 홈페이지 회원가입 및 관리
                  <br /> - 필수 : 이름, 이메일(ID), 휴대폰번호
                </li>
                <li>
                  2. 부고 및 추모관 서비스
                  <br /> - 필수 : 이름, 생년월일
                  <br />- 선택 : 은행, 계좌번호, 휴대폰 번호, 카카오페이송금 링크
                </li>
                <li>
                  {' '}
                  3. 조문보 프리미엄 서비스
                  <br />- 필수 : 이름, 연락처
                  <br />- 선택 :
                </li>
                <li>
                  {' '}
                  4. 재화 또는 서비스 제공
                  <br />- 필수 : 구매자이름, 연락처, 이메일
                  <br />- 선택 : 단체명
                </li>
                <li>
                  {' '}
                  5. 고충처리
                  <br />- 필수 : 이름, 전화번호, 이메일
                  <br />- 선택 : 문의내용
                </li>
                <li>
                  6. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수
                  있습니다.
                  <br />- IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제7조(개인정보의 파기)</h2>
              <p className="terms_txt">
                회원가입 등을 통한 개인정보의 수집, 이용, 제공에 대하여 회원은 가입 시 동의한 내용에
                대해서 언제든지 철회할 수 있습니다. 동의 철회는 당사의 서비스에 로그인 후,
                "회원정보&gt;내정보 관리&gt;회원탈퇴"에서 언제든지 철회할 수 있으며, 개인정보
                민원담당자에게 서면, 전화, 이메일 등으로 연락하시면 신속하게 개인정보의 삭제 등
                필요한 조치를 합니다. 이미 제공된 회원정보를 철회 하는데는 일정 시간이 소요되며
                소요되는 시간 동안 마케팅에 활용되지 않도록 하겠습니다. 회사는 회원님의 소중한
                정보를 보호하기 위하여 신속하게 처리되도록 최선의 노력을 다하겠습니다.
              </p>
              <ul className="terms_list type1">
                <li>
                  ① 파기절차 및 시점
                  <br />
                  고객이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성되고
                  나면 내부 방침과 기타 관련 법령의 정보보호 사유(위 개인정보의 보유 및 이용기간
                  참조)에 따른 보유 기간이 지난 후에 삭제되거나 파기합니다. 일반적으로 채권-채무
                  관계가 남아 있지 않으면 회원가입때 수집되어 전자적 파일형태로 관리하는 개인정보는
                  회원 탈퇴 시점에 바로 삭제 됩니다.
                </li>
                <li>
                  ② 파기방법
                  <br />
                  종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하고 전자적 파일형태로 저장된
                  개인정보는 기록을 복구·재생할 수 없는 기술적 방법을 사용하여 삭제 합니다.
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제8조(개인정보의 안전성 확보조치)</h2>
              <p className="terms_txt">
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
              </p>
              <ul className="terms_list type1">
                <li>
                  ① 관리적 조치 :
                  <br />
                  임직원에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고 있습니다.
                  <ul className="terms_list type2 mar_14">
                    <li>
                      -개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한
                      정기적인 교육과 캠페인을 실시하고 있습니다.
                    </li>
                    <li>
                      -이용자 정보의 보호 활동 및 체계에 대해 국내 및 국제 인증 규격을 충족하고
                      있습니다.
                    </li>
                    <li>
                      -정보보호 및 개인정보보호 관리 체계에 대해 국내 및 국제 인증 심사 규격 대비
                      충족 여부를 매년 독립된 심사 기관으로부터 검증을 받고 개선의 기회를 마련하고
                      있습니다.
                    </li>
                  </ul>
                </li>
                <li>
                  ② 기술적 조치 :
                  <br />
                  이용자의 개인정보를 암호화하고 있습니다.
                  <ul className="terms_list type2 mar_14">
                    <li>
                      -이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등
                      중요정보는 암호화하여 보관하고 있습니다.
                    </li>
                    <li>-해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.</li>
                    <li>
                      -해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는
                      것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다.
                      해커 등의 침입을 탐지하고 차단할 수 있는 시스템 을 설치하여 24시간 감시하고
                      있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지
                      않도록 노력하고 있습니다. 또한 새로운 해킹/ 보안 기술에 대해 지속적으로
                      연구하여 서비스에 적용하고 있습니다.
                    </li>
                    <li>-소중한 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.</li>
                    <li>
                      -개인정보를 처리하는 직원을 최소화 하며, 업무용 PC에서는 외부 인터넷 서비스를
                      사용할 수 없도록 차단 하여 개인정보 유출에 대한 위험을 줄이고 있습니다.{' '}
                    </li>
                    <li>
                      -개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한
                      비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을
                      마련하고 지속적인 감사를 실시하고 있습니다.
                    </li>
                  </ul>
                </li>
                <li>
                  ② 물리적 조치 :
                  <br />
                  전산실, 자료보관실 등의 접근 통제
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">
                제9조(개인정보 자동 수집 장치의 설치.운영 및 거부에 관한 사항)
              </h2>
              <p className="terms_txt">
                PC 기반 서비스의 제공을 위하여 쿠키를 이용하는 경우가 있습니다. 쿠키는 보다 빠르고
                편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.
              </p>
              <ul className="terms_list type1">
                <li>
                  ① 쿠키란?
                  <br />
                  웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트
                  파일로서 이용자 컴퓨터에 저장됩니다
                </li>
                <li>
                  ② 쿠키 수집 거부
                  <br />
                  쿠키에는 이름, 전화번호 등 개인을 식별하는 정보를 저장하지 않으며, 이용자는 쿠키
                  설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을
                  설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든
                  쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이
                  불편해지며, 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
                </li>
                <li>
                  {' '}
                  ③ 설정 방법의 예<br />
                  <div className="mar_14">
                    1) Internet Explorer의 경우
                    <br />웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정
                    <br />
                    2) Chrome의 경우
                    <br />웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt;
                    개인정보의 콘텐츠 설정 버튼 &gt; 쿠키
                  </div>
                </li>
              </ul>
            </section>
            <section>
              <h2 className="terms_tit">제10조(개인정보 보호책임자)</h2>
              <p className="terms_txt">
                개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
                불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
                있습니다.
              </p>
              <dl>
                <dt>· 개인정보 보호책임자</dt>
                <dd>성명 : </dd>
                <dd>직책 : </dd>
                <dd>연락처 : &lt;회사번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt;</dd>
              </dl>
              <dl>
                <dt>· 개인정보 보호 담당부서</dt>
                <dd>부서명 : OOO팀</dd>
                <dd> 담당자 : OOO </dd>
                <dd>연락처 : &lt;회사번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt;</dd>
              </dl>
              <p className="terms_txt">
                정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
                불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자로 문의하실 수 있습니다.
              </p>
            </section>
            <section>
              <h2 className="terms_tit">제11조(개인정보 열람청구)</h2>
              <p className="terms_txt">
                정보주체는 ‘개인정보 보호법 제35조에 따른 개인정보의 열람청구를 아래의 부서에 할 수
                있습니다.
                <br />
                회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다
              </p>
              <dl>
                <dt>· 개인정보 열람청구 접수,처리 부서</dt>
                <dd>부서명 : OOO</dd>
                <dd>담당자 : OOO </dd>
                <dd>연락처 : &lt;회사번호&gt;, &lt;이메일&gt;, &lt;팩스번호&gt;</dd>
              </dl>
            </section>
            <section>
              <h2 className="terms_tit">제12조(권익침해 구제방법)</h2>
              <p className="terms_txt">
                기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터,
                개인정보분쟁조정위원회, 대검찰청 사이버수사과, 경찰청 사이버안전국 등으로 문의하실
                수 있습니다.
              </p>
              * 개인정보침해신고센터
              <br />
              - URL : https://privacy.kisa.or.kr
              <br />
              * 개인정보분쟁조정위원회
              <br />
              - 전화 : 1833-6972
              <br />
              - URL : https://www.kopico.go.kr
              <br />
              * 경찰청 사이버안전국
              <br />
              - 전화 : (국번없이) 182
              <br />
              - URL : https://cyberbureau.police.go.kr
              <br />
              * 대검찰청 사이버수사과
              <br />
              - 전화 : (국번없이) 1301
              <br />- URL : http://www.spo.go.kr
            </section>
            <section>
              <h2 className="terms_tit">제12조(권익침해 구제방법)</h2>
              <p className="terms_txt">
                현 개인정보처리방침은 2022년 00월 00일부터 적용됩니다. 내용의 추가, 삭제 및 수정이
                있을 경우에는 시행일의 7일 전부터 웹사이트의 공지사항을 통하여 고지할 것이며 중요한
                사항일 경우 시행일 30일전부터 웹사이트의 공지사항을 통한 고지 및 이메일 고지를 할
                것입니다. 단, 개인정보 수집 및 활용, 제3자 제공 등이 변경될 경우 별도의 동의를
                받겠습니다. 또한 개인정보 처리방침에 버전번호 및 변경공고일자 및 그 시행일자 등을
                부여하여 개정여부를 쉽게 알 수 있도록 하고 있습니다.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyPrivacyPage;

function FuneralRegisterPage() {
  return (
    <section className="container is-premium" id="containerWrap">
      {/* 조문보 프리미엄 서비스일 때 class="is-premium" */}
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>신청하기</h3>

          <div className="wrap-premium-form">
            <div className="premium-form">
              <div className="premium-form-item">
                <div className="ip-item">
                  <label htmlFor="ip-name">신청자 이름</label>
                  <input type="text" id="ip-name" placeholder="이름을 입력하세요" />
                </div>
                <div className="ip-item">
                  <label htmlFor="ip-mobile">전화번호</label>
                  <input type="text" id="ip-mobile" placeholder="휴대폰번호* ex. 01011111111" />
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item">
                  <label htmlFor="ip-email">이메일</label>
                  <input type="text" id="ip-email" placeholder="sample@naver.com" />
                </div>
                <div className="ip-item">
                  <label htmlFor="ip-name2">반려동물 이름</label>
                  <input type="text" id="ip-name2" placeholder="반려동물 이름을 입력하세요" />
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item">
                  <label htmlFor="ip-chief-mourner-relation">반려동물과의 관계</label>
                  <input
                    type="text"
                    id="ip-chief-mourner-relation"
                    placeholder="반려동물과의 관계를 입력하세요"
                  />
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item">
                  <label htmlFor="textarea-01">상담내용</label>
                  <textarea
                    id="textarea-01"
                    rows="5"
                    cols="3"
                    placeholder="상담 받고싶은 내용을 입력하세요"
                  ></textarea>
                </div>
              </div>

              {/* S : 약관동의 */}
              <div className="premium-form-rc-item">
                <div className="rc-item is-error">
                  <input type="checkbox" id="chk-agree-term1" />
                  <label htmlFor="chk-agree-term1">
                    <span>(필수)</span> 이용약관 동의
                  </label>
                  <a href={() => false} className="">
                    보기
                  </a>
                  <p className="message">* 필수 체크 항목입니다.</p>
                </div>
                <div className="rc-item">
                  <input type="checkbox" id="chk-agree-term2" />
                  <label htmlFor="chk-agree-term2">
                    <span>(필수)</span> 개인정보 수집 및 이용동의
                  </label>
                  <a href={() => false}>보기</a>
                </div>
              </div>
              {/* E : 약관동의 */}

              <div className="btn-wrap">
                <button type="button" className="btn btn-pos">
                  상담 신청
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default FuneralRegisterPage;

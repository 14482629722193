import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveCommentCategoryApi, getCommentCategoryApi } from 'apis/comment';

import useDialog from 'stores/dialog/hook';
import 'css/popup.css';
import { useEffect, useState } from 'react';
import RegisterCommentCategoryDialog from './register';

function SaveCommentCategoryDialog({
  isOpen,
  onClose,
  memorialId,
  memberId,
  callBack,
  handleChangeSelectedCategory,
}) {
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    category_name: Yup.string()
      .max(10, '* 추모 모임 이름은 최대 10글자까지 입력이 가능합니다.')
      .required('* 추모 모임 이름을 입력해 주세요.'),
    category_desc: Yup.string().max(15, '* 설명글은 최대 15글자까지 입력이 가능합니다.'),
    blt_password: Yup.string().test('required', '* 필수 입력 항목입니다.', val => {
      if (!displayYn) {
        return val.length > 0;
      } else {
        return true;
      }
    }),
  }).required();

  const defaultValues = {
    memorial_id: memorialId,
    create_member_id: memberId,
    category_name: '',
    display_yn: 'Y',
    category_desc: '',
    blt_password: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [categoryName, setCategoryname] = useState('');
  const [categoryText, setCategoryText] = useState('');
  const [displayYn, setDisplayYn] = useState(true);
  const [bltPassword, setBltPassword] = useState('');
  const [showPswd, setShowPswd] = useState(false);
  const [commentCategory, setCommentCategory] = useState('');
  const [currentLastUrl, setCurrentLastUrl] = useState('');
  const [openRegisterCommentCategoryDialog, setOpenRegisterCommentCategoryDialog] = useState(false);
  const [category, setCategory] = useState([]);
  const saveCommentCategory = async params => {
    const result = await saveCommentCategoryApi(params);
    getCommentCategoryShare();

    // handleClose();

    // showAlert('알림', result.message, () => handleClose());
  };

  const handleChangeCategoryName = event => {
    setCategoryname(event.target.value);
  };

  const handleChangeCategoryText = event => {
    setCategoryText(event.target.value);
  };

  const handleChangeDisplayYn = () => {
    setDisplayYn(!displayYn);
  };

  const handleChangePassword = event => {
    setBltPassword(event.target.value);
  };

  const handleShowPswd = event => {
    if (event.target.checked === true) {
      setShowPswd(true);
    } else {
      setShowPswd(false);
    }
  };

  const handleClose = () => {
    reset();
    callBack(1);
    handleChangeSelectedCategory({ category_id: '' });
    hideAlert();
    setDisplayYn(true);
    onClose();
    clearErrors();
  };

  const handleCancel = () => {
    reset();
    clearErrors();
    onClose();
    setDisplayYn(true);
  };

  const registerClose = () => {
    setOpenRegisterCommentCategoryDialog(false);
    handleClose();
  };

  const onSubmit = data => {
    saveCommentCategory(data);
  };

  const getCommentCategoryShare = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;

    const result = await getCommentCategoryApi(params);
    if (result.code === '0000') {
      setCommentCategory(result.data);
      setCurrentLastUrl(result.data[result.data_cnt]);
      setOpenRegisterCommentCategoryDialog(true);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  useEffect(() => {
    setValue('category_name', categoryName);
  }, [categoryName]);

  useEffect(() => {
    setValue('category_desc', categoryText);
  }, [categoryText]);

  useEffect(() => {
    setValue('display_yn', displayYn ? 'Y' : 'N');
  }, [displayYn]);

  useEffect(() => {
    setValue('blt_password', bltPassword);
  }, [bltPassword]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-meeting-open" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모 모임 등록</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={handleCancel}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-meeting-open1">추모 모임 이름</label>
                    <input
                      type="text"
                      id="ip-meeting-open1"
                      placeholder="이름을 작성하세요(최대 10자)"
                      onChange={handleChangeCategoryName}
                      maxlength="10"
                      {...register('category_name')}
                    />
                    {errors.category_name && (
                      <p className="message">{errors.category_name.message}</p>
                    )}
                  </div>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-meeting-open2">설명글</label>
                    <input
                      type="text"
                      id="ip-meeting-open2"
                      placeholder="모임을 설명하세요(최대 15자)"
                      maxLength="15"
                      onChange={handleChangeCategoryText}
                      {...register('category_desc')}
                    />
                    {errors.category_desc && (
                      <p className="message">{errors.category_desc.message}</p>
                    )}
                  </div>
                </div>
                <div className="ip-wrap">
                  <div className={!displayYn ? 'ip-item is-error' : 'offscreen'}>
                    <label htmlFor="ip-meeting-open3">비밀번호 설정</label>
                    <input
                      type={showPswd ? 'text' : 'password'}
                      id="ip-meeting-open3"
                      placeholder="비밀번호를 입력하세요"
                      onChange={handleChangePassword}
                      {...register('blt_password')}
                    />

                    <div className="rc-item">
                      <input type="checkbox" id="chk-agree1" onClick={handleShowPswd} />
                      <label htmlFor="chk-agree1">비밀번호 보기</label>
                    </div>
                  </div>
                </div>

                {/* S : 공개여부 */}
                <div className="ip-wrap">
                  <div className="rc-item rc-switch">
                    <input
                      type="checkbox"
                      id="switch1"
                      switch="none"
                      onClick={handleChangeDisplayYn}
                      defaultChecked={displayYn}
                    />
                    <label htmlFor="switch1" data-on-label="공개" data-off-label="비공개"></label>
                  </div>
                </div>
                {/* E : 공개여부 */}
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleSubmit(onSubmit, onError)}
                  data-micromodal-close
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={handleCancel}
                  data-micromodal-close
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegisterCommentCategoryDialog
        isOpen={openRegisterCommentCategoryDialog}
        onClose={() => registerClose()}
        memorialId={memorialId}
        currentLastUrl={currentLastUrl}
      />
    </Dialog>
  );
}

export default SaveCommentCategoryDialog;

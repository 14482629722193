const { createSlice, createSelector } = require('@reduxjs/toolkit');

const initialState = {
  alarmCnt: 0,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAlarmState: (state, action) => {
      state.alarmCnt = action.payload;
    },
  },
});

const { reducer, actions } = commonSlice;

export const { setAlarmState } = actions;

export const commonState = state => {
  return state.common;
};

export const alarmCntState = createSelector(commonState, state => {
  return state.alarmCnt;
});

export default reducer;

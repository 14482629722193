import { useEffect, forwardRef, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import OpenJoinRequestDialog from '../OpenJoinRequestDialog';

import { getCanvasJoinListApi, modifyCanvasJoinApi, saveCanvasJoinApi } from 'apis/canvas';

function OpenJoinDialog({ isOpen, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const searchRef = useRef();
  const navigate = useNavigate();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();

  const [requests, setRequests] = useState();
  const [requestId, setRequestId] = useState();
  const [openJoinRequestDialog, setOpenJoinRequestDialog] = useState(false);

  const [searchWord, setSearchWord] = useState();
  const [page, setPage] = useState(1);
  const [listSize] = useState(3);
  const [totalPage, setTotalPage] = useState(0);

  const handleCanvasJoinModify = id => {
    showLifeAlert('연결 해제', '정말 연결을 해제하시겠습니까?', () => CanvasJoinModify(id));
  };

  const getCanvasJoinListAll = async pageNum => {
    let params = {};
    params.orderby = { direction: 'desc', field: 'join_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.canvas_id_my = loginUser.canvas_id;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasJoinListApi(params);
    //console.log('확인팝업' + JSON.stringify(result));
    if (result.code === '0000') {
      setRequests(result.data);
      setPage(1);

      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasJoinListAllMore = async pageNum => {
    let params = {};
    params.orderby = { direction: 'desc', field: 'join_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.canvas_id_my = loginUser.canvas_id;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasJoinListApi(params);
    //alert('확인팝업' + JSON.stringify(result));
    if (result.code === '0000') {
      setRequests([...requests, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const CanvasJoinModify = async id => {
    let params = {};
    params.join_id = id;
    params.status = 'R';
    //alert(JSON.stringify(params));
    const result = await modifyCanvasJoinApi(params);

    if (result.code === '0000') {
      getCanvasJoinListAll(1);
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  // 요청취소
  const saveCanvasJoin = async (canvasId, relation) => {
    let params = {};

    //params = data;

    params.action_type = '2';
    params.canvas_id_my = loginUser.canvas_id;
    params.canvas_id = canvasId;
    params.relation = relation;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;
    //console.log('dd' + JSON.stringify(params));

    const result = await saveCanvasJoinApi(params);

    if (result.code === '0000') {
      getCanvasJoinListAll(1);
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  //   const changeSubscribeList = async =>{
  // let params={};
  // params.orderby = {direction: 'desc', field:'join_id'};
  // if(searchWord) params.searchWord = searchWord;
  // if (loginUser?.member_id) params.member_id = loginUser.member_id;

  //   };
  // useEffect(() => {
  //   getCanvasJoinList();
  // }, [requestId]);

  const reflash = () => {
    // 팝업 닫고 리스트 다시 불러오고
    setOpenJoinRequestDialog(false);
    getCanvasJoinListAll(1);
  };

  const handleSearch = () => {
    getCanvasJoinListAll(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasJoinListAllMore(nextPage);
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  useEffect(() => {
    init();
    //setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    getCanvasJoinListAll(1);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      //TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        {/* S : 연결 요청 팝업 */}
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-connect-req-01" aria-hidden="true">
            <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">연결 요청하기</h2>
                  <p className="modal__desc">구독자 목록에서만 연결 요청 할 수 있습니다.</p>
                  <button
                    className="modal__close js-modal-close"
                    aria-label="Close modal"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>

                <div className="modal__content">
                  {/* S : 검색 */}
                  <div className="box-search">
                    <label for="ip-search" className="offscreen">
                      검색하기
                    </label>
                    <input
                      type="search"
                      id="ip-search"
                      placeholder="구독자 목록에서 검색하기"
                      value={searchWord}
                      onChange={e => setSearchWord(e.target.value)}
                      onKeyPress={onKeyPress}
                    />
                    <button ref={searchRef} type="button" onClick={() => handleSearch()}>
                      <i className="ic ic-search"></i>
                      <span className="offscreen">검색하기</span>
                    </button>
                  </div>
                  {/* E : 검색 */}
                  {/* S : 검색결과가 있을 때 */}
                  {requests && requests.length > 0 ? (
                    requests.map(request => (
                      <ul className="connect-user-list">
                        <li>
                          <div
                            className="name"
                            onClick={() => navigate(`/life/canvas/user/${request.canvas_id}`)}
                            style={{ cursor: 'pointer' }}
                          >
                            {request.response_name}
                          </div>
                          <div className="birth">{request.response_email}</div>
                          <div className="btn-wrap">
                            {request.status !== 'W' ? (
                              <button
                                type="button"
                                className="btn btn-xs btn-lngray02 btn-req-connect"
                                data-micromodal-trigger="modal-connect-req-02"
                                onClick={() => {
                                  setOpenJoinRequestDialog(true);
                                  setRequestId(request);
                                }}
                              >
                                요청
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-xs btn-lngray02"
                                onClick={() => saveCanvasJoin(request.canvas_id, request.relation)}
                              >
                                요청취소
                              </button>
                            )}
                          </div>
                        </li>
                      </ul>
                    ))
                  ) : (
                    <ul className="connect-user-list">
                      <li className="no-data">검색결과가 없습니다.</li>
                    </ul>
                  )}
                </div>
                {/* E : 검색결과가 없을 때 */}

                <div className="modal__footer">
                  {requests && requests.length > 0 ? (
                    <button type="button" class="btn btn-more" onClick={() => handleMore(page)}>
                      더보기 {page}/{totalPage}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      확인
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openJoinRequestDialog && (
          <OpenJoinRequestDialog
            isOpen={openJoinRequestDialog}
            onClose={() => setOpenJoinRequestDialog(false)}
            requestId={requestId}
            reflash={reflash}
          />
        )}
        {/* E : 연결 요청 팝업 */}
      </>
    </Dialog>
  );
}

export default OpenJoinDialog;

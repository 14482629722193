import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  alert: {
    isOpen: false,
    message: null,
    callback: null,
  },
  confirm: {
    isOpen: false,
    message: null,
    confirmCallback: null,
    cancelCallback: null,
  },
  lifeAlert: {
    isOpen: false,
    message: null,
    callback: null,
  },
  lifeConfirm: {
    isOpen: false,
    message: null,
    confirmCallback: null,
    cancelCallback: null,
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setConfirm: (state, action) => {
      state.confirm = action.payload;
    },
    setLifeAlert: (state, action) => {
      state.lifeAlert = action.payload;
    },
    setLifeConfirm: (state, action) => {
      state.lifeConfirm = action.payload;
    },
  },
});

const { reducer, actions } = dialogSlice;

export const { setAlert, setConfirm, setLifeAlert, setLifeConfirm } = actions;

export const dialogState = state => {
  return state.dialog;
};

export const alertState = createSelector(dialogState, state => {
  return state.alert;
});

export const confirmState = createSelector(dialogState, state => {
  return state.confirm;
});

export const lifeAlertState = createSelector(dialogState, state => {
  return state.lifeAlert;
});

export const lifeConfirmState = createSelector(dialogState, state => {
  return state.lifeConfirm;
});

export default reducer;

import { useState, useEffect } from 'react';
import useLayout from 'stores/layout/hook';
import useCode from 'stores/code/hook';

import useDialog from 'stores/dialog/hook';

import { getFaqBoardsApi } from 'apis/faqBoard';

import Faq from 'pages/pet-memorial-canvas/faq/components/Faq';

function FaqPage() {
  const { faqPetBltTypeCodes } = useCode();
  const { setGnbType } = useLayout();
  const [faqs, setFaqs] = useState([]);
  const [code, setCode] = useState([]);

  const [page, setPage] = useState(1);
  const [listSize] = useState(4);
  const [totalPage, setTotalPage] = useState(0);

  const [selectedFaqBltTypeCode, setSelectedFaqBltTypeCode] = useState('');
  const { showAlert, hideAlert } = useDialog();

  const handleMore = (pageNum, code) => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      changeFaqBltTypeCodeMore(code, nextPage);
    }
  };

  // const getFaqBoards = async params => {
  //   const result = await getFaqBoardsApi(params);
  //   setFaqs(result.data);
  // };

  const changeFaqBltTypeCode = async (faqBltTypeCode, pageNum) => {
    let params = {};
    params.canvas_type = 'P';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };
    params.page = pageNum;
    params.itemInPage = listSize;

    if (faqBltTypeCode && faqBltTypeCode !== '') params.blt_type = faqBltTypeCode;

    setSelectedFaqBltTypeCode(faqBltTypeCode);

    const result = await getFaqBoardsApi(params);
    if (result.code === '0000') {
      setFaqs(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const changeFaqBltTypeCodeMore = async (faqBltTypeCode, pageNum) => {
    let params = {};
    params.canvas_type = 'P';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (faqBltTypeCode && faqBltTypeCode !== '') params.blt_type = faqBltTypeCode;

    setSelectedFaqBltTypeCode(faqBltTypeCode);

    const result = await getFaqBoardsApi(params);
    if (result.code === '0000') {
      setFaqs([...faqs, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const init = async () => {
    changeFaqBltTypeCode('', 1);
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">자주 묻는 질문</h2>
            <p>
              메모리얼캔버스는 빛나는 고인의 삶과 남겨진 유훈을
              <br />
              조문보에 담아 기리는 고인 중심의 장례 문화를 지향합니다.{' '}
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 자주묻는 질문 탭 */}
          <div className="tabs tabs-faq">
            <ul>
              {faqPetBltTypeCodes &&
                faqPetBltTypeCodes.length > 0 &&
                faqPetBltTypeCodes.map(faqBltTypeCode => (
                  <li
                    className={` ${
                      faqBltTypeCode.code === selectedFaqBltTypeCode ? 'is-active' : ''
                    } `}
                  >
                    <a
                      href={() => false}
                      style={{ cursor: 'pointer' }}
                      key={faqBltTypeCode.code}
                      onClick={() => {
                        changeFaqBltTypeCode(faqBltTypeCode.code, 1);
                        setCode(faqBltTypeCode);
                      }}
                    >
                      {faqBltTypeCode.value}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          {/* E : 자주묻는 질문 탭 */}

          <Faq faqs={faqs} />

          {/* S : 페이징 */}
          <div class="btn-wrap">
            <button type="button" class="btn btn-more" onClick={() => handleMore(page, code.code)}>
              더보기 {page}/{totalPage}
            </button>
          </div>
          {/* E : 페이진 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default FaqPage;

import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';

function RegisterSuccess({ isOpen, onClose }) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/pet/login');
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <div className="wrap" id="memorialCanvas">
        <div className="modal is-notice" id="modal-join-complete" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">가입이 완료되었습니다.</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={onClose}
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <p>
                  사랑하는 사람을 위한 기록
                  <br />
                  마이라이프캔버스에서 시작해보세요
                </p>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleClose}
                  data-micromodal-close
                >
                  시작하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
export default RegisterSuccess;

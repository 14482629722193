import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  memberTypeCodes: [],
  memberStatusTypeCodes: [],
  loginTypeCodes: [],
  useYnCodes: [],
  displayYnCodes: [],
  relationTypeCodes: [],
  bankTypeCodes: [],
  consultingWriteTypeCodes: [],
  consultingTypeCodes: [],
  consultingPremiumCodes: [],
  relationDetailCodes: [],
  relationPetDetailCodes: [],
  bltTitleCodes: [],
  chudosaTitleCodes: [],
  commentTemplateCodes: [],
  commentCustomTemplateCodes: [],
  myfaqBltTypeCodes: [],
  faqBltTypeCodes: [],
  canvasTypeCodes: [],
  canvasStoryTypeCodes: [],
  bltTypeCodes: [],
  displayStatusCodes: [],
};

const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    getCodesRequest: state => {
      state.memberTypeCodes = [
        { code: 'A', value: '관리자', color: 'primary' },
        { code: 'E', value: '에버영관리자', color: 'warning' },
        { code: 'B', value: '베이직', color: 'success' },
        { code: 'P', value: '프리미엄', color: 'info' },
        { code: 'N', value: '일반', color: 'default' },
      ];
      state.memberStatusTypeCodes = [
        { code: '0', value: '탈퇴', color: 'primary' },
        { code: '2', value: '휴면계정', color: 'warning' },
        { code: '1', value: '일반', color: 'success' },
      ];
      state.loginTypeCodes = [
        { code: 'E', value: '이메일', color: 'default' },
        { code: 'N', value: '네이버', color: 'success' },
        { code: 'K', value: '카카오', color: 'warning' },
      ];
      state.useYnCodes = [
        { code: 'Y', value: '사용', color: 'info' },
        { code: 'N', value: '미사용', color: 'primary' },
      ];
      state.displayYnCodes = [
        { code: 'Y', value: '공개', color: 'info' },
        { code: 'N', value: '비공개', color: 'primary' },
      ];
      state.relationTypeCodes = [
        { code: 'S', value: '상주', color: 'info' },
        { code: 'F', value: '가족구성원', color: 'primary' },
      ];
      state.memorialTypeCodes = [
        { code: '1', value: '부고', color: 'info' },
        { code: '2', value: '추모관', color: 'primary' },
      ];
      state.bankTypeCodes = [
        { code: '', value: '은행', color: 'info' },
        { code: '카카오뱅크', value: '카카오뱅크', color: 'info' },
        { code: '국민은행', value: '국민은행', color: 'info' },
        { code: '기업은행', value: '기업은행', color: 'info' },
        { code: '농협은행', value: '농협은행', color: 'info' },
        { code: '신한은행', value: '신한은행', color: 'info' },
        { code: '산업은행', value: '산업은행', color: 'info' },
        { code: '우리은행', value: '우리은행', color: 'info' },
        { code: '한국씨티은행', value: '한국씨티은행', color: 'info' },
        { code: '하나은행', value: '하나은행', color: 'info' },
        { code: 'SC제일은행', value: 'SC제일은행', color: 'info' },
        { code: '경남은행', value: '경남은행', color: 'info' },
        { code: '광주은행', value: '광주은행', color: 'info' },
        { code: '대구은행', value: '대구은행', color: 'info' },
        { code: '도이치은행', value: '도이치은행', color: 'info' },
        { code: '뱅크오브아메리카', value: '뱅크오브아메리카', color: 'info' },
        { code: '부산은행', value: '부산은행', color: 'info' },
        { code: '산림조합중앙회', value: '산림조합중앙회', color: 'info' },
        { code: '저축은행', value: '저축은행', color: 'info' },
        { code: '새마을금고', value: '새마을금고', color: 'info' },
        { code: '수협은행', value: '수협은행', color: 'info' },
        { code: '신협중앙회', value: '신협중앙회', color: 'info' },
        { code: '우체국', value: '우체국', color: 'info' },
        { code: '전북은행', value: '전북은행', color: 'info' },
        { code: '제주은행', value: '제주은행', color: 'info' },
        { code: '중국건설은행', value: '중국건설은행', color: 'info' },
        { code: '중국공상은행', value: '중국공상은행', color: 'info' },
        { code: '중국은행', value: '중국은행', color: 'info' },
        { code: 'BNP파리바은행', value: 'BNP파리바은행', color: 'info' },
        { code: 'HSBC은행', value: 'HSBC은행', color: 'info' },
        { code: 'JP모간체이스은행', value: 'JP모간체이스은행', color: 'info' },
        { code: '케이뱅크', value: '케이뱅크', color: 'info' },
        { code: '토스뱅크', value: '토스뱅크', color: 'info' },
      ];
      state.consultingWriteTypeCodes = [
        { code: '001', value: '조문보', color: 'primary' },
        { code: '002', value: '자서전', color: 'info' },
        { code: '003', value: '펫 장례', color: 'info' },
        { code: '004', value: '펫 자서전', color: 'info' },
      ];
      state.consultingPremiumCodes = [
        { code: '001', value: '프리미엄', color: 'primary' },
        { code: '002', value: '프리미엄 골드', color: 'info' },
        { code: '003', value: '프리미엄 디럭스', color: 'info' },
      ];
      state.consultingTypeCodes = [
        { code: '1', value: '상담대기', color: 'primary' },
        { code: '2', value: '상담완료', color: 'info' },
      ];
      state.relationDetailCodes = [
        { code: '', value: '고인관계', color: 'info' },
        { code: '배우자', value: '배우자', color: 'info' },
        { code: '아들', value: '아들', color: 'info' },
        { code: '딸', value: '딸', color: 'info' },
        { code: '며느리', value: '며느리', color: 'info' },
        { code: '사위', value: '사위', color: 'info' },
        { code: '손', value: '손', color: 'info' },
        { code: '손자', value: '손자', color: 'info' },
        { code: '손녀', value: '손녀', color: 'info' },
        { code: '외손자', value: '외손자', color: 'info' },
        { code: '손부', value: '손부', color: 'info' },
        { code: '손서', value: '손서', color: 'info' },
        { code: '외손부', value: '외손부', color: 'info' },
        { code: '외손서', value: '외손서', color: 'info' },
        { code: '상주', value: '상주', color: 'info' },
        { code: ' ', value: '선택하지 않음', color: 'info' },
      ];
      state.relationPetDetailCodes = [
        { code: ' ', value: '관계', color: 'info' },
        { code: '엄마', value: '엄마', color: 'info' },
        { code: '아빠', value: '아빠', color: 'info' },
        { code: '누나', value: '누나', color: 'info' },
        { code: '언니', value: '언니', color: 'info' },
        { code: '형', value: '형', color: 'info' },
        { code: '오빠', value: '오빠', color: 'info' },
        { code: '동생', value: '동생', color: 'info' },
        { code: '할머니', value: '할머니', color: 'info' },
        { code: '할아버지', value: '할아버지', color: 'info' },
        { code: '보호자', value: '보호자', color: 'info' },
        { code: ' ', value: '선택하지 않음', color: 'info' },
      ];
      state.bltTitleCodes = [
        { code: '', value: '주제선택', color: 'info' },
        { code: '추도사', value: '추도사', color: 'info' },
        { code: '고인의삶', value: '고인의삶', color: 'info' },
        { code: '성장과정', value: '성장과정', color: 'info' },
        { code: '혼인과 가족 탄생', value: '혼인과 가족 탄생', color: 'info' },
        { code: '생애 보람과 열매', value: '생애 보람과 열매', color: 'info' },
        { code: '잊지 못할 일화', value: '잊지 못할 일화', color: 'info' },
        { code: '지혜의 말씀', value: '지혜의 말씀', color: 'info' },
        { code: '남기신 유언', value: '남기신 유언', color: 'info' },
        { code: '약전', value: '약전', color: 'info' },
        { code: '', value: '기타', color: 'info' },
      ];
      state.chudosaTitleCodes = [
        {
          code:
            '하나의 세상을 이루었던 한 사람이 이제는 먼 곳의 반짝이는 별이 되셨습니다.\n' +
            '\n' +
            '그저 허망히 잊어가기보다는 고인이 일구어 놓은 생의 기록을 정리하고 기억하는 것이 남겨진 사람들의 몫이라고 생각합니다.\n' +
            '\n' +
            '짧은 인생의 여정을 마치고 이제는 깊은 잠에 드신 고인이 편안하실 수 있도록\n' +
            '\n' +
            '떠나는 고인의 마지막 길을 함께 배웅해 주시기를 바랍니다.\n' +
            '\n' +
            '고인은 깊은 잠에 드셨지만, 고인의 생생한 삶이 이곳에 남아 영원히 기억될 것입니다.\n' +
            '\n' +
            '고인의 생에 사랑과 우정, 감사와 나눔으로 함께하여 주셨던 여러분들이 이곳에서 고인을 추억할 수 있기를 바랍니다.',
          value:
            '하나의 세상을 이루었던 한 사람이 이제는 먼 곳의 반짝이는 별이 되셨습니다.\n' +
            '\n' +
            '그저 허망히 잊어가기보다는 고인이 일구어 놓은 생의 기록을 정리하고 기억하는 것이 남겨진 사람들의 몫이라고 생각합니다.\n' +
            '\n' +
            '짧은 인생의 여정을 마치고 이제는 깊은 잠에 드신 고인이 편안하실 수 있도록\n' +
            '\n' +
            '떠나는 고인의 마지막 길을 함께 배웅해 주시기를 바랍니다.\n' +
            '\n' +
            '고인은 깊은 잠에 드셨지만, 고인의 생생한 삶이 이곳에 남아 영원히 기억될 것입니다.\n' +
            '\n' +
            '고인의 생에 사랑과 우정, 감사와 나눔으로 함께하여 주셨던 여러분들이 이곳에서 고인을 추억할 수 있기를 바랍니다.',
          color: 'info',
        },
        {
          code:
            '가족들을 위해 기꺼이 본인을 헌신하시며\n' +
            '\n' +
            '저희에게 행복과 희생, 감사와 부끄러움, 지혜와 겸손을 일깨워주시는 삶을 사셨습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '부족하고 약한 마음을 항상 사랑과 단단한 믿음으로 채우고 완성시켜주셔서 감사드립니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '그 크나큰 마음이 부끄럽지 않도록\n' +
            '\n' +
            '자랑스러운 어른이 되어 살아가겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '편안히 가세요.\n' +
            '\n' +
            '주신 은혜 잊지 않겠습니다. 사랑합니다.',
          value:
            '가족들을 위해 기꺼이 본인을 헌신하시며\n' +
            '\n' +
            '저희에게 행복과 희생, 감사와 부끄러움, 지혜와 겸손을 일깨워주시는 삶을 사셨습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '부족하고 약한 마음을 항상 사랑과 단단한 믿음으로 채우고 완성시켜주셔서 감사드립니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '그 크나큰 마음이 부끄럽지 않도록\n' +
            '\n' +
            '자랑스러운 어른이 되어 살아가겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '편안히 가세요.\n' +
            '\n' +
            '주신 은혜 잊지 않겠습니다. 사랑합니다.',
          color: 'info',
        },
        {
          code:
            '평생 사랑과 희생으로 우리를 돌봐주셨습니다. 헤아릴 수 없는 그 정성과 노고에 감사드립니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '당연한 줄만 알았던 그 마음이 어른이 되어보니 어렵고 무거운 마음이라는 것을 알았습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '그 큰 마음을 가슴에 새겨, 배운 가르침을 잊지 않고 다른 이들에게 나누고 베풀며 살아가겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '이제는 그 무거운 마음 내려놓으시고 편히 쉬세요.',
          value:
            '평생 사랑과 희생으로 우리를 돌봐주셨습니다. 헤아릴 수 없는 그 정성과 노고에 감사드립니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '당연한 줄만 알았던 그 마음이 어른이 되어보니 어렵고 무거운 마음이라는 것을 알았습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '그 큰 마음을 가슴에 새겨, 배운 가르침을 잊지 않고 다른 이들에게 나누고 베풀며 살아가겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '이제는 그 무거운 마음 내려놓으시고 편히 쉬세요.',
          color: 'info',
        },
        {
          code:
            '감사하고, 사랑합니다.\n' +
            '\n' +
            '주신 가르침으로 힘들 때 위로 받고, 어려울 때 지혜를 얻었습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '기꺼이 인생의 스승이 되어주시며, 살아가는 데 믿고 따라갈 수 있는 나침반이 되어 주셔서 감사합니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '저희에게 보여주신 그 가르침과 같이 남은 생을 누군가의 스승이 되어 주고, 친구가 되어 주며 위로와 지혜, 사랑을 나눌 수 있는 사람이 되겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '부디 평안히 잠드시길 바랍니다.',
          value:
            '감사하고, 사랑합니다.\n' +
            '\n' +
            '주신 가르침으로 힘들 때 위로 받고, 어려울 때 지혜를 얻었습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '기꺼이 인생의 스승이 되어주시며, 살아가는 데 믿고 따라갈 수 있는 나침반이 되어 주셔서 감사합니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '저희에게 보여주신 그 가르침과 같이 남은 생을 누군가의 스승이 되어 주고, 친구가 되어 주며 위로와 지혜, 사랑을 나눌 수 있는 사람이 되겠습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '부디 평안히 잠드시길 바랍니다.',
          color: 'info',
        },
        {
          code:
            '작은 몸으로 가족들의 근심과 걱정을 한 가득 안으시고도 내색 하나 하지 않으시며 오히려 저희를 위로해 주셨던 저희의 든든한 버팀목.\n' +
            '\n' +
            ' \n' +
            '\n' +
            '지긋이 손을 잡고 담담히 토닥여 주시던 말씀 한 마디에 가족을 생각하시는 마음이 가슴 깊이 느껴져, 눈물을 삼킨 적이 있습니다.\n' +
            '\n' +
            ' \n' +
            '\n' +
            '저희의 작은 거인, 감사합니다.\n' +
            '\n' +
            '부디 그곳에서는 편안히 쉬시길 바랍니다.',
          value:
            '작은 몸으로 가족들의 근심과 걱정을 한 가득 안으시고도 내색 하나 하지 않으시며 오히려 저희를 위로해 주셨던 저희의 든든한 버팀목.\n' +
            '\n' +
            ' \n' +
            '\n' +
            '지긋이 손을 잡고 담담히 토닥여 주시던 말씀 한 마디에 가족을 생각하시는 마음이 가슴 깊이 느껴져, 눈물을 삼킨 적이 있습니다.\n' +
            '\n' +
            ' \n' +
            '\n' +
            '저희의 작은 거인, 감사합니다.\n' +
            '\n' +
            '부디 그곳에서는 편안히 쉬시길 바랍니다.',
          color: 'info',
        },
        {
          code:
            '가족들의 평안과 화목을 지켜주시느라 고생 많으셨습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '언제나 반가이 맞아주시며, 따뜻한 밥을 공기 가득 담아 주시던 그 사랑이 벌써부터 그립습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '나쁜 일엔 대신 화를 내주시고, 좋은 일엔 그 무엇보다 기뻐하고 행복해 하시며 우리의 곁에서 응원과 지지, 위로와 치유를 주시니 얼마나 든든했는지 모릅니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '가족의 든든한 기둥으로서 한 평생 고생하셨으니, 이제는 본인을 위한 평안을 편히 누리시기 바랍니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '감사합니다. 사랑합니다.',
          value:
            '가족들의 평안과 화목을 지켜주시느라 고생 많으셨습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '언제나 반가이 맞아주시며, 따뜻한 밥을 공기 가득 담아 주시던 그 사랑이 벌써부터 그립습니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '나쁜 일엔 대신 화를 내주시고, 좋은 일엔 그 무엇보다 기뻐하고 행복해 하시며 우리의 곁에서 응원과 지지, 위로와 치유를 주시니 얼마나 든든했는지 모릅니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '가족의 든든한 기둥으로서 한 평생 고생하셨으니, 이제는 본인을 위한 평안을 편히 누리시기 바랍니다.\n' +
            '\n' +
            '\n' +
            '\n' +
            '감사합니다. 사랑합니다.',
          color: 'info',
        },
      ];
      state.chudosaPetTitleCodes = [
        {
          code: '아직도 마음이 아프지만 이제는 자유롭게 널 보내주기 위해 글을 써. 네가 나의 곁을 떠난 날은 정말 아무 생각이 나지 않더라고. 믿기지가 않아서. 그래서 너를 잠시 곁에 두고 하고 싶었던 말을 다 쏟아냈어. 다 들었겠지? 조금 더 너를 곁에 두고 싶지만 욕심인 것 같아 이제야 보내. 여기서도 자유로웠던 아이였기에 가서도 훨훨 날아다니는 자유로운 아이로 살았으면 좋겠어.',
          value:
            '아직도 마음이 아프지만 이제는 자유롭게 널 보내주기 위해 글을 써. 네가 나의 곁을 떠난 날은 정말 아무 생각이 나지 않더라고. 믿기지가 않아서. 그래서 너를 잠시 곁에 두고 하고 싶었던 말을 다 쏟아냈어. 다 들었겠지? 조금 더 너를 곁에 두고 싶지만 욕심인 것 같아 이제야 보내. 여기서도 자유로웠던 아이였기에 가서도 훨훨 날아다니는 자유로운 아이로 살았으면 좋겠어.',
          color: 'info',
        },
        {
          code: '너에게 편지를 쓰다 보니 눈물도 나지만 좋은 추억도 생각이 난다. 항상 하던 산책, 포옹, 그리고 장난. 똑같은 삶의 반복이었지만 네가 내 삶에 가져온 행복은 모두 다른 행복이었어. 더 이상 내 곁에 없지만 항상 네가 남기고 간 다양한 행복을 꺼내며 추억할게. 우리의 인연은 계속될 거라 생각해. 언젠가 재회할 때까지 잘 있어줘. 사랑해.',
          value:
            '너에게 편지를 쓰다 보니 눈물도 나지만 좋은 추억도 생각이 난다. 항상 하던 산책, 포옹, 그리고 장난. 똑같은 삶의 반복이었지만 네가 내 삶에 가져온 행복은 모두 다른 행복이었어. 더 이상 내 곁에 없지만 항상 네가 남기고 간 다양한 행복을 꺼내며 추억할게. 우리의 인연은 계속될 거라 생각해. 언젠가 재회할 때까지 잘 있어줘. 사랑해.',
          color: 'info',
        },
        {
          code: '네가 없는 집은 텅 비어 있고, 아직도 구석구석 너의 흔적이 남아있고 온기도 그대로야. 너는 우리 부부에게 반려동물 그 이상의 존재였지. 정말 마음으로 낳은 아이였어. 여기서는 많은 시간 고통과 함께 했지만 이제는 아프지 말고 끝없이 펼쳐진 들판에서 다른 친구들과 함께 신나게 놀기를 바라. 편히 쉬어, 사랑하는 ㅇㅇ아.',
          value:
            '네가 없는 집은 텅 비어 있고, 아직도 구석구석 너의 흔적이 남아있고 온기도 그대로야. 너는 우리 부부에게 반려동물 그 이상의 존재였지. 정말 마음으로 낳은 아이였어. 여기서는 많은 시간 고통과 함께 했지만 이제는 아프지 말고 끝없이 펼쳐진 들판에서 다른 친구들과 함께 신나게 놀기를 바라. 편히 쉬어, 사랑하는 ㅇㅇ아.',
          color: 'info',
        },
        {
          code: '처음 본 순간부터 지금까지 네가 준 사랑은 잊지 못할 거야. 아니 앞으로도 너와 있었던 추억과 행복은 나에게 큰 힘이 될 거야. 이제 생각해 보니 참 너에게 정말 의지를 많이 했었던 것 같아. 네가 여기에 남아 있지 않더라도 영혼은 내 마음속에 살아 있을 거야. 나의 소중한 동반자가 되어 주어서 고마워. 사랑해.',
          value:
            '처음 본 순간부터 지금까지 네가 준 사랑은 잊지 못할 거야. 아니 앞으로도 너와 있었던 추억과 행복은 나에게 큰 힘이 될 거야. 이제 생각해 보니 참 너에게 정말 의지를 많이 했었던 것 같아. 네가 여기에 남아 있지 않더라도 영혼은 내 마음속에 살아 있을 거야. 나의 소중한 동반자가 되어 주어서 고마워. 사랑해.',
          color: 'info',
        },
        {
          code: '세상을 떠난 너의 영혼은 마치 바람처럼 가볍게 날아가지만, 네가 남긴 감동은 마음 깊은 곳에 뿌리내려 두었어. 이젠 네가 남긴 그 감동의 꽃들이 아름답게 피어나고, 네가 함께한 순간들이 햇살처럼 따뜻하게 느껴지겠지. 무지개 나라 가서도 항상 따뜻하게 지내길 바랄게.',
          value:
            '세상을 떠난 너의 영혼은 마치 바람처럼 가볍게 날아가지만, 네가 남긴 감동은 마음 깊은 곳에 뿌리내려 두었어. 이젠 네가 남긴 그 감동의 꽃들이 아름답게 피어나고, 네가 함께한 순간들이 햇살처럼 따뜻하게 느껴지겠지. 무지개 나라 가서도 항상 따뜻하게 지내길 바랄게.',
          color: 'info',
        },
      ];
      state.commentTemplateCodes = [
        {
          code: '생의 마지막 순간까지 희생과 노고를 마다하지 않으셨음을 잊지않겠습니다. 부디 천국에서 편히 쉬시기를 두 손 모아 기도합니다.',
          value:
            '생의 마지막 순간까지 희생과 노고를 마다하지 않으셨음을 잊지않겠습니다. 부디 천국에서 편히 쉬시기를 두 손 모아 기도합니다.',
          color: 'info',
        },
        {
          code: '삼가 고인의 명복을 빕니다. 슬픔을 당한 온 가족께도 깊은 위로의 마음 전합니다.',
          value: '삼가 고인의 명복을 빕니다. 슬픔을 당한 온 가족께도 깊은 위로의 마음 전합니다.',
          color: 'info',
        },
        {
          code: '삶의 무거운 짐 모두 내려놓으시고 편히 쉬시길 바랍니다.',
          value: '삶의 무거운 짐 모두 내려놓으시고 편히 쉬시길 바랍니다.',
          color: 'info',
        },
        {
          code: '부디 더 좋은 곳에서 평안하시기를 기도하고 또 기도합니다.',
          value: '부디 더 좋은 곳에서 평안하시기를 기도하고 또 기도합니다.',
          color: 'info',
        },
        {
          code: '모든 날, 모든 순간, 그립습니다. 고맙습니다. 사랑합니다.',
          value: '모든 날, 모든 순간, 그립습니다. 고맙습니다. 사랑합니다.',
          color: 'info',
        },
        {
          code: '삶의 나침반이 되어주신 선생님, 잊지않고 기억하겠습니다. 편히 잠드소서.',
          value: '삶의 나침반이 되어주신 선생님, 잊지않고 기억하겠습니다. 편히 잠드소서.',
          color: 'info',
        },
        {
          code: '한평생 아낌없이 사랑과 지혜를 주시고 우리 곁을 떠나신 선생님의 업적을 기리며 영면을 기원합니다.',
          value:
            '한평생 아낌없이 사랑과 지혜를 주시고 우리 곁을 떠나신 선생님의 업적을 기리며 영면을 기원합니다.',
          color: 'info',
        },
        {
          code: '고인과 유가족께 조의를 표합니다. 마지막 가시는 길 편안하시도록 기도하겠습니다.',
          value: '고인과 유가족께 조의를 표합니다. 마지막 가시는 길 편안하시도록 기도하겠습니다.',
          color: 'info',
        },
        {
          code: '고인의 각별한 정을 떠올리며 삼가 조의를 표합니다.',
          value: '고인의 각별한 정을 떠올리며 삼가 조의를 표합니다.',
          color: 'info',
        },
        {
          code: '고귀했던 고인의 삶을 영원히 기억하겠습니다. 유가족분들께 깊은 위로의 마음을 전합니다.',
          value:
            '고귀했던 고인의 삶을 영원히 기억하겠습니다. 유가족분들께 깊은 위로의 마음을 전합니다.',
          color: 'info',
        },
        {
          code: '늘 잊지않고 기억하겠습니다. 아픔 없는 그곳에서 편히 쉬세요.',
          value: '늘 잊지않고 기억하겠습니다. 아픔 없는 그곳에서 편히 쉬세요.',
          color: 'info',
        },
        {
          code: '고인의 명복을 빌며, 유족께도 깊은 애도의 마음을 전합니다.',
          value: '고인의 명복을 빌며, 유족께도 깊은 애도의 마음을 전합니다.',
          color: 'info',
        },
        {
          code: '진심 어린 마음으로 고인의 명복을 빕니다.',
          value: '진심 어린 마음으로 고인의 명복을 빕니다.',
          color: 'info',
        },
        {
          code: '삼가 弔儀를 표하며 고인의 冥福을 빕니다.',
          value: '삼가 弔儀를 표하며 고인의 冥福을 빕니다.',
          color: 'info',
        },
        {
          code: '삼가 조의를 표하며 고인의 명복을 빕니다.',
          value: '삼가 조의를 표하며 고인의 명복을 빕니다.',
          color: 'info',
        },
        {
          code: '삼가 弔儀를 표하며 고인의 冥福을 빕니다.',
          value: '삼가 弔儀를 표하며 고인의 冥福을 빕니다.',
          color: 'info',
        },
        {
          code: '삼가 故人의 冥福을 빕니다.',
          value: '삼가 故人의 冥福을 빕니다.',
          color: 'info',
        },
        {
          code: '삼가 고인의 명복을 빕니다.',
          value: '삼가 고인의 명복을 빕니다.',
          color: 'info',
        },
      ];

      state.commentPetTemplateCodes = [
        {
          code: '너와의 만났던 시간은 짧았지만, 그 짧은 시간 동안 나에게 무한한 사랑과 감동을 선물해 줘서 고마워. 너는 언제나 나의 곁을 지켜주었지. 너로 인해 정말 매일매일이 행복하고 즐거웠어. 이젠 더 이상 함께 하진 못하지만 너와의 추억을 간직하며 영원히 기억할게. 고마웠어.',
          value:
            '너와의 만났던 시간은 짧았지만, 그 짧은 시간 동안 나에게 무한한 사랑과 감동을 선물해 줘서 고마워. 너는 언제나 나의 곁을 지켜주었지. 너로 인해 정말 매일매일이 행복하고 즐거웠어. 이젠 더 이상 함께 하진 못하지만 너와의 추억을 간직하며 영원히 기억할게. 고마웠어.',
          color: 'info',
        },
        {
          code: 'ㅇㅇ야, 안녕? 어떻게 말을 시작해야 할지 모르겠다. 너는 나에게 정말 고마운 친구이자 가족이었어. 너의 부드러운 느낌이 아직도 선명한데 실감이 나지 않는 것 같아. 너는 잘 모를 수 있겠지만 나에게 정말 많은 위로와 기쁨을 주었어. 무지개 나라에 가서도 행복하게 지낼 수 있도록 기도할게.',
          value:
            'ㅇㅇ야, 안녕? 어떻게 말을 시작해야 할지 모르겠다. 너는 나에게 정말 고마운 친구이자 가족이었어. 너의 부드러운 느낌이 아직도 선명한데 실감이 나지 않는 것 같아. 너는 잘 모를 수 있겠지만 나에게 정말 많은 위로와 기쁨을 주었어. 무지개 나라에 가서도 행복하게 지낼 수 있도록 기도할게.',
          color: 'info',
        },
        {
          code: '지금 우리 곁에 없어도, 항상 가슴 속에 살아있어. 함께했던 행복한 기억들은 시간이 지나도 절대 잊지 않을게.',
          value:
            '지금 우리 곁에 없어도, 항상 가슴 속에 살아있어. 함께했던 행복한 기억들은 시간이 지나도 절대 잊지 않을게.',
          color: 'info',
        },
        {
          code: '몇 번 보지는 못했지만 너를 보고 돌아가는 길에는 행복한 여운이 남아있었어. 아마 너의 가족인 ㅇㅇ이도 매일 너와 그런 행복한 시간을 보냈겠지. 네가 가족들의 마음을 항상 따뜻하게 해주었던 것처럼 이제는 내가 ㅇㅇ이를 잘 보살펴 줄게. 너무 걱정하지 말고 편히 쉬었으면 해.',
          value:
            '몇 번 보지는 못했지만 너를 보고 돌아가는 길에는 행복한 여운이 남아있었어. 아마 너의 가족인 ㅇㅇ이도 매일 너와 그런 행복한 시간을 보냈겠지. 네가 가족들의 마음을 항상 따뜻하게 해주었던 것처럼 이제는 내가 ㅇㅇ이를 잘 보살펴 줄게. 너무 걱정하지 말고 편히 쉬었으면 해.',
          color: 'info',
        },
        {
          code: '곁을 떠난 순간부터 큰 슬픔으로 가득찼지만, 함께했던 아름다운 시간들은 영원히 간직될 것입니다. 우리 가족에게 더했던 행복과 사랑은 결코 잊지 않고, 보여준 충성과 애정은 우리 삶에 영원한 흔적으로 남겨 둘 것입니다.',
          value:
            '곁을 떠난 순간부터 큰 슬픔으로 가득찼지만, 함께했던 아름다운 시간들은 영원히 간직될 것입니다. 우리 가족에게 더했던 행복과 사랑은 결코 잊지 않고, 보여준 충성과 애정은 우리 삶에 영원한 흔적으로 남겨 둘 것입니다.',
          color: 'info',
        },
        {
          code: '처음 아장아장 걷던 모습을 보았을 때가 엊그제 같은데 어느새 10년의 세월이 흘러 이렇게 우리의 곁을 떠났구나. 장난스러운 모습과 사랑스러운 행동들이 아직 눈에 아른아른하네. 그래도 건강하게 잘 있다가 마지막 인사도 하고 가서 다행이라고 생각해. 우리 아가 보고 싶을 때마다 종종 놀러 올게~',
          value:
            '처음 아장아장 걷던 모습을 보았을 때가 엊그제 같은데 어느새 10년의 세월이 흘러 이렇게 우리의 곁을 떠났구나. 장난스러운 모습과 사랑스러운 행동들이 아직 눈에 아른아른하네. 그래도 건강하게 잘 있다가 마지막 인사도 하고 가서 다행이라고 생각해. 우리 아가 보고 싶을 때마다 종종 놀러 올게~',
          color: 'info',
        },
        {
          code: 'ㅇㅇ이야! 비록 길 위에서 우연히 오고가며 만난 짧은 우리의 인연이지만, 함께한 날들은 나에게 큰 의미를 주었어. 지친 퇴근길에 너를 만나러 간다는 생각에 정말 가벼운 발걸음으로 항상 서둘러 집에 왔었지. 안 보일 때면 무슨 일이 있는지 걱정하며 전전긍긍했었는데 그래도 있는 동안 나의 손길을 받아줘서 고마웠어.',
          value:
            'ㅇㅇ이야! 비록 길 위에서 우연히 오고가며 만난 짧은 우리의 인연이지만, 함께한 날들은 나에게 큰 의미를 주었어. 지친 퇴근길에 너를 만나러 간다는 생각에 정말 가벼운 발걸음으로 항상 서둘러 집에 왔었지. 안 보일 때면 무슨 일이 있는지 걱정하며 전전긍긍했었는데 그래도 있는 동안 나의 손길을 받아줘서 고마웠어.',
          color: 'info',
        },
        {
          code: '떠나간 너의 발자국이 마치 시간의 길을 따라 남긴 흔적 같아. 내 마음은 네가 있던 자리로 자꾸 돌아가고, 네가 떠난 자리는 아직도 네 향기로 가득 차 있어. 이젠 네 발자국을 따라서 행복한 추억의 길을 걸으며 너와 함께한 순간을 회상하며 지내겠지. 언제나 고마웠어.',
          value:
            '떠나간 너의 발자국이 마치 시간의 길을 따라 남긴 흔적 같아. 내 마음은 네가 있던 자리로 자꾸 돌아가고, 네가 떠난 자리는 아직도 네 향기로 가득 차 있어. 이젠 네 발자국을 따라서 행복한 추억의 길을 걸으며 너와 함께한 순간을 회상하며 지내겠지. 언제나 고마웠어.',
          color: 'info',
        },
        {
          code: '우리의 추억들은 마치 무지개처럼 다양한 색깔로, 네가 남긴 흔적들로 물들여 놓았어. 네가 떠난 후에도 그 무지개가 늘 내 마음에 빛날 거야. 네가 보내준 행복한 미소와 함께한 순간들이 떠올라 미소를 지으며 널 그리워하겠지. 그리운 마음이 너에게도 전달이 되었으면 좋겠다.',
          value:
            '우리의 추억들은 마치 무지개처럼 다양한 색깔로, 네가 남긴 흔적들로 물들여 놓았어. 네가 떠난 후에도 그 무지개가 늘 내 마음에 빛날 거야. 네가 보내준 행복한 미소와 함께한 순간들이 떠올라 미소를 지으며 널 그리워하겠지. 그리운 마음이 너에게도 전달이 되었으면 좋겠다.',
          color: 'info',
        },
        {
          code: '네 발자국 하나하나가 마치 시간을 감정으로 표현한 것 같아. 어떤 날은 추억이 슬픔으로 젖어들고, 어떤 날은 미소로 환하게 빛날 때도 있어. 네가 떠난 후에도 그 감정의 파동이 늘 우리를 감싸 안으며, 네가 남긴 다양한 순간들이 우리를 채워주고 있어. 오늘따라 너무 그리워서 들렸어. 보고 싶다, ㅇㅇ아.',
          value:
            '네 발자국 하나하나가 마치 시간을 감정으로 표현한 것 같아. 어떤 날은 추억이 슬픔으로 젖어들고, 어떤 날은 미소로 환하게 빛날 때도 있어. 네가 떠난 후에도 그 감정의 파동이 늘 우리를 감싸 안으며, 네가 남긴 다양한 순간들이 우리를 채워주고 있어. 오늘따라 너무 그리워서 들렸어. 보고 싶다, ㅇㅇ아.',
          color: 'info',
        },
        {
          code: '네가 떠난 순간, 마치 시계의 초침이 멈춘 것 같은 느낌이었어. 그렇지만 네가 남긴 추억들은 내 마음을 시간의 흐름에 다시 끌어내고, 네가 보내준 행복한 기억들은 환한 미소를 짓게 만들어. 네가 남긴 이야기들이 나의 삶에 빛과 의미를 불어넣어 준 거야. 곁에 있을 때도, 떠나서도 그럴 거야.',
          value:
            '네가 떠난 순간, 마치 시계의 초침이 멈춘 것 같은 느낌이었어. 그렇지만 네가 남긴 추억들은 내 마음을 시간의 흐름에 다시 끌어내고, 네가 보내준 행복한 기억들은 환한 미소를 짓게 만들어. 네가 남긴 이야기들이 나의 삶에 빛과 의미를 불어넣어 준 거야. 곁에 있을 때도, 떠나서도 그럴 거야.',
          color: 'info',
        },
        {
          code: '우리 곁을 떠난 지 5년이 지났지만 하루도 너를 생각하지 않고는 지나간 적이 없어. 너의 장난기와 흔들리지 않는 충성심은 여전히 내 얼굴에 미소를 짓게 해. 눈에 띄는 다람쥐를 모두 쫓아 무지개 다리 위를 자유롭게 달리고 있기를 기도할게. 우리가 다시 만날 때까지 너는 내 마음속에 영원히 특별한 자리를 차지하고 있을 거야. 사랑해.',
          value:
            '우리 곁을 떠난 지 5년이 지났지만 하루도 너를 생각하지 않고는 지나간 적이 없어. 너의 장난기와 흔들리지 않는 충성심은 여전히 내 얼굴에 미소를 짓게 해. 눈에 띄는 다람쥐를 모두 쫓아 무지개 다리 위를 자유롭게 달리고 있기를 기도할게. 우리가 다시 만날 때까지 너는 내 마음속에 영원히 특별한 자리를 차지하고 있을 거야. 사랑해.',
          color: 'info',
        },
      ];

      state.commentCustomTemplateCodes = [
        {
          code: '윤영환 명예회장님 항상 기억하겠습니다.',
          value: '윤영환 명예회장님 항상 기억하겠습니다.',
          color: 'info',
        },
        {
          code: "'의약보국'에 평생 이바지한 명예회장님을 잊지 않겠습니다.",
          value: "'의약보국'에 평생 이바지한 명예회장님을 잊지 않겠습니다.",
          color: 'info',
        },
        {
          code: "제약의 불모지에서 '의약보국'의 길을 개척하신 회장님을 오늘 다시 돌아봅니다.",
          value: "제약의 불모지에서 '의약보국'의 길을 개척하신 회장님을 오늘 다시 돌아봅니다.",
          color: 'info',
        },
        {
          code: '"좋은 약으로 나라를 돕는다"는 굳은 신념과 정신을 잊지 않겠습니다.',
          value: '"좋은 약으로 나라를 돕는다"는 굳은 신념과 정신을 잊지 않겠습니다.',
          color: 'info',
        },
        {
          code: '윤영환 명예회장님! 부디 평화로운 곳에서 편히 영면하시기를 기원합니다.',
          value: '윤영환 명예회장님! 부디 평화로운 곳에서 편히 영면하시기를 기원합니다.',
          color: 'info',
        },
        {
          code: '대웅을 지극히 사랑한 명예회장님의 그 마음을 영원히 간직하겠습니다.',
          value: '대웅을 지극히 사랑한 명예회장님의 그 마음을 영원히 간직하겠습니다.',
          color: 'info',
        },
        {
          code: '지혜롭고 온화하시던 명예회장님의 모습이 떠오르며 가슴 깊이 그리움을 느낍니다.',
          value: '지혜롭고 온화하시던 명예회장님의 모습이 떠오르며 가슴 깊이 그리움을 느낍니다.',
          color: 'info',
        },
        {
          code: '지혜롭고 온화하시던 회장님의 모습이 떠오르며 가슴 깊이 그리움을 느낍니다.',
          value: '지혜롭고 온화하시던 회장님의 모습이 떠오르며 가슴 깊이 그리움을 느낍니다.',
          color: 'info',
        },
        {
          code: '윤영환 명예회장님, 당신은 진정 자랑스러운 대웅인이셨습니다.',
          value: '윤영환 명예회장님, 당신은 진정 자랑스러운 대웅인이셨습니다.',
          color: 'info',
        },
        {
          code: '제약의 불모지에서 대웅을 일궈내신 명예회장님 기억하고 잊지 않겠습니다.',
          value: '제약의 불모지에서 대웅을 일궈내신 명예회장님 기억하고 잊지 않겠습니다.',
          color: 'info',
        },
        {
          code: '"숲이 좋으면 새가 날아든다" 명예회장님 말씀 가슴 깊이 새기겠습니다.',
          value: '"숲이 좋으면 새가 날아든다" 명예회장님 말씀 가슴 깊이 새기겠습니다.',
          color: 'info',
        },
        {
          code: '"환자와 가족을 먼저 생각하라"는 명예회장님의 큰 뜻, 오늘도 깊이 새기겠습니다.',
          value: '"환자와 가족을 먼저 생각하라"는 명예회장님의 큰 뜻, 오늘도 깊이 새기겠습니다.',
          color: 'info',
        },
        {
          code: '회장님의 깊은 뜻, 마음에 새겨 앞으로도 대웅의 인재들은 크고 넉넉한 숲이 되겠습니다.',
          value:
            '회장님의 깊은 뜻, 마음에 새겨 앞으로도 대웅의 인재들은 크고 넉넉한 숲이 되겠습니다.',
          color: 'info',
        },
        {
          code: '명예회장님과 함께했던 시간을 회상해 봅니다. 명예회장님 그립습니다.',
          value: '명예회장님과 함께했던 시간을 회상해 봅니다. 명예회장님 그립습니다.',
          color: 'info',
        },
        {
          code: '명예회장님의 1주기에 다시 한 번 머리숙여 조의를 표합니다. 편안힌 곳에서 영면하시길 기원드립니다.',
          value:
            '명예회장님의 1주기에 다시 한 번 머리숙여 조의를 표합니다. 편안힌 곳에서 영면하시길 기원드립니다.',
          color: 'info',
        },
        {
          code: '명예회장님과의 추억들이 떠오르는 요즘입니다. 정도와 공생을 평생 지켜오신 회장님 말씀 잊지않겠습니다.',
          value:
            '명예회장님과의 추억들이 떠오르는 요즘입니다. 정도와 공생을 평생 지켜오신 회장님 말씀 잊지않겠습니다.',
          color: 'info',
        },
        {
          code: '“콩 심은데 콩나고 팥 심은데 팥 난다” 명예회장님 말씀 새기고 회사가 더욱 발전하는데 노력을 다하겠습니다.',
          value:
            '“콩 심은데 콩나고 팥 심은데 팥 난다” 명예회장님 말씀 새기고 회사가 더욱 발전하는데 노력을 다하겠습니다.',
          color: 'info',
        },
      ];

      state.myfaqBltTypeCodes = [
        { code: '', value: '전체', color: 'info' },
        { code: '1', value: '마이캔버스', color: 'primary' },
        { code: '2', value: '구독 및 연결', color: 'info' },
        { code: '3', value: '우리이야기', color: 'warning' },
        { code: '4', value: '메모리얼캔버스', color: 'info' },
      ];

      state.faqBltTypeCodes = [
        { code: '', value: '전체', color: 'info' },
        { code: '1', value: '부고', color: 'primary' },
        { code: '2', value: '추모관', color: 'info' },
        { code: '3', value: '조문보', color: 'warning' },
      ];

      state.faqPetBltTypeCodes = [
        { code: '', value: '전체', color: 'info' },
        { code: '1', value: '장례식', color: 'warning' },
        { code: '2', value: '부고', color: 'primary' },
        { code: '3', value: '추모관', color: 'info' },
      ];

      state.canvasTypeCodes = [
        { code: 'M', value: '메모리얼 캔버스', color: 'info' },
        { code: 'L', value: '라이프 캔버스', color: 'primary' },
        { code: 'P', value: '펫 메모리얼 캔버스', color: 'primary' },
      ];

      state.canvasStoryTypeCodes = [
        { code: 'NAME', value: '이름', color: 'info' },
        { code: 'CONTENTS', value: '내용', color: 'primary' },
      ];

      state.bltTypeCodes = [
        { code: '1', value: '조문보', color: 'info' },
        { code: '2', value: '자서전', color: 'primary' },
        { code: '3', value: '문답', color: 'info' },
        { code: '4', value: '버킷리스트', color: 'warning' },
        { code: '5', value: '발자국', color: 'info' },
      ];

      state.displayStatusCodes = [
        { code: '0', value: '전체', color: 'info' },
        { code: '1', value: '구독자', color: 'primary' },
        { code: '2', value: '비공개', color: 'info' },
      ];
    },
  },
});

const { reducer, actions } = codeSlice;

export const { getCodesRequest } = actions;

export const codeState = state => state.code;

export const memberTypeCodes = createSelector(codeState, code => {
  return code.memberTypeCodes;
});

export const loginTypeCodes = createSelector(codeState, code => {
  return code.loginTypeCodes;
});

export const useYnCodes = createSelector(codeState, code => {
  return code.useYnCodes;
});

export const displayYnCodes = createSelector(codeState, code => {
  return code.displayYnCodes;
});

export const relationTypeCodes = createSelector(codeState, code => {
  return code.relationTypeCodes;
});

export const memorialTypeCodes = createSelector(codeState, code => {
  return code.memorialTypeCodes;
});

export const bankTypeCodes = createSelector(codeState, code => {
  return code.bankTypeCodes;
});

export const consultingWriteTypeCodes = createSelector(codeState, code => {
  return code.consultingWriteTypeCodes;
});

export const consultingTypeCodes = createSelector(codeState, code => {
  return code.consultingTypeCodes;
});

export const consultingPremiumCodes = createSelector(codeState, code => {
  return code.consultingPremiumCodes;
});

export const relationDetailCodes = createSelector(codeState, code => {
  return code.relationDetailCodes;
});

export const bltTitleCodes = createSelector(codeState, code => {
  return code.bltTitleCodes;
});

export const chudosaTitleCodes = createSelector(codeState, code => {
  return code.chudosaTitleCodes;
});

export const commentTemplateCodes = createSelector(codeState, code => {
  return code.commentTemplateCodes;
});

export const canvasTypeCodes = createSelector(codeState, code => {
  return code.canvasTypeCodes;
});

export const canvasStoryTypeCodes = createSelector(codeState, code => {
  return code.canvasStoryTypeCodes;
});

export const bltTypeCodes = createSelector(codeState, code => {
  return code.bltTypeCodes;
});

export const displayStatusCodes = createSelector(codeState, code => {
  return code.displayStatusCodes;
});

export default reducer;

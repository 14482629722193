import React, { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

import { getWreathsApi, deleteWreathApi } from 'apis/wreath';

import { timestampToDate } from 'utils/date';

import 'swiper/css';
import 'swiper/css/pagination';
import 'css/swiper.min.css';
import useLogin from '../../../../../stores/login/hook';
import useDialog from '../../../../../stores/dialog/hook';
import { useNavigate } from 'react-router-dom';

function WreathContent({ memorialId, message, handleModifyOpenWreathCustomDialog }) {
  const navigate = useNavigate();
  const [wreaths, setWreaths] = useState(null);
  const wreathElement = useRef(null);
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  // 수정할
  const [modWreathId, setModWreathId] = useState(0);
  // 수정할글
  const [contentTitle, setContentTitle] = useState(null);
  const [contentMod, setContentMod] = useState(null);

  const defaultValues = {
    memorial_id: memorialId,
    member_id: loginUser && loginUser.member_id ? loginUser.member_id : 'guest',
    writer_name: loginUser && loginUser.member_id ? loginUser.member_name : '',
    blt_password: loginUser && loginUser.member_id ? loginUser.member_id : '',
    blt_contents: '',
    display_yn: 'Y',
    medias: [],
  };

  const getWreaths = async params => {
    const result = await getWreathsApi(params);
    setWreaths(result.data);
  };

  const handleModify = (wreathId, title, wreathMessage) => {
    handleModifyOpenWreathCustomDialog(wreathId, title, wreathMessage);
  };

  const handleDelete = (wreathId, title, wreathMessage) => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(wreathId),
      () => hideConfirm(),
    );
  };

  const handleConfirm = wreathId => {
    hideConfirm();
    deleteWreath(wreathId);
  };

  const deleteWreath = async params => {
    const result = await deleteWreathApi(params);
    getWreaths({
      memorial_id: memorialId,
      status: 1,
      orderBy: { direction: 'desc', field: 'id' },
    });
    showAlert('알림', result.message, () => hideAlert());
  };

  useEffect(() => {
    getWreaths({
      memorial_id: memorialId,
      status: 1,
      orderBy: { direction: 'desc', field: 'id' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message === 'wreath-complete') {
      wreathElement.current?.scrollIntoView({ block: 'center' });
    }
  });

  return (
    <>
      {wreaths && wreaths.length > 0 ? (
        <div className="wrap-digital-wreath contents-width" ref={wreathElement}>
          <div className="box-title">
            <h3 className="title">화환 해주신 분</h3>
            <p className="desc">디지털 화환으로 애도의 뜻을 전합니다</p>
          </div>
          <div className="wreath-swiper">
            <div className="swiper-asset">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={16}
              slidesPerView={3}
              centeredSlides={false}
              //loop={wreaths.length < 3 ? false : true}
              // autoHeight={true}
              simulateTouch={true}
              autoplay={{ delay: 4000 }}
              navigation={{
                nextEl: '.wreath-swiper .swiper-asset .swiper-button-next',
                prevEl: '.wreath-swiper .swiper-asset .swiper-button-prev',
              }}
              // pagination={{ el: '.wreath-swiper .swiper-asset', clickable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 11,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 22,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
            >
              <div className="swiper-wrapper ">
                {wreaths.map(wreath => (
                  <SwiperSlide className="wreath-item" key={wreath.id}>
                    {wreath.type === 'N' && (
                      <>
                        <div className="box-wreath type-01">
                          <ul className="box-btn">
                            <li>
                              <button type="button" onClick={() => alert('dd')}>
                                수정
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => alert('dd')}>
                                삭제
                              </button>
                            </li>
                          </ul>
                          {/* 국화 1송이 */}
                          <p>{wreath.message}</p>
                          {/* 1송이
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}

                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                    {wreath.type === 'R' && (
                      <>
                        <div className="box-wreath type-02">
                          <ul className="box-btn">
                            <li>
                              <button type="button" onClick={() => alert('dd')}>
                                수정
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => alert('dd')}>
                                삭제
                              </button>
                            </li>
                          </ul>
                          {/* 국화 3송이 */}
                          <p> {wreath.message}</p>
                          {/* 3송이
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}

                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                    {wreath.type === 'P' && (
                      <>
                        <div className="box-wreath type-03">
                          {loginUser.member_id === wreath.register_id && (
                            <div className="box-wrapper">
                              <ul className="box-btn">
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleModify(wreath.id, wreath.title, wreath.message)
                                    }
                                  >
                                    수정
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDelete(wreath.id, wreath.title, wreath.message)
                                    }
                                  >
                                    삭제
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                          {/* 근조화환 */}
                          <p>{wreath.message}</p>
                          {/* 근조 화환
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}
                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      ) : (
        ' '
      )}
    </>
  );
}

export default WreathContent;

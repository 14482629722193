import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getCanvasApi, toggleCanvasLinkApi } from 'apis/canvas';
import { getCanvasBltsSubApi } from 'apis/canvasBlt';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import UserLife from './components/UserLife';
import UserHistory from './components/UserHistory';
import UserQna from './components/UserQna';
import UserBucketlist from './components/UserBucketlist';
import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';

import Subscribe from './components/Subscribe';

import UserQnaDialog from './components/UserQnaDialog';
import CanvasShareDialog from './components/CanvasShareDialog';

import ImagesDialog from './components/ImagesDialog';

function UserCanvas() {
  const scrollRef = useRef();

  const location = useLocation();
  const { loginUser } = useLogin();
  const { id } = useParams();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const [canvas, setCanvas] = useState();
  const [canvasBlts, setCanvasBlts] = useState();
  const [searchBltType, setSearchBltType] = useState();
  const [profileLink, setProfileLink] = useState();
  const [title, setTitle] = useState();
  const [motto, setMotto] = useState();

  const [tabIndex, setTabIndex] = useState(0);

  const [linkCount, setLinkCount] = useState(0);
  const [bltCount, setBltCount] = useState(0);

  const [page, setPage] = useState(1);
  const [listSize] = useState(4);
  const [totalPage, setTotalPage] = useState(0);

  const [openUserQnaDialog, setOpenUserQnaDialog] = useState(false);
  const [openCanvasShareDialog, setOpenCanvasShareDialog] = useState(false);

  const [image, setImage] = useState(null);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);

  const getCanvasBltsSub = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    if (searchBltType) params.blt_type = searchBltType;
    params.canvas_id = id;
    params.goalstatus = 'N';
    const result = await getCanvasBltsSubApi(params);

    if (result.code === '0000') {
      setCanvasBlts(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  const getCanvasBltsSubMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    if (searchBltType) params.blt_type = searchBltType;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.canvas_id = id;
    params.goalstatus = 'N';
    const result = await getCanvasBltsSubApi(params);

    if (result.code === '0000') {
      setCanvasBlts([...canvasBlts, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvas = async () => {
    let params = {};
    params.canvas_id = id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getCanvasApi(params);

    if (result.code === '0000') {
      setCanvas(result.data);
      setBltCount(result.blt_cnt);
      setLinkCount(result.link_cnt);
      setProfileLink(result.data.profile_link);
      setTitle(result.data.title);
      setMotto(result.data.motto);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasBltsSubMore(nextPage);
    }
  };

  const toggleCanvasLink = async () => {
    if (loginUser && !loginUser.member_id) {
      showLifeAlert('알림', '구독 기능은 로그인 후 사용할 수 있습니다.', () => hideLifeAlert());
      return;
    }

    let params = {};

    params.action_type = '1';
    params.canvas_id = id;
    params.canvas_id_my = loginUser.canvas_id;
    params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;

    const result = await toggleCanvasLinkApi(params);

    if (result.code === '0000') {
      getCanvas();
      //showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const reflash = async () => {
    // 팝업 닫고 리스트 다시 불러오고
    setOpenUserQnaDialog(false);
    await getCanvas();
    if (tabIndex < 5) await getCanvasBltsSub(1);
  };

  const init = async () => {
    await getCanvas();
    if (tabIndex < 5) await getCanvasBltsSub(1);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setSearchBltType();
    } else if (tabIndex === 1) {
      setSearchBltType('4');
    } else if (tabIndex === 2) {
      setSearchBltType('3');
    } else if (tabIndex === 3) {
      setSearchBltType('1');
    } else if (tabIndex === 4) {
      setSearchBltType('2');
    } else if (tabIndex === 5) {
      // alert('구독 기능 작업중..');
    } else if (tabIndex === 6) {
      alert('연결 기능 작업중..');
    }
  }, [tabIndex]);

  const handleImageView = image => {
    setImage(image);
    setOpenMediaViewDialog(true);
  };

  const handleBackgroundImageView = (image, id) => {
    if (image === null || image === '' || image === undefined) {
      if (id % 2 === 0) {
        setOpenMediaViewDialog(false);
      } else {
        setOpenMediaViewDialog(false);
      }
    } else {
      setImage(image);
      setOpenMediaViewDialog(true);
    }
  };

  useEffect(() => {
    if (location?.state?.searchBltType) {
      if (location.state.searchBltType === '4') setTabIndex(1);
      if (location.state.searchBltType === '3') setTabIndex(2);
      if (location.state.searchBltType === '1') setTabIndex(3);
      if (location.state.searchBltType === '2') setTabIndex(4);
      if (location.state.searchBltType === '0') setTabIndex(0);

      if (location.state.searchBltType !== '0')
        setTimeout(() => scrollRef.current.scrollIntoView({ behavior: 'smooth' }), 1000);
    }
    // else {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }, [location]);

  useEffect(() => {
    if (loginUser) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser, searchBltType, id]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-mycanvas">
          {/* 마이 캔버스 페이지일 경우 is-mycanvas */}

          {/* S : 프로필 */}
          <div className="cover-canvas">
            {/* S : 프로필 이미지, 이름, 생년월일 */}
            <div className="user-profile-cover">
              <div className="user-cover-img">
                <span className="chk-size">
                  <img
                    alt=""
                    // src={
                    //   canvas?.background_link
                    //     ? canvas.background_link
                    //     : Math.round(Math.random()) > 0
                    //     ? imgNoThumb01
                    //     : imgNoThumb02
                    // }
                    src={
                      canvas?.background_link
                        ? canvas.background_link
                        : canvas?.canvas_id % 2 === 0
                        ? imgNoThumb01
                        : imgNoThumb02
                    }
                    onClick={() =>
                      handleBackgroundImageView(canvas.background_link, canvas.canvas_id)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </span>
              </div>
              <div className="user-pf-thumb">
                <div className="user-pf-thumb-img">
                  <img
                    alt=""
                    src={canvas?.profile_link ? canvas.profile_link : ''}
                    onClick={() => handleImageView(canvas.profile_link)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>

            {/* S : 22-08-22 공유하기 버튼 팝업 띄우기로 수정 */}
            {/* S : 22-07-21 공유하기 버튼 추가 */}
            <div className="share-wrap">
              <button
                type="button"
                data-micromodal-trigger="modal-sns"
                onClick={() => setOpenCanvasShareDialog(true)}
              >
                <i className="ic ic-share-url"></i>
                <span className="offscreen">공유하기</span>
              </button>
            </div>
            {/* E : 22-08-22 공유하기 버튼 팝업 띄우기로 수정 */}

            <div className="user-name">
              {canvas?.title}
              <button
                type="button"
                className={`btn btn-sm ${canvas?.link_yn === 'Y' ? 'btn-lngray' : 'btn-lngreen'}`}
                onClick={() => toggleCanvasLink()}
              >
                {canvas?.link_yn === 'Y' ? '구독 중' : '+ 구독'}
              </button>
            </div>

            {canvas?.birthday === '0000.00.00' || canvas?.birthday === '' ? (
              ''
            ) : (
              <div className="user-birth">
                {/* S : 생년월일 */}
                <div className="user-birth-date">
                  <p>{canvas?.birthday}</p>
                </div>
                {/* E : 생년월일 */}
              </div>
            )}
            {/* E : 프로필 이미지, 이름, 생년월일 */}
            {/* S : 좌우명 */}
            <div className="user-motto">
              <p>{canvas?.motto}</p>
            </div>
            {/* E : 좌우명 */}

            {/* S : 구독자 수, 작성글 갯수 */}
            <div className="user-count">
              구독자 수 {linkCount}명 · 작성글 {bltCount}개
            </div>
            {/* E : 구독자 수, 작성글 갯수 */}

            {/* S : 22-08-22 질문 남기기 버튼 추가 */}
            {canvas?.question_display === '0' ||
            (canvas?.question_display === '1' && canvas?.link_yn === 'Y') ? (
              <div className="btn-wrap">
                <button
                  type="button"
                  className="btn btn-sm btn-lngray02"
                  data-micromodal-trigger="modal-qna"
                  onClick={() => setOpenUserQnaDialog(true)}
                >
                  질문 남기기
                </button>
              </div>
            ) : (
              ''
            )}
            {/* E : 22-08-22 질문 남기기 버튼 추가 */}

            {/* S : 자기소개 */}
            {canvas?.letter_contents && canvas.letter_contents.length > 0 && (
              <div className="user-introduce">
                <div className="user-history has-bullet">
                  {/* 타인 계정일 경우 has-bullet 추가하여 리스트에 디자인 입힘 */}
                  <ul>
                    {canvas.letter_contents.map(letter => (
                      <li key={letter.letter_num}>{letter.letter_contents}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {/* E : 자기소개 */}
          </div>
          {/* E : 프로필 */}
          <div ref={scrollRef} style={{ position: 'absolute', marginTop: '-100px' }}></div>
          {/* S : 탭 */}
          <div className="tabs tabs-scroll">
            <ul>
              <li className={tabIndex === 0 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(0)}>
                  전체
                </a>
              </li>
              <li className={tabIndex === 1 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(1)}>
                  버킷
                </a>
              </li>
              <li className={tabIndex === 2 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(2)}>
                  문답
                </a>
              </li>
              <li className={tabIndex === 3 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(3)}>
                  일상
                </a>
              </li>
              <li className={tabIndex === 4 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(4)}>
                  자서전
                </a>
              </li>
              <li className={tabIndex === 5 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(5)}>
                  구독
                </a>
              </li>
              {/* <li className={tabIndex === 6 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(6)}>
                  연결
                </a>
              </li> */}
            </ul>
          </div>
          {/* E : 탭 */}
          {tabIndex < 5 &&
            (canvasBlts && canvasBlts.length > 0 ? (
              canvasBlts.map(canvasBlt => (
                <>
                  {canvasBlt.blt_type === '1' && <UserLife canvasBlt={canvasBlt} canvas={canvas} />}
                  {canvasBlt.blt_type === '2' && (
                    <UserHistory canvasBlt={canvasBlt} canvas={canvas} />
                  )}
                  {canvasBlt.blt_type === '3' && <UserQna canvasBlt={canvasBlt} canvas={canvas} />}
                  {canvasBlt.blt_type === '4' && (
                    <UserBucketlist canvasBlt={canvasBlt} canvas={canvas} />
                  )}
                </>
              ))
            ) : (
              <div
                style={{
                  minHeight: '40vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                게시글이 없습니다.
              </div>
            ))}
          {tabIndex === 5 && <Subscribe canvasMemberId={canvas?.member_id} canvas={canvas} />}
        </div>
        {/* S : 더보기 버튼 */}
        {tabIndex < 5 && (
          <div className="btn-wrap">
            <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
              더보기 {page}/{totalPage}
            </button>
          </div>
        )}
        {/* E : 더보기 버튼 */}
        <div class="quick-btn-wrap">
          {/* S : 22-12-14 모바일 top 버튼 추가 */}
          <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
            <span className="offscreen">위로가기</span>
            <i className="ic ic-arrow-up"></i>
          </button>
          {/* E : 22-12-14 모바일 top 버튼 추가 */}
        </div>
      </div>
      {/* E : CONTENT */}
      {openUserQnaDialog && (
        <UserQnaDialog
          isOpen={openUserQnaDialog}
          onClose={() => setOpenUserQnaDialog(false)}
          canvas={canvas}
          reflash={reflash}
        />
      )}
      {openCanvasShareDialog && (
        <CanvasShareDialog
          isOpen={openCanvasShareDialog}
          onClose={() => setOpenCanvasShareDialog(false)}
          canvasId={id}
          profileLink={profileLink}
          title={title}
          motto={motto}
        />
      )}
      {openMediaViewDialog && (
        <ImagesDialog
          isOpen={openMediaViewDialog}
          onClose={() => setOpenMediaViewDialog(false)}
          image={image}
        />
      )}
    </section>
  );
}

export default UserCanvas;

import api from '@apis/api';
import configs from '@configs/configs';

export async function uploadApi(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('path', 'photo');
  const response = await api.post(`${configs.API_BASEURL}/openapi/aws/file/upload`, formData);
  return response.data;
}

export async function uploadImageApi(file, uploadPercentage) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('path', 'image');
  const response = await api.post(
    `${configs.API_BASEURL}/openapi/aws/file/image/upload`,
    formData,
    {
      onUploadProgress: progressEvent => {
        console.log(progressEvent);
        uploadPercentage(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
        return parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
      },
    },
  );
  return response.data;
}

export async function uploadVideoApi(file, uploadPercentage) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('path', 'video');
  const response = await api.post(
    `${configs.API_BASEURL}/openapi/aws/file/video/upload`,
    formData,
    {
      onUploadProgress: progressEvent => {
        console.log(progressEvent);
        uploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        return parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    },
  );
  return response.data;
}

export async function mGuestNotiApi(memorialId) {
  const response = await api.post(`${configs.API_BASEURL}/mGuest/noti?memorial_id=${memorialId}`);
  return response.data;
}

export async function notiAuthSendApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/openapi/noti/auth/send`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/openapi/noti/auth/send`, params);
  // return response.data;
}

export async function notiAuthVerifyApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/openapi/noti/auth/verify`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/openapi/noti/auth/verify`, params);
  // return response.data;
}

export async function notiAcountApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/openapi/noti/acount`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/openapi/noti/acount`, params);
  // return response.data;
}

export async function notiUploadCsvApi(params) {
  try {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('memorial_id', params.memorial_id);
    const response = await api.post(`${configs.API_BASEURL}/openapi/noti/upload/csv`, formData);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function mGuestsApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/mGuest`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/openapi/noti/acount`, params);
  // return response.data;
}

export async function notiMultySendApi(memorialId) {
  try {
    const response = await api.get(
      `${configs.API_BASEURL}/openapi/noti/multy/send?memorial_id=${memorialId}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/openapi/noti/acount`, params);
  // return response.data;
}

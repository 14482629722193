import { useState, useEffect } from 'react';
import { getMediasApi } from 'apis/comment';
import Pagination from 'react-js-pagination';

import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';

import useDialog from 'stores/dialog/hook';
import 'css/pagination.css';

function TabVideoContent({ memorialId }) {
  const { showAlert, hideAlert } = useDialog();
  const [searchMedias, setSearchMedias] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(6);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [media, setMedia] = useState(null);

  const getSearchMedias = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'V';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    const result = await getMediasApi(params);
    if (result.code === '0000') {
      setSearchMedias(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getSearchMediasMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'V';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    const result = await getMediasApi(params);
    if (result.code === '0000') {
      setSearchMedias([...searchMedias, ...result.data]);
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchMedias(selectedPage);
  };

  const handleMediaView = media => {
    setMedia(media);
    setOpenMediaViewDialog(true);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchMediasMore(nextPage);
    }
  };

  useEffect(() => {
    getSearchMedias(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div class="tab-contents">
      {searchMedias && searchMedias.length > 0 ? (
        <div class="list_video">
          <ul>
            {searchMedias.map(media => (
              <li key={media.media_id} onClick={() => handleMediaView(media)}>
                <img src={media.thumbnail_link} alt="" />
              </li>
            ))}
          </ul>

          <div class="btn-wrap">
            <button type="button" class="btn btn-more" onClick={() => handleMore(page)}>
              더보기 {page}/{totalPage}
            </button>
          </div>
          <MediaViewDialog
            isOpen={openMediaViewDialog}
            onClose={() => setOpenMediaViewDialog(false)}
            media={media}
          />
        </div>
      ) : (
        <div className="no_data">등록된 데이터가 없습니다.</div>
      )}
    </div>
  );
}

export default TabVideoContent;

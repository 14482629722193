import { createSelector, createSlice } from '@reduxjs/toolkit';

/**
 * 기본 State 생성
 */
const initialState = {
  loading: false,
  error: null,
  data: null,
  loginUser: null,
  openLogin: false,
};

/**
 * Redux-Toolkit Slice 생성
 */
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    emailLoginRequest: (state, _action) => {
      state.loading = true;
      state.error = null;
    },
    emailLoginSuccess: (state, action) => {
      if (action.payload.code === '0000') {
        const loginUser = {};
        loginUser.member_id = action.payload.dataMap.member_id;
        loginUser.email = action.payload.dataMap.email;
        loginUser.member_name = action.payload.dataMap.member_name;
        loginUser.login_type = action.payload.dataMap.login_type;
        loginUser.member_type = action.payload.dataMap.member_type;
        loginUser.canvas_id = action.payload.dataMap.canvas_id;
        loginUser.canvas_profile = action.payload.dataMap.canvas_profile;
        loginUser.canvas_title = action.payload.dataMap.canvas_title;
        loginUser.status = action.payload.dataMap.status;
        loginUser.mobile = action.payload.dataMap.mobile;

        // [TODO] authorities가 모두 ROLE_USER로 넘어옴
        // loginUser.authorities = action.payload.dataMap.authorities;
        loginUser.role =
          action.payload.dataMap.member_type === 'A' || action.payload.dataMap.member_type === 'E'
            ? 'ADMIN'
            : 'USER';

        window.localStorage.setItem('loginUser', JSON.stringify(loginUser));
        window.localStorage.setItem('accessToken', JSON.stringify(action.payload.access_token));

        state.loginUser = loginUser;
      } else {
        state.error = action.payload;
      }
      state.loading = false;
    },
    emailLoginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    kakaoLoginRequest: (state, _action) => {
      state.loading = true;
      state.error = null;
    },
    kakaoLoginSuccess: (state, action) => {
      if (action.payload.code === '0000') {
        const loginUser = {};
        loginUser.member_id = action.payload.dataMap.member_id;
        loginUser.email = action.payload.dataMap.email;
        loginUser.member_name = action.payload.dataMap.member_name;
        loginUser.login_type = action.payload.dataMap.login_type;
        loginUser.member_type = action.payload.dataMap.member_type;
        loginUser.canvas_id = action.payload.dataMap.canvas_id;
        loginUser.canvas_profile = action.payload.dataMap.canvas_profile;
        loginUser.canvas_title = action.payload.dataMap.canvas_title;
        // [TODO] authorities가 모두 ROLE_USER로 넘어옴
        // loginUser.authorities = action.payload.dataMap.authorities;
        loginUser.role =
          action.payload.dataMap.member_type === 'A' || action.payload.dataMap.member_type === 'E'
            ? 'ADMIN'
            : 'USER';

        window.localStorage.setItem('loginUser', JSON.stringify(loginUser));
        window.localStorage.setItem('accessToken', JSON.stringify(action.payload.access_token));

        state.loginUser = loginUser;
      } else {
        state.error = action.payload;
      }
      state.loading = false;
    },
    kakaoLoginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    naverLoginRequest: (state, _action) => {
      state.loading = true;
      state.error = null;
    },
    naverLoginSuccess: (state, action) => {
      if (action.payload.code === '0000') {
        const loginUser = {};
        loginUser.member_id = action.payload.dataMap.member_id;
        loginUser.email = action.payload.dataMap.email;
        loginUser.member_name = action.payload.dataMap.member_name;
        loginUser.login_type = action.payload.dataMap.login_type;
        loginUser.member_type = action.payload.dataMap.member_type;
        loginUser.canvas_id = action.payload.dataMap.canvas_id;
        loginUser.canvas_profile = action.payload.dataMap.canvas_profile;
        loginUser.canvas_title = action.payload.dataMap.canvas_title;
        // [TODO] authorities가 모두 ROLE_USER로 넘어옴
        // loginUser.authorities = action.payload.dataMap.authorities;
        loginUser.role =
          action.payload.dataMap.member_type === 'A' || action.payload.dataMap.member_type === 'E'
            ? 'ADMIN'
            : 'USER';

        window.localStorage.setItem('loginUser', JSON.stringify(loginUser));
        window.localStorage.setItem('accessToken', JSON.stringify(action.payload.access_token));

        state.loginUser = loginUser;
      } else {
        state.error = action.payload;
      }
      state.loading = false;
    },
    naverLoginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logoutRequest: (state, _action) => {
      window.localStorage.removeItem('loginUser');
      window.localStorage.removeItem('accessToken');
      const loginUser = window.localStorage.getItem('loginUser');
      loginUser ? (state.loginUser = JSON.parse(loginUser)) : (state.loginUser = {});
    },
    setOpenLoginRequest: (state, action) => {
      state.openLogin = action.payload;
    },
    getLoginUserRequest: (state, _action) => {
      state.loginUser = window.localStorage.getItem('loginUser');
    },
    checkUserAuthRequest: (state, _action) => {
      const loginUser = window.localStorage.getItem('loginUser');
      loginUser ? (state.loginUser = JSON.parse(loginUser)) : (state.loginUser = {});
    },
    checkAdminUserAuthRequest: (state, _action) => {
      const loginUser = window.localStorage.getItem('loginUser');
      loginUser ? (state.loginUser = JSON.parse(loginUser)) : (state.loginUser = {});
    },
    notiAuthSendRequest: (state, _action) => {
      state.loading = true;
      state.error = null;
    },
    notiAuthSendSuccess: (state, action) => {
      if (action.payload.code === '0000') {
        alert('인증코드를 입력하세요.');
      } else {
        alert(action.payload.message);
      }
      state.loading = false;
    },
    notiAuthSendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    notiAuthVerifyRequest: (state, _action) => {
      state.loading = true;
      state.error = null;
    },
    notiAuthVerifySuccess: (state, action) => {
      if (action.payload.code === '0000') {
        alert('인증 완료 입력하세요.');
      } else {
        alert(action.payload.message);
      }
      state.loading = false;
    },
    notiAuthVerifyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { reducer, actions } = loginSlice;
export const {
  emailLoginRequest,
  emailLoginSuccess,
  emailLoginFailure,
  kakaoLoginRequest,
  kakaoLoginSuccess,
  kakaoLoginFailure,
  naverLoginRequest,
  naverLoginSuccess,
  naverLoginFailure,
  logoutRequest,
  setOpenLoginRequest,
  getLoginUserRequest,
  checkUserAuthRequest,
  checkAdminUserAuthRequest,
  notiAuthSendRequest,
  notiAuthSendSuccess,
  notiAuthSendFailure,
  notiAuthVerifyRequest,
  notiAuthVerifySuccess,
  notiAuthVerifyFailure,
} = actions;

export const loginState = state => state.login;

export const loginUser = createSelector(loginState, login => {
  return login.loginUser;
});

export default reducer;

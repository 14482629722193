import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import { changePasswordApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function Password() {
  const navigate = useNavigate();

  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    member_old_pwd: Yup.string().required('필수 입력 항목입니다.'),
    member_pwd: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
        '패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
      ),
    member_pwd_confirm: Yup.string().oneOf(
      [Yup.ref('member_pwd'), null],
      '비밀번호가 일치하지 않습니다',
    ),
  }).required();

  const defaultValues = {
    email: loginUser.email,
    member_old_pwd: '',
    member_pwd: '',
    member_pwd_confirm: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async params => {
    const result = await changePasswordApi(params);

    if (result.code === '0000') {
      navigate('/life/settings/member');
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  // const handleClose = () => {
  //   reset();
  //   onClose();
  // };

  const onError = error => {
    console.log('error > ', error);
  };

  useEffect(() => {
    if (loginUser && loginUser.member_id) setValue('email', loginUser.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-setting">
          <div className="tabs tabs-setting">
            <ul>
              <li className="is-active">
                <a href={() => false}>회원설정</a>
              </li>
              <li>
                <a href={() => false}>캔버스 관리</a>
              </li>
            </ul>
          </div>

          <h2 className="offscreen">회원설정</h2>
          <h3>비밀번호 변경</h3>

          <div className="setting-form">
            <div className="ip-item is-error">
              <label for="user-password">현재 비밀번호</label>
              <input
                {...register('member_old_pwd')}
                aria-invalid={errors.member_old_pwd ? 'true' : 'false'}
                type="password"
                className="input"
                placeholder="현재 비밀번호를 입력해 주세요"
              />
              <p class="message">{errors.member_old_pwd && errors.member_old_pwd.message}</p>
            </div>
            <div className="ip-item is-error">
              <label for="user-re-password">새로운 비밀번호</label>
              <input
                {...register('member_pwd')}
                aria-invalid={errors.member_pwd ? 'true' : 'false'}
                type="password"
                className="input"
                placeholder="최소 8자 이상 16자 이하 영문, 숫자, 특수기호 포함"
              />
              <p class="message">{errors.member_pwd && errors.member_pwd.message}</p>
            </div>
            {/* S : 입력 전 */}
            <div className="ip-item is-error">
              <label for="user-re-password1">새로운 비밀번호 확인</label>
              <input
                {...register('member_pwd_confirm')}
                aria-invalid={errors.member_pwd_confirm ? 'true' : 'false'}
                type="password"
                className="input"
                placeholder="새 비밀번호를 다시 한 번 입력해주세요"
              />
              <p class="message">
                {errors.member_pwd_confirm && errors.member_pwd_confirm.message}
              </p>
            </div>
            {/* E : 입력 전 */}
            {/* S : 입력 후 */}
            {/* S : 입력 오류 시 */}
            {/* <div className="ip-item is-error">
              <label for="user-re-password2">새로운 비밀번호 확인</label>
              <input
                type="password"
                id="user-re-password2"
                placeholder="새 비밀번호를 다시 한 번 입력해주세요"
              />
              <p className="message">* 비밀번호가 일치하지 않습니다.</p>
            </div> */}
            {/* E : 입력 오류 시 */}
            {/* S : 입력 성공 시 */}
            {/* <div className="ip-item is-success">
              <label for="user-re-password3">새로운 비밀번호 확인</label>
              <input
                type="password"
                id="user-re-password3"
                placeholder="새 비밀번호를 다시 한 번 입력해주세요"
              />
            </div> */}
            {/* E : 입력 성공 시 */}
            {/* E : 입력 후 */}
          </div>
          <div className="btn-wrap column">
            <button
              type="button"
              onClick={handleSubmit(onSubmit, onError)}
              className="btn btn-blue"
              data-micromodal-trigger="modal-sample-01"
            >
              비밀번호 변경
            </button>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default Password;

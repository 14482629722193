import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import {
  toggleCanvasBltLikeApi,
  getCanvasBltCommentApi,
  saveCanvasBltCommentApi,
  deleteCanvasBltCommentApi,
  modifyCanvasBltApi,
  getCanvasBltsSubApi,
} from 'apis/canvasBlt';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useNavigate } from 'react-router-dom';
import MyQnaDialog from '../MyQnaDialog';
import useCommon from 'stores/common/hook';
import { getCanvasAlarmCountApi } from 'apis/canvas';

function MyQna({ canvasBlt, deletedCanvasBlt, canvasBltStatus, canvasBltStatusCount }) {
  const navigate = useNavigate();
  const schema = Yup.object({
    com_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_id: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    com_contents: '',
    blt_id: '',
  };

  const { register, setValue, getValues, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async data => {
    saveCanvasBltComment(data);
    const alarmCount = await getCanvasAlarmCountApi({
      read_yn: 'N',
      member_id: loginUser.member_id,
    });
    setAlarmCnt(alarmCount.data_cnt);
  };

  const onError = error => {
    console.log('error > ', error);
  };
  const [qnas, setQnas] = useState();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();
  const { loginUser } = useLogin();
  const { setAlarmCnt } = useCommon();

  const [isLike, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const [comments, setComments] = useState();

  const [selectedComment, setSelectedComment] = useState();
  const [openMyQnaDialog, setOpenMyQnaDialog] = useState(false);
  const [canvasId, setCanvasId] = useState();

  const toggleCanvasBltLike = async () => {
    if (!loginUser || !loginUser.member_id) {
      showLifeAlert('알림', '좋아요 기능은 로그인 후 이용가능합니다.', () => hideLifeAlert());
      return;
    }

    let params = {};
    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;

    const result = await toggleCanvasBltLikeApi(params);

    if (result.code === '0000') {
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
      setLikeCount(isLike ? likeCount - 1 : likeCount + 1);
      setLike(!isLike);
      const alarmCount = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      setAlarmCnt(alarmCount.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasBltComment = async () => {
    const params = {};
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.blt_id = canvasBlt.blt_id;

    const result = await getCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setComments(result.data);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const saveCanvasBltComment = async data => {
    let params = {};

    params = data;

    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;

    if (selectedComment) {
      params.up_blt_id = selectedComment.comment_id;
      params.comment_type = '2';
    }

    const result = await saveCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setValue('com_contents', '');
      setSelectedComment();
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const deleteCanvasBltComment = async commentId => {
    const result = await deleteCanvasBltCommentApi(commentId);

    if (result.code === '0000') {
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleCommentDelete = (commentId, type) => {
    switch (type) {
      case 'comment': {
        const isComment = comments.find(comment => comment.comment_id === commentId);
        if (isComment.sub_comment_list.length > 0) {
          showLifeConfirm(
            '삭제 확인',
            '해당 댓글의 답글이 모두 삭제됩니다. 삭제하시겠습니까?',
            () => confirmCommentDelete(commentId),
            () => hideLifeConfirm(),
          );
          return;
        }
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmCommentDelete(commentId),
          () => hideLifeConfirm(),
        );
        break;
      }
      case 'sub_comment': {
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmCommentDelete(commentId),
          () => hideLifeConfirm(),
        );
        break;
      }
      default: {
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmCommentDelete(commentId),
          () => hideLifeConfirm(),
        );
      }
    }
  };

  const confirmCommentDelete = commentId => {
    hideLifeConfirm();
    deleteCanvasBltComment(commentId);
  };

  const handleDelete = () => {
    showLifeConfirm(
      '삭제 확인',
      '정말 삭제하시겠습니까?',
      () => confirmDelete(),
      () => hideLifeConfirm(),
    );
  };

  const confirmDelete = () => {
    hideLifeConfirm();
    modifyCanvasBlt(getValues('blt_id'));
  };

  const modifyCanvasBlt = async id => {
    let params = {};
    params.blt_id = id;
    params.use_yn = 'N';

    const result = await modifyCanvasBltApi(params);

    if (result.code === '0000') {
      deletedCanvasBlt(id);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleModify = () => {
    navigate(`/life/everyone/faq/modify/${getValues('blt_id')}`);
  };

  const reflash = () => {
    // 팝업 닫고 리스트 다시 불러오고
    setOpenMyQnaDialog(false);
    getCanvasBltComment();
  };

  useEffect(() => {
    setValue('blt_id', canvasBlt?.blt_id);
    setLike(canvasBlt.like_yn === 'Y' ? true : false);
    setLikeCount(canvasBlt.like_count ? canvasBlt.like_count : 0);
    getCanvasBltComment();
    //console.log(JSON.stringify(canvasBlt));
    if (canvasBlt?.blt_contents) {
      const obj = JSON.parse(canvasBlt.blt_contents.replace(/(\n|\r\n)/g, '<br />'));
      setQnas(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasBlt]);

  return (
    <>
      <div className="canvas-post">
        <div className="canvas-inner">
          {/* S : 글 작성자 이름 */}
          <div className="canvas-head">
            <div className="user-info">
              <div className="user-thumb">
                {/*<span className="chk-size"><img alt="" src="https://image.shutterstock.com/z/stock-photo-smiling-indian-businessman-working-on-laptop-in-modern-office-lobby-space-young-indian-student-1935739078.jpg" /></span>*/}
                <span className="chk-size">
                  <img alt="" src={canvasBlt.profile_link ? canvasBlt.profile_link : ''} />
                </span>
              </div>
              <span className="name">{canvasBlt.title}</span>

              <span className="date">{canvasBlt.modifydtime}</span>
              <ul class="user-util">
                <li>
                  <button type="button" class="btn btn-sm-text" onClick={() => handleModify()}>
                    수정
                  </button>
                </li>
                <li>
                  <button type="button" class="btn btn-sm-text" onClick={() => handleDelete()}>
                    삭제
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* E : 글 작성자 정보 */}

          {/* S : 글 내용 */}

          <div className="canvas-content">
            {/* S : 분류 */}
            <div className="category">
              <i className="ic ic-cate-faq"></i>문답
            </div>
            {/* E : 분류 */}

            {/* S : 글 */}
            {qnas &&
              qnas.length > 0 &&
              qnas.map((qna, index) => (
                <div className="qna-content" key={index}>
                  <p className="qna-q">{qna.questions}</p>

                  <div class="qna-util">
                    <div class="qna-questioner">
                      <span class="date">{canvasBlt.createdtime}</span>
                      <span class="name">
                        {' '}
                        {canvasBlt.title !== canvasBlt.request_title
                          ? (canvasBlt.goalcomment ? canvasBlt.goalcomment : ' ') +
                            ' ' +
                            (canvasBlt.blt_anonymous_yn === 'N' ? canvasBlt.request_title : '익명')
                          : ' '}
                      </span>
                    </div>
                  </div>

                  <p className="qna-a">{parse(qna.answer)}</p>
                </div>
              ))}
            {/* E : 글 */}
          </div>
          {/* E : 글 내용 */}
          {/* S : 댓글 목록 */}
          <div className="canvas-cmt">
            {/* S : 좋아요 버튼 + 갯수 */}
            <div className="canvas-like">
              <button type="button" onClick={() => toggleCanvasBltLike()}>
                <i className={`ic ${isLike ? 'ic-canvas-like-active' : 'ic-canvas-like'}`}></i>
              </button>
              <span>{likeCount} </span>
            </div>
            {/* E : 좋아요 버튼 + 갯수 */}

            <div className="canvas-cmt-list">
              {/* S : 댓글 내용 */}
              {comments &&
                comments.length > 0 &&
                comments.map(comment => (
                  <div className="box-cmt">
                    <div className="user-info">
                      <div
                        className="user-thumb"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/life/canvas/user/${comment.canvas_id}`)}
                      >
                        <span className="chk-size">
                          <img alt="" src={comment.profile_link ? comment.profile_link : ''} />
                        </span>
                        {/*<span className="chk-size"><img src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg"></span>*/}
                      </div>
                      <span
                        className="name"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/life/canvas/user/${comment.canvas_id}`)}
                      >
                        {comment.title}
                      </span>
                      <span className="date">{comment.createdtime} </span>
                      {(loginUser?.member_id === comment.member_id ||
                        loginUser?.member_type === 'A') &&
                        comment.use_yn === 'Y' && (
                          <button
                            type="button"
                            className="btn-del-cmt"
                            onClick={() => handleCommentDelete(comment.comment_id, 'comment')}
                          >
                            <i className="ic ic-x"></i>
                            <span className="offscreen">삭제</span>
                          </button>
                        )}
                    </div>
                    <div className="cmt-content">
                      <p>{comment.com_contents}</p>
                      {comment.use_yn === 'Y' && (
                        <button
                          type="button"
                          className="btn-reply"
                          onClick={() => setSelectedComment(comment, 'sub_comment')}
                        >
                          답글달기
                        </button>
                      )}
                    </div>
                    {/* S : 댓글 내용 */}
                    {comment.sub_comment_list &&
                      comment.sub_comment_list.length > 0 &&
                      comment.sub_comment_list.map(subComment => (
                        <div className="box-cmt">
                          <div className="user-info">
                            <div
                              className="user-thumb"
                              style={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/life/canvas/user/${subComment.canvas_id}`)}
                            >
                              <span className="chk-size">
                                <img
                                  alt=""
                                  src={subComment.profile_link ? subComment.profile_link : ''}
                                />
                              </span>
                              {/*<span className="chk-size"><img src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg"></span>*/}
                            </div>
                            <span
                              className="name"
                              style={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/life/canvas/user/${subComment.canvas_id}`)}
                            >
                              {subComment.title}
                            </span>
                            <span className="date">{subComment.createdtime} </span>
                            {(loginUser?.member_id === subComment.member_id ||
                              loginUser?.member_type === 'A') &&
                              subComment.use_yn === 'Y' && (
                                <button
                                  type="button"
                                  className="btn-del-cmt"
                                  onClick={() => handleCommentDelete(subComment.comment_id)}
                                >
                                  <i className="ic ic-x"></i>
                                  <span className="offscreen">삭제</span>
                                </button>
                              )}
                          </div>
                          <div className="cmt-content">
                            <p>{subComment.com_contents}</p>
                            {/* <button type="button" className="btn-reply">
                            답글달기
                          </button> */}
                          </div>
                        </div>
                      ))}
                    {/* E : 댓글 내용 */}
                  </div>
                ))}
              {/* E : 댓글 내용 */}
            </div>
          </div>
          {/* E : 댓글 목록 */}
          {/* S : 댓글 쓰기 */}
          <div className="ip-wrap ip-user">
            {selectedComment?.member_name && (
              // <div>
              //   <span style={{ cursor: 'pointer' }} onClick={() => setSelectedComment()}>
              //     X&nbsp;
              //   </span>
              // </div>
              <button type="button" className="btn-cancel" onClick={() => setSelectedComment()}>
                <i className="ic ic-x-gray"></i>
                <span className="offscreen">댓글 달기 취소</span>
              </button>
            )}
            <div className="ip-item">
              <label for="ip-cmt2" className="offscreen">
                댓글 입력
              </label>
              <input
                {...register('com_contents')}
                type="text"
                id="ip-cmt2"
                placeholder={
                  loginUser?.member_id
                    ? selectedComment?.member_name
                      ? `${selectedComment?.title}님에게 답글`
                      : '댓글을 입력하세요'
                    : '로그인 후 이용가능합니다.'
                }
                maxlength="500"
              />
            </div>
            {loginUser?.member_id && (
              <button
                type="button"
                className="btn btn-text"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
            )}
          </div>
          {/* E : 댓글 쓰기*/}
        </div>
      </div>
      {openMyQnaDialog && (
        <MyQnaDialog
          canvasId={canvasId}
          isOpen={openMyQnaDialog}
          onClose={() => setOpenMyQnaDialog(false)}
          reflash={reflash}
        />
      )}
    </>
  );
}

export default MyQna;

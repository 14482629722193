import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import imgIntro01 from 'assets/life/images/contents/img_premium.png';
import imgIntro02 from 'assets/life/images/contents/img_premium_mo.png';

function ServicePremiumPage({ setMode }) {
  const navigate = useNavigate();

  const [isActive, setActive] = useState(false);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content about-service is-premium">
        <h2 className="contents-width">서비스 소개</h2>

        <div className="contents-width">
          <div className="tabs tabs-about">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('life')}>
                  라이프 캔버스란?
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('premuim')}>
                  자서전 프리미엄
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h3 className="title">라이프 캔버스 자서전은</h3>
            <p className="desc">
              본인이 남기고 싶은 삶에 대한 이야기를 담거나, 부모님의 소중한 인생을 기록하여 선물할
              수 있습니다.
              <br />
              자서전을 만들며 사랑하는 가족의 인생을 새롭게 알아가고 감사하는 시간을 갖습니다.
            </p>
          </div>

          <div className="wrap-premium">
            <div className="about-premium-service">
              <div className="img-premium">
                <img alt="" src={imgIntro01} className="d-block d-md-none" />
                <img alt="" src={imgIntro02} className="d-none d-md-block" />
              </div>
              <div className="text-premium">
                <h5>자서전 프리미엄 서비스는</h5>
                <p>
                  <em>완성도 있는 자서전을 만들고자 할 경우,</em>
                  <br />
                  에버영피플의 자서전 전문 작가의 도움을 받아 <br className="d-none d-md-block" />
                  제작하는 서비스 입니다.
                </p>
                <p className="desc">
                  준비물은 오직 진솔함입니다.
                  <br />
                  전문적으로 제작된 인터뷰와 질문지에 답변하며 진솔하게 본인의 삶을 이야기하세요.
                  <br />
                  때론 가슴아팠고 때론 찬란했던 나의 이야기를 영원히 이 세상에 남겨보세요.
                </p>
                <div className="btn-wrap">
                  <a
                    href={() => false}
                    onClick={() => navigate('/life/service/premium')}
                    className="btn btn-pos"
                  >
                    신청하기
                  </a>
                </div>
              </div>
              {/* S : 신청 페이지 바로가기*/}
              <div className="wrap-quick-btn">
                <button
                  type="button"
                  onClick={() => navigate('/life/service/premium')}
                  className="btn-quick-link"
                >
                  <span>신청</span>
                </button>
              </div>
              {/* E : 신청 페이지 바로가기*/}
            </div>

            <div className="wrap-process">
              <h5>제작방법</h5>

              <div className="contents-process">
                <p>인터뷰와 질문지를 기반으로 자서전 전문 작가가 제작합니다.</p>
                <div className="box-process">
                  <div className="row">
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">1</span>고객
                        <br />
                        상담
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">2</span>작가
                        <br />
                        배정
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">3</span>작가
                        <br />
                        인터뷰
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">4</span>자서전
                        <br />
                        제작
                      </div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">
                        <span className="num">5</span>결과물
                        <br />
                        완성
                      </div>
                    </div>
                  </div>
                </div>
                <ol className="box-process-detail">
                  <li>
                    1. 고객 상담
                    <ul>
                      <li>신청 버튼을 클릭하여 상담 신청 양식을 제출합니다.</li>
                      <li>입력하신 E-mail로 자서전 작성 준비물을 안내 받습니다.</li>
                    </ul>
                  </li>
                  <li>
                    2. 작가 배정
                    <ul>
                      <li>자서전 전문 작가를 배정 받습니다.</li>
                      <li>
                        자서전에 필요한 질문 답변지와 사진을 전달합니다.
                        <br />
                        방법 1. 라이프캔버스 문답 템플릿에 답변하고 주인공 사진 업로드 하기
                        <br />
                        방법 2. 에버영피플에서 전달해준 질문지 및 주인공 사진 송부하기
                      </li>
                    </ul>
                  </li>
                  <li>
                    3. 작가 인터뷰
                    <ul>
                      <li>전화/줌/대면인터뷰를 진행하며 자서전 내용을 구체화합니다.</li>
                    </ul>
                  </li>
                  <li>
                    4. 조문보 제작
                    <ul>
                      <li>자서전 주제, 핵심어를 고객과 협의하고 사진과 글을 구성합니다.</li>
                      <li>초안 검토 후 2회 수정 가능합니다.</li>
                    </ul>
                  </li>
                  <li>
                    5. 결과물 완성
                    <ul>
                      <li>라이프캔버스에 완성된 자서전이 게시됩니다.</li>
                      <li>소책자 3부가 컬러 인쇄로 제공됩니다.</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>

            <div className="wrap-process">
              <h5>서비스 문의</h5>
              <div className="contents-process">
                <div className="box-gray">
                  <dl>
                    <dt>제작 회사</dt>
                    <dd>㈜에버영피플</dd>
                    <dt>상담 시간</dt>
                    <dd>
                      월요일~금요일 08:00~15:00 <br className="d-none d-md-block" />
                      (점심시간 12:30 ~ 13:30)
                    </dd>
                    <dt>빠른 상담</dt>
                    <dd className="num">02-2138-8381 , 010-5483-1965</dd>
                    <dt>이메일</dt>
                    <dd>kyunghyukchang@everyoungpeople.com</dd>
                  </dl>
                  {/* <p>kyunghyukchang@everyoungpeople.com</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default ServicePremiumPage;

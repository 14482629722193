import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { routeParse } from '@utils';

import MainLayout from 'layouts/main/Layout';
import LifeCanvasLayout from 'layouts/life-canvas/Layout';
import MemorialCanvasLayout from 'layouts/memorial-canvas/Layout';
import MemorialCanvasBoardLayout from 'layouts/memorial-canvas-board/Layout';
import PetMemorialCanvasLayout from 'layouts/pet-memorial-canvas/Layout';

import MainPage from 'pages/memorial-canvas/main';

import ServicePage from 'pages/memorial-canvas/service';
import PremiumRegisterPage from 'pages/memorial-canvas/service/premiumRegister';
import PremiumServicePage from 'pages/memorial-canvas/service/premiumService';

import ObituaryPage from 'pages/memorial-canvas/obituary';
import ObituaryRegisterPage from 'pages/memorial-canvas/obituary/register';
import ObituaryModifyPage from 'pages/memorial-canvas/obituary/modify';
import ObituaryViewPage from 'pages/memorial-canvas/obituary/view';
import ObituaryViewBoardPage from 'pages/memorial-canvas/obituary/view2';

import MemorialPage from 'pages/memorial-canvas/memorial';
import MemorialRegisterPage from 'pages/memorial-canvas/memorial/register';
import MemorialModifyPage from 'pages/memorial-canvas/memorial/modify';
import MemorialViewPage from 'pages/memorial-canvas/memorial/view';
import MemorialViewBoardPage from 'pages/memorial-canvas/memorial/view2';
import MemorialViewCustomPage from 'pages/memorial-canvas/memorial/view3';

import TabCondolencesGroupContent from '../pages/memorial-canvas/obituary/components/TabCondolencesGroupContent';
import TabAlbumContent from '../pages/memorial-canvas/memorial/components/TabAlbumContent';
import TabChudosasContent from '../pages/memorial-canvas/obituary/components/TabChudosasContent';

import BltPage from 'pages/memorial-canvas/blt';

import FaqPage from 'pages/memorial-canvas/faq';

import MypageMemberPage from 'pages/memorial-canvas/mypage/member';
import MypageObituaryPage from 'pages/memorial-canvas/mypage/obituary';
import MypageMemorialPage from 'pages/memorial-canvas/mypage/memorial';

import WreathCalculatePage from 'pages/memorial-canvas/mypage/wreathCalculate';
import WreathCalculateResultPage from 'pages/memorial-canvas/mypage/wreathResult';

import LoginKakao from 'pages/memorial-canvas/login/kakao';
import LoginNaver from 'pages/memorial-canvas/login/naver';
import LoginPage from 'layouts/memorial-canvas/Login/Login';
import FindIdPage from 'layouts/memorial-canvas/Login/FindId';
import FindPwPage from 'layouts/memorial-canvas/Login/FindPw';
import RegisterPage from 'layouts/memorial-canvas/Login/Register';
import RegisterDefaultPage from 'layouts/memorial-canvas/Login/RegisterDefault';

import KiccPayment from 'pages/memorial-canvas/kicc/payment';
import KiccPaymentResult from 'pages/memorial-canvas/kicc/result';

import PolicyPrivacyPage from 'pages/memorial-canvas/policy/privacy';
import PolicyServicePage from 'pages/memorial-canvas/policy/service';

import ObituaryWreathPage from 'pages/memorial-canvas/obituary/wreath';
import ObituaryWreathResultPage from 'pages/memorial-canvas/obituary/wreathResult';

import SearchPage from 'pages/memorial-canvas/search';

/* 라이프캔버스 router */

import LifeLoginKakao from 'pages/life-canvas/login/kakao';
import LifeLoginNaver from 'pages/life-canvas/login/naver';

import CanvasServicePage from 'pages/life-canvas/service';
import CanvasPremiumRegisterPage from 'pages/life-canvas/service/premiumRegister';
import CanvasPremiumServicePage from 'pages/life-canvas/service/premiumService';

import LifeLogin from 'pages/life-canvas/login';
import LifeLoginRegister from 'pages/life-canvas/login/register';
import DefaultRegister from 'pages/life-canvas/login/components/DefaultRegister';
import FindId from 'pages/life-canvas/login/components/FindId';
import ResetPw from 'pages/life-canvas/login/components/ResetPw';

import LifeCanvas from 'pages/life-canvas';
import Everyone from 'pages/life-canvas/everyone';
import EveryoneBucketlistRegister from 'pages/life-canvas/everyone/bucketlist-register';
import EveryoneBucketlistModify from 'pages/life-canvas/everyone/bucketlist-modify';
import EveryoneDailyRegister from 'pages/life-canvas/everyone/daily-register';
import EveryoneDailyModify from 'pages/life-canvas/everyone/daily-modify';

import EveryoneFaqRegister from 'pages/life-canvas/everyone/faq-register';
import EveryoneFaqModify from 'pages/life-canvas/everyone/faq-modify';

import EveryoneHistoryRegister from 'pages/life-canvas/everyone/history-register';
import EveryoneHistoryModify from 'pages/life-canvas/everyone/history-modify';

import MyCanvas from 'pages/life-canvas/canvas/my';
import UserCanvas from 'pages/life-canvas/canvas/user';
import StoryCanvas from 'pages/life-canvas/canvas/story';

import LifeFaqPage from 'pages/life-canvas/faq';

/* 펫 메모리얼 */
import PetMemorialCanvas from '../pages/pet-memorial-canvas/main';

import PetServicePage from 'pages/pet-memorial-canvas/service';
import PetPremiumRegisterPage from 'pages/pet-memorial-canvas/service/premiumRegister';
import PetPremiumServicePage from 'pages/pet-memorial-canvas/service/premiumService';
import PetFuneralRegisterPage from 'pages/pet-memorial-canvas/service/funeralRegister';

import PetObituaryPage from 'pages/pet-memorial-canvas/obituary';
import PetObituaryRegisterPage from 'pages/pet-memorial-canvas/obituary/register';
import PetObituaryModifyPage from 'pages/pet-memorial-canvas/obituary/modify';
import PetObituaryViewPage from 'pages/pet-memorial-canvas/obituary/view';
import PetObituaryViewBoardPage from 'pages/pet-memorial-canvas/obituary/view2';

import PetMemorialPage from 'pages/pet-memorial-canvas/memorial';
import PetMemorialRegisterPage from 'pages/pet-memorial-canvas/memorial/register';
import PetMemorialModifyPage from 'pages/pet-memorial-canvas/memorial/modify';
import PetMemorialViewPage from 'pages/pet-memorial-canvas/memorial/view';
import PetMemorialViewBoardPage from 'pages/pet-memorial-canvas/memorial/view2';

import PetTabCondolencesGroupContent from '../pages/pet-memorial-canvas/obituary/components/TabCondolencesGroupContent';
import PetTabAlbumContent from '../pages/pet-memorial-canvas/memorial/components/TabAlbumContent';
import PetTabChudosasContent from '../pages/pet-memorial-canvas/obituary/components/TabChudosasContent';

import PetBltPage from 'pages/pet-memorial-canvas/blt';

import PetFaqPage from 'pages/pet-memorial-canvas/faq';

import PetMypageMemberPage from 'pages/pet-memorial-canvas/mypage/member';
import PetMypageObituaryPage from 'pages/pet-memorial-canvas/mypage/obituary';
import PetMypageMemorialPage from 'pages/pet-memorial-canvas/mypage/memorial';
import PetMypageWreathPage from 'pages/pet-memorial-canvas/mypage/wreath';

import PetWreathCalculatePage from 'pages/pet-memorial-canvas/mypage/wreathCalculate';
import PetWreathCalculateResultPage from 'pages/pet-memorial-canvas/mypage/wreathResult';

import PetLoginKakao from 'pages/pet-memorial-canvas/login/kakao';
import PetLoginNaver from 'pages/pet-memorial-canvas/login/naver';
import PetLoginPage from 'layouts/pet-memorial-canvas/Login/Login';
import PetFindIdPage from 'layouts/pet-memorial-canvas/Login/FindId';
import PetFindPwPage from 'layouts/pet-memorial-canvas/Login/FindPw';
import PetRegisterPage from 'layouts/pet-memorial-canvas/Login/Register';
import PetRegisterDefaultPage from 'layouts/pet-memorial-canvas/Login/RegisterDefault';

import PetKiccPayment from 'pages/pet-memorial-canvas/kicc/payment';
import PetKiccPaymentResult from 'pages/pet-memorial-canvas/kicc/result';

import PetPolicyPrivacyPage from 'pages/pet-memorial-canvas/policy/privacy';
import PetPolicyServicePage from 'pages/pet-memorial-canvas/policy/service';

import PetObituaryWreathPage from 'pages/pet-memorial-canvas/obituary/wreath';
import PetObituaryWreathResultPage from 'pages/pet-memorial-canvas/obituary/wreathResult';

import PetSearchPage from 'pages/pet-memorial-canvas/search';

import PetCommunityPage from 'pages/pet-memorial-canvas/community';
import PetStoryRegisterPage from 'pages/pet-memorial-canvas/community/storyRegister';
import PetStoryViewPage from 'pages/pet-memorial-canvas/community/storyView';
import PetStoryModifyPage from 'pages/pet-memorial-canvas/community/storyModify';
import PetDonationRegisterPage from 'pages/pet-memorial-canvas/community/donationRegister';
import PetDonationViewPage from 'pages/pet-memorial-canvas/community/donationView';
import PetDonationModifyPage from 'pages/pet-memorial-canvas/community/donationModify';

/* 공통 */
import IndexPage from 'pages/index';

/* Settings */
import SettingsMember from 'pages/life-canvas/settings/member';
import SettingsPassword from 'pages/life-canvas/settings/password';
import SettingsWithdrawal from 'pages/life-canvas/settings/withdrawal';
import SettingsCanvas from 'pages/life-canvas/settings/canvas';
import SettingsMyCanvas from 'pages/life-canvas/settings/change-mycanvas';
import SettingsLinkedCanvas from 'pages/life-canvas/settings/change-linkedcanvas';
import SettingsAlarm from 'pages/life-canvas/settings/alarm';

import ChumoRedirect from '../pages/chumo-redirect';

const userRoutes = [
  {
    path: '/memorial',
    element: MemorialCanvasLayout,
    children: [
      { path: '', element: MainPage },

      { path: 'login', element: LoginPage },
      { path: 'login/register', element: RegisterPage },
      { path: 'login/register/info', element: RegisterDefaultPage },
      { path: 'login/findid', element: FindIdPage },
      { path: 'login/findpw', element: FindPwPage },

      { path: 'search', element: SearchPage },

      { path: 'policy/privacy', element: PolicyPrivacyPage },
      { path: 'policy/service', element: PolicyServicePage },

      { path: 'service', element: ServicePage },
      { path: 'service/premium', element: PremiumServicePage },
      { path: 'service/premium/register', element: PremiumRegisterPage },

      { path: 'obituary', element: ObituaryPage },
      { path: 'obituary/register', element: ObituaryRegisterPage },
      { path: 'obituary/modify/:memorialId', element: ObituaryModifyPage },
      {
        path: 'obituary/view/:memorialId',
        element: ObituaryViewPage,
      },
      {
        path: 'obituary/view/:memorialId/:groupId',
        element: ObituaryViewPage,
      },
      { path: 'obituary/wreath', element: ObituaryWreathPage },
      { path: 'obituary/wreath/result', element: ObituaryWreathResultPage },

      { path: 'memorial', element: MemorialPage },
      { path: 'memorial/register', element: MemorialRegisterPage },
      { path: 'memorial/modify/:memorialId', element: MemorialModifyPage },
      { path: 'memorial/view/:memorialId', element: MemorialViewPage },
      { path: 'memorial/view/:memorialId/:groupId', element: MemorialViewPage },
      { path: 'memorial/views/:memorialId', element: MemorialViewCustomPage },
      { path: 'memorial/views/:memorialId/:groupId', element: MemorialViewCustomPage },

      { path: 'letter/:memorialId', element: TabCondolencesGroupContent },
      { path: 'album/:memorialId', element: TabAlbumContent },

      { path: 'blt', element: BltPage },
      { path: 'chudosa/:memorialId', element: TabChudosasContent },

      { path: 'faq', element: FaqPage },

      { path: 'mypage/member', element: MypageMemberPage },
      { path: 'mypage/obituary', element: MypageObituaryPage },
      { path: 'mypage/memorial', element: MypageMemorialPage },

      { path: 'mypage/wreath/:memorialId', element: WreathCalculatePage },
      { path: 'mypage/wreath/result/:memorialId', element: WreathCalculateResultPage },
    ],
  },
  { path: '/login/kakao', element: LoginKakao },
  { path: '/login/naver', element: LoginNaver },
  // { path: 'login/register/info', element: DefaultRegister },
  { path: '/kicc/pay/result/:id', element: KiccPayment },
  { path: '/kicc/payment/result/:id', element: KiccPaymentResult },
  // { path: 'login', element: LifeLogin },
  // { path: 'login/register', element: LifeLoginRegister },
  // { path: 'login/findid', element: FindId },
  // { path: 'login/findpw', element: FindPw },

  {
    path: '/life',
    element: LifeCanvasLayout,
    children: [
      { path: '', element: LifeCanvas },
      { path: 'service', element: CanvasServicePage },
      { path: 'service/premium', element: CanvasPremiumServicePage },
      { path: 'service/premium/register', element: CanvasPremiumRegisterPage },
      { path: 'everyone', element: Everyone },
      { path: 'everyone/bucketlist/register', element: EveryoneBucketlistRegister },
      { path: 'everyone/bucketlist/modify/:id', element: EveryoneBucketlistModify },
      { path: 'everyone/daily/register', element: EveryoneDailyRegister },
      { path: 'everyone/daily/modify/:id', element: EveryoneDailyModify },

      { path: 'everyone/faq/register', element: EveryoneFaqRegister },
      { path: 'everyone/faq/modify/:id', element: EveryoneFaqModify },

      { path: 'everyone/history/register', element: EveryoneHistoryRegister },
      { path: 'everyone/history/modify/:id', element: EveryoneHistoryModify },

      { path: 'canvas/my', element: MyCanvas },
      { path: 'canvas/user/:id', element: UserCanvas },
      { path: 'canvas/story', element: StoryCanvas },
      { path: 'login/kakao', element: LifeLoginKakao },
      { path: 'login/naver', element: LifeLoginNaver },
      { path: 'login', element: LifeLogin },
      { path: 'login/register', element: LifeLoginRegister },
      { path: 'login/register/info', element: DefaultRegister },
      { path: 'login/findid', element: FindId },
      { path: 'login/resetpw', element: ResetPw },
      { path: 'settings/member', element: SettingsMember },
      { path: 'settings/password', element: SettingsPassword },
      { path: 'settings/withdrawal', element: SettingsWithdrawal },
      { path: 'settings/canvas', element: SettingsCanvas },
      { path: 'settings/canvas/my', element: SettingsMyCanvas },
      { path: 'settings/canvas/link', element: SettingsLinkedCanvas },
      { path: 'settings/alarm', element: SettingsAlarm },

      { path: 'faq', element: LifeFaqPage },
    ],
  },
  {
    path: '/pet',
    element: PetMemorialCanvasLayout,
    children: [
      { path: '', element: PetMemorialCanvas },

      { path: 'login', element: PetLoginPage },
      { path: 'login/register', element: PetRegisterPage },
      { path: 'login/register/info', element: PetRegisterDefaultPage },
      { path: 'login/findid', element: PetFindIdPage },
      { path: 'login/findpw', element: PetFindPwPage },

      { path: 'search', element: PetSearchPage },

      { path: 'policy/privacy', element: PetPolicyPrivacyPage },
      { path: 'policy/service', element: PetPolicyServicePage },

      { path: 'service', element: PetServicePage },
      { path: 'service/premium', element: PetPremiumServicePage },
      { path: 'service/premium/register', element: PetPremiumRegisterPage },
      { path: 'service/funeral/register', element: PetFuneralRegisterPage },

      { path: 'obituary', element: PetObituaryPage },
      { path: 'obituary/register', element: PetObituaryRegisterPage },
      { path: 'obituary/modify/:memorialId', element: PetObituaryModifyPage },
      {
        path: 'obituary/view/:memorialId',
        element: PetObituaryViewPage,
      },
      {
        path: 'obituary/view/:memorialId/:groupId',
        element: PetObituaryViewPage,
      },
      { path: 'obituary/wreath', element: PetObituaryWreathPage },
      { path: 'obituary/wreath/result', element: PetObituaryWreathResultPage },

      { path: 'memorial', element: PetMemorialPage },
      { path: 'memorial/register', element: PetMemorialRegisterPage },
      { path: 'memorial/modify/:memorialId', element: PetMemorialModifyPage },
      { path: 'memorial/view/:memorialId', element: PetMemorialViewPage },
      { path: 'memorial/view/:memorialId/:groupId', element: PetMemorialViewPage },

      { path: 'letter/:memorialId', element: PetTabCondolencesGroupContent },
      { path: 'album/:memorialId', element: PetTabAlbumContent },

      { path: 'blt', element: PetBltPage },
      { path: 'chudosa/:memorialId', element: PetTabChudosasContent },

      { path: 'faq', element: PetFaqPage },

      { path: 'mypage/member', element: PetMypageMemberPage },
      { path: 'mypage/obituary', element: PetMypageObituaryPage },
      { path: 'mypage/memorial', element: PetMypageMemorialPage },
      { path: 'mypage/wreath', element: PetMypageWreathPage },

      { path: 'mypage/wreath/:memorialId', element: PetWreathCalculatePage },
      { path: 'mypage/wreath/result/:memorialId', element: PetWreathCalculateResultPage },

      { path: 'community', element: PetCommunityPage },
      { path: 'community/register', element: PetStoryRegisterPage },
      { path: 'community/modify/:bltId', element: PetStoryModifyPage },
      { path: 'community/view/:bltId', element: PetStoryViewPage },
      { path: 'community/donation/register', element: PetDonationRegisterPage },
      { path: 'community/donation/modify/:bltId', element: PetDonationModifyPage },
      { path: 'community/donation/view/:bltId', element: PetDonationViewPage },

      { path: 'login/naver', element: PetLoginNaver },
    ],
  },

  {
    path: '/',
    element: MainLayout,
    children: [{ path: '', element: IndexPage }],
  },
  {
    path: '/board',
    element: MemorialCanvasBoardLayout,
    children: [
      { path: 'memorial/obituary/view/:memorialId', element: ObituaryViewBoardPage },
      { path: 'memorial/view/:memorialId', element: MemorialViewBoardPage },
    ],
  },
  {
    path: '/dw',
    element: ChumoRedirect,
  },
];

const UserRoutes = () => {
  const elements = useRoutes(routeParse(userRoutes));
  return <Suspense fallback={<div />}>{elements}</Suspense>;
};

export default UserRoutes;

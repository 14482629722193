import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

function LinkCanvasDialog({ isOpen, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-about-connect" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">연결 기능이란?</h2>
                </div>
                <div className="modal__content">
                  <p>
                    연결 관계를 맺을 경우 연결 대상은 회원님이 고인이 된 이후 회원님이 작성한
                    자서전을 활용하여 회원님을 추모하는 조문보를 작성할 수 있습니다.
                  </p>
                  <hr />
                  <p>
                    <strong>연결 가능 대상</strong>
                  </p>
                  <p>
                    내가 구독한 캔버스 계정 목록에서만 연결 요청 가능합니다. 연결하고자 하는 계정이
                    있을 경우 해당 계정을 먼저 찾아 구독하신 후, 연결 하시면 됩니다.
                  </p>
                  <hr />
                  <p>
                    <strong>연결 대상자 권한</strong>
                  </p>
                  <p>
                    회원님이 남긴 자서전을 활용하여 연결 계정은 회원님의 조문보를 작성할 수
                    있습니다. 자서전으로 등록한 글만 활용할 수 있으며, 자서전 글은 비공개로
                    등록하더라도 연결 대상자가 메모리얼 캔버스 등록 시 활용할 수 있습니다.
                  </p>
                  <hr />
                  <p className="sm">
                    * 일상, 문답, 버킷리스트 비공개 글은 연결 계정이 확인 및 활용할 수 없습니다.
                  </p>
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      확인
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default LinkCanvasDialog;
